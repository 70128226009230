import axios from 'axios';

const TAG = 'base request';


export default async function listBase(url, options, retries = 3, backoff = 300) {
  return new Promise(((resolve, reject) => {
    axios.get(url, options)
      .then(response => resolve(response.data))
      .catch((error) => {
        if (retries === 1) {
          return reject(error);
        }
        if (retries > 1) {
          return resolve(listBase(url, options, retries - 1, backoff));
        }
        return resolve([]);
      });
  }));
}
