import style from '~/appStyles';
import { Image, StyleSheet, Text, View } from 'react-native';
import React from 'react';

type Props = {
  imageSrc: any,
  imageStyle: any,
  containerStyle: any,
  text: string,
  textStyle: any
}

const styles = StyleSheet.create({
  icon: {
    width: 14,
    height: 14,
    marginRight: 8,
  },
  text: {
    flex: 1,
  },
});

class IconText extends React.Component<Props, void> {
  render() {
    const { containerStyle, imageSrc, imageStyle, text, textStyle } = this.props;

    return (
      <View style={[style.rowDirection, containerStyle]}>
        <Image resizeMode="contain" source={imageSrc} style={[styles.icon, imageStyle]} />
        <Text style={[styles.text, textStyle]} numberOfLines={1}>{text} </Text>
      </View>);
  }
}

export default IconText;
