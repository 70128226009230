// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import TabBottomRoutes from './tabBottom.routes';
import { useAuth } from '~/hooks/auth';
import NetInfo from '@react-native-community/netinfo';
import setCurrentNetInfo from '~/store/setCurrentNetInfo/action';
import { useDispatch } from 'react-redux';
import { SCREEN_LOGIN } from '~/screens/screens';
import SignScreen from '~/screens/Login';
import setCurrentAccount from '~/store/setCurrentAccount/action';


const RootNavigation = createStackNavigator();

const RootStack = () => {
  return (
    <RootNavigation.Navigator screenOptions={{
      headerShown: false,
    }}>
      <RootNavigation.Screen name="Root" component={TabBottomRoutes}/>
      <RootNavigation.Group
        screenOptions={{
          presentation: 'modal',
          headerShown: true,
        }}>
        <RootNavigation.Screen name={SCREEN_LOGIN.name} component={SignScreen}/>
      </RootNavigation.Group>
    </RootNavigation.Navigator>
  );
};

function AppNavigation() {
  const { dataAccount } = useAuth();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    NetInfo.addEventListener(state => {
      const netInfo = {
        type: state.type,
        is_connected: state.isConnected,
      };

      dispatch(setCurrentNetInfo(netInfo));
      dispatch(setCurrentAccount(dataAccount));
      setLoading(false);
    });
  }, [dataAccount]);

  if (loading) {
    return <></>;
  }

  return RootStack();
};

export default AppNavigation;
