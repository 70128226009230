import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { LOCATION_REQUEST, success, failure } from './action';

const requestLocation = () =>
  Observable.fromPromise(new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((geo) => {
      if (geo) {
        const data = {
          latitude: geo.coords.latitude,
          longitude: geo.coords.longitude,
        };
        resolve(data);
        return data;
      }
      reject();
      return null;
    }, { enableHighAccuracy: true });
  }));

const productsList = (action$: any) =>
  action$
    .ofType(LOCATION_REQUEST)
    .mergeMap(() => requestLocation()
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(productsList);
