/* @flow */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { AsyncStorage, NativeModules, Platform } from 'react-native';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';
import Config from 'react-native-config';
import isEmpty from 'lodash/isEmpty';

export const VERSION = 1;
export const ENDPOINT = Config.ENDPOINT || 'https://prod.izicash.app/v3/api';
// export const ENDPOINT = 'http://192.168.15.29:8080/bsboard3/v3/api';
export const ENDPOINTLOGIN = Config.ENDPOINTLOGIN || 'https://eauth.enovar.app/eauth';

const initialOptions = {
  headers: {},
  onCloseError: () => {},
  retries: 3,
  timeout: 30000,
};

const onError = (err, onClose = () => {}) => Observable.throw({ ...err.xhr.response, onClose });

const onSuccess = response => {
  console.log(response);
  return response;
};

export const endpoint = (URI: string = '', isLogin: boolean = false) => {
  const currentEndpoint = (() => (isLogin ? ENDPOINTLOGIN : ENDPOINT))();
  const mobile = URI.includes('?') ? '&mobile=true' : '?mobile=true';
  return `${currentEndpoint}${URI}${mobile}`;
};

function removeToken() {
  AsyncStorage.removeItem('@Session:authenticationToken');
}

export const ajaxRequest = (method, uri, data = null, options = {}, isLogin = false) => {
  const {
    headers, onCloseError, retries, timeout,
  } = Object.assign({}, initialOptions, options);

  return ajax({
    method,
    timeout,
    body: isEmpty(data) ? null : data,
    responseType: 'json',
    dataType: 'json',
    crossDomain: false,
    url: endpoint(uri, isLogin),
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
    .catch(err => Observable.throw(err))
    .retryWhen(err =>
      err
        .flatMap((error: any) => {
          console.log(error);
          if ([400, 404, 500].indexOf(error.status) !== -1) {
            return Observable.throw(error);
          } else if (error.status === 0 && Platform.OS === 'ios') {
            try {
              NativeModules.CacheURLCleaner.clean();
            } catch (e) {
              console.log('Not possible to clean ios cache');
            }
          } else if (error.status === 403 || error.status === 401) {
            // Navigator.showLogin();
            removeToken();
            return Observable.throw(error);
          }
          return Observable.of(error.status).delay(1000);
        })
        .take(retries)
        .concat(Observable.throw({
          xhr: {
            response: { messages: [{ text: 'Sem conexão com o servidor.' }], resource: {} },
          },
        })))
    .catch(err => onError(err, onCloseError))
    .map(onSuccess);
};

export default (method, uri, data = null, options = {}, isLogin = false) =>
  Observable.of({})
    .mergeMap(() => {
      if (process.env.NODE_ENV === 'test') return Observable.of('string');
      return Observable.fromPromise(AsyncStorage.getItem('@Session:authenticationToken'));
    })
    .mergeMap((token: string) => {
      const headers = options.headers || {};

      if (typeof token === 'string') headers.Authorization = `Bearer ${token}`;

      console.log('Ajax request', token, uri, data);

      return ajaxRequest(method, uri, data, Object.assign(options, { headers }), isLogin);
    });
