import Controller from "~/controllers/Controller";
import PaymentOptionRepository from "~/database/repositories/PaymentOptionRepository";

export default class PaymentOptionController extends Controller {
  constructor() {
    super();
    this.repository = new PaymentOptionRepository();
  }
  addPaymentOption(pay) {
    return this.repository.addPaymentOption(pay);
  }
  getAllPaymentOption() {
    return this.repository.getAllPaymentOption();
  }
}
