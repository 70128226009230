import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderRadius: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    height: 80,
    width: 80,
    resizeMode: 'contain',
    marginVertical: 10,
  },
  infoContainer: {
    flex: 1,
    padding: 16,
  },
  nameLikeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  name: {
    color: colors.grayDarkest,
    fontSize: 16,
    fontWeight: '300',
    marginRight: 6,
    flex: 1,
  },
  startingAt: {
    fontSize: 12,
    color: colors.grayMid,
  },
});

export default styles;
