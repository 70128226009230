import React, { Component } from 'react';
import { View, TextInput } from 'react-native';
import Masked from 'vanilla-masker';
import timer from 'react-native-timer';

import { Button } from '~/components';
import Images from '~/../assets/images';
import styles from './styles';
import { validateQuantity } from '~/utils/product';

type Props = {
  quantity?: number,
  maxQuantity?: number,
  minQuantity?: number,
  multiple?: number,
  onChangeQuantity?: (quantity: number) => void,
  validateNewQuantity?: (quantity: number) => void,
  inputStyle?: {},
  style?: {},
  disabled?: boolean,
};

class Quantity extends Component<Props> {
  constructor(props) {
    super(props);

    const {
      quantity, multiple, minQuantity, maxQuantity,
    } = this.props;

    this.state = {
      quantity: validateQuantity(quantity, multiple, minQuantity, maxQuantity),
    };
  }

  componentDidUpdate({ quantity }): void {
    (() => {
      if (quantity === this.props.quantity) return;
      if (this.state.quantity === this.props.quantity) return;
      this.setState({ quantity: this.props.quantity });
    })();
  }

  onChangeTextQuantity = quantity => {
    let qty = quantity;
    if (quantity) {
      qty = Number.parseInt(Masked.toNumber(quantity));
    } else {
      qty = 0;
    }
    this.setState({ quantity: qty || 0 });
  };

  onEndEditingQuantity = () => {
    const {
      validateNewQuantity, multiple, minQuantity, maxQuantity,
    } = this.props;

    const qty = validateQuantity(this.state.quantity, multiple, minQuantity, maxQuantity);

    if (validateNewQuantity && validateNewQuantity(qty)) {
      this.setState({ quantity: qty }, () => {
        this.props.onChangeQuantity(qty);
      });
    } else {
      this.setState({ quantity: qty }, () => {
        this.props.onChangeQuantity(qty);
      });
    }
  };

  addRemoveClick(qty) {
    const {
      validateNewQuantity, multiple, minQuantity, maxQuantity,
    } = this.props;
    let quantity = this.state.quantity + qty;
    quantity = validateQuantity(quantity, multiple, minQuantity, maxQuantity);

    timer.clearTimeout(this);
    if (quantity >= 0) {
      if (validateNewQuantity && validateNewQuantity(quantity)) {
        const self = this;
        this.setState({ quantity }, () => {
          timer.setTimeout(
            self,
            'add',
            () => {
              self.props.onChangeQuantity(quantity);
            },
            400,
          );
        });
      } else {
        const self = this;
        this.setState({ quantity }, () => {
          timer.setTimeout(
            self,
            'add',
            () => {
              self.props.onChangeQuantity(quantity);
            },
            400,
          );
        });
      }
    }
  }

  render() {
    const {
      multiple, maxQuantity, minQuantity, style, inputStyle, disabled,
    } = this.props;
    const { quantity } = this.state;

    return (
      <View style={[styles.rootContainer, style]}>
        <Button
          style={styles.buttonQuantity}
          iconLeft={Images.iconMinus}
          disabled={disabled || quantity <= minQuantity}
          iconButton
          onPress={() => this.addRemoveClick(-multiple)}
        />
        <TextInput
          value={`${this.state.quantity}`}
          style={[styles.input, inputStyle]}
          editable={!disabled}
          onChangeText={this.onChangeTextQuantity}
          onEndEditing={this.onEndEditingQuantity}
          keyboardType="numeric"
        />
        <Button
          style={styles.buttonQuantity}
          iconLeft={Images.iconPlus}
          disabled={disabled || quantity >= maxQuantity}
          iconButton
          onPress={() => this.addRemoveClick(multiple)}
        />
      </View>
    );
  }
}

Quantity.defaultProps = {
  onChangeQuantity: () => {},
  maxQuantity: Number.MAX_SAFE_INTEGER,
  minQuantity: 0,
  multiple: 1,
  quantity: 0,
};

export default Quantity;
