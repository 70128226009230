import React from 'react';
import { View } from 'react-native-web';

const Spinner = () => {
  return (
    <>

    </>
  )
}


export default Spinner;
