import Repository from "./Repository";
import { PAYMENT_TERM_SCHEMA } from "../schemas";
import SQLiteManager from '../SQLiteManager';

export default class PaymentTermRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PAYMENT_TERM_SCHEMA;
  }

  addPaymentTerm(pay) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addPaymentTerm(pay).then(paymentTerm => {
        resolve(paymentTerm);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllPaymentTerm() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllPaymentTerm().then(paymentTerm => {
        resolve(paymentTerm);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
