import React from 'react';
import { View } from 'react-native';
import {Placeholder, PlaceholderMedia } from 'rn-placeholder';

import colors from '../../values/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeCategoriesStyle = {
  ...backgroundGray, height: 80, width: 80, borderRadius: 80, marginRight: 5,
};


export default function HomeCategories() {
  return (
    <Placeholder animation="fade">
      <View>
        <View style={{ marginTop: 10, paddingLeft: 10 }}>
          <View style={{ flexDirection: 'row' }}>
            <PlaceholderMedia hasRadius style={homeCategoriesStyle} />
            <PlaceholderMedia hasRadius style={homeCategoriesStyle} />
            <PlaceholderMedia hasRadius style={homeCategoriesStyle} />
            <PlaceholderMedia hasRadius style={homeCategoriesStyle} />
            <PlaceholderMedia hasRadius style={homeCategoriesStyle} />
          </View>
        </View>
      </View>
    </Placeholder>
  );
}
