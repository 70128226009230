import Images from '~/../assets/images';
import {
  SCREEN_ABOUT,
  SCREEN_CART,
  SCREEN_COMBOS,
  SCREEN_CREDIT_CARD_LIST,
  SCREEN_FAVORITES,
  SCREEN_HOME, SCREEN_LOGIN,
  SCREEN_ORDERS,
  SCREEN_PRODUCTS_AND_CATEGORIES, SCREEN_PROFILE,
  SCREEN_PROMOTIONS,
  SCREEN_STORES,
  SCREEN_TITLES,
  SCREEN_WALLET,
  SCREEN_NOTIFICATION,
  SCREEN_NOTICES,
  SCREEN_LIST_ADDRESS,
} from '../../screens';

export const items = {
  login: {
    id: 'login',
    screen: SCREEN_LOGIN.name,
  },
  profile: {
    id: 'profile',
    screen: SCREEN_PROFILE.name,
  },
  notifications: {
    id: 'notifications',
    screen: SCREEN_NOTIFICATION.name,
    icon: Images.iconBell,
  },
  favorites: {
    id: 'favorites',
    screen: SCREEN_FAVORITES.name,
    icon: Images.iconHeart,
  },
  cart: {
    id: 'cart',
    screen: SCREEN_CART.name,
    icon: Images.iconCart,
  },
  home: {
    id: 'home',
    screen: SCREEN_HOME.name,
    icon: Images.iconHome,
  },
  orders: {
    id: 'orders',
    screen: SCREEN_ORDERS.name,
    icon: Images.iconClipboard,
  },
  titles: {
    id: 'titles',
    screen: SCREEN_TITLES.name,
    icon: Images.iconTitles,
  },
  categories: {
    id: 'categories',
    title: 'Categorias',
    screen: SCREEN_PRODUCTS_AND_CATEGORIES.name,
    icon: Images.iconList,
  },
  promotions: {
    id: 'promotions',
    title: 'Promoções',
    screen: SCREEN_PROMOTIONS.name,
    icon: Images.iconStar,
  },
  stores: {
    id: 'stores',
    title: 'Lojas',
    screen: SCREEN_STORES.name,
    icon: Images.iconShop,
  },
  about: {
    id: 'about',
    title: 'Sobre',
    screen: SCREEN_ABOUT.name,
    icon: Images.iconAbout,
  },
  logout: {
    id: 'logout',
    title: 'Sair',
    screen: 'logout',
    icon: Images.iconLogout,
  },
  wallet: {
    id: 'wallet',
    title: 'Minha carteira',
    screen: SCREEN_WALLET.name,
    icon: Images.iconWallet,
  },
  credit_card_list: {
    id: 'credit_card_list',
    title: 'Meus Cartões',
    screen: SCREEN_CREDIT_CARD_LIST.name,
    icon: Images.iconWallet,
    should_be_logged: true,
  },
  denounce: {
    id: 'denounce',
    title: 'Violência contra a mulher',
    screen: '',
    icon: Images.iconAlert,
  },
  contact: {
    id: 'contact',
    title: 'Fale Conosco',
    screen: '',
    icon: Images.iconPhone,
  },
  address: {
    id: 'address',
    title: 'Endereços de Entrega',
    screen: SCREEN_LIST_ADDRESS.name,
    icon: Images.iconPlace,
  },
};

export default items;
