import React, { Component } from 'react';
import { Alert, View, Platform, PermissionsAndroid, TouchableOpacity, Text } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import axios from 'axios';
import Geolocation from '@react-native-community/geolocation';

import { SCREEN_COMPLETE_ADDRESS } from '../screens';

import colors from '~/values/color';
import AppConfig from '~/AppConfig';

type Props = {
    componentId: string,
    place: any,
  };

class Map extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      markerData: {
        latitude: this.props.place && this.props.place[1] ? this.props.place[1].geometry.location.lat : 0,
        longitude: this.props.place && this.props.place[1] ? this.props.place[1].geometry.location.lng : 0,
      },
      initLocation: {
        latitude: this.props.place && this.props.place[1] ? this.props.place[1].geometry.location.lat : 0,
        longitude: this.props.place && this.props.place[1] ? this.props.place[1].geometry.location.lng : 0,
        latitudeDelta: 0.001,
        longitudeDelta: 0.001,
      },
      finalLocation: {
        street_number: '',
        street: '',
        district: '',
        city: '',
        state_city: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
      },
    };
  }

  componentDidMount() {
    console.log('DATA MAPA VIEW DIDMOUNT PROPS: ', this.props);
    if (this.props.place.length > 0) {
      console.log('DATA MAPA VIEW: ', this.props);
      console.log('DATA STATE MAPA VIEW: ', this.state.markerData);
      console.log('DATA STATE INIT MAPA VIEW: ', this.state.initLocation);
      this.findCoordinatesGps();
    }
  }

  onPress = () => {
    setTimeout(() => {
      console.log('PROPS NAVIGATE: ', this.props);

      this.props.navigation.navigate(SCREEN_COMPLETE_ADDRESS.name, {...this.props, finalLocation: this.state.finalLocation});

      /* Navigation.push(this.props.componentId, {
        component: {
          name: SCREEN_COMPLETE_ADDRESS.name,
          passProps: {
            finalLocation: this.state.finalLocation,
          },
          options: {
            topBar: {
              title: {
                text: '',
              },
            },
          },
        },
      }); */
    }, 1000);
  };

  getUserPosition = () => {
    Geolocation.getCurrentPosition(async (position) => {
      await this.getAddressByLatLong(position.coords);
    },
    (error) => Alert.alert('Error', JSON.stringify(error)),
    { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 },
    );
  };

  getAddressByLatLong = async mapData => {
    console.log('DATA GET ADDR by LATLNG: ', mapData);
    const data = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${mapData.latitude},${mapData.longitude}&key=AIzaSyDRvXD60ZyXFCdcXee6NmDSCRRYmoiVcKU`);
    const leength = data.data.results[0].address_components.length;
    let location = {};
    this.setState({
      initLocation: {
        latitude: data.data.results[0].geometry.location.lat,
        longitude: data.data.results[0].geometry.location.lng,
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
      },
      markerData: {
        latitude: data.data.results[0].geometry.location.lat,
        longitude: data.data.results[0].geometry.location.lng,
      },
    });

    location = {
      ...location,
      latitude: data.data.results[0].geometry.location.lat,
      longitude: data.data.results[0].geometry.location.lng,
    };

    for (let i = 0; i < leength; i += 1) {
      if (data.data.results[0].address_components[i].types[0] === 'street_number') {
        location = {
          ...location,
          street_number: data.data.results[0].address_components[i].long_name,
        };
      }
      if (data.data.results[0].address_components[i].types[0] === 'route') {
        location = {
          ...location,
          street: data.data.results[0].address_components[i].short_name,
        };
      }
      if (data.data.results[0].address_components[i].types[1] === 'sublocality' || data.data.results[0].address_components[i].types[2] === 'sublocality_level_1') {
        location = {
          ...location,
          district: data.data.results[0].address_components[i].long_name,
        };
      }
      if (data.data.results[0].address_components[i].types[0] === 'administrative_area_level_2') {
        location = {
          ...location,
          city: data.data.results[0].address_components[i].long_name,
        };
      }
      if (data.data.results[0].address_components[i].types[0] === 'administrative_area_level_1') {
        location = {
          ...location,
          state_city: data.data.results[0].address_components[i].short_name,
        };
      }
      if (data.data.results[0].address_components[i].types[0] === 'country') {
        location = {
          ...location,
          country: data.data.results[0].address_components[i].long_name,
        };
      }
      if (data.data.results[0].address_components[i].types[0] === 'postal_code') {
        location = {
          ...location,
          postal_code: data.data.results[0].address_components[i].long_name,
        };
      }
    }
    console.log('DATA LOCATION GETADDR: ', location);
    this.setState({ finalLocation: location }, () => {
      console.log('DATA STATE FINAL LOCATION: ', this.state.finalLocation);
    });
  };

  findCoordinatesGps = async () => {
    if (Platform.OS !== 'web') {
      console.log('DATA FINDCOORDGPS');
    if (Platform.OS === 'ios') {
      this.getUserPosition();
    }
    if (Platform.OS === 'web') {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(
          async position => {
            await this.getAddressByLatLong(position.coords);
          },
          error => console.log(error.message),
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
        );
      }, 1000);
    } 
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        {
          title: AppConfig.appName,
          message: `${AppConfig.appName} solicita acesso ao gps`,
        },
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        setTimeout(() => {
          navigator.geolocation.getCurrentPosition(
            async position => {
              await this.getAddressByLatLong(position.coords);
            },
            error => Alert.alert(error.message),
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
          );
        }, 1000);
      } else {
        // Alert.alert('Acesso ao GPS não foi autorizado!');
        console.log('DATA: Acesso ao GPS não foi autorizado!');
      }
    } catch (err) {
      console.warn(err);
    }
    }

    geocodeByLatLng({ lat: this.state.initLocation.latitude, lng: this.state.initLocation.longitude })
    .then(results => {
      const leength = results[0].address_components.length;
      let location = {};
      location = {
        ...location,
        latitude: this.state.initLocation.latitude,
        longitude: this.state.initLocation.longitude,
      };
  
      for (let i = 0; i < leength; i += 1) {
        if (results[0].address_components[i].types[0] === 'street_number') {
          location = {
            ...location,
            street_number: results[0].address_components[i].long_name,
          };
        }
        if (results[0].address_components[i].types[0] === 'route') {
          location = {
            ...location,
            street: results[0].address_components[i].short_name,
          };
        }
        if (results[0].address_components[i].types[1] === 'sublocality' || results[0].address_components[i].types[2] === 'sublocality_level_1') {
          location = {
            ...location,
            district: results[0].address_components[i].long_name,
          };
        }
        if (results[0].address_components[i].types[0] === 'administrative_area_level_2') {
          location = {
            ...location,
            city: results[0].address_components[i].long_name,
          };
        }
        if (results[0].address_components[i].types[0] === 'administrative_area_level_1') {
          location = {
            ...location,
            state_city: results[0].address_components[i].short_name,
          };
        }
        if (results[0].address_components[i].types[0] === 'country') {
          location = {
            ...location,
            country: results[0].address_components[i].long_name,
          };
        }
        if (results[0].address_components[i].types[0] === 'postal_code') {
          location = {
            ...location,
            postal_code: results[0].address_components[i].long_name,
          };
        }
      }
      console.log('DATA LOCATION GETADDR: ', location);
      this.setState({ finalLocation: location });
    })
    .catch(error => console.error(error));
    
  };

  async handleRegionChange(mapData) {
    console.log('DATA HANDLE REGION CHANGE: ', mapData);
    // const dataMap = await mapData;
    this.setState({
      markerData: { latitude: mapData.latitude, longitude: mapData.longitude },
      initLocation: { latitude: mapData.latitude, longitude: mapData.longitude, latitudeDelta: 0.01, longitudeDelta: 0.01 }
    });
    await this.getAddressByLatLong(dataMap);
  };

  render() {
    const { markerData, initLocation } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <MapView
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
    }}
          // initialRegion={initLocation}
          provider={PROVIDER_GOOGLE} // remove if not using Google Maps
          region={initLocation}
          // loadingEnabled={this.loadingMap}
          // loadingIndicatorColor={colors.primaryColor}
          onRegionChangeComplete={ this.handleRegionChange }
          // onRegionChange={ this.handleRegionChange }
          onLayout={async () => { await this.mark.showCallout(); }}
          // onMarkerDragEnd={() => { this.mark.showCallout(); }}
          // zoomEnabled
          // zoomControlEnabled
          // showsUserLocation
          // followsUserLocation
        >
          <Marker
            ref={ref => { this.mark = ref; }}
            coordinate={markerData}
            title="Você está aqui?"
            description="Ajuste sua localização"
            onDragEnd={console.log('DATA dragEnd')}
          />
        </MapView>
        <TouchableOpacity
          onPress={this.onPress}
          style={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: colors.primaryColor,
              justifyContent: 'center',
              textAlign: 'center',
              width: '90%',
              height: 30,
              borderRadius: 2,
              alignSelf: 'center',
              marginBottom: 10,
            }}
        >
          <Text style={{ color: '#fff', textAlign: 'center', fontWeight: 'bold' }}>Confirmar localização</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default Map;
