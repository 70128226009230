import Controller from '~/controllers/Controller';
import RegisterAddressRepository from '~/database/repositories/RegisterAddressRepository';

export default class RegisterAddressController extends Controller {
  constructor() {
    super();
    this.repository = new RegisterAddressRepository();
  }
  getRegisterAddress() {
    return this.repository.getRegisterAddress();
  }

  addRegisterAddress(addr) {
    return this.repository.addRegisterAddress(addr);
  }

  updateRegisterAddress(addr) {
    return this.repository.updateRegisterAddress(addr);
  }

  deleteRegisterAddress(id) {
    return this.repository.deleteRegisterAddress(id);
  }
}
