import axios from 'axios';
import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'Update Shipping Method';

export default async function updateShippingMethod(cart) {
  const objShippMethod = {
    code: cart.shipping_method.ord,
    description: cart.shipping_method.valor,
    price: cart.shipping_method.preco,
    estimated_time: '',
    amount: 0,
  };
  const config = await getConfig();
  const company = await getCurrentCompany();
  const payload = {
    address_id: cart.delivery_address.code,
    shipping_method: objShippMethod.code,
    shipping_price: parseFloat(objShippMethod.price),
  };

  const url = `${URL_BASE}/cart/${company}/detail/${cart.id}/delivery_addresses/`;

  return new Promise((resolve, reject) =>
    axios.post(url, payload, config)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(TAG, error.response.data);
        reject(error.response.data);
      }));
}
