import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    padding: 8,
  },
  errorContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: colors.warningColorLightest,
    padding: 8,
    paddingHorizontal: 16,
  },
  text: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 4,
    color: colors.warningColorDark,
    textAlign: 'left',
  },
  clearButton: {
    backgroundColor: colors.warningColor,
  },
  iconClose: {
    tintColor: colors.primaryColor,
    width: 18,
    height: 18,
  },
});

export default styles;
