import React from 'react';
import { View, Image } from 'react-native';
import { Form } from '@unform/web';
import { Button } from '~/components';
import InputWebMask from '~/components/Input/unform/InputWebMask';
import styles from './styles';
import AppConfig from '~/AppConfig';
import sizes from '~/values/sizes';
import colors from '~/values/color';

export default function ChooseStoreWeb({ requesting, onPressEnter }) {
    const [valueNin, setValueNin] = React.useState('');

    const sendSolicitation = () => {
        if (valueNin.length === 14 || valueNin.length === 18) {
            onPressEnter({ nin: valueNin });
            setValueNin('');
        } else {
            alert('Por favor, informe o CPF/CNPJ');
            return;
        }
      }

    return (
        <View style={styles.container}>
        <Image style={{ 
          width: 240,
          height: 65,
          alignSelf: 'center',
          resizeMode: 'contain',
          margin: 16,
          marginTop: 4, 
        }} 
        source={AppConfig.logoApp}/>
        <View style={styles.loginBoxInputs}>
        <Form >
            <InputWebMask 
            name="nin" 
            required="required"
            label="CPF/CNPJ" 
            placeholder="CPF/CNPJ" 
            mask={valueNin.length < 15 ? "999.999.999-999" : "99.999.999/0001-99"}
            maskChar=""
            value={valueNin}
            onChange={(e) => setValueNin(e.target.value)}
            /> 
        </Form>
        </View>
        <Button
          title={requesting ? 'Solicitando...' : 'Solicitar acesso'}
          big
          containerStyle={{ margin: sizes.horizontalSpacing }}
          style={[
            styles.loginButton,
            requesting ? { backgroundColor: colors.grayDark } : styles.loginButton,
          ]}
          onPress={sendSolicitation}
        />
      </View>
      );
};