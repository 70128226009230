export const PRODUCTS_CONTEXT_LIST_REQUEST = 'PRODUCTS_CONTEXT_LIST_REQUEST';
export const PRODUCTS_CONTEXT_LIST_SUCCESS = 'PRODUCTS_CONTEXT_LIST_SUCCESS';
export const PRODUCTS_CONTEXT_LIST_FAILURE = 'PRODUCTS_CONTEXT_LIST_FAILURE';

export function success(payload) {
  return dispatch => dispatch({ type: PRODUCTS_CONTEXT_LIST_SUCCESS, payload });
}

export function failure(payload) {
  return dispatch => dispatch({ type: PRODUCTS_CONTEXT_LIST_FAILURE, payload });
}

export default function list() {
  return dispatch => dispatch({ type: PRODUCTS_CONTEXT_LIST_REQUEST });
}
