/* @flow */

import React, { Component } from 'react';
import { Keyboard } from 'react-native';
import moment from 'moment';

import Input from './Input';

const isValid = (entry: string) => {
  if (entry.replace('/', '').length !== 6) return false;
  const split = entry.split('/');
  const month = Number.parseInt(split[0]);
  const year = Number.parseInt(split[1]);
  if (
    month >= 1
    && month <= 12
    && year >= moment().year()
  ) {
    return true;
  }
  return false;
};

type Props = {
  onBlur: () => void,
  onError: () => void,
  onSuccess: () => void,
  value: string,
};

class CreditCardDate extends Component<Props, void> {
  state = {
    error: '',
  };

  onBlur = (entry: string) => {
    this.props.onBlur(entry);
    if (isValid(entry) === false) {
      this.setState({
        error: 'Data inválida.',
      });
      this.props.onError('Data inválida.');
    }
  };

  onChange = (entry: string) => {
    /**
     * Valida a entrada e remove a mensagem de erro caso tenha sida apresentada.
     */
    if (isValid(entry)) {
      this.setState({
        error: '',
      });
      this.props.onError('');

      /**
       * Desfoca o campo quando o mesmo estiver completo e válido.
       */
      Keyboard.dismiss();

      /**
       * Invoca sucesso transmitindo a entrada.
       */
      this.props.onSuccess(entry);
    }
    this.props.onChange(entry);
  };

  focus = () => {
    this.input.focus();
  };

  render() {
    return (
      <Input
        {...this.props}
        ref={ref => { this.input = ref; }}
        error={this.state.error}
        onBlur={this.onBlur}
        onChange={this.onChange}
        keyboardType="numeric"
        format="99/9999"
        collapsable={false}
        value={this.props.value}
      />
    );
  }
}

CreditCardDate.defaultProps = {
  onBlur: () => { },
  onError: () => { },
  onSuccess: () => { },
  value: '',
};

export default CreditCardDate;
