import type { ISource } from '../../entities/Source';
import type { IRequest } from '../../entities/Request';

function generateId() {
  return new Date().getTime();
}

export default function RequestBuilder(source: ISource, context: string): IRequest {
  const id = generateId();
  const request = { id, source, context };
  return request;
}
