/* @flow */

import { Map } from 'immutable';

import {
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
} from './action';

const initialState = Map({
  loading: false,
  error: false,
});

export default function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === UPDATE_STORE_REQUEST) {
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => true);
  }

  if (type === UPDATE_STORE_SUCCESS) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => false)
      .updateIn(['error'], () => false);
  }

  if (type === UPDATE_STORE_FAILURE) {
    return state.updateIn(['type'], () => type)
      .updateIn(['loading'], () => false)
      .updateIn(['error'], () => true);
  }

  return state;
}
