import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import { Warning } from '~/components';
import Product from '~/components/Product/ProductContainer';
import EmptyView from '~/components/EmptyView';
import Images from '~/../assets/images';
import Spinner from '~/modules-wrapper/react-native-loading-spinner-overlay';

type WarningsProps = {
  clearingWarnings: Boolean,
  messages: [],
  onClearWarning: (product: IProduct) => void,
  screenId: string,
};

class Warnings extends Component<WarningsProps> {
  onClearWarning = item => {
    this.props.onClearWarning(item);
  };

  keyExtractor = (item, index) => `${item.content}-${index}`;

  renderMessage = item => <Warning item={item} text={item.content} onClear={this.onClearWarning} />;

  renderItem = ({ item }) => {
    if (item.product) {
      return (
        <Product
          product={item.product}
          FooterComponent={this.renderMessage(item)}
          hideQuantity
          canOpenDetail={false}
          screenId={this.props.screenId}
        />
      );
    }

    return this.renderMessage(item);
  };

  renderSeparator() {
    return <View style={{ marginVertical: 4 }} />;
  }

  emptyView = () => <EmptyView icon={Images.iconAlert} message="Sem alertas no carrinho. :D" />;

  render() {
    const { messages, clearingWarnings } = this.props;
    return (
      <View>
        <Spinner
          visible={clearingWarnings}
          textContent="Limpando avisos do carrinho..."
          overlayColor="rgba(0, 0, 0, 0.7)"
          textStyle={{ color: '#FFF' }}
        />
        <FlatList
          data={messages}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
          ItemSeparatorComponent={this.renderSeparator}
          ListEmptyComponent={messages && messages.length === 0 ? this.emptyView : null}
        />
      </View>
    );
  }
}

export default Warnings;
