import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  containerWrapper: {
    overflow: 'scroll',
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  brandImage: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  inputSize: {
    width: 100,
    height: 20
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  dontKnowCVVContainer: {
    justifyContent: 'flex-end',
    marginTop: -16,
  },
  dontKnowCVV: {
    alignSelf: 'flex-end',
  },
  sectionStyle: {
    borderBottomColor: colors.grayContainer,
    borderBottomWidth: 8,
  },
});

export default styles;
