import CategoryImages from '~/../assets/CategoryImages';

export default class CategoryIconHelper {
  static sourceIconFromItem(item) {
    console.log('item', item);
    let sourceIcon = CategoryImages.iconCategory;
    if (item && item.images) {
      const iconJson = JSON.parse(item.images);
      if (iconJson && iconJson.type === 'resource') {
        sourceIcon = this.sourceFromData(iconJson.data);
      }
    }
    return sourceIcon;
  }

  static sourceFromData(data) {
    let sourceIcon = CategoryImages.iconCategory;
    switch (data) {
      case 'pet':
        sourceIcon = CategoryImages.iconPet;
        break;
      case 'drinks':
        sourceIcon = CategoryImages.iconDrinks;
        break;
      case 'housekeeping':
        sourceIcon = CategoryImages.iconHousekeeping;
        break;
      case 'food':
        sourceIcon = CategoryImages.iconFood;
        break;
      case 'maintenance':
        sourceIcon = CategoryImages.iconMaintenance;
        break;
      case 'alimenticios':
        sourceIcon = CategoryImages.iconAlimenticios;
        break;
      case 'bebidas':
        sourceIcon = CategoryImages.iconBebidas;
        break;
      case 'cuidadodiario':
        sourceIcon = CategoryImages.iconCuidados;
        break;
      case 'farmacia':
        sourceIcon = CategoryImages.iconFarmacia;
        break;
      case 'higienepessoal':
        sourceIcon = CategoryImages.iconHigiene;
        break;
      case 'limpeza':
        sourceIcon = CategoryImages.iconLimpeza;
        break;
      default:
        break;
    }
    return sourceIcon;
  }
}
