import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  categories: {
    marginTop: 15,
    marginBottom: 15,
  },
  dataContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.grayContainer,
    justifyContent: 'space-between',
  },
  separator: {
    height: 1,
    marginHorizontal: sizes.horizontalSpacing,
    backgroundColor: colors.grayContainerDark,
  },
});

export default styles;
