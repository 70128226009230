/* @flow */

import { Map, List } from 'immutable';

import {
  PRODUCTS_CONTEXT_LIST_REQUEST,
  PRODUCTS_CONTEXT_LIST_SUCCESS,
  PRODUCTS_CONTEXT_LIST_FAILURE,
} from './action';

const initialState = Map({
  type: '', loading: false, payload: List([]), messages: List([]),
});

export default function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === PRODUCTS_CONTEXT_LIST_REQUEST) {
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => true);
  }

  if (type === PRODUCTS_CONTEXT_LIST_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => false)
      .updateIn(['messages'], () => List([]))
      .setIn(['payload'], List(payload));
  }

  if (type === PRODUCTS_CONTEXT_LIST_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => false).setIn(['messages'], List(payload));
  }

  return state;
}
