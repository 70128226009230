import find from 'lodash/find';
import get from 'lodash/get';

import CacheStore from '~/modules-wrapper/react-native-cache-store';
import NetInfo from '@react-native-community/netinfo';
import { store } from '~/App.web';

import { APP_INFO, COMPANY_ACTIVE, DATA_ACCOUNT, STORE_ITEM_ACTIVE, SYNC } from '~/constants/cache';
import download from './download';
import DataManager from '../../database/DataManager';
import requestManager from '../RequestManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import { CART_ERROR } from '~/constants/cache';

import { getConfig, URL_BASE } from '../../services/index';

import { setCompanyCustomerActiveFromCompany } from '~/utils/CompanyCustomer';
import setLoadCart from '../../store/setLoadCart/action';
import listBase from '../../services/listBase';

import requestCompanies from '~/services/companies/request';
import getCompanies from '~/services/companies/list';
import AppConfig from '~/AppConfig';
import { SALE_MODEL_B2C } from '~/constants/saleModel';
import setIconsChange from '../../store/loadIcons/action';
import setLoadPromotions from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setErrorCompanyCustomer from '../../store/setErrorCompanyCustomer/action';
import SQLiteManager from '../../database/SQLiteManager';

import ProductController from '~/controllers/ProductController';

import CartController from '~/controllers/CartController';
import ProductContextController from '~/controllers/ProductContextController';
import CategoryController from '~/controllers/CategoryController';
import CompanyController from '~/controllers/CompanyController';
import CategoriesRelationshipsController from '~/controllers/CategoriesRelationshipsController';
import ProductsCategories from '~/controllers/ProductsCategoriesController';
import PaymentTermController from '~/controllers/PaymentTermController';
import PaymentOptionController from '~/controllers/PaymentOptionController';
import PaymentCombineKeyController from '~/controllers/PaymentCombineKeyController';
import ShippingMethodsController from '~/controllers/ShippingMethodsController';
import SalesController from '~/controllers/SalesController';
import PromotionController from '~/controllers/PromotionController';
import ComboController from '~/controllers/ComboController';
import NoticesController from '~/controllers/NoticesController';
import RegisterAddressController from '~/controllers/RegisterAddressController';
import AppConfigController from '~/controllers/AppConfigController';

const MESSAGE_SERVER_ERROR = { type: 'error', message: 'Não foi possível recuperar os dados ' };

const MESSAGE_OFFLINE_ERROR = {
  messages: [
    { type: 'error', message: 'Você está offline! Conecte a internet para atualizar seus dados.' },
  ],
};

const MESSAGE_OFFLINE_UPLOAD_REQUEST_QUEUE = {
  messages: [{ type: 'error', message: 'Não foi possível enviar suas informações.' }],
};

const MESSAGE_DB_ERRO = {
  messages: [{ type: 'error', message: 'Erro ao inserir dados nos banco de dados.' }],
};

const MESSAGE_UPLOAD_REQUEST_QUEUE = {
  messages: [{ type: 'info', message: 'Aguarde! Estamos sincronizando os itens do seu carrinho.' }],
};

export const WISH_LIST_PRODUCTS = 'WISH_LIST_PRODUCTS';

const TAG = 'Sync';

export default class Sync {
  constructor(onUpdateProgress = null) {
    this.onUpdateProgress = onUpdateProgress;
    this.total = 0;
  }

  getContext = (title) => {
    if (title == 'Produtos sugeridos') {
      return 'order_suggestion';
    }
    if (title == 'Produtos mais comprados') {
      return 'best_sellers';
    }
    if (title == 'Histórico de compras') {
      return 'sales_history';
    }
    return '';
  };

  async sync() {
    const netInfo = await NetInfo.fetch();
    const config = await getConfig();
    const companyController = new CompanyController();
    const registerAddrController = new RegisterAddressController();
    const appConfigController = new AppConfigController();
    if (!netInfo.isConnected) {
      console.log(TAG, MESSAGE_OFFLINE_ERROR);
      throw MESSAGE_OFFLINE_ERROR;
    }

    const requestsFinish = await requestManager.isJobProcessing();
    if (requestsFinish) {
      console.log(TAG, MESSAGE_UPLOAD_REQUEST_QUEUE);
      throw MESSAGE_UPLOAD_REQUEST_QUEUE;
    } else if (requestManager.getItemToProcess().length > 0) {
      await requestManager.execProcessItem();
      throw MESSAGE_UPLOAD_REQUEST_QUEUE;
    }

    const promise = new Promise(async (resolve, reject) => {
      try {
        const results = await Promise.all([
          listBase(`${URL_BASE}/companies/`, config),
          listBase(`${URL_BASE}/system/app_info/`, config),
          listBase(`${URL_BASE}/address/`, config),
          listBase(`${URL_BASE}/system/config/${AppConfig.appCode}/`),
        ]);

        let response = results[0];
        const appInfo = results[1];
        const res_addr = results[2];
        const appConfig = results[3];

        await CacheStore.set(APP_INFO, appInfo);

        const items = get(response, ['source', 'data']) || [];
        let companies = items.filter((c) => c.customer_company_accounts.length);

        await companies.forEach((company) => {
          companyController.addCompany(company);
          console.log('AFTER ADD COMPANY');

          company.customer_company_accounts.forEach((customerInside) => {
            console.log('INSIDE FOREACH');
            companyController.addCustomerCompanyAccounts(customerInside);
            console.log('AFTER ADD CUSTOMER COMPANY COMPANY');
            companyController.addCustomer(customerInside.customer);
          });
        });

        registerAddrController.addRegisterAddress(res_addr.data);
        console.log('APP CONFIG: ', appConfig);
        appConfigController.addAppConfig(appConfig.config);

        const account = await CacheStore.get(DATA_ACCOUNT);
        console.log('ACCOUNT: ', account);
        if (!account.isAnonymous && AppConfig.sale_model === SALE_MODEL_B2C) {
          const nin = get(account, 'user.nin');
          let registration = await this.findMyRegistration(companies);
          if (!registration) {
            let response = {};
            try {
              response = await requestCompanies(nin);
              const responseCompanies = await getCompanies();
              const itemsCompanies = get(responseCompanies, 'source.data');

              companies = itemsCompanies.filter((c) => c.customer_company_accounts.length);

              registration = await this.findMyRegistration(companies);
              await setCompanyCustomerActiveFromCompany(registration.company, registration.registration);
            } catch (error) {
              response = error.data;
            }

            if (!response.success) {
              const errorLoadedCart = {
                type: response.message,
                load: false,
              };

              await CacheStore.set(CART_ERROR, response.message);

              store.dispatch(setLoadCart(errorLoadedCart));
              reject(new Error([response]));
            }
          } else {
            await setCompanyCustomerActiveFromCompany(registration.company, registration.registration);
          }
        }

        const dataDownload = [];
        dataDownload[SchemaModels.COMPANY] = companies;

        console.log('PARIMA companies', companies);

        if (companies.length === 0) {
          const noStore = {
            type: '',
            error: false,
            notStore: true,
          };

          store.dispatch(setErrorCompanyCustomer(noStore));
          resolve();
          return;
        }

        const companyActiveCache = await CacheStore.get(COMPANY_ACTIVE) || {};
        const customerActiveCache = await CacheStore.get(STORE_ITEM_ACTIVE) || {};

        console.log('PARIMA companyActiveCache: ', companyActiveCache);
        console.log('PARIMA customerActiveCache: ', customerActiveCache);
        console.log('PARIMA companies', companies);
        let customer = null;
        let company = null;

        if (companies && companies.length > 0) {
          const findCompany = find(companies, { id: companyActiveCache.id });
          company = findCompany || companies[0];

          console.log('PARIMA company', company);

          const customerCompanyAccounts = company.customer_company_accounts || [];

          console.log('PARIMA customerCompanyAccounts', customerCompanyAccounts);

          const findCustomer = find(customerCompanyAccounts, {
            customer_id: customerActiveCache.customer_id,
            account_id: customerActiveCache.account_id,
            company_id: customerActiveCache.company_id,
          });

          console.log('PARIMA findCustomer', findCustomer);

          customer = findCustomer || customerCompanyAccounts[0];
          await setCompanyCustomerActiveFromCompany(company, customer);
        } else {
          resolve();
        }

        response = await listBase(`${URL_BASE}/cart/${company.id}/?customer_id=${customer.customer_id}`, config);
        console.log('RESPONSE CART: ', response);

        if (response.source.data[0].message == 'Cliente está bloqueado para novos pedidos!') {
          const errorLoadedCart = {
            type: '',
            load: false,
            userBlocked: true,
          };
          store.dispatch(setLoadCart(errorLoadedCart));
          await CacheStore.set(CART_ERROR, response.source.data[0].message);
        }

        if (response.source.data[0].code == 'condicoes_venda_invalida') {
          const errorLoadedCart = {
            type: '',
            load: false,
            userBlocked: false,
            condSalesError: true,
          };
          store.dispatch(setLoadCart(errorLoadedCart));
        }

        if (response.source.data[0].code == 'permission_denied') {
          const errorLoadedCart = {
            type: '',
            load: false,
            userBlocked: false,
            condSalesError: false,
            permission_denied: true,
          };
          store.dispatch(setLoadCart(errorLoadedCart));
          await CacheStore.set(CART_ERROR, response.source.data[0].message);
        }

        const data = get(response, ['source', 'data']) || [];
        const cart = data.length ? data[0] : {};

        if (cart.success) {
          await CacheStore.set('CART_ID', cart.id);
        }

        dataDownload[SchemaModels.CART] = data.map(item => ({ ...item, id: `${item.id}` }));

        if (cart.success) {
          await CacheStore.remove(CART_ERROR);
          const dataDwd = await download();

          // console.log('dataDwd[5]', dataDwd[5]);

          dataDownload[WISH_LIST_PRODUCTS] = this.getDataResponse(dataDwd[0]);
          dataDownload[SchemaModels.PROMOTION] = this.getDataResponse(dataDwd[1]);
          dataDwd[2].forEach((item, key) => (dataDownload[key] = item));
          dataDownload[SchemaModels.NOTICES] = dataDwd[3];
          dataDownload[SchemaModels.SALES] = this.getDataResponse(dataDwd[4]);
          dataDwd[5].forEach((item, key) => {
            if (item) {
              dataDownload[key] = item;
            }
          });
          dataDownload[SchemaModels.SHIPPING_METHODS] = this.getDataResponse(dataDwd[6]);

          dataDownload[SchemaModels.PRODUCT_CONTEXT] = [];

          for (let i = 9; i >= 7; i--) {
            const dataResponse = this.getDataResponse(dataDwd[i]);
            const ctx = this.getContext(dataDwd[i].source.title);

            const productsContext = dataResponse.map(dataProdCtx => ({
              ...dataProdCtx,
              context_id: ctx,
              context_title: dataDwd[i].source.title,
            }));

            dataDownload[SchemaModels.PRODUCT_CONTEXT].push(...productsContext);
          }

          dataDownload[SchemaModels.COMBOS] = this.getDataResponse(dataDwd[10]);

          this.calculateTotalProcess(dataDownload);
          await this.process(dataDownload);
        } else {
          const dataManager = new DataManager();
          const loadedFalse = {
            type: '',
            load: false,
          };
          store.dispatch(setLoadCart(loadedFalse));
          store.dispatch(setLoadPromotions(loadedFalse));
          store.dispatch(setLoadCategories(loadedFalse));
          store.dispatch(setLoadProdContext(loadedFalse));
          store.dispatch(setLoadNoticies(loadedFalse));
          dataManager.clearInCache(SchemaModels.CART);
          dataManager.clearInCache(SchemaModels.PAYMENT_COMBINE_KEYS);
          dataManager.clearInCache(SchemaModels.PAYMENT_OPTIONS);
          dataManager.clearInCache(SchemaModels.PAYMENT_TERMS);
        }

        const date = new Date().getTime();
        await CacheStore.set(SYNC, { lastDate: date });
        resolve();
      } catch (e) {

        console.log('e::::e', e);

        const errorLoadedCart = {
          type: '',
          load: false,
        };
        store.dispatch(setLoadCart(errorLoadedCart));
        const loadedFalse = {
          type: '',
          load: false,
        };
        store.dispatch(setLoadCart(loadedFalse));
        store.dispatch(setLoadPromotions(loadedFalse));
        store.dispatch(setLoadCategories(loadedFalse));
        store.dispatch(setLoadProdContext(loadedFalse));
        store.dispatch(setLoadNoticies(loadedFalse));
        reject(new Error(MESSAGE_SERVER_ERROR.message));
      }
    });

    return await promise;
  }

  async findMyRegistration(companies) {
    const account = await CacheStore.get(DATA_ACCOUNT);
    const nin = get(account, 'user.nin', '')
      .replace('-', '')
      .replace('.', '');

    let foundRegistration = null;

    companies.forEach((company) => {
      const registrations = company.customer_company_accounts || [];
      registrations.forEach(async (registration) => {
        const customer = registration.customer;
        if (customer.nin
          .replace('-', '')
          .replace('.', '') === nin) {
          foundRegistration = {
            company, registration,
          };
        }
      });
    });

    return foundRegistration;
  }

  getDataResponse(response) {
    const data = get(response, ['source', 'data']) || [];
    return data;
  }

  calculateTotalProcess(data) {
    data.forEach(item => (this.total += item.length));
  }

  async process(data) {
    let current = 0;
    const wishListProducts = data[WISH_LIST_PRODUCTS];
    delete data[WISH_LIST_PRODUCTS];

    const productController = new ProductController();
    const cartController = new CartController();
    const productContextController = new ProductContextController();
    const categoryController = new CategoryController();
    const categoriesRelationshipsController = new CategoriesRelationshipsController();
    const productsCategories = new ProductsCategories();
    const paymentTermController = new PaymentTermController();
    const paymentOptionController = new PaymentOptionController();
    const paymentCombineKeyController = new PaymentCombineKeyController();
    const shippingMethodsController = new ShippingMethodsController();
    const salesController = new SalesController();
    const promotionController = new PromotionController();
    const comboController = new ComboController();
    const noticesController = new NoticesController();

    const promises = [];
    for (const model in data) {
      if (parseInt(model) === SchemaModels.CART) {
        const cart = data[model][0];
        if (cart.success === true) {
          cartController.addCart(data[model], false, true);

          const loadedCart = {
            type: '',
            load: false,
          };
          store.dispatch(setLoadCart(loadedCart));
        }
      } else if (parseInt(model) === SchemaModels.PRODUCT) {
        const listWish = wishListProducts.map(p => p.product.id);
        const products = data[model].map(item => {
          const notifyMe = listWish.includes(item.id);
          return { ...item, notify_me: notifyMe };
        });
        try {
          SQLiteManager.clearTable('Product');
        } catch (e) {
          throw new Error('error clear product table');
        }
        try {
          productController.addProduct(products);
        } catch (e) {
          throw new Error('error add product table');
        }
      } else if (parseInt(model) === SchemaModels.PROMOTION) {
        try {
          SQLiteManager.clearTable('Promotion');
        } catch (e) {
          throw new Error('error clear table Promotion');
        }
        try {
          promotionController.addPromotion(data[model]);
        } catch (e) {
          throw new Errorg('error try add data Promotion table');
        }
      } else if (parseInt(model) === SchemaModels.COMPANY) {
        // inserted company after call endpoint companies
      } else if (parseInt(model) === SchemaModels.PRODUCT_CONTEXT) {
        try {
          SQLiteManager.clearTable('ProductContext');
        } catch (e) {
          throw new Error('error clear table prod ctx');
        }
        try {
          productContextController.addProductCtx(data[model]);
        } catch (e) {
          throw new Error('error try add data prod ctx table');
        }
      } else if (parseInt(model) === SchemaModels.CATEGORY) {
        try {
          SQLiteManager.clearTable('Category');
        } catch (e) {
          throw new Error('error try clear table category');
        }
        try {
          categoryController.addCategory(data[model]);
        } catch (e) {
          throw new Error('error try add data table category');
        }
      } else if (parseInt(model) === SchemaModels.CATEGORIES_RELATIONSHIPS) {
        try {
          SQLiteManager.clearTable('CategoryRelationships');
        } catch (e) {
          throw new Error('error try clear table category');
        }
        try {
          categoriesRelationshipsController.addCategoryRel(data[model]);
        } catch (e) {
          throw new Error('error try add data table CategoryRelationships');
        }
      } else if (parseInt(model) === SchemaModels.PRODUCTS_CATEGORIES) {
        try {
          SQLiteManager.clearTable('ProductCategories');
        } catch (e) {
          throw new Error('error try clear table ProductCategories');
        }
        try {
          productsCategories.addProductsCategories(data[model]);
        } catch (e) {
          throw new Error('error try add data table ProductCategories');
        }
      } else if (parseInt(model) === SchemaModels.SHIPPING_METHODS) {
        try {
          SQLiteManager.clearTable('ShippingMethods');
        } catch (e) {
          throw new Error('error try clear table ShippingMethods');
        }
        try {
          shippingMethodsController.addShippingMethods([data[model]]);
        } catch (e) {
          throw new Error('error try add data table ShippingMethods');
        }
      } else if (parseInt(model) === SchemaModels.SALES) {
        try {
          SQLiteManager.clearTable('Sales');
        } catch (e) {
          throw new Error('error try clear table Sales');
        }
        try {
          salesController.addSales(data[model]);
        } catch (e) {
          throw new Error('error try add data table Sales');
        }
      } else if (parseInt(model) === SchemaModels.PAYMENT_TERMS) {
        try {
          SQLiteManager.clearTable('PaymentTerm');
        } catch (e) {
          throw new Error('error try clear table PaymentTerm');
        }
        try {
          data[model].forEach((payment) => {
            paymentTermController.addPaymentTerm(payment);
          });
        } catch (e) {
          throw new Error('error try add data table PaymentTerm');
        }
      } else if (parseInt(model) === SchemaModels.PAYMENT_OPTIONS) {
        try {
          SQLiteManager.clearTable('PaymentOption');
        } catch (e) {
          throw new Error('error try clear table PaymentOption');
        }
        try {
          data[model].forEach((payment) => {
            paymentOptionController.addPaymentOption(payment);
          });
        } catch (e) {
          throw new Error('error try add data table PaymentOption');
        }
      } else if (parseInt(model) === SchemaModels.PAYMENT_COMBINE_KEYS) {
        try {
          SQLiteManager.clearTable('PaymentCombineKey');
        } catch (e) {
          throw new Error('error try clear table PaymentCombineKey');
        }
        try {
          data[model].forEach((payment) => {
            paymentCombineKeyController.addPaymentCombineKey(payment);
          });
        } catch (e) {
          throw new Error('error try add data table PaymentCombineKey');
        }
      } else if (parseInt(model) === SchemaModels.COMBOS) {
        try {
          SQLiteManager.clearTable('Combo');
        } catch (e) {
          throw new Error('error try clear table Combo');
        }
        try {
          comboController.addCombos(data[model]);
        } catch (e) {
          throw new Error('error try add data table Combo');
        }
      } else if (parseInt(model) === SchemaModels.NOTICES) {
        try {
          SQLiteManager.clearTable('Notices');
        } catch (e) {
          throw new Error('error try clear table Notices');
        }
        try {
          noticesController.addNotices(data[model].notices);
        } catch (e) {
          throw new Error('error try add data table Notices');
        }
      }

      current += data[model].length;
    }

    await Promise.all(promises);

    this.updateProgress(current);

    return true;
  }

  updateProgress(current) {
    const value = (current * 100) / this.total;
    if (this.onUpdateProgress) this.onUpdateProgress(value);
  }
}
