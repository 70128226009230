import React, { Component } from 'react';
import { View } from 'react-native';
import Places from '../../components/Places';
// import { Navigation } from 'react-native-navigation';

import { SCREEN_MAP } from '../screens';

type Props = {
  componentId: string,
};


class Address extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    };
  }

  onPress = () => {
    console.log('THIS PROPS ADDR: ', this.props);
    console.log('THIS STATE ADDR: ', this.state);
    /* Navigation.push(this.props.componentId, {
      component: {
        name: SCREEN_MAP.name,
        passProps: {
          place: this.state.data,
        },
        options: {
          topBar: {
            title: {
              text: this.state.data,
            },
          },
        },
      },
    }); */
  };

  handleCallback = (childData) => {
    this.setState({ data: childData }, () => {
      console.log('THIS PROPS ADDR: ', this.props);
      console.log('THIS STATE ADDR: ', this.state);


      this.props.navigation.navigate(
        SCREEN_MAP.name,
        {
          place: [
            [
              {
                description: '',
                matched_substrings: [
                  {
                    length: 0,
                    offset: 0,
                  },
                ],
                place_id: '',
                reference: '',
                structured_formatting: {
                  main_text: '',
                  main_text_matched_substrings: [
                    {
                      length: 0,
                      offset: 0,
                    },
                  ],
                  secondary_text: '',
                },
                terms: [
                  {
                    offset: 0,
                    value: '',
                  },
                  {
                    offset: 0,
                    value: '',
                  },
                  {
                    offset: 0,
                    value: '',
                  },
                  {
                    offset: 0,
                    value: '',
                  },
                  {
                    offset: 0,
                    value: '',
                  },
                ],
                types: [
                  '',
                  '',
                ],
              },
              {
                address_components: [
                  {
                    long_name: '0',
                    short_name: '0',
                    types: [
                      '',
                    ],
                  },
                  {
                    long_name: '',
                    short_name: '',
                    types: [
                      '',
                    ],
                  },
                  {
                    long_name: '',
                    short_name: '',
                    types: [
                      '',
                      '',
                      '',
                    ],
                  },
                  {
                    long_name: '',
                    short_name: '',
                    types: [
                      '',
                      '',
                    ],
                  },
                  {
                    long_name: '',
                    short_name: '',
                    types: [
                      '',
                      '',
                    ],
                  },
                  {
                    long_name: '',
                    short_name: '',
                    types: [
                      '',
                      '',
                    ],
                  },
                ],
                adr_address: '',
                icon: '',
                name: '',
                place_id: '',
                reference: '',
                types: [
                  '',
                ],
                url: '',
                utc_offset: 0,
                vicinity: '',
              },
            ],
            {
              geometry: {
                location: {
                  lat: this.state.data[0].lat,
                  lng: this.state.data[0].lng,
                },
                viewport: {
                  northeast: {
                    lat: 0,
                    lng: 0,
                  },
                  southwest: {
                    lat: 0,
                    lng: 0,
                  },
                },
              },
            },
          ],
        },
      );

      // this.props.navigation.navigate(SCREEN_MAP.name,  {...this.props, place: this.state.data[0]});
      /*Navigation.push(this.props.componentId, {
        component: {
          name: SCREEN_MAP.name,
          passProps: {
            place: childData,
          },
          options: {
            topBar: {
              title: {
                text: childData[0].description,
              },
            },
          },
        },
      });*/
    });
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Places parentCallback={this.handleCallback} componentId={this.props.componentId} />
      </View>
    );
  }
}

export default Address;
