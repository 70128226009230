import axios from 'axios';
import get from 'lodash/get';
import {URL_BASE, getConfig} from '../index';

class PaymentResource {
  async isipagPaymentRequest(cart, payment) {
    const config = await getConfig();
    const { id: idOrder, company_id: companyId } = cart;

    return new Promise((resolve, reject) =>
      axios
        .post(`${URL_BASE}/cart/${companyId}/detail/${idOrder}/pay/`, payment, config)
        .then(response => {
          console.log('isipagPaymentRequest response', response);
          resolve(response.data);
        })
        .catch(error => {
          const data = get(error, 'response.data.source.data', []);
          let message = 'Não foi possível realizar o pagamento';
          if (data.length && data[0].message) {
            message = data[0].message || message
          }

          reject({
            message: message
          });
        }));
  }
}

export default new PaymentResource();
