import SourceInterpreter from '~/utils/SourceInterpreter';
import isEmpty from 'lodash/isEmpty';
import { ERROR_REPONSE_DEFAULT } from '~/constants/messages';

export const PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST = 'PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST';
export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS';
export const PRODUCTS_LIST_FAILURE = 'PRODUCTS_LIST_FAILURE';

export const PRODUCT_NOTIFY_REQUEST = 'PRODUCT_NOTIFY_REQUEST';
export const PRODUCT_NOTIFY_SUCCESS = 'PRODUCT_NOTIFY_SUCCESS';
export const PRODUCT_NOTIFY_FAILURE = 'PRODUCT_NOTIFY_FAILURE';
export const PRODUCT_NOTIFY_RESPONSE_FAILURE = 'PRODUCT_NOTIFY_RESPONSE_FAILURE';
export const PRODUCT_NOTIFY_RESPONSE_SUCCESS = 'PRODUCT_NOTIFY_RESPONSE_SUCCESS';

// Products List
export function success(payload) {
  return dispatch => dispatch({ type: PRODUCTS_LIST_SUCCESS, payload });
}

export function failure(payload) {
  return dispatch => dispatch({ type: PRODUCTS_LIST_FAILURE, payload });
}

export function listProductsByProductsAndCategories(categoryCode, productsLimit = null) {
  return dispatch => dispatch({ type: PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST, payload: { categoryCode, productsLimit } });
}

export default function list(categoryCode, productsLimit = null) {
  return dispatch => dispatch({ type: PRODUCTS_LIST_REQUEST, payload: { categoryCode, productsLimit } });
}

// Notify me Product
export function successNotifyProduct(payload) {
  return dispatch => dispatch({ type: PRODUCT_NOTIFY_SUCCESS, payload });
}

export function failureNotifyProduct(payload) {
  return dispatch => dispatch({ type: PRODUCT_NOTIFY_FAILURE, payload });
}

function responseNotifyProductFailure(payload) {
  return dispatch => dispatch({ type: PRODUCT_NOTIFY_RESPONSE_FAILURE, payload });
}

function responseNotifyProductSuccess(payload) {
  return dispatch => dispatch({ type: PRODUCT_NOTIFY_RESPONSE_SUCCESS, payload });
}

export function notifyProduct(quantity, product, customerId) {
  return dispatch => dispatch({
    type: PRODUCT_NOTIFY_REQUEST,
    payload: { quantity, product, customerId },
  });
}

export function responseNotifyProduct(response) {
  const data = SourceInterpreter(response);
  const emptySource = isEmpty(response.source);

  if (emptySource) {
    const errors = data || ERROR_REPONSE_DEFAULT;
    return responseNotifyProductFailure(errors);
  }

  return responseNotifyProductSuccess(data);
}
