import axios from 'axios';

import { getConfig, URL_BASE } from '../index';

const TAG = 'companies list';

export default async function list() {
  const configCompaniesList = await getConfig();

  return new Promise((resolve) =>
    axios.get(`${URL_BASE}/companies/`, configCompaniesList)
      .then(response => resolve(response.data))
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));
}
