import React from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';

import { TabBar } from '~/components';
import EmptyView from '../../components/EmptyView';
import ProductsAndCategories from '../ProductsAndCategories/ProductsAndCategories';
import Categories from '../ProductsAndCategories/scenes/Categories';
import Images from '~/../assets/images';

type Props = {
  products: [],
  categories: [],
  onPressCategory: () => void,
  componentId: string,
}

class SearchResult extends React.Component<Props> {
  state = {
    index: 1,
    routes: [
      { key: 'first', title: 'Categorias' },
      { key: 'second', title: 'Produtos' },
    ],
  };

  componentDidUpdate({ products, categories }): void {
    (() => {
      if (products === this.props.products && categories === this.props.categories) return;
      this.setState({
        routes: [
          { key: 'first', title: `Categorias (${this.props.categories.length})` },
          { key: 'second', title: `Produtos (${this.props.products.length})` },
        ],
      });
    })();
  }

  EmptyView = (title) => (
    <EmptyView
      icon={Images.iconSad}
      message="Sem resultados..."
      submessage={`Não foi encontrado(a) ${title} com o termo buscado.`}
    />
  );

  renderScene = ({ route }) => {
    const { products = [], categories = [] } = this.props;
    switch (route.key) {
      case 'first':
        return categories && categories.length > 0 ? (
          <Categories
            navigation={this.props.navigation}
            categories={this.props.categories}
            onPressCategory={this.props.onPressCategory}
          />
        ) : this.EmptyView('categoria');
      case 'second':
        return products && products.length > 0 ? (
          <ProductsAndCategories
            navigation={this.props.navigation}
            products={this.props.products}
            componentId={this.props.componentId}
          />
        ) : this.EmptyView('produto');
      default:
        return null;
    }
  }

  render() {

    console.log('SearchResult PRODUCTS render', this.props);

    return (
      <TabView
        navigationState={this.state}
        removeClippedSubviews
        renderScene={(props) => this.renderScene(props)}
        renderTabBar={props => (<TabBar
          {...props}
        />)}
        onIndexChange={index => this.setState({ index })}
        initialLayout={{ width: Dimensions.get('window').width }}
      />
    );
  }
}

export default SearchResult;
