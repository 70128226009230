import React, { Component } from 'react';
import { View, Text, FlatList } from 'react-native';
import moment from 'moment';
import Section from '~/components/Section';
import StatusHistory from '~/components/StatusHistory';
import ProductOrder from '~/components/ProductOrder';
import { Money } from '~/common';
import sortBy from 'lodash/sortBy';

import styles from './styles';
import appStyles from '~/appStyles';
import AppConfig from '~/AppConfig';

type Props = {
  order: {},
  addressDelivery: {},
};

class OrderDetail extends Component<Props> {
  keyExtractor = item => item.id;

  paymentMethod(saleConditions) {
    let paymentMethod = 'Sem meio de pagamento';
    if (saleConditions) {
      saleConditions.forEach(saleCond => {
        if (saleCond.key === 'meio') {
          paymentMethod = saleCond.value_description;
        }
      });
    }
    return paymentMethod;
  }

  renderHeader(order) {
    let historyIntegrationStatus;
    if (order && order.history_integration_status && order.history_integration_status.length > 0) {
      historyIntegrationStatus = order.history_integration_status;
    } else if (order && order.integration_status) {
      historyIntegrationStatus = [order.integration_status];
    }

    const history = {
      code: historyIntegrationStatus.length + 1,
      description: 'ENVIADO',
    };

    const statusSorted = sortBy(historyIntegrationStatus, (item) => moment(item.status_date)).reverse();

    const status = [...statusSorted, history];

    return (
      <View>
        <View style={styles.clientContainer}>
          <Text style={styles.client}>{(order && order.customer_name) || 'Cliente sem nome'}</Text>
        </View>
        <Section title="Acompanhe seu pedido">
          <StatusHistory status={status} />
        </Section>
      </View>
    );
  }

  renderItem = ({ item, index }) => <ProductOrder product={item} />;

  renderItemSeparator() {
    return <View style={appStyles.listSeparator} />;
  }

  renderFooter(order) {
    let amount_shipping;
    let amount_tax;
    let amount_items;
    const { amount, subtotals } = order;
    const { showTaxes } = AppConfig.screenSettings.orderDetail;
    if (subtotals) {
      ({ amount_items, amount_shipping, amount_tax } = subtotals);
    }
    return (
      <View>
        { (!!amount_shipping || !!amount_tax) && <View style={appStyles.listSeparator} /> }

        {!!amount_shipping && (
        <View style={[appStyles.spaceBetween, styles.totalContainer]}>
          <Text style={styles.shippingTaxs}>Frete</Text>
          <Text style={styles.shippingTaxs}>{Money(amount_shipping)}</Text>
        </View>)
        }

        {!!amount_tax && showTaxes && (
        <View style={[appStyles.spaceBetween, styles.totalContainer]}>
          <Text style={[styles.shippingTaxs]}>Taxas</Text>
          <Text style={styles.shippingTaxs}>{Money(amount_tax)}</Text>
        </View>)
        }

        {!!amount_items && (
        <View style={[appStyles.spaceBetween, styles.totalContainer]}>
          <Text style={[styles.shippingTaxs]}>Total em Produtos</Text>
          <Text style={styles.shippingTaxs}>{Money(amount_items)}</Text>
        </View>)
        }

        <View style={appStyles.listSeparator} />
        <View style={[appStyles.spaceBetween, styles.totalContainer]}>
          <Text style={styles.totalLabel}>Total</Text>
          <Text style={styles.totalValue}>{Money(amount)}</Text>
        </View>
        <Section title="Endereço de entrega">
          {this.props.addressDelivery ? (
            <View>
              <Text style={styles.address}>{this.props.addressDelivery.address || 'Sem endereço'}</Text>
              <Text style={styles.address}>
                {this.props.addressDelivery.district || null}, {this.props.addressDelivery.city || null} -{' '}
                {this.props.addressDelivery.state || null}
              </Text>
            </View>
          ) : (
            <Text style={styles.address}>Sem endereço</Text>
          )}
          {order && order.account_phone ? (
            <Text style={styles.address}>{order.account_phone.main || 'Sem telefone'}</Text>
          ) : (
            <Text style={styles.address}>Sem telefone</Text>
          )}
        </Section>
        <Section title="Mais informações">
          <View style={appStyles.spaceBetween}>
            <Text style={styles.address}>Número do pedido</Text>
            <Text style={styles.address}>{order && order.order_code}</Text>
          </View>
          <View style={appStyles.spaceBetween}>
            <Text style={styles.address}>Data do pedido</Text>
            <Text style={styles.address}>
              {moment(order && order.sent_date).format('DD/MM/YYYY')}
            </Text>
          </View>
          <View style={appStyles.spaceBetween}>
            <Text style={styles.address}>Forma de pagamento</Text>
            <Text style={styles.address}>{this.paymentMethod(order && order.sale_conditions)}</Text>
          </View>
        </Section>
        {/* <View style={styles.actionsContainer}> */}
        {/* <Button */}
        {/*  title="Novo pedido" */}
        {/*  big */}
        {/*  flat */}
        {/*  transparent */}
        {/*  onPress={() => {}} */}
        {/* /> */}
        {/* <Button */}
        {/*  title="Copiar este pedido" */}
        {/*  big */}
        {/*  onPress={() => {}} */}
        {/* /> */}
        {/* </View> */}
      </View>
    );
  }

  render() {
    const { order } = this.props;
    const products = order ? order.products : [];

    return (
      <FlatList
        style={styles.listStyle}
        keyExtractor={this.keyExtractor}
        ListHeaderComponent={this.renderHeader(order)}
        ListFooterComponent={this.renderFooter(order)}
        renderItem={this.renderItem}
        ItemSeparatorComponent={this.renderItemSeparator}
        data={products}
      />
    );
  }
}

export default OrderDetail;
