// @flow

import type { ScrollEvent, SyntheticEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import type { ColorValue } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';
import type { ViewProps } from 'react-native/Libraries/Components/View/ViewPropTypes';
import type { TextStyleProp, ViewStyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';

export type ChangeEvent = SyntheticEvent<
  $ReadOnly<{|
    eventCount: number,
    target: number,
    text: string,
  |}>,
  >;

export type TextInputEvent = SyntheticEvent<
  $ReadOnly<{|
    eventCount: number,
    previousText: string,
    range: $ReadOnly<{|
      start: number,
      end: number,
    |}>,
    target: number,
    text: string,
  |}>,
  >;

export type ContentSizeChangeEvent = SyntheticEvent<
  $ReadOnly<{|
    target: number,
    contentSize: $ReadOnly<{|
      width: number,
      height: number,
    |}>,
  |}>,
  >;

type TargetEvent = SyntheticEvent<
  $ReadOnly<{|
    target: number,
  |}>,
  >;

export type BlurEvent = TargetEvent;
export type FocusEvent = TargetEvent;

type Selection = $ReadOnly<{|
  start: number,
  end: number,
|}>;

export type SelectionChangeEvent = SyntheticEvent<
  $ReadOnly<{|
    selection: Selection,
    target: number,
  |}>,
  >;

export type KeyPressEvent = SyntheticEvent<
  $ReadOnly<{|
    key: string,
    target?: ?number,
    eventCount?: ?number,
  |}>,
  >;

export type EditingEvent = SyntheticEvent<
  $ReadOnly<{|
    eventCount: number,
    text: string,
    target: number,
  |}>,
  >;

const DataDetectorTypes = [
  'phoneNumber',
  'link',
  'address',
  'calendarEvent',
  'none',
  'all',
];

type DataDetectorTypesType =
  | 'phoneNumber'
  | 'link'
  | 'address'
  | 'calendarEvent'
  | 'none'
  | 'all';

export type KeyboardType =
  // Cross Platform
  | 'Orders'
  | 'LetMeKnowContainer'
  | 'FavoritesContainer'
  | 'StoresContainer'
  | 'ProdCatContainer'
  | 'ProductContainer'
  | 'email-address'
  | 'numeric'
  | 'phone-pad'
  | 'number-pad'
  | 'decimal-pad'
  // iOS-only
  | 'ascii-capable'
  | 'numbers-and-punctuation'
  | 'url'
  | 'name-phone-pad'
  | 'twitter'
  | 'web-search'
  // Android-only
  | 'visible-password';

export type ReturnKeyType =
  // Cross Platform
  | 'done'
  | 'go'
  | 'next'
  | 'search'
  | 'send'
  // Android-only
  | 'none'
  | 'previous'
  // iOS-only
  | 'Orders'
  | 'LetMeKnowContainer'
  | 'FavoritesContainer'
  | 'StoresContainer'
  | 'ProdCatContainer'
  | 'ProductContainer'
  | 'emergency-call'
  | 'google'
  | 'join'
  | 'route'
  | 'yahoo';

export type AutoCapitalize = 'none' | 'sentences' | 'words' | 'characters';

type IOSProps = $ReadOnly<{|
  spellCheck?: ?boolean,
  keyboardAppearance?: ?('Orders' | 'light' | 'dark'),
  enablesReturnKeyAutomatically?: ?boolean,
  selectionState?: ?DocumentSelectionState,
  clearButtonMode?: ?('never' | 'while-editing' | 'unless-editing' | 'always'),
  clearTextOnFocus?: ?boolean,
  dataDetectorTypes?:
    | ?DataDetectorTypesType
    | $ReadOnlyArray<DataDetectorTypesType>,
  inputAccessoryViewID?: ?string,
  textContentType?: ?(
    | 'none'
    | 'URL'
    | 'addressCity'
    | 'addressCityAndState'
    | 'addressState'
    | 'countryName'
    | 'creditCardNumber'
    | 'emailAddress'
    | 'familyName'
    | 'fullStreetAddress'
    | 'givenName'
    | 'jobTitle'
    | 'location'
    | 'middleName'
    | 'name'
    | 'namePrefix'
    | 'nameSuffix'
    | 'nickname'
    | 'organizationName'
    | 'postalCode'
    | 'streetAddressLine1'
    | 'streetAddressLine2'
    | 'sublocality'
    | 'telephoneNumber'
    | 'email'
    | 'password'
    | 'newPassword'
    | 'oneTimeCode'
    ),
  scrollEnabled?: ?boolean,
|}>;

type AndroidProps = $ReadOnly<{|
  autoCompleteType?: ?(
    | 'cc-csc'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-number'
    | 'email'
    | 'name'
    | 'password'
    | 'postal-code'
    | 'street-address'
    | 'tel'
    | 'email'
    | 'off'
    ),
  returnKeyLabel?: ?string,
  numberOfLines?: ?number,
  disableFullscreenUI?: ?boolean,
  textBreakStrategy?: ?('simple' | 'highQuality' | 'balanced'),
  underlineColorAndroid?: ?ColorValue,
  inlineImageLeft?: ?string,
  inlineImagePadding?: ?number,
  importantForAutofill?: ?(
    | 'auto'
    | 'no'
    | 'noExcludeDescendants'
    | 'yes'
    | 'yesExcludeDescendants'
    ),
|}>;

export type Props = $ReadOnly<{|
  ...$Diff<ViewProps, $ReadOnly<{|style: ?ViewStyleProp|}>>,
  ...IOSProps,
  ...AndroidProps,
  autoCapitalize?: ?AutoCapitalize,
  autoCorrect?: ?boolean,
  autoFocus?: ?boolean,
  allowFontScaling?: ?boolean,
  maxFontSizeMultiplier?: ?number,
  editable?: ?boolean,
  keyboardType?: ?KeyboardType,
  returnKeyType?: ?ReturnKeyType,
  maxLength?: ?number,
  multiline?: ?boolean,
  onBlur?: ?(e: BlurEvent) => mixed,
  onFocus?: ?(e: FocusEvent) => mixed,
  onChange?: ?(e: ChangeEvent) => mixed,
  onChangeText?: ?(text: string) => mixed,
  onContentSizeChange?: ?(e: ContentSizeChangeEvent) => mixed,
  onTextInput?: ?(e: TextInputEvent) => mixed,
  onEndEditing?: ?(e: EditingEvent) => mixed,
  onSelectionChange?: ?(e: SelectionChangeEvent) => mixed,
  onSubmitEditing?: ?(e: EditingEvent) => mixed,
  onKeyPress?: ?(e: KeyPressEvent) => mixed,
  onScroll?: ?(e: ScrollEvent) => mixed,
  placeholder?: string,
  placeholderTextColor?: ?ColorValue,
  secureTextEntry?: ?boolean,
  selectionColor?: ?ColorValue,
  selection?: ?$ReadOnly<{|
    start: number,
    end?: ?number,
  |}>,
  value?: string,
  defaultValue?: string,
  selectTextOnFocus?: ?boolean,
  blurOnSubmit?: ?boolean,
  style?: ?TextStyleProp,
  caretHidden?: ?boolean,
  contextMenuHidden?: ?boolean,
|}>;
