import React, { Component } from 'react';
import { Alert, View, Text, TextInput, StyleSheet, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import axios from 'axios';
import colors from '~/values/color';
import { URL_BASE, getCurrentCustomer, getConfig } from '~/services/index';
import RegisterAddressController from '~/controllers/RegisterAddressController';
import NavigationHelper from '~/screens/NavigationHelper';
import Images from '~/../assets/images';
import alert from '~/components/AlertWeb';
import { StackActions } from '@react-navigation/native';



const styles = StyleSheet.create({
  container: {
    paddingTop: 23,
  },
  inputStart: {
    justifyContent: 'flex-start',
    margin: 15,
    height: 40,
    borderBottomWidth: 1,
    borderColor: colors.primaryColor,
    fontWeight: 'bold',
  },
  inputEnd: {
    justifyContent: 'flex-end',
    margin: 15,
    height: 40,
    borderBottomWidth: 1,
    borderColor: colors.primaryColor,
    fontWeight: 'bold',
  },
  submitButton: {
    backgroundColor: colors.primaryColor,
    padding: 10,
    margin: 15,
    height: 40,
  },
  tagButton: {
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: colors.grayDark,
    borderWidth: 1,
    padding: 10,
    margin: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectedButton: {
    // backgroundColor: colors.primaryColor,
    backgroundColor: 'white',
    borderColor: colors.primaryColor,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
    margin: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submitButtonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    top: 2,
  },
  tagText: {
    color: colors.grayDark,
    textAlign: 'center',
    fontWeight: 'bold',
    top: 2,
  },
  submitButtonTextSelected: {
    color: colors.primaryColor,
    textAlign: 'center',
    fontWeight: 'bold',
    top: 2,
  },
});

type Props = {
    componentId: string,
    finalLocation: any,
  };

class CompleteAddress extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      editLocation: false,
      sendData: false,
      address: {
        account_id: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.account_id !== 'undefined' ? this.props.route.params.finalLocation.account_id  : '',
        street_number: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.street_number !== 'undefined' ? this.props.route.params.finalLocation.street_number  : '',
        street: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.street !== 'undefined' ? this.props.route.params.finalLocation.street  : '',
        district: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.district !== 'undefined' ? this.props.route.params.finalLocation.district  : '',
        city: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.city !== 'undefined' ? this.props.route.params.finalLocation.city  : '',
        state_city: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.state_city !== 'undefined' ? this.props.route.params.finalLocation.state_city  : '',
        country: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.country !== 'undefined' ? this.props.route.params.finalLocation.country  : '',
        postal_code: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.postal_code !== 'undefined' ? this.props.route.params.finalLocation.postal_code  : '',
        complement: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.complement !== 'undefined' ? this.props.route.params.finalLocation.complement  : '',
        reference_point: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.reference_point !== 'undefined' ? this.props.route.params.finalLocation.reference_point  : '',
        // select: false,
        latitude: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.latitude !== 'undefined' ? this.props.route.params.finalLocation.latitude  : '',
        longitude: this.props.route.params.finalLocation && typeof this.props.route.params.finalLocation.longitude !== 'undefined' ? this.props.route.params.finalLocation.longitude  : '',
        // latitudeDelta: 0.01,
        // longitudeDelta: 0.01,
        surname: null,
        kind: null,
      },
      selectedTag: {
        home: false,
        office: false,
        other: false,
      },
    };
    this.registerAddrController = new RegisterAddressController();
  }


  async componentDidMount() {
    console.log('COMPLETE ADDR PROPS DIDMOUNT: ', this.props);
    if (typeof this.props.route.params.finalLocation.account_id === 'undefined' || this.props.route.params.finalLocation.account_id == '') {
      const acc = await getCurrentCustomer();
      console.log('COMPLETE ACC: ', acc);
      this.setState(state => ({ address: Object.assign({}, state.address, { account_id: acc.account_id }) }));
    } else {
      console.log('COMPLETE ELSE');
      if (this.props.route.params.finalLocation.kind === 'HOME') {
        this.setState(state => ({ editLocation: true, selectedTag: Object.assign({}, state.selectedTag, { home: true }), address: Object.assign({}, state.address, { surname: this.props.route.params.finalLocation.surname, kind: this.props.route.params.finalLocation.kind }) }));
      }
      if (this.props.route.params.finalLocation.kind === 'OFFICE') {
        this.setState(state => ({ editLocation: true, selectedTag: Object.assign({}, state.selectedTag, { office: true }), address: Object.assign({}, state.address, { surname: this.props.route.params.finalLocation.surname, kind: this.props.route.params.finalLocation.kind }) }));
      }
      if (this.props.route.params.finalLocation.kind === 'OTHER') {
        this.setState(state => ({ editLocation: true, selectedTag: Object.assign({}, state.selectedTag, { other: true }), address: Object.assign({}, state.address, { surname: this.props.route.params.finalLocation.surname, kind: this.props.route.params.finalLocation.kind }) }));
      }
      if (this.props.route.params.finalLocation.kind === null) {
        this.setState(state => ({ editLocation: true, address: Object.assign({}, state.address) }));
      }
    }
  }

  postAddr = async () => {
    console.log('DATA CALLED POST ADDR');
    const { address } = this.state;

    if (address.street.trim() === '' || address.district.trim() === ''
    || address.street_number.trim() === '' || address.reference_point.trim() === '') {
      alert('Há campos vazios. Preencha todos os campos');
    } else {
      console.log('DATA POST ADDR ELSE');
      // eslint-disable-next-line no-lonely-if
      if (this.state.editLocation) {
        console.log('DATA IF STATE EDIT');
        console.log('DATA STATE EDIT LOCALTION: ', this.state.editLocation);
        const payloadEdit = {
          account_id: address.account_id,
          street_number: parseInt(address.street_number),
          street: address.street,
          district: address.district,
          city: address.city,
          state_city: address.state_city,
          country: address.country,
          postal_code: address.postal_code,
          complement: address.complement,
          reference_point: address.reference_point,
          latitude: JSON.parse(address.latitude),
          longitude: JSON.parse(address.longitude),
          // created_at: address.created_at,
          // updated_at: address.updated_at,
          // deleted_at: address.deleted_at,
          surname: address.surname,
          kind: address.kind,
        };
        this.setState({ sendData: true });
        const config = await getConfig();
        let apiRes = null;
        try {
          console.log('DATA CONFIG: ', config);
          console.log('DATA FINAL LOCATION ID: ', this.props.route.params.finalLocation.id);
          apiRes = await axios.put(`${URL_BASE}/address/${this.props.route.params.finalLocation.id}/`, payloadEdit, config);
          // console.log('DATA API RES: ', apiRes);
          console.log('API RES: ', apiRes);
          if (apiRes !== null && apiRes.status === 200) {
            const payloadBD = {
              id: apiRes.data.id,
              account_id: apiRes.data.account_id,
              street_number: apiRes.data.street_number,
              street: apiRes.data.street,
              district: apiRes.data.district,
              city: apiRes.data.city,
              state_city: apiRes.data.state_city,
              country: apiRes.data.country,
              postal_code: apiRes.data.postal_code,
              complement: apiRes.data.complement,
              reference_point: apiRes.data.reference_point,
              latitude: JSON.stringify(address.latitude),
              longitude: JSON.stringify(address.longitude),
              created_at: apiRes.data.created_at,
              updated_at: apiRes.data.updated_at,
              deleted_at: apiRes.data.deleted_at,
              surname: apiRes.data.surname,
              kind: apiRes.data.kind,
            };
            await this.registerAddrController.updateRegisterAddress(payloadBD);
            alert(
              'Sucesso',
              'Seu endereço foi alterado!',
              [
                {
                  text: 'OK',
                  onPress: () => {
                    this.setState({ sendData: false });
                    this.props.navigation.dispatch(StackActions.popToTop());
                  },
                },
              ],
              { cancelable: false },
            );
          }
        } catch (err) {
          alert(
            `Erro: ${err.response.status}`,
            'Contate o suporte',
            [
              {
                text: 'OK',
                onPress: () => {
                  // NavigationHelper.switchTab(this.props.componentId, 0);
                  this.setState({ sendData: false });
                },
              },
            ],
            { cancelable: false },
          );
        }
      } else {
        console.log('DATA ELSE STATE EDIT');
        this.setState({ sendData: true });
        const config = await getConfig();
        console.log('DATA ELSE CONFIG: ', config);
        let apiRes = null;
        try {
          console.log('DATA ELSE TRY');
          apiRes = await axios.post(`${URL_BASE}/address/`, {
            ...this.state.address,
            street_number: parseInt(this.state.address.street_number),
          }, config);
          console.log('API RES: ', apiRes);
          if (apiRes !== null && apiRes.status === 200) {
            this.registerAddrController.addRegisterAddress([apiRes.data]);
            alert(
              'Sucesso',
              'Seu endereço foi cadastrado!',
              [
                {
                  text: 'OK',
                  onPress: () => {
                    this.setState({ sendData: false });
                    this.props.navigation.dispatch(StackActions.popToTop());
                  },
                },
              ],
              { cancelable: false },
            );
          }
        } catch (err) {
          console.log('ERROR: ', err);
          alert(
            `Erro: ${err.response.status}`,
            'Contate o suporte',
            [
              {
                text: 'OK',
                onPress: () => {
                // NavigationHelper.switchTab(this.props.componentId, 0);
                  this.setState({ sendData: false });
                },
              },
            ],
            { cancelable: false },
          );
        }
      }
    }
  }

  selectedTagHome = () => {
    this.setState(prevState => ({
      selectedTag: {
        home: !this.state.selectedTag.home,
        office: false,
        other: false,
      },
    }), () => {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          kind: this.state.selectedTag.home ? 'HOME' : '',
          surname: this.state.selectedTag.home ? 'home' : '',
        },
      }));
    });
  }

  selectedTagOffice = () => {
    this.setState(prevState => ({
      selectedTag: {
        home: false,
        office: !this.state.selectedTag.office,
        other: false,
      },
    }), () => {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          kind: this.state.selectedTag.office ? 'OFFICE' : '',
          surname: this.state.selectedTag.office ? 'office' : '',
        },
      }));
    });
  }

  selectedTagOther = () => {
    this.setState(prevState => ({
      selectedTag: {
        home: false,
        office: false,
        other: !this.state.selectedTag.other,
      },
      address: {
        ...prevState.address,
        kind: this.state.selectedTag.other ? 'OTHER' : '',
        surname: '',
      },
    }));
  }

  render() {
    const { address } = this.state;
    return (
      <View styles={styles.container}>
        <View style={{ paddingTop: 30, margin: 15 }}>
          <Text style={{ fontWeight: 'bold' }}>{`${address.street}, ${address.street_number}`}</Text>
          <Text>{`${address.city} - ${address.state_city}`}</Text>
        </View>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <TextInput placeholderTextColor={colors.grayTextInput} placeholder="Rua" value={address.street} onChangeText={text => this.setState(state => ({ address: { ...state.address, street: text } }))} style={styles.inputStart} />
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <TextInput placeholderTextColor={colors.grayTextInput} placeholder="Bairro" value={address.district} onChangeText={text => this.setState(state => ({ address: { ...state.address, district: text } }))} style={styles.inputEnd} />
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <TextInput
                placeholderTextColor={colors.grayTextInput}
                placeholder="Número"
                keyboardType="numeric"
                value={address.street_number}
                onChangeText={text => this.setState(state => ({ address: { ...state.address, street_number: text } }))}
                style={styles.inputStart}
              />
            </View>
            <View style={{ flex: 1 }}>
              <TextInput placeholderTextColor={colors.grayTextInput} placeholder="Complemento" value={address.complement} onChangeText={text => this.setState(state => ({ address: { ...state.address, complement: text } }))} style={styles.inputEnd} />
              <View style={{ marginLeft: 15 }}>
                <Text>Apto / Bloco / Casa</Text>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <TextInput placeholderTextColor={colors.grayTextInput} placeholder="Ponto de referência" value={address.reference_point} onChangeText={text => this.setState(state => ({ address: { ...state.address, reference_point: text } }))} style={styles.inputStart} />
            </View>
          </View>
          <View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <TouchableOpacity
                  style={this.state.selectedTag.home ? styles.selectedButton : styles.tagButton}
                  onPress={this.selectedTagHome}
                >
                  <Image
                    style={{ tintColor: this.state.selectedTag.home ? colors.primaryColor : colors.gray, height: 20, width: 20 }}
                    source={Images.iconHome}
                  />
                  <Text style={this.state.selectedTag.home ? styles.submitButtonTextSelected : styles.tagText}> Casa </Text>
                </TouchableOpacity>

              </View>


              <View>
                <TouchableOpacity
                  style={this.state.selectedTag.office ? styles.selectedButton : styles.tagButton}
                  onPress={this.selectedTagOffice}
                >
                  <Image
                    style={{ tintColor: this.state.selectedTag.office ? colors.primaryColor : colors.gray, height: 20, width: 20 }}
                    source={Images.iconOffice}
                  />
                  <Text style={this.state.selectedTag.office ? styles.submitButtonTextSelected : styles.tagText}> Trabalho </Text>
                </TouchableOpacity>
              </View>

              <View>
                <TouchableOpacity
                  style={this.state.selectedTag.other ? styles.selectedButton : styles.tagButton}
                  onPress={this.selectedTagOther}
                >
                  <Image
                    style={{ tintColor: this.state.selectedTag.other ? colors.primaryColor : colors.gray , height: 20, width: 20 }}
                    source={Images.iconAddress}
                  />
                  <Text style={this.state.selectedTag.other ? styles.submitButtonTextSelected : styles.tagText}> Outro </Text>
                </TouchableOpacity>
              </View>
            </View>

            {this.state.selectedTag.other ?
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <TextInput placeholder="Nome para o endereço" value={this.state.address.surname} onChangeText={text => this.setState(state => ({ address: { ...state.address, surname: text, kind: 'OTHER' } }))} style={styles.inputStart} />
                </View>
              </View> : null }

          </View>
        </View>
        <View>
          {this.state.sendData ?
            <ActivityIndicator size="large" color={colors.primaryColor} /> :
            <TouchableOpacity
              style={styles.submitButton}
              onPress={this.postAddr}
            >
              <Text style={styles.submitButtonText}> Salvar Endereço </Text>
            </TouchableOpacity>}
        </View>
      </View>
    );
  }
}

export default CompleteAddress;
