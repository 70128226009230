// @flow

import React from 'react';
import { View, Text } from 'react-native';

import styles from './styles';

type PropsSection = {
  title?: string,
  children?: React.ReactElement | React.ReactNode | React.ReactNodeArray,
  style: any,
};


const Section = ({ title, children, style }: PropsSection) => (
  <View style={[styles.container, style]}>
    {
      title &&
      <Text style={styles.title}>{title}</Text>
    }
    {children}
  </View>
);

Section.defaultProps = {
  children: null,
};

export default Section;
