import axios from 'axios';

import { URL_BASE } from '../index';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import AppConfig from '~/AppConfig';

export default function login(email, password) {

  console.log('export default function login', email, password);

  return new Promise(async (resolve, reject) => {
    try {
      const device_info = {
        device_uuid: DeviceInfo.getUniqueId(),
        device_model: DeviceInfo.getModel(),
        system_info: Platform.OS,
      };

      const application_code = AppConfig.appCode;
      const dataAccount = {
        email, password, device_info, application_code,
      };

      const response = await axios.post(`${URL_BASE}/auth/login/`, dataAccount);
      resolve(response.data);
    } catch (error) {
      reject(error.response);
    }
  });
}
