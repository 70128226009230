import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  listStyle: { backgroundColor: '#FFF' },
  clientContainer: {
    backgroundColor: colors.grayContainer,
    paddingHorizontal: sizes.horizontalSpace,
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
  },
  client: {
    color: '#000',
    fontWeight: '600',
    fontSize: sizes.textLittleBig,
  },
  totalContainer: {
    paddingHorizontal: sizes.horizontalSpacing,
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    ...appStyles.borderBottomSeparator,
  },
  shippingTaxs: {
    color: colors.grayMid,
    fontSize: 13,
  },
  totalLabel: {
    color: '#000',
    fontWeight: '600',
    fontSize: sizes.textSmall,
  },
  totalValue: {
    color: '#000',
    fontSize: sizes.textSmall,
  },
  address: {
    color: colors.grayMid,
  },
  actionsContainer: {
    paddingHorizontal: sizes.horizontalSpacing,
  },
});

export default styles;
