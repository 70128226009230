import Config from '../app.json';
import Images from '~/../assets/images';
import { SALE_MODEL_HYBRID } from '~/constants/saleModel';

const AppConfig = {
  appName: 'Parima App',
  appCode: 'pari',
  combos: {
    visible: false,
  },
  logoApp: Images.pariLogo,
  primaryColor: '#074885',
  need_request_access: true,
  shipping: true,
  imageStocker: true,
  tokenStocker:
    'PARI4jZjjhuMNbf5A8ysCAbm0A62O5eW',
  iconsCategories: {
    '101-ALIMENTICIO': '{"type":"resource","setup":{},"data":"alimenticios"}',
    '102-BEBIDAS': '{"type":"resource","setup":{},"data":"bebidas"}',
    '103-CUIDADO DIARIO': '{"type":"resource","setup":{},"data":"cuidadodiario"}',
    '104-LIMPEZA': '{"type":"resource","setup":{},"data":"limpeza"}',
    '105-FARMACIA': '{"type":"resource","setup":{},"data":"farmacia"}',
    '106-HIGIENE PESSOAL': '{"type":"resource","setup":{},"data":"higienepessoal"}',
    '109-outros': '{"type":"resource","setup":{},"data":"others"}',
  },
  allContexts: ['sales_history', 'order_suggestion', 'best_sellers'],
  contexts: [
    {
      screen: 'home',
      ids: ['sales_history', 'order_suggestion'],
    },
    {
      screen: 'cart',
      ids: ['best_sellers'],
    },
  ],
  screenSettings: {
    cart: {
      showTaxes: true,
    },
    orderDetail: {
      showTaxes: true,
    },
    register_settings: {
      acceptCNPJ: true,
      fields: [
        {
          id: 'personDocument',
          description: 'CPF/CNPJ',
          required: true,
        },
        {
          id: 'name',
          description: 'Nome',
          required: true,
        },
        {
          id: 'exhibitionName',
          description: 'Nome de Exibição',
          required: true,
        },
        {
          id: 'birthday',
          description: 'Data de Nascimento',
          required: false,
        },
        {
          id: 'email',
          description: 'Email',
          required: false,
        },
        {
          id: 'password',
          description: 'Senha',
          required: false,
        },
        {
          id: 'confirmationPassword',
          description: 'Confirmar Senha',
          required: false,
        },
        {
          id: 'phone',
          description: 'Celular',
          required: true,
        },
        {
          id: 'landline',
          description: 'Telefone fixo',
          required: false,
        },
        {
          id: 'zipCode',
          description: 'CEP',
          required: false,
        },
        {
          id: 'street',
          description: 'Rua',
          required: false,
        },
        {
          id: 'addressNumber',
          description: 'Número',
          required: false,
        },
        {
          id: 'district',
          description: 'Bairro',
          required: false,
        },
        {
          id: 'city',
          description: 'Cidade',
          required: false,
        },
        {
          id: 'complement',
          description: 'Complemento',
          required: false,
        },
        {
          id: 'state',
          description: 'Estado',
          required: false,
        },
      ],
    },
  },
  version: Config.version,
  showPricesToAnonymous: true,
  productDescriptionFontSize: 16,
  sortByProductsNoStock: false,
  has_observation: true,
  limitCharactersInObservation: 100,
  generateLogFile: false,
  sale_model: SALE_MODEL_HYBRID,
  canEditRegister: true,
  license_agreement: {
    exhibition: false,
    url: '',
  },
  descriptionPrice: {
    show: true,
    text: 'A partir de',
  },
  startFromStore: false,
  showDenounceOption: false,
  contacts: [
    {
      type: 'whatsapp',
      setup: [{ key: '', value: '' }],
    },
    {
      type: 'phone',
      setup: [{ key: '', value: '' }],
    },
    {
      type: 'email',
      setup: [{ key: '', value: '' }],
    },
    {
      type: 'facebook',
      setup: [{ key: '', value: '' }],
    },
    {
      type: 'instagram',
      setup: [{ key: '', value: '' }],
    },
  ],
  registerAddress: true,
  categoriesIconConfig: {
    width: 40,
    height: 40,
    tintColor: '#074885',
  },
  sortCategoriesRootByDescription: false,
};

export default AppConfig;
