import { Platform } from 'react-native';
import chroma from 'chroma-js';
import AppConfig from '~/AppConfig';

const PRIMARY_COLOR = AppConfig.primaryColor;
const ACCENT_COLOR = '#fd9700';
const SUCCESS_COLOR = '#89D500';
const WARNING_COLOR = '#fd8a00';
const DANGER_COLOR = '#ff000f';

const lighten = (color, hslPercent) => chroma(color).set('hsl.l', chroma(color).get('hsl.l') + hslPercent);
const darken = (color, hslPercent) => lighten(chroma(color), -hslPercent);

export default {
  primaryColorTransparent: `${PRIMARY_COLOR}10`,
  primaryColor: PRIMARY_COLOR,
  primaryColorLight: lighten(PRIMARY_COLOR, 0.2),
  primaryColorLightest: lighten(PRIMARY_COLOR, 0.45),
  primaryColorDark: darken(PRIMARY_COLOR, 0.2),
  primaryColorDarkest: darken(PRIMARY_COLOR, 0.4),
  accentColor: ACCENT_COLOR,
  accentColorLight: chroma(ACCENT_COLOR).brighten(2).hex(),
  accentColorLightest: chroma(ACCENT_COLOR).brighten(2.6).hex(),
  accentColorDark: chroma(ACCENT_COLOR).darken(2).hex(),
  accentColorDarkest: chroma(ACCENT_COLOR).darken(2.6).hex(),
  successColor: SUCCESS_COLOR,
  successColorLight: chroma(SUCCESS_COLOR).brighten(2).hex(),
  successColorLightest: chroma(SUCCESS_COLOR).brighten(2.6).hex(),
  successColorDark: chroma(SUCCESS_COLOR).darken(2).hex(),
  successColorDarkest: chroma(SUCCESS_COLOR).darken(2.6).hex(),
  warningColor: WARNING_COLOR,
  warningColorLight: lighten(WARNING_COLOR, 0.2),
  warningColorLightest: lighten(WARNING_COLOR, 0.4),
  warningColorDark: chroma(WARNING_COLOR).darken(2).hex(),
  warningColorDarkest: chroma(WARNING_COLOR).darken(2.6).hex(),
  dangerColor: DANGER_COLOR,
  dangerColorLight: lighten(DANGER_COLOR, 0.2),
  dangerColorLightest: lighten(DANGER_COLOR, 0.4),
  dangerColorDark: chroma(DANGER_COLOR).darken(2).hex(),
  dangerColorDarkest: chroma(DANGER_COLOR).darken(2.6).hex(),
  black: '#000',
  grayRed: '#ae2029',
  grayRedDark: '#8d343a',
  grayTextInput: '#7d7d7d',
  grayContainer: Platform.OS === 'ios' ? '#EFEFF4' : '#F5F5F9',
  grayContainerDark: '#dcdcdc',
  grayDark: '#7a7a7a',
  grayDarkest: '#414141',
  grayMid: '#969696',
  gray: '#D8D8D8',
  grayAlmostWhite: '#f8f8f8',
  navBarButtonColor: '#fff',
  navBarSubtitleColor: '#fff',
  tabBarBackgroundColor: '#fff',
  tabBarSelectedTextColor: PRIMARY_COLOR,
  separatorList: '#d8e1e1',
  blueBadge: '#00a4ff',
  blueIOS: '#157efb',
  redBadge: '#df0800',
  redLight: '#FF6D6D',
  redLightest: '#ffacb1',
  yellowVibrant: '#ffb318',
  yellowBadge: '#df9900',
  facebookBlue: '#4267b2',
  googleRed: '#db4437',
  graySearchContainer: '#dcdce1',
  stripGreen: '#7dc736',
  stripGreenLightest: '#e7ffd0',
  whatsappGreen: '#075E54',
  whatsappGreenLight: '#25D366',
  stripOrange: '#c76e00',
  infoAlert: '#FF7E00',
  stripGreenDark: '#5f9f2d',
  stripBlue: '#3ba1c1',
  textEmpty: '#8cc5da',
  sectionIOS: '#6D6D72',
};
