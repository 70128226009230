// @flow

import React from 'react';
import { View, Image, Text } from 'react-native';
import { TouchableOpacity } from 'react-native';

import styles from './styles';
import Images from '~/../assets/images';

type OptionProps = {
  selected: boolean,
  text: string,
  onPress: () => void,
};

const Option = ({ image, text, onPress, selected }: OptionProps) => {
  return (
    <TouchableOpacity
      style={styles.optionContainer}
      onPress={onPress}
    >
      <View style={styles.optionContainer}>
        <View>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              source={image}
            />
          </View>
          {
            selected ?
              <View
                style={styles.badge}
              >
                <Image
                  style={styles.iconCheck}
                  source={Images.iconCheck}
                />
              </View>
              :
              null
          }
        </View>
        <Text style={styles.optionText}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default Option;
