import Controller from "~/controllers/Controller";
import PaymentCombineKeyRepository from "~/database/repositories/PaymentCombineKeyRepository";

export default class PaymentCombineKeyController extends Controller {
  constructor() {
    super();
    this.repository = new PaymentCombineKeyRepository();
  }
  addPaymentCombineKey(pay) {
    return this.repository.addPaymentCombineKey(pay);
  }
  getAllPaymentCombineKey() {
    return this.repository.getAllPaymentCombineKey();
  }
}
