import { Image, StyleSheet, View } from 'react-native';
import React from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  imageSrc: any,
  imageStyle: any,
  containerStyle: any,
  text: string,
  textStyle: any,
};

const size = 40;
const margin = 4;
const iconContainerSize = size - 2 * margin;
const iconPadding = 6;

const styles = StyleSheet.create({
  rootIconContainer: {
    height: iconContainerSize,
    width: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'flex-end',
    margin,
  },
  iconContainer: {
    height: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  icon: {
    height: iconContainerSize - 3 * iconPadding,
    width: iconContainerSize - 3 * iconPadding,
  },
});

class Icon extends React.Component<Props, void> {
  render() {
    const { icon, onRemove, item } = this.props;

    return (
      <View style={styles.rootIconContainer}>
        <TouchableOpacity
          onPress={() => onRemove(item)}
        >
          <View style={styles.iconContainer}>
            <Image style={styles.icon} source={icon} />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default Icon;
