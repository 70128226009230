import get from 'lodash/get';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import listCompanies from '../../services/companies';
import { COMPANY_ACTIVE } from '~/constants/cache';
import CompanyController from '~/controllers/CompanyController';

export async function verifyCustomerInCompany() {
  const company = await CacheStore.get(COMPANY_ACTIVE);

  if (company && company.customer_company_accounts &&
    company.customer_company_accounts.length > 0) {
    return true;
  }

  return false;
}

export async function syncCompanies() {
  const response = await listCompanies();
  const companies = get(response, ['source', 'data']) || [];

  const companyController = new CompanyController();
  await companyController.addCompany(companies);

  return true;
}
