// eslint-disable-next-line no-use-before-define
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PromotionDetail from '~/screens/PromotionDetail';
import Promotions from '~/screens/Promotions';
import { colors } from '~/values';
import { SCREEN_PROMOTION_DETAIL, SCREEN_PROMOTIONS } from '~/screens/screens';

const PromotionsStack = createStackNavigator();

const PromotionsRoutes = () => (
  <PromotionsStack.Navigator screenOptions={{
    title: 'Promoções',
    headerStyle: {
      backgroundColor: colors.primaryColor,
      textAlign: 'center',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',

    },
  }}>
    <PromotionsStack.Screen name={SCREEN_PROMOTIONS.name} component={Promotions}/>
    <PromotionsStack.Screen name={SCREEN_PROMOTION_DETAIL.name} component={PromotionDetail}/>
  </PromotionsStack.Navigator>
);

export default PromotionsRoutes;
