import axios from 'axios';
import get from 'lodash/get';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

export default async function clearAllMessages(idOrder) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  return new Promise((resolve, reject) =>
    axios
      .post(`${URL_BASE}/cart/${company}/detail/${idOrder}/message/clear/`, {}, config)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(get(error, 'response.data', {}));
      }));
}
