import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { Icon } from '~/components';
import styles from './styles';
import Images from '~/../assets/images';

type Props = {
  text: string,
};

class Warning extends Component<Props> {
  render() {
    const { text, onClear, item } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.errorContainer}>
          <Text style={styles.text}>{text}</Text>
          <Icon icon={Images.iconClose} style={styles.iconClose} onRemove={onClear} item={item} />
        </View>
      </View>
    );
  }
}

export default Warning;
