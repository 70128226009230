import RNFS from '~/modules-wrapper/react-native-fs';

const path = RNFS.DocumentDirectoryPath;

class FileSystem {
  static create = (fileName, content) => RNFS.writeFile(`${path}/${fileName}`, content, 'utf8');

  static read = (fileName) => RNFS.readFile(`${path}/${fileName}`, 'utf8');

  static delete = (fileName) => RNFS.unlink(`${path}/${fileName}`);
}

export default FileSystem;
