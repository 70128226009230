import { StyleSheet } from 'react-native';
import { colors } from '~/values';



const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primaryColor,
    minHeight: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  icon: {
    tintColor: '#FFF',
    width: 18,
    height: 18,
  },
  text: {
    color: '#FFF',
    marginHorizontal: 6,
    fontSize: 13,
    flex: 1,
  },
  textStore: {
    fontWeight: '600',
  },
});

export default styles;
