import React, { useRef } from 'react';
import { StyleSheet } from 'react-native';
import { registerField, useField } from '@unform/core';
import { useEffect } from 'react';
import { colors } from '~/values';
import "./styles.css";

function InputWeb({ name, label, ...rest }) {
    const inputRef = useRef(null);
    const {fieldName, registerField, defaultValue = "", error } = useField(name);


    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]); 

    return (
        <div className="input-container">
            {/*{label && <label className="label"  htmlFor={fieldName}>{label}</label>}*/}
             <input ref={inputRef} id={fieldName} defaultValue={defaultValue} {...rest} />
            {error && <span style={{ color: '#f00' }}>{error}</span>}
        </div>
        
    );
};

export default InputWeb;