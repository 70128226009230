import Controller from "~/controllers/Controller";
import PaymentTermRepository from "~/database/repositories/PaymentTermRepository";

export default class PaymentTermController extends Controller {
  constructor() {
    super();
    this.repository = new PaymentTermRepository();
  }
  addPaymentTerm(pay) {
    return this.repository.addPaymentTerm(pay);
  }
  getAllPaymentTerm() {
    return this.repository.getAllPaymentTerm();
  }
}
