import Controller from '~/controllers/Controller';
import SalesRepository from '~/database/repositories/SalesRepository';

export default class SalesController extends Controller {
  constructor() {
    super();
    this.repository = new SalesRepository();
  }
  addSales(sales) {
    return this.repository.addSales(sales);
  }
  
  getAllSales() {
    return this.repository.getAllSales();
  }
}
