import { StyleSheet } from 'react-native';
import { sizes, colors } from '~/values';

const avatarSize = 50;

const defaultText = {
  fontSize: sizes.textLittleBig,
  fontWeight: '600',
};

const defaultEmail = {
  fontSize: sizes.textNormal,
};

const defaultMenuDown = {
  width: 20,
  height: 20,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    width: avatarSize,
    height: avatarSize,
    borderRadius: avatarSize / 2,
    overflow: 'hidden',
    backgroundColor: colors.grayContainerDark,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: sizes.horizontalSpacing,
  },
  imageNoUserContainer: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: sizes.horizontalSpacing,
  },
  iconAvatar: {
    width: avatarSize / 1.5,
    height: avatarSize / 1.5,
    tintColor: '#FFF',
  },
  imageAvatar: {
    width: avatarSize,
    height: avatarSize,
    resizeMode: 'cover',
  },
  name_light: {
    ...defaultText,
    color: colors.grayDarkest,
  },
  name_dark: {
    ...defaultText,
    color: '#FFF',
  },
  email_light: {
    ...defaultEmail,
    color: colors.grayDarkest,
  },
  email_dark: {
    ...defaultEmail,
    color: '#FFF',
  },
  iconMenuDown_light: {
    ...defaultMenuDown,
    tintColor: colors.grayDarkest,
  },
  iconMenuDown_dark: {
    ...defaultMenuDown,
    tintColor: '#FFF',
  },
});

export default styles;
