// @flow

import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Touchable } from 'react-native-web';

import styles from './styles';

export type SimpleOptionProps = {
  id: number,
  text: string,
  onPress: () => void,
};

const SimpleOption = ({ id, text, onPress }: SimpleOptionProps) => (
  <TouchableOpacity key={id} onPress={onPress}>
    <View style={styles.container}>
      <Text style={styles.label}>{text}</Text>
    </View>
  </TouchableOpacity>
);

export default SimpleOption;
