import Repository from './Repository';
import { PRODUCT_CONTEXT_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'ProductContextRepository';

export default class ProductContextRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PRODUCT_CONTEXT_SCHEMA;
  }

  allContexts() {
    return new Promise((resolve, reject) => {
      SQLiteManager.allContexts().then(context => {
        resolve(context);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addProductCtx(prods) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addProductCtx(prods).then(prodCTX => {
        resolve(prodCTX);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  allByContexts(contexts) {
    return new Promise((resolve, reject) => {
      SQLiteManager.allByContexts(contexts).then(context => {
        resolve(context);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
