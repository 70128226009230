import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';
import type { ISource } from '~/entities/Source';

const TAG = 'listProductContext';

export default async function listProductContext(source: ISource, retries = 3, backoff = 300) {
  const { args } = source.setup;
  const queryParam = args.length > 0 ? `?${args[0].key}=${args[0].value}` : '';

  const config = await getConfig();
  const company = await getCurrentCompany();

  const retryCodes = [408, 500, 502, 503, 504, 522, 524];
  return new Promise(((resolve, reject) => {
    axios.get(`${URL_BASE}/products/${company}/context/${queryParam}`, config)
      .then(response => resolve(response.data))
      .catch((error) => {
        if (retries === 1) {
          console.log(TAG);
          return reject(error);
        }
        if (retries > 1 && retryCodes.includes(error.response.status)) {
          return resolve(listProductContext());
        }
        return resolve([]);
      });
  }));
}
