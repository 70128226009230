import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

export default function Input({ name, label, onChange, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue: (ref, value) => {
        ref.current.value = value;
        console.log('setValue', value);
      },
    });

    console.log('setValue fieldName, registerField', fieldName, registerField);

  }, [fieldName, registerField]);

  console.log('setValue fieldName, registerField');

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <input
        ref={inputRef}
        id={fieldName}
        defaultValue={defaultValue}
        onChange={(event)=> {
          console.log('INPUT event', event.target.value);
          onChange(event.target.value);
        }}
        {...rest}
      />

      {error && <span style={{ color: "#f00" }}>{error}</span>}
    </>
  );
}
