import Images from '~/../assets/images';

const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
const mastercard = /^(5[1-5]\d{4}|677189)\d{10}$/;
const american = /^3[47]\d{13}$/;
const aura = /^(5078\d{2})(\d{2})(\d{11})$/;
const discover  = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
const elo = /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/;
const diners = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
const hipercard = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
const jbc = /^(?:2131|1800|35\d{3})\d{11}$/;

const cards = [
  {
    name: 'Visa',
    pattern: visa,
    brand: Images.flag_visa,
    brandName: 'visa',
  },
  {
    name: 'Mastercard',
    pattern: mastercard,
    brand: Images.flag_mastercard,
    brandName: 'mastercard',
  },
  {
    name: 'American Express',
    pattern: american,
    brand: Images.flag_american,
    brandName: 'amex',
  },
  {
    name: 'Aura',
    pattern: aura,
    brand: Images.flag_aura,
    brandName: 'aura',
  },
  {
    name: 'ELO',
    pattern: elo,
    brand: Images.flag_elo,
    brandName: 'elo',
  },
  {
    name: 'Discover',
    pattern: discover,
    brand: Images.flag_discover,
    brandName: 'discover',
  },
  {
    name: 'Diners Club',
    pattern: diners,
    brand: Images.flag_diners,
    brandName: 'diners',
  },
  {
    name: 'Hipercard',
    pattern: hipercard,
    brand: Images.flag_hiper,
    brandName: 'hipercard',
  },
  {
    name: 'JCB',
    pattern: jbc,
    brand: Images.flag_jbc,
    brandName: 'jcb',
  },
];

class Card {
  brandFromNumber(number) {
    console.log(number)
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].pattern.test(String(number))) {
        return cards[i].brand;
      }
    }
    return Images.flag_default;
  }

  brandFromBrandName(brandName) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].brandName === brandName) {
        return cards[i].brand;
      }
    }
    return null;
  }

  nameFromNumber(number) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].pattern.test(String(number))) {
        return cards[i].name;
      }
    }
    return '';
  }

  nameFromBrand(brand) {
    const card = cards.find(o => o.brandName === brand)
    return card ? card.name : 'Cartão não definido';
  }

  brandNameFromNumber(number) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].pattern.test(String(number))) {
        return cards[i].brandName;
      }
    }
    return '';
  }

  validDateIsValid(date) {
    const reDate = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
    return reDate.test(date);
  }

  brandFromName(name) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].name == name) {
        return cards[i].brand;
      }
    }
    return '';
  }

  brandImageFromBrandName(brandName) {
    const card = cards.find(o => o.brandName === brandName);
    return card ? card.brand : Images.imageCreditCard;
  }
}

export default new Card();
