export const STORE_SET_CURRENT_ACCOUNT = 'setCurrentAccount';
export const STORE_PRODUCTS_FETCH = 'productsFetch';
export const STORE_ACTIVE_ORDER_FETCH = 'activeOrderFetch';
export const STORE_PROCESS_ORDER_FETCH = 'processOrder';
export const STORE_PROMOTIONS_FETCH = 'promotionsFetch';
export const STORE_PRODUCTS_CONTEXT_FETCH = 'productsContextFetch';
export const STORE_TITLES_FETCH = 'titlesFetch';
export const STORE_SET_CURRENT_COMPANY = 'setCurrentCompany';
export const STORE_SET_CURRENT_NET_INFO = 'setCurrentNetInfo';
export const STORE_LOGIN_STATE = 'setNewLogin';
export const STORE_LOAD_PROMOTIONS_STATE = 'setLoadPromotions';
export const STORE_LOAD_CATEGORIES_STATE = 'setLoadCategories';
export const STORE_LOAD_PROD_CONTEXT_STATE = 'setLoadProdContext';
export const STORE_LOAD_NOTICIES_STATE = 'setLoadNoticies';
export const STORE_CHANGE_STORE_STATE = 'changeStore';
export const STORE_LOAD_CART_STATE = 'setLoadCart';
export const STORE_LOAD_INFO_USER_STATE = 'setInfoUser';
export const STORE_SET_CURRENT_STORE_ITEM = 'setCurrentStoreItem';
export const STORE_SALE_CONDITIONS_FETCH = 'saleConditionsFetch';
export const STORE_SHIPPING_METHODS_FETCH = 'shippingMethodsFetch';
export const STORE_ERROR_COMPANYCUSTOMER = 'setErrorCompanyCustomer';
export const STORE_SET_LOAD_AFTER_SALE_STATE = 'setLoadAferSale';
export const STORE_CHANGE_ICONS_STATE = 'setIconsChange';
export const SELECT_LOCATION_CHANGE_STATE = 'setSelectLocationChange';

export const STORE_QUEUE_ITEMS = 'queueItems';
