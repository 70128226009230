import { StyleSheet } from 'react-native';
import { sizes, colors } from '~/values';

const iconSize = 20;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: sizes.horizontalSpacing,
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    backgroundColor: '#FFF',
  },
  container__active: {
    backgroundColor: colors.primaryColorTransparent,
  },
  text: {
    fontSize: sizes.textNormal,
    color: colors.grayDark,
    marginVertical: 4,
  },
  text__active: {
    fontWeight: '500',
    color: colors.primaryColor,
  },
  icon: {
    tintColor: colors.grayMid,
    width: iconSize,
    height: iconSize,
    marginRight: sizes.horizontalSpacing,
  },
  icon__active: {
    tintColor: colors.primaryColor,
  },
});

export default styles;
