/* @flow */

import React, { Component } from 'react';
import { Keyboard } from 'react-native';
import s from 'underscore.string';

import Input from './Input';

export const isCpfValid = (entry: string) => {
  if (
    s(entry)
      .slugify()
      .replaceAll('-', '')
      .value().length === 8
  ) {
    return true;
  }
  return false;
};

type Props = {
  onBlur: () => void,
  onError: () => void,
  onSuccess: () => void,
  value: string,
};

class ZipCode extends Component<Props, void> {
  state = {
    error: '',
  };

  onBlur = (entry: string) => {
    this.props.onBlur(entry);
    if (entry) {
      if (isCpfValid(entry) === false) {
        this.setState({
          error: 'CEP inválido.',
        });
        this.props.onError('CEP inválido.');
      }
    }
  };

  onChange = (entry: string) => {
    /**
     * Valida a entrada e remove a mensagem de erro caso tenha sida apresentada.
     */
    if (isCpfValid(entry)) {
      this.setState({
        error: '',
      });
      this.props.onError('');

      /**
       * Desfoca o campo quando o mesmo estiver completo e válido.
       */
      Keyboard.dismiss();

      /**
       * Invoca sucesso transmitindo a entrada.
       */
      this.props.onSuccess(entry.replace('-', ''));
    }
    this.props.onSuccess(entry);
  };

  focus = () => {
    this.input.focus();
  };

  render() {
    return (
      <Input
        {...this.props}
        ref={ref => { this.input = ref; }}
        error={this.state.error}
        onBlur={this.onBlur}
        onChange={this.onChange}
        keyboardType="numeric"
        format="99999-999"
        collapsable={false}
        value={this.props.value}
      />
    );
  }
}

ZipCode.defaultProps = {
  onBlur: () => {},
  onError: () => {},
  onSuccess: () => {},
  value: '',
};

export default ZipCode;
