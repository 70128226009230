import React from 'react';
import { View, Image, Text } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import { SCREEN_LIST_ADDRESS } from '~/screens/screens';
import Images from '~/../assets/images';

import styles from './styles';
import { TouchableOpacity } from 'react-native';

type Props = {
    componentId: any,
    dataAddr: any,
  };

class ChosenAddress extends React.Component<Props> {

  onPress = () => {
    // Navigation.push(this.props.componentId, {
    //   component: {
    //     name: SCREEN_LIST_ADDRESS.name,
    //     options: {
    //       topBar: {
    //         title: {
    //           text: SCREEN_LIST_ADDRESS.title,
    //         },
    //       },
    //     },
    //   },
    // });
  };

  render() {
    const {
      street, street_number, number, address, complement, district,
    } = this.props.dataAddr;
    return (
      <TouchableOpacity
        onPress={this.onPress}
      >
        {Object.keys(this.props.dataAddr).length > 0 ?
          <View style={styles.container}>
            <View style={styles.addressContainer}>
              <Text style={styles.addressLineTitle}>Entregar em</Text>
              <Text style={styles.addressLine1}>{`${address || 'N/A'}, ${ number || 'N/A'}`}</Text>
              <Text style={styles.addressLine2}>{`${complement || 'N/A'}, ${district || 'N/A'}`}</Text>
            </View>
            <Image style={styles.iconLast} source={Images.iconChevronRight} />
          </View>
        : <View /> }
      </TouchableOpacity>
    );
  }
}

export default ChosenAddress;
