import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  ORDER_ACTIVE_REQUEST,
  FINALLY_ORDER_REQUEST,
  success,
  failure,
} from './action';
import { activeOrderDataManager } from '../common';

const activeOrder = (action$: any) =>
  action$
    .ofType(ORDER_ACTIVE_REQUEST, FINALLY_ORDER_REQUEST)
    .mergeMap(() => activeOrderDataManager()
      .flatMap(data => Observable.of(success(data && data[0] ? data[0] : {})))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(activeOrder);
