export const PROCESS_ORDER_START = 'PROCESS_START';
export const PROCESS_ORDER_FINISH = 'PROCESS_FINISH';

export function processOrderStart() {
  return dispatch => dispatch({ type: PROCESS_ORDER_START, payload: true });
}

export function processOrderFinish() {
  return dispatch => dispatch({ type: PROCESS_ORDER_FINISH, payload: false });
}
