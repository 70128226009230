import { StyleSheet } from 'react-native';
import { colors, defaultShadow } from '~/values';

const maxWidth = 177;
const maxHeight = 256;

const baseContainer = {
  borderRadius: 20,
  backgroundColor: '#FFF',
};

const styles = StyleSheet.create({
  shadowContainer__linear: {},
  shadowContainer__grid: {
    ...baseContainer,
    ...defaultShadow,
    maxWidth,
    maxHeight,
    margin: 8,
  },
  overflowContainer__grid: {
    ...baseContainer,
    overflow: 'hidden',
    maxWidth,
    maxHeight,
  },
  overflowContainer__linear: {
    ...baseContainer,
    borderRadius: 0,
    overflow: 'hidden',
  },
  container__grid: {
    ...baseContainer,
    maxWidth,
    maxHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container__linear: {
    ...baseContainer,
    marginRight: 4,
    borderRadius: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image__grid: {
    height: 112,
    width: 260,
  },
  infosContainer: {
    padding: 10,
  },
  title: {
    color: colors.grayDarkest,
    fontSize: 12,
    fontWeight: '600',
  },
  description: {
    color: colors.grayDarkest,
    fontSize: 10,
    textAlign: 'auto',
  },
});

export default styles;
