import React, { Component } from 'react';
import { Image, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';

import Images from '~/../assets/images';

import styles from './styles';

type Props = {
  item: string,
  active: boolean,
  index: number,
  onPressRoute: () => void,
}

class Item extends Component<Props> {
  state = {
    size: 0,
  };

  onLayout = (e) => {
    this.setState({
      size: e.nativeEvent.layout.width,
    });
  };

  render() {
    const { item, index, active } = this.props;
    return (
      <TouchableOpacity
        onPress={this.props.onPressRoute(index, this.state.size)}
      >
        <View
          key={item}
          onLayout={this.onLayout}
          style={[
            styles.containerBreadcrumb,
            active
              ? styles.containerBreadcrumb__active
              : {},
          ]}
        >
          <Text
            style={[
              styles.text,
              active
                ? styles.text__active
                : {},
            ]}
          >
            {item}
          </Text>
          {
            !active
              ? (
                <Image
                  style={styles.icon}
                  source={Images.iconChevronRight}
                />
              )
              : null
          }
        </View>
      </TouchableOpacity>
    );
  }
}

export default Item;
