import React, { Component } from 'react';
import { View, ScrollView, FlatList, Text, Image } from 'react-native';
import { colors } from '~/values';
import Icon from '~/modules-wrapper/react-native-vector-icons/MaterialIcons';
import ProfileAvatar from '~/components/ProfileAvatar';
import AppConfig from '~/AppConfig';
import styles from './styles';
import moment from 'moment';
import TextUtils from "~/utils/TextUtils";

type Props = {
  user: {},
  company: {},
  data: [],
};

class Profile extends Component<Props> {
  getValueFromLabel(key, value) {
    const { user, company } = this.props;
    let valueRes = value;
    if (user) {
      switch (key) {
        case 'display_name':
          valueRes = user.display_name;
          break;
        case 'company':
          valueRes = (company && company.name) || '#';
          break;
        case 'email':
          valueRes = user.email;
          break;
        case 'nin':
          valueRes = TextUtils.formatCNPJorCPF(user.nin);
          break;
        case 'birthday':
          valueRes = user.birthday ? moment(user.birthday, 'YYYY/MM/DD').format('DD/MM/YYYY') : '';
          break;
        default:
          break;
      }
    }
    return valueRes;
  }

  renderHeaderInfo() {
    const { user } = this.props;

    return (
      <View style={styles.headerContainer}>
        <ProfileAvatar user={user} type="light" />
      </View>
    );
  }

  renderItem({
    key, label, value, icon,
  }) {
    return (
      <View style={styles.itemRender}>
        <Image source={icon} style={{ tintColor: colors.grayMid }} />
        <View style={{ marginLeft: 14 }}>
          <Text style={styles.label}>{`${label} `}</Text>
          <Text style={styles.value}>{this.getValueFromLabel(key, value)}</Text>
        </View>
      </View>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <View>
        <ScrollView>
          <View>
            <FlatList
              keyExtractor={(item) => item.key}
              data={data}
              ListHeaderComponent={this.renderHeaderInfo()}
              renderItem={({ item }) => this.renderItem(item)}
            />

            <View style={styles.itemRender}>
              <Icon style={{ color: colors.grayMid }} name={'info'} size={26} />
              <View style={{ marginLeft: 14 }}>
                <Text style={styles.label}>Versão</Text>
                <Text style={{...styles.value, fontSize: 13}}>
                  {AppConfig.version}
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default Profile;
