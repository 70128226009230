/* @flow */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement"] */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */

import 'rxjs'
import { Map } from 'immutable';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';

import ajax from './ajax';
import rootEpics from './epics';

const reducers = require('./reducers');

export default function configureStore() {
  let store = {};

  const epicMiddleware = createEpicMiddleware(rootEpics, {
    dependencies: { ajax },
  });

  const logger = createLogger({
    stateTransformer: state => {
      const newState = state.toJS();

      for (const i of Object.keys(newState)) {
        if (!!newState[i].present && typeof newState[i].present.toJS === 'function') {
          newState[i].present = newState[i].present.toJS();
        }
      }

      return newState;
    },
    actionTransformer: action => action,
  });

  store = createStore(reducers, Map({}), applyMiddleware(thunk, epicMiddleware));

  return store;
}
