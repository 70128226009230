export const PRODUCT_SALES_SCHEMA = 'ProductSales';
export const PRICE_CONDITIONS_SCHEMA = 'PriceConditionsSchema';
export const CATEGORY_PRODUCT_CONTEXT_SCHEMA = 'CategoryProductContext';
export const EXTRA_INFO_SCHEMA = 'ExtraInfo';
export const CUSTOMER_COMPANY_ACCOUNTS_SCHEMA = 'CustomerCompanyAccounts';
export const ICON_SCHEMA = 'Icon';
export const MESSAGES_SCHEMA = 'Messages';
export const PAYMENT_STATUS_SCHEMA = 'PaymentStatus';
export const INTEGRATION_STATUS_SCHEMA = 'IntegrationStatus';
export const DELIVERY_ADDRESS_SCHEMA = 'DeliveryAddress';
export const SALE_CONDITIONS_SCHEMA = 'SalesConditions';
export const IMAGES_SCHEMA = 'Images';
export const PHONES_SCHEMA = 'Phones';
export const ADDRESS_SCHEMA = 'Address';
export const COMPANY_SCHEMA = 'Company';
export const CUSTOMER_SCHEMA = 'Customer';
export const PROMOTION_SCHEMA = 'Promotion';
export const PRODUCT_SCHEMA = 'Product';
export const PRODUCT_CHILDREN_SCHEMA = 'ProductChildrenSchema';
export const PRODUCT_CONTEXT_SCHEMA = 'ProductContext';
export const CATEGORY_SCHEMA = 'Category';
export const CATEGORIES_RELATIONSHIPS_SCHEMA = 'CategoryRelationships';
export const PRODUCTS_CATEGORIES_SCHEMA = 'ProductCategories';
export const CART_SCHEMA = 'Cart';
export const NOTIFICATION_SCHEMA = 'Notification';
export const TITLES_SCHEMA = 'Titles';
export const SALES_SCHEMA = 'Sales';
export const NOTICES_SCHEMA = 'Notices';
export const COMBO_SCHEMA = 'Combo';

export const PAYMENT_OPTION_SCHEMA = 'PaymentOption';
export const PAYMENT_TERM_SCHEMA = 'PaymentTerm';
export const PAYMENT_COMBINE_KEY_SCHEMA = 'PaymentCombineKey';

export const SHI = 'PaymentCombineKey';

export const PRODUCT_ATTRIBUTE_SCHEMA = 'ProductAttributeSchema';
export const ATTRIBUTES_VALUES_SCHEMA = 'AttributesValuesSchema';

export const ADDRESS_OBJECT = 'Address';
export const PHONES_OBJECT = 'Phones';
export const IMAGES_OBJECT = 'Images';
export const CATEGORY_CODE_OBJECT = 'CategoryCode';
export const SALE_CONDITIONS_OBJECT = 'SaleConditions';
export const SHIPPING_METHODS_SCHEMA = 'ShippingMethods';
export const DELIVERY_ADDRESS_OBJECT = 'DeliveryAddress';
export const MESSAGES_OBJECT = 'Messages';
export const ICON_OBJECT = 'Icon';
export const CUSTOMER_COMPANY_ACCOUNTS_OBJECT = 'CustomerCompanyAccounts';
export const EXTRA_INFO_OBJECT = 'ExtraInfo';
export const CATEGORY_PRODUCT_CONTEXT_OBJECT = 'CategoryProductContext';
export const PRICE_CONDITIONS_OBJECT = 'PriceConsitions';
export const PRODUCT_CART_OBJECT = 'ProductCart';
export const PRODUCT_SALES_OBJECT = 'ProductSales';
export const INTEGRATION_STATUS_OBJECT = 'IntegrationStatus';
export const PAYMENT_STATUS_OBJECT = 'PaymentStatus';
export const CUSTOMER_OBJECT = 'Customer';
export const REQUIREMENT_OBJECT = 'Requirements';
export const ITEMS_PROMOTION_OBJECT = 'ItemsPromotion';
export const ACTION_OBJECT = 'Action';
export const ACTION_DATA_OBJECT = 'ActionData';
export const APP_CONFIG_SCHEMA = 'AppConfig';
export const REGISTER_ADRESS_SCHEMA = 'RegisterAddress';


export const SupportedTypes = {
  INTEGER: { value: 'INTEGER', type: 'INTEGER', default_value: null },
  LONG: { value: 'LONG', type: 'INTEGER', default_value: null },
  DOUBLE: { value: 'DOUBLE', type: 'REAL', default_value: null },
  TEXT: { value: 'TEXT', type: 'TEXT', default_value: null },
  BOOLEAN: { value: 'BOOLEAN', type: 'INTEGER', default_value: null },
  DATETIME: { value: 'DATETIME', type: 'TEXT', default_value: null },
  SYNC_STATUS: { value: 'STATUS', type: 'TEXT', default_value: null },
  JSON: { value: 'JSON', type: 'TEXT', default_value: null },
};

export const Tables = {
  version: {
    version_number: {
      type: SupportedTypes.INTEGER,
    },
  },
  Product: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    type: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    min_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    product_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    company_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    ean: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    volume_factor: {
      type: SupportedTypes.INTEGER,
      primary_key: false,
      default_value: null,
    },
    stock: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    attributes: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    children: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    notify_me: {
      type: SupportedTypes.BOOLEAN,
      primary_key: false,
      default_value: null,
    },
    liked: {
      type: SupportedTypes.BOOLEAN,
      primary_key: false,
      default_value: null,
    },
  },
  ProductContext: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    type: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    context_title: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    context_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    min_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    product_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    company_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    ean: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    volume_factor: {
      type: SupportedTypes.INTEGER,
      primary_key: false,
      default_value: null,
    },
    stock: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    extra_info: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    categories: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    price_conditions: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Category: {
    category_code: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    type: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    is_root: {
      type: SupportedTypes.BOOLEAN,
      primary_key: false,
      default_value: null,
    },
  },
  CategoryRelationships: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    parent_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    category_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  ProductCategories: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    product_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    category_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    product_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Cart: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    cart_code: {
      type: SupportedTypes.INTEGER,
      primary_key: false,
      default_value: null,
    },
    amount: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    success: {
      type: SupportedTypes.BOOLEAN,
      primary_key: false,
      default_value: null,
    },
    company_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    customer_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    account_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    shipping_method: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    delivery_address: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    sale_conditions: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    products: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    messages: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    campaigns: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    payment_type: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    total_paid: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    subtotals: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Sales: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    customer_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    customer_name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    account_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    account_name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    account_phone: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    shipping_address: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    billing_address: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    sale_conditions: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    integration_status: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    history_integration_status: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    payment_status: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    products: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    messages: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    order_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    amount: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    sent_date: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    observation: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Customer: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    trading_name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    nin: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    address: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    phones: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Address: {
    address: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    district: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    postal_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    city: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    state: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Phones: {
    main: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
  },
  Images: {
    brand: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
  },
  SalesConditions: {
    key: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    value_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    value_description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  ShippingMethods: {
    enderecos: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    fretes: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  DeliveryAddress: {
    code: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    district: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    postal_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    complement: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    number: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    city: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    state: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    address: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  IntegrationStatus: {
    code: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    status_date: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  PaymentStatus: {
    status: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  PaymentTerm: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    display_order: {
      type: SupportedTypes.INTEGER,
      primary_key: false,
      default_value: 1,
    },
  },
  PaymentCombineKey: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    payment_option_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    payment_term_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  PaymentOption: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    kind: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Company: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    trading_name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    display_name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    address: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    phones: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    images: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    customer_company_accounts: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Messages: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    content: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    sent_at: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    sender_class: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    sender_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Icon: {
    source: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    content: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  CustomerCompanyAccounts: {
    company_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    customer_id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    account_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    authorized_by: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    approved: {
      type: SupportedTypes.BOOLEAN,
      primary_key: false,
      default_value: null,
    },
    customer: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  ExtraInfo: {
    content: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    position: {
      type: SupportedTypes.INTEGER,
      primary_key: true,
      default_value: null,
    },
    title: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  CategoryProductContext: {
    code: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  PriceConditionsSchema: {
    description: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
  },
  ProductSales: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    unit_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    name: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    product_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    total_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    ean: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    quantity: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    original_total_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    original_quantity: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
    original_unit_price: {
      type: SupportedTypes.DOUBLE,
      primary_key: false,
      default_value: null,
    },
  },
  Notices: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    title: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    image: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    action: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Promotion: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    group_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    image: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    title: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    requirements: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
    items: {
      type: SupportedTypes.JSON,
      primary_key: false,
      default_value: null,
    },
  },
  Combo: {
    pkey: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    campanha: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    coddesconto: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    codgrupo: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    codproduto: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    descgrupo: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    descricao: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    descricao_unidade: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    ean: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    estoque: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    faixa: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    multiplo_venda: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    ordem: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    periodo: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    preco: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    produto: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    tipo: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    unidade: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  Notification: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    type: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    title: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    description: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    date: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    read: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    payload: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    action: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  RegisterAddress: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    account_id: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    street_number: {
      type: SupportedTypes.INTEGER,
      primary_key: false,
      default_value: null,
    },
    street: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    district: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    city: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    state_city: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    country: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    postal_code: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    complement: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    reference_point: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    latitude: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    longitude: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    created_at: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    updated_at: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    deleted_at: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    surname: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
    kind: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
  AppConfig: {
    id: {
      type: SupportedTypes.TEXT,
      primary_key: true,
      default_value: null,
    },
    data: {
      type: SupportedTypes.TEXT,
      primary_key: false,
      default_value: null,
    },
  },
};

export const database_version = '7.0';
