/* @flow */

import React, { Component } from 'react';
import { omit } from 'underscore';

import Input from './Input';
import { checkCPF, checkCNPJ } from '~/common';

import TextUtils from '~/utils/TextUtils';


const isValid = (entry: string) => {
  const entryNoFormatted = TextUtils.cleanCNPJCPF(entry);
  let checkedCPFCNPJ = false;

  if (entryNoFormatted.length === 11) {
    checkedCPFCNPJ = checkCPF(entryNoFormatted);
  } else if (entryNoFormatted.length === 14) {
    checkedCPFCNPJ = checkCNPJ(entryNoFormatted);
  }

  return checkedCPFCNPJ;
};

type Props = {
  onError: () => void,
  onSuccess: () => void,
  label?: string,
  value?: string,
  defaultValue?: string,
  error?: string,
  onChangeText?: (text?: string) => void,
  autoFocus?: string,
  error?: string,
  format?: string | (() => string),
  inputRef?: () => void,
  keyboardType?: string,
  label?: string,
  maxLength?: number,
  name?: string,
  onBlur?: () => void,
  onChange?: () => void,
  onInputFocus?: () => void,
  onSuccess?: () => void,
  placeholder?: string,
  required?: boolean,
  returnKeyType?: () => void,
  secureTextEntry?: boolean,
  style?: {},
  isMoney?: boolean,
};

class PersonDocument extends Component<void, Props, void> {
  state = {
    error: '',
  };

  onBlur = (entry: string) => {
    if (isValid(entry) === false && entry.length > 0) {
      const entryNoFormatted = TextUtils.cleanCNPJCPF(entry);
      const errorDocument = `${entryNoFormatted.length <= 11 ? 'CPF' : 'CNPJ'} inválido.`;

      this.setState({
        error: errorDocument,
      });
      this.props.onError(errorDocument);
    }
  };

  onChange = (entry: string) => {
    this.props.onChange(entry);
    if (isValid(entry)) {
      this.setState({
        error: '',
      });
      this.props.onError('');
    }

    this.props.onSuccess(TextUtils.cleanCNPJCPF(entry));
  };

  render() {
    return (
      <Input
        error={this.state.error}
        format="999.999.999-99"
        keyboardType="numeric"
        onBlur={this.onBlur}
        onChange={this.onChange}
        onInputFocus={this.onInputFocus}
        {...omit(this.props, 'onBlur', 'onChange', 'onInputFocus')}
      />
    );
  }
}

PersonDocument.defaultProps = {
  inputRef: () => {},
  onChange: () => {},
  onError: () => {},
  onSuccess: () => {},
};

export default PersonDocument;
