import axios from 'axios';
import get from 'lodash/get';
import Images from '../../../assets/images';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'Image products';

export default async function imagesProduct(id) {
  const configImages = await getConfig();
  const company = await getCurrentCompany();

  configImages.headers['Cache-Control'] = 'max-age=3600';

  return new Promise((resolve, reject) =>
    axios.get(`${URL_BASE}/products/${company}/detail/${id}/images/`, configImages)
      .then(response => {
        let images = get(response, 'data.source.data.images');
        if (images && images.length > 0) {
          images = images.map(i => ({ uri: i.url }));
        } else {
          images = [];
          images.push(Images.iconNoImageBig);
        }
        resolve(images);
      })
      .catch(error => {
        console.log(TAG, error);
        reject(error.response.data);
      }));
}
