import Controller from '~/controllers/Controller';
import ProductContextRepository from '../database/repositories/ProductContextRepository';

export default class ProductContextController extends Controller {
  constructor() {
    super();
    this.repository = new ProductContextRepository();
  }

  allContexts() {
    return this.repository.allContexts();
  }

  addProductCtx(prods) {
    return this.repository.addProductCtx(prods);
  }

  allByContexts(contexts) {
    return this.repository.allByContexts(contexts);
  }
}
