import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'Wish List Products';

export default async function wishListProducts(customerId) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  const promise = new Promise((resolve) =>
    axios
      .get(`${URL_BASE}/wish_lists/${company}/?customer=${customerId}`, config)
      .then(response => resolve(response.data))
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));

  return await promise;
}
