import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  topContainer: {
    ...defaultShadow,
    zIndex: 2,
  },
  switchContainer: {
    paddingHorizontal: sizes.horizontalSpaceSmall,
    paddingVertical: sizes.verticalSpaceSeparatorSmall,
    ...appStyles.rowDirection,
  },
  textSameAddress: {
    color: colors.grayDark,
  },
  checkoutButtonContainer: {
    paddingHorizontal: sizes.horizontalSpacing,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    paddingHorizontal: sizes.horizontalSpacing,
  },
  paymentSlipOptionsContainer: {
    marginTop: sizes.horizontalSpacing,
    maxHeight: 250,
  },
});

export default styles;
