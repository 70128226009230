import React, { Component } from 'react';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import connect from 'react-redux/es/connect/connect';

import Promotions from './Promotions';
import { STORE_PROMOTIONS_FETCH, STORE_LOAD_PROMOTIONS_STATE } from '../../store/storesConstants';
import listPromotions from '../../store/promotionsFetch/action';
import { SCREEN_PROMOTION_DETAIL, SCREEN_PROMOTIONS } from '../screens';
// import NavigationHelper from '../NavigationHelper';
import ComponentHelper from '~/components/ComponentHelper';

type Props = {
  componentId: string,
  valueLoadPromo: any,
  sPromotions: Array<any>,
  dispatch: () => void,
};

class PromotionsContainer extends Component<Props> {
  constructor(props) {
    super(props);
    // Navigation.mergeOptions(this.props.componentId, {
    //   topBar: {
    //     elevation: 0,
    //     title: {
    //       text: SCREEN_PROMOTIONS.title,
    //     },
    //   },
    // });
  }

  // UNSAFE_componentWillMount() {
    // this.bottomTabEventListener = NavigationHelper.popRootTab(3, SCREEN_PROMOTIONS.name);
  // }

  componentDidMount() {
    console.log('PROMOTIONS componentDidMount');

    this.props.dispatch(listPromotions());

    // this.navigationEventListener = Navigation.events().bindComponent(this);
  }

  componentDidUpdate(prevProps) {

    console.log('PROMOTIONS componentDidUpdate');

    if (prevProps.valueLoadPromo.load !== this.props.valueLoadPromo.load) {
      this.props.dispatch(listPromotions());
    }
  }

  componentWillUnmount() {
    // if (this.bottomTabEventListener) {
    //   this.bottomTabEventListener.remove();
    // }
  }

  doPromotionClick = (promotion: Object<any>) => {
    // const props = { promotion };

    this.props.navigation.navigate(SCREEN_PROMOTION_DETAIL.name, {promotion});

    // NavigationHelper.push(this.props.componentId, SCREEN_PROMOTION_DETAIL.name, false, props);
  };

  onPromotionClick = ComponentHelper.debounce(this.doPromotionClick);

  onRefresh = () => {
    console.log('PROMOTIONS listPromotions');

    this.props.dispatch(listPromotions());
  };


  render() {
    const { sPromotions, valueLoadPromo } = this.props;

    return (
      <Promotions
        promotions={sPromotions.payload}
        onPromotionClick={this.onPromotionClick}
        refreshing={sPromotions.loading}
        onRefresh={this.onRefresh}
        loadData={valueLoadPromo.load}
        {...this.props}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getPromotions = createImmutableSelector([state => state], state =>
  state.getIn([STORE_PROMOTIONS_FETCH]).toJS());

const getLoadPromo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_PROMOTIONS_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sPromotions: getPromotions(state),
    valueLoadPromo: getLoadPromo(state),
  };
}

export default connect(mapStateToProps)(PromotionsContainer);
