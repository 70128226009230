import React, {Component, createRef} from 'react';
import { View, ScrollView, Text, Switch, Alert, Image } from 'react-native';
// import KeyboardAwareScrollView from '~/modules-wrapper/react-native-keyboard-aware-scroll-view';

import { Button, Input, Section } from '~/components';
import Card from '~/utils/Wallet/Card';

import Images from '~/../assets/images';

import styles from './styles';
import appStyles from '~/appStyles';
import InputWeb from "~/components/Input/unform/InputWeb";
import {Form} from "@unform/web";
import "./styles.css";
import InputWebMask from "~/components/Input/unform/InputWebMask";
import CustomAlert from "~/components/CustomAlert";

type Props = {
  save: (card: {}) => () => void,
  loading: boolean,
}


class AddCreditCard extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      brandImage: Images.flag_default,
      brandName: '',
      brand: null,
      isMain: true,
      cardHolder: '',
      flipped: false,
      valueNin: '',
      showCvvAlert: false
    };
  }

  onPressDontKnowCVV = () => {
    this.setState({showCvvAlert: true})
 };

  onChangeText = (attribute) => (value) => {
    value = value.target.value;
    if (attribute === 'cardNumber') {
      value = value.trim();
      this.setState({
        brandImage: Card.brandFromNumber(value),
        brandName: Card.nameFromNumber(value),
        brand: Card.brandNameFromNumber(value),
      });
      console.log(this.state)
    }
    if (attribute === 'cardHolder') {
      this.setState({ [attribute]: value });
    } else {
      this.setState({ [attribute]: value });
    }
  };

  onEndEditing = (attribute) => () => {
    if (attribute === 'cardHolder') {
      this.setState({ [attribute]: this.state[attribute].toUpperCase() });
    }
  };

  changeMain = () => {
    this.setState({ isMain: !this.state.isMain });
  };

  formatCardNumber = (value) => {
    return value.replace(/(\d{4})?(\d{4})?(\d{4})?(\d{4})?/g, '$1  $2  $3  $4')
  }



  render() {
    const formRef = createRef();
    return (
      // <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
        <View style={styles.containerWrapper}>
          <CustomAlert
              showModal={this.state['showCvvAlert']}
              title="O que é o CVV?"
              message="CVV é um código composto de 3 ou 4 dígitos que fica localizado na parte de trás do cartão."
              okButtonPress={() => {this.setState({showCvvAlert: false})}}
          />
          <Section style={styles.sectionStyle}>
            <section ng-app="payment" className="payment">
              <div className="card-container">
                <div className={`card ${this.state.flipped ? 'flipped' : ''}`}>
                  <div className="card__front">
                    <span className="card__number">{this.formatCardNumber(this.state.cardNumber || '0000 0000 0000 0000')}</span>
                    <span
                        className="card__expiration-date">{this.state.cardValidDate || '12/99'}</span>
                    <span className="card__owner">{this.state.cardHolder || 'Polibras Software'}</span>
                    <span className="card__chip"></span>
                  </div>
                  <div className="card__back">
                    <span className="card__verification-value">{this.state.cardCVV || '999'}</span>
                    <span className="card__bar"></span>
                  </div>
                </div>
              </div>
            </section>
            <Form ref={formRef}>
              <Image
                style={styles.brandImage}
                source={this.state.brandImage}
              />
              <InputWeb
                name="card_number"
                label="Número do cartão"
                placeholder="Número do cartão"
                maxLength="16"
                type="text"
                onChange={this.onChangeText('cardNumber')}
                onFocus={() => this.setState({ flipped: false })}
              />
              <InputWeb
                name="owner"
                label="Nome do títular"
                placeholder="Nome do titular"
                onChange={this.onChangeText('cardHolder')}
                onFocus={() => this.setState({ flipped: false })}
              />
              <InputWebMask
                  name="validade"
                  required="required"
                  label="Validade"
                  placeholder="Ex: 12/2099"
                  mask={"99/2099"}
                  maskChar=""
                  onChange={this.onChangeText('cardValidDate')}
                  onFocus={() => this.setState({ flipped: false })}
              />
              <InputWebMask
                  name="cpf"
                  required="required"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  mask={"999.999.999-99"}
                  maskChar=""
                  onChange={this.onChangeText('cardCPF')}
                  onFocus={() => this.setState({ flipped: false })}
              />
              <InputWebMask
                  name="cvv"
                  required="required"
                  label="CVV"
                  placeholder="CVV"
                  mask={"999"}
                  maskChar=""
                  onChange={this.onChangeText('cardCVV')}
                  onFocus={() => this.setState({ flipped: true })}
              />
              <View style={styles.dontKnowCVVContainer}>
                <Button
                  title="Não sabe o que é CVV?"
                  onPress={this.onPressDontKnowCVV}
                  small
                  flat
                  noUpperCase
                  transparent
                  containerStyle={styles.dontKnowCVV}
                />
              </View>
            </Form>
          </Section>
          <Section style={styles.sectionStyle}>
            <View style={appStyles.spaceBetween}>
              <Text style={{ flex: 1, marginRight: 16 }}>
                Marcar esse cartão como principal
              </Text>
              <Switch
                value={this.state.isMain}
                onChange={this.changeMain}
              />
            </View>
          </Section>
          <Button
            onPress={this.props.save(this.state)}
            title="Adicionar"
            big
            disabled={this.props.loading}
          />
        </View>
      // </KeyboardAwareScrollView>
    );
  }
}

export default AddCreditCard;
