import Controller from '~/controllers/Controller';
import ProductsCategoriesRepository from '~/database/repositories/ProductsCategoriesRepository';

export default class ProductsCategoriesController extends Controller {
  constructor() {
    super();
    this.repository = new ProductsCategoriesRepository();
  }

  addProductsCategories(prods) {
    return this.repository.addProductsCategories(prods);
  }

  productsIdByCategoryCode(categoryCode) {
    return this.repository.productsIdByCategoryCode(categoryCode);
  }

  insertAll(data, update = false) {
    return this.repository.insertAll(data, update);
  }
}
