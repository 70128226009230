import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const borderRadius = 5;

const container = {
  padding: 12,
  borderRadius,
  flexDirection: 'row',
  alignItems: 'center',
};

const animation = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius,
};

const styles = StyleSheet.create({
  animation__danger: {
    ...animation,
    backgroundColor: colors.dangerColorLight,
  },
  animation__warning: {
    ...animation,
    backgroundColor: colors.warningColorLight,
  },
  animation__success: {
    ...animation,
    backgroundColor: colors.successColorDark,
  },
  containerOverflow: {
    overflow: 'hidden',
    borderRadius,
  },
  container__danger: {
    ...container,
    backgroundColor: colors.dangerColorLight,
  },
  container__warning: {
    ...container,
    backgroundColor: colors.warningColorLight,
  },
  container__success: {
    ...container,
    backgroundColor: colors.successColorDark,
  },
  containerIcon: {
    backgroundColor: '#FFF',
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  icon__danger: {
    tintColor: colors.dangerColorLight,
    width: 24,
    height: 24,
  },
  icon__warning: {
    tintColor: colors.warningColorLight,
    width: 24,
    height: 24,
  },
  icon__success: {
    tintColor: colors.successColorDark,
    width: 24,
    height: 24,
  },
  icon__light: {
    tintColor: colors.primaryColorLight,
    width: 24,
    height: 24,
  },
  text: {
    marginRight: 42,
    fontWeight: '700',
    textAlign: 'center',
    color: '#FFF',
    flex: 1,
    fontSize: 12,
  },
});

export default styles;
