// @flow

import React, { Component } from 'react';
import {Modal, ScrollView, TouchableWithoutFeedback, View} from 'react-native';
import InstallmentCard from '~/components/InstallmentCard/InstallmentCard';
import styles from "~/screens/CheckOut/styles";
import {Box} from "~/components";
import SimpleOption from "~/components/SimpleOption";

type InstallmentCardContainerProps = {
  showInstallmentCardModal: boolean,
  showIcon: boolean,
};

class InstallmentCardContainer extends Component<InstallmentCardContainerProps> {
  render() {
    const {
      onSelectInstallment,
      onHandleInstallmentCardModal,
      showInstallmentCardModal,
      installmentSelected,
      installments,
    } = this.props;

    return (
      <View>
        <InstallmentCard
          installmentSelected={installmentSelected}
          onPress={onHandleInstallmentCardModal}
        />

        <Modal onRequestClose={() => onHandleInstallmentCardModal()}
               visible={showInstallmentCardModal} animated transparent>
          <TouchableWithoutFeedback onPress={() => onHandleInstallmentCardModal()}>
            <View style={styles.modalContainer}>
              <View>
                <Box title={'Parcelamento'} onClose={onHandleInstallmentCardModal}>
                  <View style={styles.paymentSlipOptionsContainer}>
                    <ScrollView>
                      <View>
                        {installments.map(option => (
                          <SimpleOption
                            key={option.value}
                            text={option.description}
                            onPress={() => {
                              onSelectInstallment(option);
                            }}
                          />
                        ))}
                      </View>
                    </ScrollView>
                  </View>
                </Box>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    );
  }
}

export default InstallmentCardContainer;
