import Repository from "./Repository";
import { PAYMENT_COMBINE_KEY_SCHEMA } from "../schemas";
import SQLiteManager from '../SQLiteManager';

export default class PaymentCombineKeyRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PAYMENT_COMBINE_KEY_SCHEMA;
  }
  addPaymentCombineKey(pay) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addPaymentCombineKey(pay).then(paymentCK => {
        resolve(paymentCK);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllPaymentCombineKey() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllPaymentCombineKey().then(paymentCK => {
        resolve(paymentCK);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
