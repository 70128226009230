import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  headerContainer: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayContainerDark,
  },
  itemRender: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    height: 70,
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
  rowContent: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    paddingHorizontal: 26,
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
  rowContentItem: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 14,
    color: '#000',
  },
  label: {
    fontSize: 14,
    color: '#000',
  },
  value: {
    fontSize: 12,
    color: colors.grayDark,
  },
  iosIcon: {
    width: 24,
    height: 24,
    tintColor: 'rgba(0,0,0,0.25)',
  },
  bottomLine: {
    backgroundColor: 'rgba(0, 0, 0, 0.054)',
    height: 1,
    marginLeft: 0,
  },
});

export default styles;
