import Repository from './Repository';
import { CART_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'CartRepository';

export default class CartRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = CART_SCHEMA;
  }

  getCart(sorted, cache) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getCart(sorted, cache).then(cart => {
        resolve(cart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCartInCache() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getCartInCache().then(cart => {
        resolve(cart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCart(cart, update, cache) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCart(cart, update, cache).then(dataCart => {
        resolve(dataCart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  insertCartInCache(cart) {
    return new Promise((resolve, reject) => {
      SQLiteManager.insertCartInCache(cart).then(dataCart => {
        resolve(dataCart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  clearCartCache() {
    return new Promise((resolve, reject) => {
      SQLiteManager.clearCartCache().then(cart => {
        resolve(cart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateCart(cart, cache) {
    return new Promise((resolve, reject) => {
      SQLiteManager.updateCart(cart, cache).then(dataCart => {
        resolve(dataCart);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  all(sorted = null) {
    return new Promise((resolve, reject) => {
      super.all(sorted)
        .then(carts => {
          const cartsConvert = carts.map(cart => ({
            ...cart,
            sale_conditions: Array.from(cart.sale_conditions),
            products: Array.from(cart.products),
            messages: Array.from(cart.messages),
          }));

          resolve(cartsConvert);
        })
        .catch(error => {
          console.log(TAG, error);
          reject(error);
        });
    });
  }

  insertAll(data, update = false) {
    const cartsConvert = data.map(cart => ({
      ...cart,
      messages: cart && cart.messages ? cart.messages.map(msg => ({ ...msg, id: `${msg.id}` })) : [],
    }));
    return super.insertAll(cartsConvert, update);
  }
}
