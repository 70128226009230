import axios from 'axios';
import moment from 'moment';

import { getConfig, URL_BASE } from '../index';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import AppConfig from '~/AppConfig';

function validPhone(phone) {
  return phone !== '' && phone.length >= 10 && phone.length <= 11;
}

export default async function edit(form) {
  const config = await getConfig();

  return new Promise((resolve, reject) => {
    form.phone = form.phone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    if (!validPhone(form.phone)) {
      reject('Número de telefone inválido');
      return;
    }

    try {
      const device_info = {
        device_uuid: DeviceInfo.getUniqueId(),
        device_model: DeviceInfo.getModel(),
        system_info: Platform.OS,
      };

      const address = {
        postal_code: form.zipCode,
        address: form.street,
        number: form.addressNumber,
        district: form.district,
        city: form.city,
        state: form.state,
        complement: form.complement,
      };

      const editAccount = {
        name: form.name,
        display_name: form.display_name || form.name,
        phones: { main: form.phone, secondary: form.landline },
        address,
        device_info,
        application_code: AppConfig.appCode,
      };

      const birthday = form.birthday;
      if (birthday) {
        editAccount.birthday = moment(birthday, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      axios
        .post(`${URL_BASE}/users/me/`, editAccount, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    } catch (error) {
      reject(error);
    }
  });
}
