import { StyleSheet } from 'react-native';
import colors from './values/color';
import { sizes } from '~/values';

const appStyles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100vh',
    backgroundColor: colors.grayContainer,
  },
  containerBanner: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imgBanner: {
    width: 200,
    height: 200,
  },
  price: {
    fontSize: 20,
    color: colors.grayDark,
    fontWeight: '300',
  },
  productName: {
    color: colors.grayDarkest,
    fontSize: 16,
    fontWeight: '300',
    marginRight: 6,
    flex: 1,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  rowDirection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listSeparator: {
    backgroundColor: colors.grayContainerDark,
    height: 1,
    marginHorizontal: sizes.horizontalSpace,
  },
  charValue: {
    fontSize: 15,
    fontWeight: '200',
  },
});

export default appStyles;
