// @flow

import React from 'react';
import { View, Text } from 'react-native';

import styles from './styles';

export type SimpleInfoProps = {
  label: string,
  value: string,
};

const SimpleInfo = ({ label, value }: SimpleInfoProps) => (
  <View style={styles.container}>
    {label && <Text style={styles.label}>{label}</Text>}
    <Text style={styles.value}>{value}</Text>
  </View>
);

export default SimpleInfo;
