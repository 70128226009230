import axios from 'axios';
import AppConfig from '~/AppConfig';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

const url = 'https://stocker.isilist.com.br/api/v2/product_image/${ean}/';
const token = AppConfig.tokenStocker;

class StockerProductResource {
  async getInfo(ean) {
    const url = `https://stocker.isilist.com.br/api/v1/product/search/ean/?q=${ean}`;

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const response = await axios.get(url);

    if (response.status === 200) {
      return response.data;
    }
    throw Error(`Product EAN ${ean} not found`);
  }

  getImage(url, token, ean) {
    return this.getThumbImage(url, token, ean, false);
  }

  getRedirect = async uri => {
    const response = await fetch(uri);
    const data = await response;
    return data.status === 200 ? data.url : '';
  };

  getThumbImage(ean, isThumb = true) {
    const urlImage = url.replace('${ean}', ean) + (isThumb ? 'thumb/?default_company_id=71' : '');
    return {
      uri: urlImage,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async getImages(ean) {
    const cacheKey = `product_image_${ean}`;
    const cachedImages = await CacheStore.get(cacheKey);

    if (!!cachedImages && cachedImages.length > 0) {
      return cachedImages;
    }

    const urlSearch = 'https://stocker.isilist.com.br/api/v2/product_info/';

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const response = await axios.get(urlSearch, {
      params: {
        eans: ean,
        include_company_group: 1,
        include_variants: 0,
      },
    }).catch((err) => {
      console.log('ERROR REQUEST GET IMAGES: ', err);
    });

    if (response.status === 200) {
      const images = [];
      for (const idx in response.data) {
        const item = response.data[idx];
        images.push({
          uri: item.files[0].url,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      await CacheStore.set(cacheKey, images, 60 * 6);
      return images;
    }
    return [];
  }
}

export default new StockerProductResource();
