export const SET_CURRENT_STORE_ITEM = 'SET_CURRENT_STORE_ITEM';
export const SYNC_CURRENT_STORE_ITEM = 'SYNC_CURRENT_STORE_ITEM';

export function syncCurrentStoreItem(payload) {
  return {
    type: SYNC_CURRENT_STORE_ITEM,
    payload,
  };
}

export default function setCurrentStoreItem(payload) {
  return {
    type: SET_CURRENT_STORE_ITEM,
    payload,
  };
}
