import {StyleSheet} from 'react-native';
import {sizes, colors} from '~/values';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  topContainer: {
    justifyContent: 'flex-end',
    backgroundColor: colors.primaryColor,
  },
  profileContainer: {
    paddingHorizontal: sizes.horizontalSpacing,
    paddingBottom: sizes.verticalSpaceSeparatorNormal,
    marginBottom: 6,
  },
  profileSeparator: {
    backgroundColor: colors.primaryColorDark,
    height: 1,
  },
  footerVersion: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10
  },

  button: {
    backgroundColor: "lightblue",
    padding: 12,
    margin: 16,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)"
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0
  },
  scrollableModal: {
    height: 300
  },
  scrollableModalContent1: {
    height: 200,
    backgroundColor: "orange",
    alignItems: "center",
    justifyContent: "center"
  },
  scrollableModalContent2: {
    height: 200,
    backgroundColor: "lightgreen",
    alignItems: "center",
    justifyContent: "center"
  }
});

export default styles;
