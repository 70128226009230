import { Map } from 'immutable';
import { PROCESS_ORDER_FINISH, PROCESS_ORDER_START } from './action';

const initialState = Map({ type: '', payload: false });

export default function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === PROCESS_ORDER_START || type === PROCESS_ORDER_FINISH) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  return state;
}
