import CacheStore from '~/modules-wrapper/react-native-cache-store';

import {
  COMPANY_ACTIVE,
  CART_ANONYMOUS,
  DATA_ACCOUNT,
  STORE_ITEM_ACTIVE,
} from '../constants/cache';
import DataManager from '../database/DataManager';
import Sync from './Sync';
import transfOrder from '../services/carts/trasfOrder';
import loginAnonymous from '../services/account/loginAnonymous';
import getCompanies from '../services/companies/list';
import requestCompanies from '../services/companies/request';
import SQLiteManager from '../database/SQLiteManager';
import { Platform } from 'react-native';

const TAG = 'Login';

export default class Login {
  constructor() {
    this.sync = new Sync();
  }

  async deleteDataUser(data, cartAnonymous) {
    const dataManager = new DataManager();

    console.log('dataManager', dataManager);

    try {
      if (Platform.OS !== 'web') {
        await SQLiteManager.clearAllInCache();
      }

      console.log('SQLiteManager 1', SQLiteManager);

      await CacheStore.set(COMPANY_ACTIVE, null);
      await CacheStore.set(STORE_ITEM_ACTIVE, null);

      await CacheStore.set(CART_ANONYMOUS, cartAnonymous);
      await CacheStore.set(DATA_ACCOUNT, { ...data, sync: false });

      console.log('SQLiteManager 2', SQLiteManager);

      await SQLiteManager.clearAllTables();

      console.log('SQLiteManager 3', SQLiteManager);
    } catch (error) {
      console.log('erro delete cache or allBDs', error);
    }
  }

  async processLoginUser(data) {
    try {
      await CacheStore.set(DATA_ACCOUNT, { ...data, sync: false });
      await this.sync.sync();
      await CacheStore.set(DATA_ACCOUNT, { ...data, sync: true });
    } catch (erro) {
      console.log(TAG, erro);
      throw erro;
    }

    return true;
  }

  async associateAnonymousToUser(customerCompany) {
    const { customer_id } = customerCompany;
    let order = {};

    const cartAnonymous = await CacheStore.get(CART_ANONYMOUS);
    if (cartAnonymous && cartAnonymous.products && cartAnonymous.products.length > 0) {
      try {
        const response = await transfOrder(cartAnonymous.id, customer_id);
        order = response.source.data;

        await CacheStore.set(CART_ANONYMOUS, null);
        await CacheStore.set(STORE_ITEM_ACTIVE, customerCompany);
      } catch (errors) {
        console.log(TAG, errors);
        throw errors;
      }

      return order;
    }
    return;
  }

  async processLoginAnonymous() {

    await SQLiteManager.clearAllInCache();

    await CacheStore.set(COMPANY_ACTIVE, null);
    await CacheStore.set(STORE_ITEM_ACTIVE, null);

    const accountAnonymous = await loginAnonymous();

    await SQLiteManager.clearAllTables();

    const dataAccount = { ...accountAnonymous, isAnonymous: true, sync: false };

    await CacheStore.set(DATA_ACCOUNT, dataAccount);

    try {
      await this.sync.sync();
      await CacheStore.set(DATA_ACCOUNT, { ...dataAccount, sync: true });
    } catch (erro) {
      console.log(TAG, erro);
      throw erro;
    }

    return dataAccount;
  }
}
