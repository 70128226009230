/* @flow */

import React, { Component } from 'react';
import {
  KeyboardAvoidingView, View, Text, ActivityIndicator,
  Platform, StyleSheet, Dimensions,
} from 'react-native';

// import { Modal } from 'react-native';
import Modal from 'modal-enhanced-react-native-web';

// import Modal from 'react-native-modal';
import colors from '~/values/color';

import AndroidButton from './Button';
// import { BlurView } from 'react-native-blur';

const widthDimension = Dimensions.get('window').width;
let width = 500;
if (widthDimension < 500) {
  width = widthDimension - 50;
}


type Props = {
  modalLoading?: boolean,
  showModal: boolean,
  disableButtons?: boolean,
  cancelButtonTitle?: string,
  okButtonTitle?: string,
  cancelButtonPress?: () => {},
  okButtonPress?: () => {},
  title: string,
  message?: string,
  children?: any,
  danger?: boolean,
  noButtons?: boolean,
}

const styles = StyleSheet.create({
  modal: {
    borderRadius: Platform.OS === 'ios' ? 10 : 2,
    backgroundColor: Platform.OS === 'ios' ? 'transparent' : '#fff',
    elevation: 3,
    width: width,
    maxWidth: '500px',
    marginRight: '20px',
  },
  modalContainer: {
    backgroundColor: Platform.OS === 'ios' ? 'rgba(255,255,255,0.8)' : 'transparent',
    width: width - 48,
    marginHorizontal: 6,
    borderRadius: Platform.OS === 'ios' ? 10 : 2,
  },
  modalTitle: {
    fontSize: 18,
    color: Platform.OS === 'ios' ? '#000' : colors.primaryColor,
    textAlign: Platform.OS === 'ios' ? 'center' : 'left',
  },
  modalTitle_danger: {
    color: colors.redBadge,
  },
  modalMessage: {
    color: '#000',
    marginTop: Platform.OS === 'ios' ? 5 : 16,
    fontSize: Platform.OS === 'ios' ? 14 : 15,
    textAlign: 'center',
    marginBottom: 20,
  },
  containerButtonsAndroid: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 6,
  },
});

export default class CustomAlert extends Component<Props, void> {
  static defaultProps ={
    showModal: false,
    cancelButtonTitle: '',
    message: '',
  };
  constructor(props) {
    super(props);
  }

  getModalContent() {
    return (
      this.props.modalLoading ?
        <ActivityIndicator
          colors={[colors.primaryColor, colors.accentColor]}
          style={{ width: 80, height: 80 }}
        />
        :
        <View>
          <View style={{ padding: 16 }}>
            <Text style={[
              styles.modalTitle,
              this.props.danger ? styles.modalTitle_danger : {},
            ]}
            >
              {this.props.title}
            </Text>
            { this.props.message !== '' && <Text style={styles.modalMessage}>{this.props.message}</Text> }
            <View>
              {this.props.children}
            </View>
          </View>
          <View style={styles.containerButtonsAndroid}>
            {this.props.cancelButtonTitle !== ''
                &&
                <AndroidButton
                  disabled={this.props.disableButtons}
                  transparent={Platform.OS === 'ios'}
                  flat
                  title={this.props.cancelButtonTitle || 'Cancelar'}
                  onPress={() => {
                    if (this.props.cancelButtonPress) {
                      this.props.cancelButtonPress();
                    }
                  }}
                />
              }
            {
              this.props.noButtons ?
              null
              :
              <AndroidButton
                disabled={this.props.disableButtons}
                flat
                transparent={Platform.OS === 'ios'}
                title={this.props.okButtonTitle || 'OK'}
                onPress={() => {
                    if (this.props.okButtonPress) {
                      this.props.okButtonPress();
                    }
                  }}
              />
            }
          </View>
        </View>
    );
  }

  render() {

    if (!this.props.showModal) {
      return null;
    }

    return (
      <Modal isVisible={this.props.showModal}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : null}
        >
          <View style={[styles.modalContainer, { margin: 'auto' }]}>
            {/*{Platform.OS === 'ios' ?*/}
            {/*  <BlurView*/}
            {/*    style={styles.modal}*/}
            {/*    blurType="light"*/}
            {/*  >*/}
            {/*    {this.getModalContent()}*/}
            {/*  </BlurView>*/}
            {/*  :*/}
            {/*  <View style={styles.modal}>{this.getModalContent()}</View>*/}
            {/*}*/}

            <View style={styles.modal}>{this.getModalContent()}</View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}
