import React, { Component } from 'react';
import Map from './Map';

class MapContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log('DATA PROPS MAPA CT: ', this.props);
  }

  render() {
    return (
      <Map {...this.props} place={this.props.route.params.place} />
    );
  }
}

export default MapContainer;
