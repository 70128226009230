import React, { Component } from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  Linking,
  Platform,
  TextInput,
  Keyboard
} from 'react-native';

import ProfileAvatar from '~/components/ProfileAvatar';
import AlertBuyingFor from '~/components/AlertBuyingFor';
import SideMenuOption from '~/components/SideMenuOption';

import styles from './styles';
import SideMenuSection from './components/SideMenuSection';
import orderBy from 'lodash/orderBy';
import items from '~/screens/SideMenu/utils/MenuItemEnum';
import ProgressView from '~/components/ProgressView';
import AppConfig from '~/AppConfig';
import ContactUs from './components/ContactUs';
import RBSheetListItems from '~/components/RBSheetListItems';
import Images from '~/../assets/images';
import linksBase from './utils/linksBase';
import { TouchableOpacity } from 'react-native-web';
import Modal from "modal-enhanced-react-native-web";
import CustomAlert from '~/components/CustomAlert';
import colors from '~/values/color';

type SideMenuProps = {
    onPressOption: (screen: string) => void,
    onPressLogout: () => void,
    onAlertBuyingForPress: () => void,
    user: any,
    menu: any,
    screenId: string,
    progressVisible: boolean,
    textProgress: string,
    alertTitle: string,
    onPressSheet: () => void,
};

class SideMenu extends Component<SideMenuProps> {
    state = {
      curIndexActive: items.home.id,
      visible: false,
    };

    onPressMenuItem(item) {
      const { onPressLogout, onPressOption, onPressSheet } = this.props;
      this.setState({ curIndexActive: item.id });
      if (item.id === items.logout.id) {
        this.props.onShowModalLogout();
        return;
      } else if (item.id === 'denounce') {
        onPressSheet(this.RBSheetGender);
        return;
      } else if (item.id === 'contact') {
        onPressSheet(this.RBSheetContact);
        return;
      }
      const screen = items[item.id];
      onPressOption(screen);
    }

    getIconFromId(id) {
      return items[id].icon;
    }

    onPressContactOption = ({ type, setup = [] }) => {
      setup.forEach(({ key, value }) => {
        if (key === 'url') {
          let link = linksBase[type].supported;
          Linking.canOpenURL(link)
            .then((supported) => {
              if (!supported) {
                link = linksBase[type].store[Platform.OS];
                Linking.openURL(`${linksBase.storeBase[Platform.OS]}${link}`);
                return;
              }
              Linking.openURL(`${link}${value}`);
            }).catch((err) => console.error('An error occurred', err));
        }
      });
    };

    getViewsFromJson(menu) {
      const menuViews = [];
      if (menu) {
        const { sections } = menu;
        if (sections && sections.length > 0) {
          orderBy(sections, ['order'], ['asc']).forEach((section) => {
            const items = [];
            orderBy(section.items, ['order'], ['asc']).forEach((item) => {
              items.push((<SideMenuOption
                key={item.id}
                active={this.state.curIndexActive === item.id}
                onPress={() => this.onPressMenuItem(item)}
                text={item.title}
                icon={this.getIconFromId(item.id)}
              />));
            });
            menuViews.push((
              <SideMenuSection key={section.id} title={section.title}>
                { items }
              </SideMenuSection>
            ));
          });
        }
      }
      return menuViews;
    }

    render() {
      const {
        user, menu, progressVisible, textProgress, alertTitle,
        showModalLogout, onCancelShowModalLogout, onPressLogout,
      } = this.props;

      const hasUser = user && user.display_name && user.email;

      const profileAvatar =
            hasUser ?
              (<ProfileAvatar
                user={{ ...user, name: user.display_name }}
                onPress={() => this.onPressMenuItem(items.profile)}
              />) :
              (<ProfileAvatar
                onPress={() => this.onPressMenuItem(items.login)}
              />);

      return (
        <ScrollView>

          <CustomAlert
            showModal={showModalLogout}
            okButtonTitle={"Sim"}
            cancelButtonTitle="Não"
            cancelButtonPress={onCancelShowModalLogout}
            okButtonPress={onPressLogout}
          >
            <View style={{ alignItems: 'center' }}>
              <Text style={{
                fontSize: 18,
                color: Platform.OS === 'ios' ? '#000' : colors.primaryColor,
                textAlign: Platform.OS === 'ios' ? 'center' : 'left',
              }}>
                Deseja realmente sair ?
              </Text>
            </View>
          </CustomAlert>

          <View style={styles.container}>
            <View style={styles.container}>
              <View
                style={
                  [
                    styles.topContainer,
                    hasUser ? { minHeight: 150 } : { minHeight: 100 },
                  ]
                }
              >
                <View style={styles.profileContainer}>
                  { profileAvatar }
                </View>
              </View>
              <AlertBuyingFor
                navigation={this.props.navigation}
                onAlertBuyingForPress={this.props.onAlertBuyingForPress}
              />
              <View>
                { progressVisible &&
                <ProgressView indeterminate text={textProgress} title={alertTitle} /> }
                { this.getViewsFromJson(menu) }
              </View>
            </View>
          </View>
          <View style={styles.footerVersion}>
            <Text style={{ fontWeight: 'bold', }}>Versão: </Text>
            <Text>{AppConfig.version}</Text>
          </View>

          <ContactUs
            onSelectOption={(item) => {
              this.onPressContactOption(item);
              this.RBSheetContact.close();
             }}
            setRef={(ref) => {
              this.RBSheetContact = ref;
            }}
          />

          <RBSheetListItems
            showSearch={false}
            height={220}
            setRef={(ref) => {
              this.RBSheetGender = ref;
            }}
            renderLabel={(item) => (
              <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <Image source={Images.iconPhone} style={{ tintColor: 'red' }} />
                <Text style={{ marginLeft: 10, fontSize: 15 }}>{`${item.message}`}</Text>
              </View>
            )}
            onSelectOption={(item) => {
              this.RBSheetGender.close();
              Linking.openURL(`tel:${item.value}`);
            }}
            items={[
              { message: '190 - Polícia Militar', value: '190' },
              { message: '180 - Delegacia da Mulher', value: '180' },
              { message: '197 - Polícia Civil', value: '197' },
            ]}
            title="Acessar telefone"
          />
        </ScrollView>
      );
    }
}

export default SideMenu;
