import axios from 'axios';

import { getConfig, URL_BASE, getCurrentCompany } from '../index';

export default async function transfOrder(orderId, customerId) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  const params = { source_cart_id: orderId, customer_id: customerId };

  return new Promise((resolve, reject) =>
    axios.post(`${URL_BASE}/cart/${company}/transfer/`, params, config)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data)));
}