import SQLiteManager from '../SQLiteManager';
import {
  Address,
  AttributesValuesSchema,
  CartSchema,
  CategoriesRelationshipsSchema,
  CategoryCode,
  CategoryProductContext,
  CategorySchema,
  CompanySchema,
  Customer,
  CustomerCompanyAccounts,
  ExtraInfo,
  Icon,
  Images,
  Messages,
  NoticesSchema,
  NotificationSchema,
  ComboSchema,
  Phones,
  PriceConditions,
  ProductCart,
  ProductContextSchema,
  ProductSales,
  ProductsCategoriesSchema,
  ProductChildrenScheme,
  ProductSchema,
  ProductAttributeSchema,
  PromotionSchema,
  SaleConditions,
  ShippingMethodsSchema,
  DeliveryAddress,
  SalesSchema,
  TitlesSchema,
  IntegrationStatus,
  PaymentStatus,
  PaymentOptionSchema,
  PaymentTermSchema,
  PaymentCombineKeySchema,
  Requirements,
  ItemsPromotion,
  Action,
  ActionData,
  Subtotal,
} from '../schemas';

const TAG = 'Repository';

export default class Repository {
  constructor() {
    this.tableSchema = undefined;
    this.databaseOptions = {
      schema: [
        CompanySchema,
        PromotionSchema,
        ProductSchema,
        ProductContextSchema,
        CategorySchema,
        CategoriesRelationshipsSchema,
        ProductsCategoriesSchema,
        CartSchema,
        NotificationSchema,
        ComboSchema,
        TitlesSchema,
        SalesSchema,
        NoticesSchema,
        Address,
        Phones,
        Images,
        CategoryCode,
        SaleConditions,
        ShippingMethodsSchema,
        DeliveryAddress,
        Icon,
        Messages,
        CustomerCompanyAccounts,
        ExtraInfo,
        CategoryProductContext,
        PriceConditions,
        ProductCart,
        IntegrationStatus,
        PaymentStatus,
        ProductSales,
        Customer,
        PaymentOptionSchema,
        PaymentTermSchema,
        PaymentCombineKeySchema,
        Requirements,
        ItemsPromotion,
        ProductAttributeSchema,
        ProductChildrenScheme,
        AttributesValuesSchema,
        Action,
        ActionData,
        Subtotal,
      ],
      schemaVersion: 5,
    };
  }
}
