import Controller from '~/controllers/Controller';
import CategoryRepository from '~/database/repositories/CategoryRepository';

const TAG = 'CategoryController';

export default class CategoryController extends Controller {
  constructor() {
    super();
    this.repository = new CategoryRepository();
  }

  getAllCategoriesByCategoryCode(code) {
    return this.repository.getAllCategoriesByCategoryCode(code);
  }

  addCategory(categorys) {
    return this.repository.addCategory(categorys);
  }

  allRoot() {
    return this.repository.allRoot();
  }

  allCategoriesBySearch(value) {
    return this.repository.allCategoriesBySearch(value);
  }

  allCategoryRelationships() {
    return this.repository.getAllCategoryRelationships();
  }
}
