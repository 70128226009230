import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const size = 20;
const padding = 8;

const styles = StyleSheet.create({
  overflowContainer: {
    overflow: 'hidden',
    borderRadius: size + (padding * 2) / 2,
    width: size + (padding * 2),
    height: size + (padding * 2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: size,
    height: size,
    borderColor: colors.primaryColor,
    borderWidth: 2,
    borderRadius: size * 0.2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container__active: {
    backgroundColor: colors.primaryColor,
  },
  icon: {
    tintColor: '#FFF',
    width: size * 0.8,
    height: size * 0.8,
    resizeMode: 'contain',
  },
});

export default styles;
