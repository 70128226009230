import { StyleSheet } from 'react-native';
import colors from '~/values/color';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: colors.grayContainer,
  },
  logo: {
    width: 240,
    height: 65,
    alignSelf: 'center',
    resizeMode: 'contain',
    margin: 16,
    marginTop: 24,
  },
  loginBoxInputs: {
    paddingLeft: 18,
    paddingRight: 18,
  },
  iconTextField: {
    width: 20,
    height: 20,
    marginRight: 16,
    tintColor: '#949494',
  },
  loginButton: {
    paddingHorizontal: 40,
    paddingVertical: 12,
    marginBottom: 14,
  },
});

export default styles;
