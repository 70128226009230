// @flow

import React, { PureComponent } from 'react';
import { View, Text, ActivityIndicator, FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native';
import moment from 'moment';

import type { IOrder } from '~/entities/Orders';
import { abbreviatedMonths } from '~/common/months';
import { Money } from '~/common';

import styles from './styles';
import colors from '~/values/color';
import { Button } from '~/components';
import Images from '~/../assets/images';
import EmptyView from '~/components/EmptyView';

type OrderProps = {
  onPressOrder: (order: IOrder) => void,
  orders: any,
  loading: boolean,
};

class Orders extends PureComponent<OrderProps> {
  constructor(props) {
    super(props);
    this.state = {
      lastOrdersVisible: false,
    };
  }

  keyExtractor = (item) => item.order_code;

  itemSeparator = () => <View style={styles.separator} />;

  renderDate(order) {
    const momentDate = moment(order.sent_date);
    const day = momentDate.date();
    const month = abbreviatedMonths[momentDate.month()];
    const hour = momentDate.hour();
    const minute = momentDate.minute();
    return (
      <View style={styles.dateContainer}>
        <Text style={styles.day}>{day}</Text>
        <Text style={styles.text}>{month}</Text>
        <Text style={styles.text}>{`${hour}:${minute}`}</Text>
      </View>
    );
  }

  renderStatus(order) {
    const status = order.integration_status;
    if (!status) return null;
    return (
      <Text style={styles.text}>Situação:
        <Text style={styles.situation}> {status.description}</Text>
      </Text>
    );
  }

  renderObservation(item) {
    const { observation } = item;
    if (!observation || observation === '') return null;
    return (
      <Text style={styles.text}>Observação:
        <Text style={styles.situation}> {observation} </Text>
      </Text>
    );
  }

  renderHeader = (lastOrdersVisible) => (
    <View style={{ margin: 3 }}>
      <Button
        containerStyle={{ alignItems: 'flex-start' }}
        title="Últimos pedidos"
        iconRight={lastOrdersVisible ? Images.iconCollapseArrow : Images.iconExpandArrow}
        flat
        transparent
        noUpperCase
        small
      />
    </View>);

  renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => this.props.onPressOrder(item)}
    >
      <View style={styles.container}>
        {this.renderDate(item)}
        <View>
          <Text style={styles.text}>{item.order_code}</Text>
          <Text style={styles.client}>{item.customer_name || 'Sem nome do cliente'}</Text>
          <Text style={styles.text}>{Money(item.amount)}</Text>
          {this.renderStatus(item)}
          {this.renderObservation(item)}
        </View>
      </View>
    </TouchableOpacity>);

  emptyView = () => <EmptyView icon={Images.iconClipboard} message="Você ainda não possui pedidos" />;

  render() {
    const { lastOrdersVisible } = this.state;
    const { orders, loading } = this.props;

    return (
      <View style={styles.dataContainer}>
        { loading ?
          <View><ActivityIndicator size="small" color={colors.primaryColor} /></View>
              :
          <FlatList
            ListHeaderComponent={this.renderHeader(lastOrdersVisible)}
            data={orders}
            keyExtractor={this.keyExtractor}
            refreshing={false}
            initialNumToRender={7}
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.itemSeparator}
            ListEmptyComponent={this.emptyView}
          />
          }
      </View>
    );
  }
}

Orders.defaultProps = {
  orders: [],
  loading: false,
};

export default Orders;
