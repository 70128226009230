import React, {Component} from 'react';
// import {Navigation} from 'react-native-navigation';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import {Alert} from 'react-native';

import Stores from './Stores';
import {SCREEN_CHOOSE_STORE, SCREEN_STORES} from '~/screens/screens';
// import NavigationHelper from '~/screens/NavigationHelper';
import ComponentHelper from '~/components/ComponentHelper';
import DataManager from '~/database/DataManager';
import {SchemaModels} from '~/database/utils/DBUtils';
import {COMPANY_ACTIVE, STORE_ITEM_ACTIVE} from '~/constants/cache';
import setCurrentStoreItem from '~/store/setCurrentStoreItem/action';
import {createSelectorCreator, defaultMemoize} from 'reselect';
import {changeStore} from '../../store/changeStore/action';
import setCurrentCompany from '../../store/setCurrentCompany/action';
import Sync from '~/utils/Sync';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_SET_CURRENT_ACCOUNT,
  STORE_SET_CURRENT_NET_INFO,
} from '../../store/storesConstants';
import activeCart from '~/services/carts';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import {startHomeUI} from "~/App.web";
import NetInfo from "@react-native-community/netinfo";
import setCurrentNetInfo from "~/store/setCurrentNetInfo/action";
import listStores from "~/services/stores";
import AppConfig from "~/AppConfig";

import CompanyController from '~/controllers/CompanyController';
import CartController from '~/controllers/CartController';
import { toast } from 'react-toastify';

type
Props = {
  componentId: any,
  dispatch: () => {
  },
  currentNetInfo: any,
  sCurrentAccount: any,
  sOrderActive: any,
};

const TAG = 'StoresContainer';

class StoresContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.sync = new Sync();
    this.state = {
      stores: [],
      storeItemActive: null,
      refreshing: false,
      progressVisible: false,
      textProgress: '',
      alertTitle: 'Aguarde',
      needRequestAccess: true,
    };

    this.props.navigation.setOptions({
      title: SCREEN_STORES.title,
    })
  }

  UNSAFE_componentWillMount() {
    NetInfo.addEventListener(state => {
      const netInfo = {
        type: state.type,
        is_connected: state.isConnected,
      };
      this.props.dispatch(setCurrentNetInfo(netInfo));
    });
  }

  loadDataFromDb() {
    this.setState({refreshing: true});

    const dataManager = new DataManager();
    const companyController = new CompanyController();

    const storeItemActivePromise = CacheStore.get(STORE_ITEM_ACTIVE);
    const allCompaniesPromise = companyController.allCompany();
    const needRequestAccess = dataManager.getConfigValue('need_request_access');

    Promise.all([storeItemActivePromise, allCompaniesPromise])
      .then(data => {
        this.loadData(data[0] || [], data[1] || []);
        this.setState({needRequestAccess, refreshing: false, progressVisible: false});
      })
      .catch(error => {
        console.log(TAG, error);
        this.setState({refreshing: false, progressVisible: false});
      });
  }

  loadCompaniesFromServer = async () => {
    this.setState({refreshing: true});

    try {
      const companies = await listStores();
      const companyController = new CompanyController();
      companyController.addCompany(companies);

      this.setState({
        stores: companies.map((company)=> {
          return {
            ...company,
            registersVisible: true,
          }
        }),
        storeItemActive: null
      });
    } catch (e) {
      console.log(TAG, 'loadCompaniesFromServer companies');
    } finally {
      this.setState({refreshing: false, progressVisible: false});
    }
  }

  componentDidMount() {
    if (AppConfig.startFromStore) {
      this.loadCompaniesFromServer();
    } else {
      this.loadDataFromDb();
    }
  }

  /* componentDidAppear = () => {
    console.log('componentDidAppear');
    if (AppConfig.startFromStore) {
      this.loadCompaniesFromServer();
    } else {
      this.loadDataFromDb();
    }
  }; */

  onRefresh = () => {
    if (AppConfig.startFromStore) {
      this.loadCompaniesFromServer();
    } else {
      this.loadDataFromDb();
    }
  };

  doRegisterItemPress = async storeItem => {
    const netInfo = this.props.currentNetInfo;

    if (!netInfo.is_connected) {
      Alert.alert('', 'Você está offline! Conecte a internet para trocar de cliente.');
      return;
    }

    this.setState({
      progressVisible: true,
      textProgress: `Alterando para o cliente: ${storeItem.customer.trading_name}...`,
    });

    const response = await activeCart(storeItem.company_id, storeItem.customer_id);
    const data = get(response, ['source', 'data']) || [];
    const cart = data.length ? data[0] : {};

    if (!cart.success) {
      const cartErroMsg = cart.message || 'Não foi possível obter o carrinho!';

      setTimeout(() => {
        toast.error(`${cartErroMsg}. Por favor contate o suporte.`);
      }, 200);

      this.setState({
        progressVisible: false,
        textProgress: '',
      });

      return;
    }

    this.setState({
      progressVisible: true,
      textProgress: 'Estamos sincronizando seus dados...',
    });

    const cartController = new CartController();
    const companyController = new CompanyController();
    let currentCompany = {};

    if (storeItem) {
      await CacheStore.set(STORE_ITEM_ACTIVE, storeItem);
      this.props.dispatch(setCurrentStoreItem(storeItem));
      currentCompany = await companyController.getCompany(storeItem.company_id);
    }

    if (currentCompany) {
      await CacheStore.set(COMPANY_ACTIVE, currentCompany);
      this.props.dispatch(setCurrentCompany(currentCompany));

      this.loadData(storeItem, this.state.stores);

      if (AppConfig.startFromStore) {
        startHomeUI();

        return;
      }

      let orderActive = await cartController.getCart(null, true);
      if (orderActive && orderActive[0].customer_id === storeItem.customer_id) {
        orderActive = orderActive[0];

        orderActive.sale_conditions = [];
        this.props.dispatch(changeStore(
          {
            ...orderActive,
            company_id: storeItem.company_id,
            customer_id: storeItem.customer_id,
          },
          SCREEN_STORES.name,
        ));
      } else {
        this.props.dispatch(changeStore(
          {
            ...cart,
            company_id: storeItem.company_id,
            customer_id: storeItem.customer_id,
          },
          SCREEN_STORES.name,
        ));
      }
      this.setState({
        progressVisible: false,
        textProgress: '',
      });
    }
  };

  onRegisterItemPress = ComponentHelper.debounce(this.doRegisterItemPress);

  doRegisterPress = store => {
    const {stores} = this.state;
    const objIndex = stores.findIndex(obj => obj.id === store.id);
    stores[objIndex].registersVisible = !stores[objIndex].registersVisible;
    this.setState({stores});
  };

  onRegisterPress = ComponentHelper.debounce(this.doRegisterPress);

  loadData(storeItemActive, stores) {
    const storesData = stores.map(store => ({
      ...store,

      customer_company_accounts:
        store.customer_company_accounts && store.customer_company_accounts.length > 0
          ? store.customer_company_accounts
          : [],

      registersVisible:
        store.customer_company_accounts && store.customer_company_accounts.length > 0
          ? store.customer_company_accounts.find(obj => obj.customer_id === (storeItemActive ? storeItemActive.customer_id : null)) !== undefined
          : false,
    }));

    this.setState({
      stores: storesData,
      storeItemActive,
    });
  }

  doRequestNewRegister = (item) => {
    this.props.navigation.navigate(SCREEN_CHOOSE_STORE.name, {company: item}, {...this.props});
  };

  requestNewRegister = ComponentHelper.debounce(this.doRequestNewRegister);

  render() {
    const {
      stores,
      storeItemActive,
      refreshing,
      progressVisible,
      textProgress,
      alertTitle,
      needRequestAccess,
    } = this.state;

    const items = AppConfig.startFromStore ? stores : stores.filter(store => store.customer_company_accounts.length);

    return (
      <Stores
        stores={orderBy(items, 'display_name')}
        storeItemActive={storeItemActive}
        currentAccount={this.props.sCurrentAccount}
        onRegisterPress={this.onRegisterPress}
        onRegisterItemPress={this.onRegisterItemPress}
        requestNewRegister={this.requestNewRegister}
        refreshing={refreshing}
        onRefresh={this.onRefresh}
        progressVisible={progressVisible}
        textProgress={textProgress}
        alertTitle={alertTitle}
        needRequestAccess={needRequestAccess}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentAccount: getAccount(state),
    sOrderActive: getOrderActive(state),
    currentNetInfo: getNetInfo(state),
  };
}

export default connect(mapStateToProps)(StoresContainer);
