import { SET_CURRENT_STORE_ITEM, SYNC_CURRENT_STORE_ITEM } from '~/store/setCurrentStoreItem/action';
import { Map } from 'immutable';

const initialState = Map({
  type: '',
  payload: Map({}),
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SET_CURRENT_STORE_ITEM) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], Map(payload));
  }

  if (type === SYNC_CURRENT_STORE_ITEM) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;
