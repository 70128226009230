// @flow

import React, { Component } from 'react';
import { View } from 'react-native';
import AlertBuyingFor from '~/components/AlertBuyingFor/AlertBuyingFor';
import ComponentHelper from '~/components/ComponentHelper';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_STORES } from '~/screens/screens';
import { STORE_ITEM_ACTIVE } from '~/constants/cache';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { STORE_SET_CURRENT_ACCOUNT, STORE_SET_CURRENT_STORE_ITEM, STORE_LOGIN_STATE } from '~/store/storesConstants';
import { connect } from 'react-redux';
import setCurrentStoreItem from '~/store/setCurrentStoreItem/action';
import DataManager from '../../database/DataManager';
import AppConfig from '../../AppConfig';
import { SALE_MODEL_B2C } from '~/constants/saleModel';

const TAG = 'AlertBuyingForContainer';

type AlertBuyingContainerProps = {
  dispatch: () => {},
  pressEnabled: boolean,
  componentId: string,
  showIcon: boolean,
  sCurrentStoreItem: any,
  onAlertBuyingForPress: any,
  valueLogin: any,
  sCurrentAccount: {
    isAnonymous: boolean,
    user: {
      id: string,
    },
  },
};

class AlertBuyingForContainer extends Component<AlertBuyingContainerProps> {
  constructor(props) {
    super(props);
    this.state = {
      needRequestAccess: true,
    };
  }

  UNSAFE_componentWillMount() {
    const dataManager = new DataManager();
    const needRequestAccess = dataManager.getConfigValue('need_request_access');
    this.setState({ needRequestAccess });
  }

  componentDidMount() {
    CacheStore.get(STORE_ITEM_ACTIVE)
      .then(storeItemActive => {
        this.props.dispatch(setCurrentStoreItem(storeItemActive));
      })
      .catch(error => {
        console.log(TAG, error);
      });
  }

  onPress = () => {
    const { pressEnabled } = this.props;
    if (pressEnabled) {
      this.props.navigation.push(SCREEN_STORES.name);
    }
    if (this.props.onAlertBuyingForPress) this.props.onAlertBuyingForPress();
  };

  onAlertBuyingForPress = ComponentHelper.debounce(this.onPress);

  render() {
    const {
      sCurrentStoreItem, sCurrentAccount, showIcon, valueLogin,
    } = this.props;
    let name;
    if (
      AppConfig.sale_model !== SALE_MODEL_B2C &&
      sCurrentAccount &&
      !sCurrentAccount.isAnonymous &&
      sCurrentStoreItem &&
      sCurrentStoreItem.customer
    ) {
      name = sCurrentStoreItem.customer.name || sCurrentStoreItem.customer.trading_name;
    }

    return (
      <View>
        {this.state.needRequestAccess &&
          name && (
            <AlertBuyingFor
              storeName={name}
              onPress={this.onAlertBuyingForPress}
              showIcon={showIcon}
              loadData={valueLogin.auth}
            />
          )}
      </View>
    );
  }
}

AlertBuyingForContainer.defaultProps = {
  pressEnabled: true,
};

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getCurrentStoreItem = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM, 'payload']).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

const getLoginState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOGIN_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentStoreItem: getCurrentStoreItem(state),
    sCurrentAccount: getAccount(state),
    valueLogin: getLoginState(state),
  };
}

export default connect(mapStateToProps)(AlertBuyingForContainer);
