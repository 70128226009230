/* @flow */

import { Map, List } from 'immutable';

import {
  LOCATION_REQUEST,
  LOCATION_SUCCESS,
  LOCATION_FAILURE,
} from './action';

const initialState = Map({
  type: '', loading: false, payload: {}, messages: List([]),
});

export default function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === LOCATION_REQUEST) {
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => true);
  }

  if (type === LOCATION_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => false)
      .updateIn(['messages'], () => List([]))
      .setIn(['payload'], payload);
  }

  if (type === LOCATION_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => false).setIn(['messages'], List(payload));
  }

  return state;
}
