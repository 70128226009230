import React, { Component } from 'react';
import get from 'lodash/get';
import Warnings from './Warnings';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { STORE_ACTIVE_ORDER_FETCH, STORE_PRODUCTS_FETCH } from '../../store/storesConstants';
import connect from 'react-redux/es/connect/connect';
import { mergeOnlyProductWithOrder } from '~/utils/product';
import Images from '~/../assets/images';
// import { Navigation } from 'react-native-navigation';
import { Alert } from 'react-native';
import { clearAllMessages, clearMessage } from '../../services/carts';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import activeOrder from '~/store/activeOrderFetch/activeOrder/action';
import DataManager from '~/database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import {
  ORDER_ACTIVE_REQUEST,
  ORDER_ACTIVE_SUCCESS,
} from '../../store/activeOrderFetch/activeOrder/action';

import CartController from '~/controllers/CartController';
import {toast} from 'react-toastify'
export type Props = {
  componentId: string,
  order: Object<any>,
  productsFetch: Object<any>,
  sOrderActive: Object<any>,
  dispatch: () => void,
};

const TAG = 'WarningsContainer';

class WarningsContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      clearingWarnings: false,
    };
    // Navigation.events().bindComponent(this);
    // Navigation.mergeOptions(this.props.componentId, {
    //   topBar: {
    //     elevation: 0,
    //     rightButtons: [
    //       {
    //         id: 'clearAll',
    //         text: 'Limpar\ntudo',
    //         icon: Images.iconDoubleTick,
    //       },
    //     ],
    //   },
    // });
  }

  // UNSAFE_componentWillMount() {
  //   Navigation.events().registerNavigationButtonPressedListener(({ buttonId }) => {
  //     if (buttonId === 'clearAll') {
  //       Alert.alert('Descartar tudo', 'Descartar todos os avisos?', [
  //         {
  //           text: 'Cancelar',
  //           onPress: () => {},
  //           style: 'cancel',
  //         },
  //         {
  //           text: 'Sim',
  //           onPress: async () => {
  //             if (this.props.order) {
  //               this.setState({ clearingWarnings: true });
  //               try {
  //                 const responseClearAllMsgs = await clearAllMessages(this.props.order.id);
  //                 const data = get(responseClearAllMsgs, ['source', 'data']) || {};
  //
  //                 await this.refreshCart(data);
  //               } catch (error) {
  //                 this.attemptError(error);
  //               }
  //             } else {
  //               Snackbar.show({
  //                 title:
  //                   'Erro ao descartar as mensagens. Carrinho não disponível. Contate o suporte.',
  //                 duration: Snackbar.LENGTH_LONG,
  //               });
  //             }
  //           },
  //         },
  //       ]);
  //     }
  //   });
  // }

  componentDidUpdate(prevProps) {
    (() => {
      if (
        prevProps.sOrderActive.type === ORDER_ACTIVE_REQUEST &&
        this.props.sOrderActive.type === ORDER_ACTIVE_SUCCESS
      ) {
        this.setState({
          clearingWarnings: false,
        });
      }
    })();
  }

  async onClearWarning(item) {
    const { order } = this.props;
    if (order) {
      this.setState({ clearingWarnings: true });
      try {
        const responseClearMessage = await clearMessage(order.id, item.id);
        const data = get(responseClearMessage, ['source', 'data']) || {};
        await this.refreshCart(data, false);
        toast.success('Aviso descartado com sucesso!')
      } catch (error) {
        this.attemptError(error);
        toast.error('Ocorreu um erro.')
      }
    } else {
      Snackbar.show({
        title: 'Erro ao descartar a mensagem. Carrinho não disponível. Contate o suporte.',
        duration: Snackbar.LENGTH_LONG,
      });
    }
  }

  getMessages() {
    const { order, productsFetch } = this.props;
    const products = get(productsFetch, 'payload.products', []);

    return order.messages.map(message => {
      if (message.sender_class === 'item') {
        let product = products.find(prod => prod.id === message.sender_id);
        if (product) {
          product = mergeOnlyProductWithOrder(product, order.products);
          return { ...message, product };
        }
        return message;
      }
      return message;
    });
  }

  async refreshCart(data, all = true) {
    if (data.success) {
      const cartController = new CartController();
      const cart = { ...data, id: `${data.id}` };
      await cartController.addCart([cart], null, true);
      this.props.dispatch(activeOrder());
    } else {
      Snackbar.show({
        title: `Erro ao descartar ${all ? 'as mensagens' : 'a mensagem'}. Contate o suporte.`,
        duration: Snackbar.LENGTH_LONG,
      });
    }
  }

  attemptError(error, all = true) {
    console.log(TAG, error);
    Snackbar.show({
      title: `Erro ao descartar ${all ? 'as mensagens' : 'a mensagem'}. Contate o suporte.`,
      duration: Snackbar.LENGTH_LONG,
    });
    this.setState({ clearingWarnings: false });
  }

  render() {
    return (
        <Warnings
          messages={this.getMessages()}
          onClearWarning={item => this.onClearWarning(item)}
          clearingWarnings={this.state.clearingWarnings}
          screenId={this.props.componentId}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getProductsAndCategories = createImmutableSelector([state => state], state =>
  state.getIn([STORE_PRODUCTS_FETCH, 'list']).toJS());

const getOrderActiveImmut = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

function mapStateToProps(state) {
  return {
    productsFetch: getProductsAndCategories(state),
    order: getOrderActiveImmut(state),
    sOrderActive: getOrderActive(state),
  };
}

export default connect(mapStateToProps)(WarningsContainer);
