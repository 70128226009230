// eslint-disable-next-line no-use-before-define
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Cart from '~/screens/Cart';
import Warnings from '~/screens/Warnings';
import {
  SCREEN_CHECKOUT, SCREEN_CONFIRMATION,
  SCREEN_LIST_ADDRESS,
  SCREEN_PRODUCT,
  SCREEN_STORES,
  SCREEN_WARNINGS
} from '~/screens/screens';
import Stores from '~/screens/Stores/StoresContainer';
import { colors } from '~/values';
import Product from '~/screens/Product';
import CheckOut from '~/screens/CheckOut';
import ListAddress from '~/screens/Address/ListAddress';
import Confirmation from '~/screens/Confirmation';

const CartsStack = createStackNavigator();

const CartsRoutes = () => (
  <CartsStack.Navigator screenOptions={{
    title: 'Carrinho',
    headerStyle: {
      backgroundColor: colors.primaryColor,
      textAlign: 'center',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',

    },
  }}>
    <CartsStack.Screen name="Cart" component={Cart}/>
    <CartsStack.Screen name={SCREEN_STORES.name} component={Stores}/>
    <CartsStack.Screen
      name={SCREEN_WARNINGS.name} component={Warnings}
      options={{
        title: SCREEN_WARNINGS.title
      }}
    />
    <CartsStack.Screen name={SCREEN_PRODUCT.name} component={Product}/>
    <CartsStack.Screen
      name={SCREEN_CHECKOUT.name}
      component={CheckOut}
      options={{
        title: SCREEN_CHECKOUT.title
      }}
    />
    <CartsStack.Screen name={SCREEN_LIST_ADDRESS.name} component={ListAddress}/>
    <CartsStack.Screen name={SCREEN_CONFIRMATION.name} component={Confirmation}/>
  </CartsStack.Navigator>
);

export default CartsRoutes;
