import { StyleSheet } from 'react-native';
import { colors, defaultShadow, sizes } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  logo: {
    width: 240,
    height: 65,
    alignSelf: 'center',
    resizeMode: 'contain',
    margin: 16,
    marginTop: 4,
  },
  icon: {
    width: 20,
    height: 20,
    tintColor: colors.primaryColor,
  },
  infoContainer: {
    padding: 16,
    paddingTop: 0,
  },
  iconInfo: {
    width: 24,
    height: 24,
    marginRight: sizes.horizontalSpaceSmall,
    tintColor: colors.grayMid,
  },
  infoText: {
    fontSize: sizes.textNormal,
    color: colors.grayDark,
  },
  loginBoxInputs: {
    padding: 16,
    backgroundColor: '#FFF',
    ...defaultShadow,
  },
  switchContainer: {
    ...appStyles.rowDirection,
  },
  label: {
    color: colors.grayDark,
    fontWeight: '600',
    fontSize: sizes.textBig,
    margin: sizes.horizontalSpacing,
  },
});

export default styles;
