import isEmpty from 'lodash/isEmpty';

import SourceInterpreter from '~/utils/SourceInterpreter';
import { ERROR_REPONSE_DEFAULT } from '~/constants/messages';

export const ORDER_UPDATE_ORDER_REQUEST = 'ORDER_UPDATE_ORDER_REQUEST';
export const ORDER_UPDATE_ORDER_SUCCESS = 'ORDER_UPDATE_ORDER_SUCCESS';
export const ORDER_UPDATE_ORDER_FAILURE = 'ORDER_UPDATE_ORDER_FAILURE';
export const ORDER_UPDATE_ORDER_RESPONSE_FAILURE = 'ORDER_UPDATE_ORDER_RESPONSE_FAILURE';
export const ORDER_UPDATE_ORDER_RESPONSE_SUCCESS = 'ORDER_UPDATE_ORDER_RESPONSE_SUCCESS';

export function successUpdateOrder(payload) {
  return dispatch => dispatch({ type: ORDER_UPDATE_ORDER_SUCCESS, payload });
}

export function failureUpdateOrder(payload) {
  return dispatch => dispatch({ type: ORDER_UPDATE_ORDER_FAILURE, payload });
}

function responseUpdateOrderFailure(payload) {
  return dispatch => dispatch({ type: ORDER_UPDATE_ORDER_RESPONSE_FAILURE, payload });
}

function responseUpdateOrderSuccess(payload) {
  return dispatch => dispatch({ type: ORDER_UPDATE_ORDER_RESPONSE_SUCCESS, payload });
}

export function updateOrder(order, screen) {
  return dispatch => dispatch({
    type: ORDER_UPDATE_ORDER_REQUEST,
    payload: { order, screen },
  });
}

export function responseUpdateOrder(response) {
  const data = SourceInterpreter(response);
  const emptySource = isEmpty(response.source);

  if (emptySource) {
    const errors = data ? data.messages : ERROR_REPONSE_DEFAULT;
    return responseUpdateOrderFailure(errors);
  }

  return responseUpdateOrderSuccess(data);
}
