import React, { Component } from 'react';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
// import { Navigation } from 'react-native-navigation';
import find from 'lodash/find';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

import login, { signUp, edit } from '~/services/account';
import { SCREEN_SIGNUP, SCREEN_STORES } from '~/screens/screens';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import NavigationHelper from '~/screens/NavigationHelper';
import SignUp from '~/screens/SignUp/SignUp';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_SET_CURRENT_NET_INFO,
  STORE_SET_CURRENT_ACCOUNT,
} from '../../store/storesConstants';
import Login from '~/utils/Login';
import { syncCompanies } from '~/utils/store';
import { STORE_ITEM_ACTIVE } from '~/constants/cache';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import { setCompanyCustomerActiveFromCompany } from '~/utils/CompanyCustomer';
import setCurrentCompany from '../../store/setCurrentCompany/action';
import setCurrentStoreItem, { syncCurrentStoreItem } from '../../store/setCurrentStoreItem/action';
import WalletController from '~/controllers/WalletController';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import { DATA_ACCOUNT } from '~/constants/cache';
import moment from 'moment';
import TextUtils from '~/utils/TextUtils';
import AppConfig from '../../AppConfig';
import { SALE_MODEL_B2C } from '~/constants/saleModel';
import { isCpfValid } from '~/components/Input/ZipCode';

import setNewLogin from '../../store/newLogin/action';
import setLoadPromotions from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setErrorCompanyCustomer from '../../store/setErrorCompanyCustomer/action';

import CompanyController from '~/controllers/CompanyController';
import CartController from '~/controllers/CartController';
import SignUPWeb from './SignUPWeb';

import { StackActions } from '@react-navigation/native';
import { toast } from 'react-toastify';


const TAG = 'SignUpContainer';

type Props = {
  componentId: string,
  currentNetInfo: any,
  isUpdate: boolean,
  sCurrentAccount: { user: {} },
  dispatch: () => {},
};

class SignUpContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      logging: false,
    };

    this.login = new Login();
    this.myRef = React.createRef();
  }

  onPressSave = async (form,reset) => {
    console.log('DATA FORM: ', form);
    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      toast.error('Você está offline! Conecte a internet para realizar o cadastro');
      return;
    }

    this.setState({ logging: true });
    const { isUpdate } = this.props.route.params;
    console.log('iSUPDATE PROP: ', isUpdate);
    const dataFromFormOrUser = this.getDataFromFormOrUser(form);

    // const errors = this.validateFields({ ...dataFromFormOrUser, ...form });
    const errors = [];

    if (errors.length > 0) {
      this.showErrorInForm(errors.join('\n'));
      this.setState({ logging: false });
      return;
    }

    const {
      name, exhibitionName, birthday, phone, city, state, street, complement,
    } = dataFromFormOrUser;
    const logData = `Dados: ${name}, ${exhibitionName}, ${birthday}, ${phone}, ${city}-${state}, ${street}, ${complement}`;

    try {
      if (isUpdate) {
        const newForm = {
          ...this.props.sCurrentAccount.user,
          ...dataFromFormOrUser,
        };
        await edit(newForm);
      } else {
        await signUp(form);
        reset()
      }
    } catch (error) {
      if (error.error && find(error.error.errors, { message: 'email must be unique' })) {
        toast.error(
          'Já existe um usuário cadastrado com esse email! \n Utilize a opção de recuperar senha!',
        );
      } else {
        let msgComplement = 'fazer novo cadastro';
        if (this.props.route.params.isUpdate) {
          msgComplement = 'editar o cadastro';
        }

        toast.error(`Não foi possível ${msgComplement}`)
      }

      this.setState({ logging: false });

      console.log(TAG, error);
      return;
    }

    let data = {};
    if (this.props.route.params.isUpdate) {
      const dataAccount = await CacheStore.get(DATA_ACCOUNT);
      const {
        birthday,
        phone,
        name,
        exhibitionName,
        personDocument,
        zipCode,
        addressNumber,
        street,
        district,
        city,
        state,
        complement,
        landline
      } = dataFromFormOrUser;

      const dataAccountUser = {
        ...dataAccount.user,
        name,
        display_name: exhibitionName,
        nin: personDocument,
        phones: { main: phone, secondary: landline },
        address: {
          postal_code: zipCode,
          number: addressNumber,
          address: street,
          state,
          district,
          city,
          complement,
        },
      };

      if (form.birthday) {
        dataAccountUser.birthday = moment(form.birthday, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      const newAccount = { ...dataAccount, user: dataAccountUser };
      await CacheStore.set(DATA_ACCOUNT, newAccount);
      this.props.dispatch(setCurrentAccount(newAccount));

      toast.success('Cadastro alterado com sucesso');

      this.setState({ logging: false });
      // NavigationHelper.pop(this.props.componentId);
      const popAction = StackActions.pop(1);
      this.props.navigation.dispatch(popAction);

    } else {
      try {
        data = await login(form.email, form.password);
        // await WalletController.create(data);
      } catch (error) {
        this.setState({ logging: false });
        if (error) {
          toast.error('Ops...' + error);
        } else {
          /*Snackbar.show({
            title: 'Erro ao tentar fazer novo cadastro',
            duration: Snackbar.LENGTH_LONG,
          });*/
          toast.error('Erro ao tentar fazer novo cadastro');
        }
        console.log(TAG, error);
        return;
      }

      try {
        const { sOrderActive } = this.props;

        await this.login.deleteDataUser(data, sOrderActive);

        const newLogin = {
          type: '',
          auth: true,
        };
        const loadData = {
          type: '',
          load: true,
        };
        this.props.dispatch(setNewLogin(newLogin));
        this.props.dispatch(setLoadPromotions(loadData));
        this.props.dispatch(setLoadCategories(loadData));
        this.props.dispatch(setLoadProdContext(loadData));
        this.props.dispatch(setLoadNoticies(loadData));

        await this.login.processLoginUser(data);

        const companyController = new CompanyController();
        const cartController = new CartController();
        const companies = await companyController.allCompany();
        let orderActive = await cartController.getCart(null, true);
        if (orderActive !== null) {
          orderActive = orderActive[0];
          const currentCompany = companies.find(com => com.id === orderActive.company_id);
          if (currentCompany) {
            const companyCustomer = currentCompany.customer_company_accounts[0];
            this.props.dispatch(setCurrentCompany(currentCompany));

            if (companyCustomer) {
              await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);
              this.props.dispatch(setCurrentStoreItem(companyCustomer));
              this.props.dispatch(syncCurrentStoreItem(companyCustomer));
            }
          }
        }

        this.props.dispatch(setCurrentAccount(data));

        toast.success('Cadastro realizado com sucesso');

        this.setState({ logging: false });

        if (AppConfig.sale_model !== SALE_MODEL_B2C) {
          const errorCompanyCustomer = {
            type: '',
            error: true,
          };
          this.props.dispatch(setErrorCompanyCustomer(errorCompanyCustomer));
          this.props.navigation.navigate(SCREEN_STORES.name, {...this.props});
        } else {
          setTimeout(() => {
            this.props.navigation.navigate('Home');
          }, 200);
        }
      } catch (error) {
        console.log(TAG, error);
        this.setState({ logging: false });
        if (error && error.response && error.response.status === 403) {
          this.props.dispatch(setCurrentAccount(data));

          await CacheStore.set(STORE_ITEM_ACTIVE, {});
          this.props.dispatch(setCurrentStoreItem(null));
          this.props.dispatch(syncCurrentStoreItem(null));

          await syncCompanies();
          // NavigationHelper.push(this.props.componentId, SCREEN_STORES.name);
          this.props.navigation.navigate(SCREEN_STORES.name, {...this.props});
          return;
        }

        toast.error('Erro ao realizar a autenticação.');
      }
    }
  };

  getDataFromFormOrUser(form) {
    const { isUpdate } = this.props.route.params;
    const {
      name, display_name, nin, birthday, phones, address,
    } = typeof this.props.sCurrentAccount.user !== 'undefined' && this.props.sCurrentAccount.user;

    const {
      postal_code, number, address: street, district, state, city, complement,
    } = address || {};

    let newBirthday = null;
    if (form.birthday) {
      newBirthday = form.birthday;
    } else if (isUpdate && birthday) {
      newBirthday = moment(birthday, 'YYYY/MM/DD').format('DD/MM/YYYY');
    }

    return {
      name: form.name || (isUpdate && name ? name : ''),
      exhibitionName: form.exhibitionName || (isUpdate && display_name ? display_name : ''),
      personDocument: form.personDocument || (isUpdate && nin ? nin : ''),
      birthday: newBirthday,
      phone: form.phone || (isUpdate && phones && phones.main ? phones.main : ''),
      landline: form.landline || (isUpdate && phones && phones.secondary ? phones.secondary : ''),
      zipCode: form.zipCode || (isUpdate && postal_code ? postal_code : ''),
      addressNumber: form.addressNumber || (isUpdate && number ? number : ''),
      street: form.street || (isUpdate && street ? street : ''),
      district: form.district || (isUpdate && district ? district : ''),
      state: form.state || (isUpdate && state ? state : ''),
      city: form.city || (isUpdate && city ? city : ''),
      complement: form.complement || (isUpdate && complement ? complement : ''),
    };
  }

  validateFields(fields) {
    const { isUpdate } = this.props.route.params;
    const { fields: configFields } = AppConfig.screenSettings.register_settings;
    const messages = [];
    if (fields.password && fields.confirmationPassword
      && fields.password !== fields.confirmationPassword && !isUpdate) {
      messages.push('- Por favor, digite as senhas iguais');
    }
    configFields.forEach(f => {
      if (!isUpdate && (f.id === 'email' || f.id === 'password' || f.id === 'confirmationPassword')) {
        f = { ...f, required: true };
      }
      if ((!fields[f.id] || fields[f.id].trim() === '') && f.required) {
        messages.push(`- ${f.description}`);
      } else if (fields[f.id]) {
        if (f.id === 'personDocument' && !isUpdate && !TextUtils.validateCpfCnpj(fields.personDocument)) {
          messages.push('- CPF/CNPJ: Por favor, digite um CPF/CNPJ válido');
        }
        if (f.id === 'email' && !TextUtils.validateEmail(fields.email) && !isUpdate) {
          messages.push('- Insira um email válido');
        }
        if (f.id === 'password' && !isUpdate && fields.password.length < 6) {
          messages.push('- Senha: A senha deve conter pelo menos 6 caracteres');
        }
        if (f.id === 'phone' && !TextUtils.validatePhone(fields.phone) ||
          f.id === 'landline' && !TextUtils.validatePhone(fields.landline, true)
        ) {
          messages.push(`- ${f.description}: Por favor, digite um número válido.`);
        }
        if (f.id === 'name' && fields.name.length < 3) {
          messages.push('- Nome: Nome deve conter pelo menos 3 caracteres');
        }
        if (f.id === 'zipCode' && !isCpfValid(fields.zipCode)) {
          messages.push('- CEP: Digite um CEP válido');
        }
      }
    });
    return messages;
  }

  showErrorInForm(message) {
    toast.error('Complete seu cadastro', `Os seguintes campos são obrigatórios: \n\n${message}`);
  }

  render() {
    const { user } = this.props.sCurrentAccount;
    const isAnonymous = user && user.email.includes('@tempuser.com');

    console.log('USER: ', user);

    return (
      /* <SignUp
        logging={this.state.logging}
        onPressSave={this.onPressSave}
        isUpdate={this.props.route.params.isUpdate}
        form={isAnonymous ? {} : user}
        myRef={this.myRef}
      /> */
       <SignUPWeb
        logging={this.state.logging}
        onPressSave={this.onPressSave}
        isUpdate={this.props.route.params.isUpdate}
        form={isAnonymous ? {} : user}
        // myRef={this.myRef}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    currentNetInfo: getNetInfo(state),
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(SignUpContainer);
