import React, { Component } from 'react';
import { View, Text, ScrollView, Modal, TouchableOpacity, Platform } from 'react-native';
import Spinner from '~/modules-wrapper/react-native-loading-spinner-overlay';
import get from 'lodash/get';
import {
  Box,
  Breadcrumbs,
  InstallmentCard,
  CheckoutOptions,
  DeliveryAddress,
  CheckBox,
} from '~/components';

import styles from './styles';
import appStyles from '~/appStyles';
import SimpleOption from '../../components/SimpleOption';
import Images from '~/../assets/images';
import ProgressView from '~/components/ProgressView';
import { SCREEN_LIST_ADDRESS } from '~/screens/screens';
import CustomAlert from '~/components/CustomAlert';
import AppConfig from '~/AppConfig';

type Props = {
  componentId: any,
  account: any,
  user: any,
  cart: any,
  customer: any,
  showSameAddressCheckBox: boolean,
};

class Checkout extends Component<Props> {
  componentDidMount() {
    this.setState({
      options: this.props.options,
    });
  }

  getAddress = () => {
    const { customer, cart } = this.props;
    const deliveryAddress = get(cart, 'delivery_address', {});
    const fullAddress = deliveryAddress.code ? deliveryAddress : get(customer, 'address', {});

    const {
      address, number, district, city, postal_code,
    } = fullAddress;

    const addressLine1 = (() => {
      const text = [];
      if (address) text.push(address);
      if (number) text.push(number);
      return text.join(', ');
    })();

    const addressLine2 = (() => {
      const text = [];
      if (district) text.push(district);
      if (city) text.push(city);
      const text2 = [text.join(', ')];
      if (postal_code) text2.push(postal_code);
      return text2.join(' - ');
    })();

    return {
      addressLine1,
      addressLine2,
    };
  }

  render() {
    const {
      componentId,
      customer,
      paymentSlip,
      onPressNewAddress,
      useShipping,
      updatingCart,
      updatingAddress,
      showSameAddressCheckBox,
      onHandleInstallmentCardModal,
      showInstallmentCardModal,
      onSelectInstallment,
      showInstallments,
      installments,
      installmentSelected,
      showModalChangeItems,
    } = this.props;

    const address = this.getAddress();
    const phone = get(customer, 'phones.main', 'Sem número');

    return (
      <ScrollView style={appStyles.container}>
        <View style={appStyles.container}>
          <View style={styles.topContainer}>
            <Breadcrumbs routes={['Carrinho', 'Pagamento e Entrega']} />
            <CheckoutOptions title="Como você prefere pagar?" options={this.props.options} />

            {
              showInstallments && (
                <InstallmentCard
                  onHandleInstallmentCardModal={onHandleInstallmentCardModal}
                  showInstallmentCardModal={showInstallmentCardModal}
                  onSelectInstallment={onSelectInstallment}
                  installments={installments}
                  installmentSelected={installmentSelected}
                />
              )
            }
          </View>

          {useShipping && !AppConfig.registerAddress ?
            <DeliveryAddress
              icon={Images.iconAddress}
              addressLine1={address.addressLine1}
              addressLine2={address.addressLine2}
              phoneInfo={phone}
              onPressNewAddress={onPressNewAddress}
            />
          : null}

          {showSameAddressCheckBox ? (
            <View style={styles.switchContainer}>
              <CheckBox value />
              <Text style={styles.textSameAddress}>Mesmo endereço de cobrança</Text>
            </View>
          ) : null}

          <Modal onRequestClose={() => this.props.onRequestClose()} visible={this.props.showPaymentSlipModal} animated transparent>
            <TouchableOpacity onPress={() => this.props.onRequestClose()}>
              <View style={styles.modalContainer}>
                <View>
                  <Box title={paymentSlip.text} onClose={this.props.onRequestClose}>
                    <View style={styles.paymentSlipOptionsContainer}>
                      <ScrollView>
                        <View>
                          {this.props.paymentSlipOptions.map(option => (
                            <SimpleOption
                              key={option.id}
                              text={option.description}
                              onPress={() => {
                                this.props.onSelectPaymentSlip(option);
                              }}
                            />
                          ))}
                        </View>
                      </ScrollView>
                    </View>
                  </Box>
                </View>
              </View>
            </TouchableOpacity>
          </Modal>
        </View>

        {updatingCart && <ProgressView indeterminate text={'Atualizando forma de pagamento...'} title={''} />}

        {updatingAddress && <ProgressView indeterminate text={'Atualizando endereço...'} title={''} />}

        <CustomAlert
          showModal={showModalChangeItems}
          title="Atenção"
          okButtonPress={()=> {
            this.setState({
              showModalChangeItems: false,
            }, ()=> {
              this.props.navigation.popToTop();
            });
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
              Seu carrinho pode ter sofrido alterações. Verifique antes de finalizar
            </Text>
          </View>
        </CustomAlert>
      </ScrollView>
    );
  }
}

export default Checkout;
