// @flow

import React, { Component } from 'react';
import { View, FlatList, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

import styles from './styles';
import Item from './Item';

type BreadcrumbsProps = {
  routes: Array<string>,
  onPressRoute?: (route: string, index: number) => void,
};

class Breadcrumbs extends Component<BreadcrumbsProps> {
  state = {
    paddingRight: width / 2.5,
  };

  componentDidMount(): void {
    setTimeout(() => {
      this.list.scrollToEnd({
        animated: true,
      });
    }, 400);
  }

  onPressRoute = (index: number, size: number) => () => {
    const viewOffset = (width - size) / 2;
    this.props.onPressRoute(this.props.routes[index], index);
    this.setState({ paddingRight: viewOffset }, () => {
      setTimeout(() => {
        this.list.scrollToIndex({
          index,
          animated: true,
          viewOffset,
        });
      }, 300);
    });
  };

  renderItem = ({ item, index }) => (
    <Item
      active={index === this.props.routes.length - 1}
      item={item}
      index={index}
      onPressRoute={this.onPressRoute}
    />
  );

  render() {
    const { routes } = this.props;
    const { paddingRight } = this.state;
    return (
      <View style={[styles.container]}>
        <FlatList
          keyExtractor={(item, index) => index.toString()}
          ref={ref => {
            this.list = ref;
          }}
          snapToAlignment="center"
          automaticallyAdjustInsets
          horizontal
          data={routes}
          extraData={routes}
          renderItem={this.renderItem}
        />
      </View>
    );
  }
}

Breadcrumbs.defaultProps = {
  onPressRoute: () => {},
};

export default Breadcrumbs;
