import Controller from '~/controllers/Controller';
import NoticesRepository from '~/database/repositories/NoticesRepository';

export default class NoticesController extends Controller {
  constructor() {
    super();
    this.repository = new NoticesRepository();
  }

  getNotices() {
    return this.repository.getNotices();
  }

  addNotices(notices) {
    return this.repository.addNotices(notices);
  }
}
