import axios from 'axios';
import AppConfig from '~/AppConfig';

const URL_POLISUITE = 'https://api.polisuite.com.br/suite';
const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlYmRjZjlhNS1iMGJhLTRhZmUtYTc5ZC0zZDZlMTcyOTczMGEiLCJlbWFpbCI6ImZhc3RzZWxsZXJhcHBAcG9saWJyYXNuZXQuY29tLmJyIiwiaWF0IjoxNTE3OTMxOTEzLCJleHAiOjE1MzM2OTk5MTN9.mnX16HKq_WBs_CAyutbjUyA8vWRwUCOcvp5aTA_u2y0';

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Authorization': `Bearer ${TOKEN}`,
    // 'x-Application-code': AppConfig.appCode,
  },
};

export default function requestPassword(email) {
  const params = {
    application_code: AppConfig.appCode,
    email
  };

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${URL_POLISUITE}/password/request/`, params, config)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error.response);
        });
    } catch (error) {
      reject(error);
    }
  });
}
