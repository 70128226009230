import axios from 'axios';
import { URL_BASE, getConfig } from '../index';

export default async function confirmOrder(cart) {
  const config = await getConfig();
  const { id: idOrder, company_id: companyId } = cart;

  return new Promise((resolve, reject) =>
    axios.post(`${URL_BASE}/cart/${companyId}/detail/${idOrder}/confirm_order/`, cart, config)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data)));
}
