import DataManager from '../../database/DataManager';

export function addIconsCategories(categories, categoryCode) {
  const dataManager = new DataManager();
  const confIcons = dataManager.getConfigValue('iconsCategories');

  const categoriesIcons = categories.map(category => {
    if (!category.images && confIcons) {
      let images = categoryCode ? confIcons[categoryCode] : '';
      if (confIcons[category.category_code]) {
        images = confIcons[category.category_code];
      }
      return { ...category, images };
    }

    return category;
  });

  return categoriesIcons;
}
