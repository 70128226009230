import Repository from "./Repository";
import { PAYMENT_OPTION_SCHEMA } from "../schemas";
import SQLiteManager from '../SQLiteManager';

export default class PaymentOptionRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PAYMENT_OPTION_SCHEMA;
  }
  addPaymentOption(pay) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addPaymentOption(pay).then(paymentOP => {
        resolve(paymentOP);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  getAllPaymentOption() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllPaymentOption().then(paymentOP => {
        resolve(paymentOP);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
