import Controller from '~/controllers/Controller';
import ProductRepository from '~/database/repositories/ProductRepository';

export default class ProductController extends Controller {
  constructor() {
    super();
    this.repository = new ProductRepository();
  }

  allProds() {
    return this.repository.allProds();
  }

  allProductsBySearch(value) {
    return this.repository.allProductsBySearch(value);
  }

  getAllProductsByCategoryCode(code) {
    return this.repository.getAllProductsByCategoryCode(code);
  }

  getProductById(id) {
    return this.repository.getProductById(id);
  }

  getProdsByLimit(limit) {
    return this.repository.getProdsByLimit(limit);
  }

  getFavorites() {
    return this.repository.getFavorites();
  }

  setFavorite(productId, liked) {
    return this.repository.setFavorite(productId, liked);
  }

  addProduct(data) {
    return this.repository.addProduct(data);
  }

  updateProduct(prod) {
    return this.repository.updateProduct(prod);
  }
}
