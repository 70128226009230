import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors, defaultShadow } from '~/values';

const size = 40;
const margin = 4;
const iconContainerSize = size - 2 * margin;
const iconPadding = 6;
const iconColor = colors.grayAlmostWhite;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: colors.primaryColor,
    width: '500px',
  },
  rootIconContainer: {
    height: iconContainerSize,
    width: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    overflow: 'hidden',
    margin,
  },
  iconContainer: {
    height: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: iconContainerSize - 2 * iconPadding,
    width: iconContainerSize - 2 * iconPadding,
    tintColor: iconColor,
  },
  textInput: {
    fontWeight: '200',
    color: '#FFF',
    backgroundColor: colors.primaryColorDark,
    borderRadius: 5,
    height: size - margin * 2,
    paddingVertical: 4,
    paddingHorizontal: 10,
    fontSize: 13,
    width: '100%',
  },
});

export default styles;
