export interface IProduct {
  id: string,
  code: string,
  name: string,
  price: number,
  quantity: number,
  multiple: number,
  minPrice?: number,
  stock: number,
  description?: string,
  type: string,
  taxes?: string,
  ean: string,
  liked: boolean,
  images?: Array<{
    uri: string,
  }>,
  attributes?: Array<{
    name: string,
    value: string,
    type: 'color' | 'string' | 'image',
  }>,
}

class Product implements IProduct {
  id = '';
  code = '';
  name = '';
  price = 0;
  quantity = 0;
  multiple = 1;
  minPrice = 0;
  stock = 0;
  description = '';
  taxes = '';
  ean = '';
  code = '';
  type = 'product';
  liked = false;
  images = undefined;
  attributes = undefined;

  constructor(product: IProduct) {
    if (product) {
      this.id = product.id;
      this.code = product.code;
      this.name = product.name;
      this.price = product.price;
      this.quantity = product.quantity;
      this.multiple = product.multiple;
      this.minPrice = product.minPrice;
      this.stock = product.stock;
      this.description = product.description;
      this.type = product.type;
      this.ean = product.ean;
      this.liked = product.liked;
      this.taxes = product.taxes;
      this.images = product.images;
      this.attributes = product.attributes;
    }
  }
}

export default Product;
