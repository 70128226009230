import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import type { IProduct } from '~/entities/Product';
import Product from '~/components/Product/ProductContainer';

import styles from './styles';

type DetailProps = {
  products: Array<IProduct>,
  screenId: string,
};

class Added extends PureComponent<DetailProps> {
  constructor(props) {
    super(props);
    this.state = {
      productSelect: new Map(),
    };
  }

  onProductSelect = (id: string) => {
    this.setState((state) => {
      const productSelect = new Map(state.productSelect);
      productSelect.set(id, !productSelect.get(id));
      return { productSelect };
    });
  };

  renderItem = ({ item }) => (
    <Product
      id={item.id}
      onProductSelect={this.onProductSelect}
      isProductSelect={!!this.state.productSelect.get(item.id)}
      product={item}
      showPopupMenu
      type="linear"
      screenId={this.props.screenId}
    />
  );
  render() {
    const { products } = this.props;
    return (
      <FlatList
        keyExtractor={(item) => item.id}
        data={products}
        extraData={this.state}
        renderItem={this.renderItem}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
      />
    );
  }
}

Added.defaultProps = {
};

export default Added;
