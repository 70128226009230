import { Observable } from 'rxjs';

import { SchemaModels } from '../../database/utils/DBUtils';
import DataManager from '../../database/DataManager';
import CartController from '~/controllers/CartController';

export const activeOrderDataManager = () => {
  const cartController = new CartController();
  const promise = cartController.getCart(null, true);

  return Observable.fromPromise(promise);
};

export const updateOrderDataManager = (order) => {
  const cartController = new CartController();

  const orderParse = { ...order, id: `${order.id}` };
  const promise = cartController.addCart([orderParse], true, true);
  return Observable.fromPromise(promise);
};
