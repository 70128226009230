import { StyleSheet } from 'react-native';
import { colors, defaultShadow } from '~/values';
import AppConfig from '../../AppConfig';

const maxWidth = 140;
const maxHeight = 256;

const baseContainer = {
  borderRadius: 20,
  backgroundColor: '#FFF',
};

const styles = StyleSheet.create({
  shadowContainer__linear: {},
  shadowContainer__grid: {
    ...baseContainer,
    ...defaultShadow,
    maxWidth,
    maxHeight,
    margin: 8,
  },
  overflowContainer__grid: {
    ...baseContainer,
    overflow: 'hidden',
    maxWidth,
    maxHeight,
  },
  overflowContainer__linear: {
    ...baseContainer,
    borderRadius: 0,
    overflow: 'hidden',
  },
  container__grid: {
    ...baseContainer,
    maxWidth,
    maxHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container__linear: {
    ...baseContainer,
    marginRight: 4,
    borderRadius: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image__linear: {
    height: 70,
    marginLeft: 4,
    width: 70,
  },
  image__grid: {
    height: 112,
    width: 90,
  },
  infoContainer__linear: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  infoContainer__grid: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  nameLikeContainer__linear: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  nameLikeContainer__grid: {
    marginBottom: 8,
  },
  name__linear: {
    color: colors.grayDarkest,
    fontSize: AppConfig.productDescriptionFontSize,
    marginRight: 6,
    flex: 1,
  },
  name__grid: {
    color: colors.grayDarkest,
    fontSize: 10,
    fontWeight: '600',
    textAlign: 'center',
  },
  priceContainer__linear: {
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  priceContainer__grid: {
    alignItems: 'center',
    marginBottom: 8,
  },
  startingAt__linear: {
    fontSize: 12,
    color: colors.grayMid,
  },
  startingAt__grid: {
    fontSize: 11,
    color: colors.grayMid,
  },
  contentText: {
    fontSize: 20,
    color: colors.grayDark,
    fontWeight: '300',
  },
  priceQuantityContainer__linear: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  priceQuantityContainer__grid: {
    alignItems: 'center',
  },
  badgeErrorOverflow: {
    width: 28,
    height: 28,
    position: 'absolute',
    right: -8,
    bottom: -8,
    borderRadius: 14,
    overflow: 'hidden',
  },
  badgeError: {
    width: 24,
    height: 24,
    borderColor: '#fff',
    borderWidth: 1,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.warningColor,
  },
  iconError: {
    tintColor: '#FFF',
    width: 16,
    height: 16,
  },
  inputModal: {
    width: 280,
    backgroundColor: 'transparent',
    borderBottomWidth: 1,
    borderBottomColor: colors.primaryColor,
  },
});

export default styles;
