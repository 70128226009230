import AlertBuyingFor from './AlertBuyingFor';
import Banners from './Banners';
import Box from './Box';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import Carousel from './Carousel';
import Categories from './Categories';
import Category from './Category';
import CheckBox from './CheckBox';
import CheckoutOptions from './CheckoutOptions';
import CreditCard from './CreditCard';
// import DateRangePicker from './DateRangePicker';
import DeliveryAddress from './DeliveryAddress';
import Warning from './Warning';
import Icon from './Icon';
import Input from './Input';
import InstallmentCard from './InstallmentCard';
import Product from './Product';
import ProductBonus from './ProductBonus';
import Quantity from './Quantity';
import RangeSlider from './RangeSlider';
import SearchBar from './SearchBar';
import Section from './Section';
import SimpleInfo from './SimpleInfo';
import TabBar from './TabBar';
import WalletOption from './WalletOption';
import { setCustomText } from '~/modules-wrapper/react-native-global-props';
import Location from './Location';
import ModalAddress from './ModalAddress';

const CustomText = {
  style: {
    fontFamily: 'Roboto',
  },
};

setCustomText(CustomText);

export {
  AlertBuyingFor,
  Banners,
  Button,
  Box,
  Breadcrumbs,
  Carousel,
  Categories,
  Category,
  CheckBox,
  CheckoutOptions,
  CreditCard,
  // DateRangePicker,
  DeliveryAddress,
  Warning,
  Input,
  Icon,
  InstallmentCard,
  Product,
  ProductBonus,
  Quantity,
  RangeSlider,
  SearchBar,
  Section,
  SimpleInfo,
  TabBar,
  WalletOption,
  Location,
  ModalAddress,
};
