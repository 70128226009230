import Repository from './Repository';
import { PRODUCTS_CATEGORIES_SCHEMA, CATEGORIES_RELATIONSHIPS_SCHEMA } from '~/database/schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'ProductsCategoriesRepository';

export default class ProductsCategoriesRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PRODUCTS_CATEGORIES_SCHEMA;
    this.tableCatRelSchema = CATEGORIES_RELATIONSHIPS_SCHEMA;
  }

  addProductsCategories(prods) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addProductsCategories(prods).then(prodCat => {
        resolve(prodCat);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
