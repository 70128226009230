import React, { Component } from 'react';

import CompleteAddress from './CompleteAddress';

class CompleteAddressContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CompleteAddress {...this.props} />
    );
  }
}

export default CompleteAddressContainer;
