import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';

const styles = StyleSheet.create({
  containerCalc: {
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'flex-start',
    padding: sizes.horizontalSpacing,
  },
  aditionalTitle: {
    color: '#000',
    fontWeight: 'bold',
    paddingLeft: 6,
  },
  aditionalInput: {
    height: 60,
    paddingLeft: 6,
    borderWidth: 1,
    borderColor: 'gray',
  },
  list: {
    backgroundColor: '#FFF',
  },
  infoText: {
    color: colors.grayDark,
    marginBottom: sizes.verticalSpaceSeparatorNormal,
  },
  textSync: {
    fontSize: 16,
    textAlign: 'center',
  },
  totalLabel: {
    fontWeight: '600',
    color: '#000',
  },
  totalValue: {
    color: '#000',
  },
  separator: {
    height: 1,
    marginHorizontal: sizes.horizontalSpacing,
    backgroundColor: colors.grayContainerDark,
  },
  divider: {
    height: 1,
    backgroundColor: colors.grayContainerDark,
    marginBottom: sizes.verticalSpaceSeparatorNormal,
  },
  sectionPromo: {
    borderTopColor: colors.grayContainerDark,
    borderTopWidth: 3,
    padding: 0,
  },
  promoTopContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: colors.grayContainerDark,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    paddingHorizontal: sizes.horizontalSpacing,
  },
  iconPromoContainer: {
    borderRadius: 2,
    backgroundColor: colors.successColor,
    paddingVertical: 3,
    paddingHorizontal: 1,
    marginRight: sizes.horizontalSpacing,
  },
  iconPromo: {
    tintColor: '#FFF',
    width: 15,
    height: 15,
  },
  textPromo: {
    color: colors.successColor,
  },
});

export default styles;
