import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { DATA_ACCOUNT, COMPANY_ACTIVE, STORE_ITEM_ACTIVE } from '../constants/cache';
import AppConfig from '~/AppConfig';

// export const URL_BASE = 'http://127.0.0.1:8000/app_v2/api';
export const URL_BASE = '//legacy.polisuite.com.br/app_v2/api';
export const URL_BASE_V1 = '//legacy.polisuite.com.br/api';
// export const URL_BASE = 'http://diamantes-services.us-east-1.elasticbeanstalk.com/app/api';

export async function getCurrentCompany() {
  const company = await CacheStore.get(COMPANY_ACTIVE);
  return company ? company.id : AppConfig.appCode;
}

export async function getCurrentCustomer() {
  const customer = await CacheStore.get(STORE_ITEM_ACTIVE);
  return customer;
}

export async function getConfig() {
  const data = await CacheStore.get(DATA_ACCOUNT);

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`,
      'x-Application-code': AppConfig.appCode,
      'Cache-Control': 'no-cache',
    },
  };
}
