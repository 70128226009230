// @flow

import React, { Component } from 'react';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { FlatList, Image, Text, View, TouchableOpacity } from 'react-native';
import styles from './styles';
import { Touchable } from 'react-native-web';
import CategoryIconHelper from '~/utils/CategoryIconHelper';
import { STORE_CHANGE_ICONS_STATE } from '~/store/storesConstants';
import setIconsChange from '../../store/loadIcons/action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';

type ItemCategory = {
    icon?: number,
    title: string,
};

type Props = {
    dispatch: () => void,
    valueIconsChange: any,
    categories: Array<ItemCategory>,
    onPressItem?: (item: ItemCategory) => void,
};

class Categories extends Component<Props> {
  state = {
    configCat: [],
  }

  /*
    * TODO: a função ALL foi removida.
    * Esse código deve ser alteado quando as
    * configurações estiverem vindo do serviço.
    async componentDidMount() {
    const dataManager = new DataManager();
    try {
      const result = await dataManager.all(SchemaModels.APP_CONFIG);
      if (result && result.length) {
        const res = JSON.parse(result[0].data);
        this.setState({ configCat: res.icons_categories });
      }
    } catch (error) {
      console.log(error);
    }
  } */

  componentDidUpdate() {
    (async () => {
      if (this.props.valueIconsChange.updateIcon === true) {
        /*
        * TODO: a função ALL foi removida.
        * Esse código deve ser alteado quando as
        * configurações estiverem vindo do serviço.
        const dataManager = new DataManager();
        try {
          const result = await dataManager.all(SchemaModels.APP_CONFIG);
          if (result && result.length) {
            const res = JSON.parse(result[0].data);
            this.setState({ configCat: res.icons_categories });
          }
        } catch (error) {
          console.log(error);
        } */
        const changeIcons = {
          type: '',
          load: false,
          updateIcon: false,
        };
        this.props.dispatch(setIconsChange(changeIcons));
      }
    })();
  }

    keyExtractor = (item, index) => (item ? `${item.category_code}-${index}` : index);

    renderItem = ({ item }) => (
      <View key={item.description} style={styles.rootContainer}>
        <View style={styles.shadowContainer}>
          <View style={styles.overflowContainer}>
            <TouchableOpacity
              foreground={()=> {}}
              onPress={() => this.props.onPressItem(item)}
            >
              <View style={styles.container}>
                { this.state.configCat && this.state.configCat.map(el => {
                  if (item && item.images) {
                    if (el.data == JSON.parse(item.images).data) {
                      return (<Image
                        key={el.name}
                        style={styles.icon}
                        source={{ uri: `${el.setup.url}` }}
                      />);
                    }
                  }
                }) }
                {this.state.configCat.length === 0 && <Image
                  style={styles.icon}
                  source={CategoryIconHelper.sourceIconFromItem(item)}
                />}
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <Text style={styles.title}>{item.description}</Text>
      </View>
    );

    render() {
      const { categories } = this.props;
      return (
        <FlatList
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          initialNumToRender={7}
          data={categories}
          showsHorizontalScrollIndicator={false}
          horizontal
          extraData={[categories, this.state.configCat]}
        />
      );
    }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getChangeIconState = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_CHANGE_ICONS_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    valueIconsChange: getChangeIconState(state),
  };
}

export default connect(mapStateToProps)(Categories);
