
import axios from 'axios';

const TAG = 'states methods list';

export default async function list() {
  const promise = new Promise(resolve =>
    axios
      .get('https://gist.githubusercontent.com/letanure/3012978/raw/2e43be5f86eef95b915c1c804ccc86dc9790a50a/estados-cidades.json')
      .then((response) => {
        resolve(response.data.estados.map(state => ({
          uf: state.sigla,
          name: state.nome,
          cities: state.cidades.map(city =>
            ({
              name: city,
            })),
        })));
      })
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));

  return await promise;
}
