import React, { Component } from 'react';
import { Alert, Platform } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import Immutable from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import connect from 'react-redux/es/connect/connect';
import { updateOrderDataManager } from '~/store/activeOrderFetch/common';
import {
  ORDER_UPDATE_ORDER_REQUEST,
  ORDER_UPDATE_ORDER_SUCCESS,
} from '~/store/activeOrderFetch/updateOrder/action';
import Cart from './Cart';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_PROCESS_ORDER_FETCH,
  STORE_PRODUCTS_FETCH,
  STORE_SET_CURRENT_NET_INFO,
  STORE_SET_CURRENT_STORE_ITEM,
  STORE_SHIPPING_METHODS_FETCH,
  STORE_LOAD_CART_STATE,
  SELECT_LOCATION_CHANGE_STATE,
} from '../../store/storesConstants';
import {
  mergeProductsOrderWithProducts,
  mergeProductsOrderWithProductsMatriz,
  parseProducts,
  mergeProductsWithOrder,
} from '~/utils/product';
import AccountController from '~/controllers/AccountController';
import listProducts from '../../store/productsFetch/action';
import { toast } from 'react-toastify';
import {
  SCREEN_CART,
  SCREEN_CHECKOUT,
  SCREEN_CONFIRMATION,
  SCREEN_LOGIN,
  SCREEN_WARNINGS,
} from '../screens';
import activeOrder, {
  finallyOrder,
  FINALLY_ORDER_REQUEST,
  ORDER_ACTIVE_SUCCESS,
} from '~/store/activeOrderFetch/activeOrder/action';

import { SHIPPING_METHODS_FETCH_SUCCESS } from '~/store/shippingMethodsFetch/action';
import CartController from '~/controllers/CartController';
import ProductContextController from '~/controllers/ProductContextController';
import SQLiteManager from '../../database/SQLiteManager';
import setNewLogin from '../../store/newLogin/action';
import setSelectLocationChange from '~/store/selectLocation/action';
import setLoadPromo from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setLoadCart from '../../store/setLoadCart/action';
import setLoadAferSale from '../../store/setLoadAfterSale/action';

import requestManager from '~/utils/RequestManager';
import {
  PROCESS_ORDER_FINISH,
  PROCESS_ORDER_START,
  processOrderStart,
} from '../../store/processOrder/action';
import DataManager from '~/database/DataManager';
import AppConfig from '../../AppConfig';
import { SchemaModels } from '../../database/utils/DBUtils';
import get from 'lodash/get';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { COMPANY_ACTIVE, CREDIT_CARD_SELECTED, DATA_ACCOUNT, INSTALLMENT_SELECTED } from '~/constants/cache';
import { SCREEN_STORES } from '~/screens/screens';
import DeviceInfo from 'react-native-device-info';
import PaymentResource from '~/services/resources/PaymentResource';
import Geolocation from '@react-native-community/geolocation';
import confirmOrder from '~/services/resources/OrderResource';
import constantsIsipag from '~/constants/isipag';
import updateShipping from '~/services/delivery-addresses/update';
import moment from 'moment';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import Sync from '~/utils/Sync';
import activeCart from '../../services/carts';
import requestClearCart from '../../services/carts/requestClearCart';
import {Button} from "~/components";

type Props = {
  componentId: string,
  currentNetInfo: any,
  valueLoadCart: boolean,
  sOrderActive: Object<any>,
  sOrder: Object<any>,
  sProductFetch: Object<any>,
  sCustomerActive: Object<any>,
  sProcessOrder: boolean,
  dispatch: () => void,
  valueAddress: any,
};

const TAG = 'CartContainer';

class CartContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      productsContext: [],
      modalShippingChoiceVisible: false,
      modalClearCart: false,
      shippingMethods: [],
      updatingCart: false,
      isAnonymous: true,
      aditionalMessage: '',
      displayAddrSelect: false,
    };

    this.finallyOrder = false;
    this.sync = new Sync();
    this.props.navigation.addListener('focus', async () => {
      this.props.dispatch(listProducts(0));
      const addrSelect = await CacheStore.get('ADDRSELECT');
      if (addrSelect !== null) {
        this.setState({ displayAddrSelect: true });
      }
    });
  }

  async componentDidMount() {
    const dataManager = new DataManager();
    const productContextController = new ProductContextController();
    const isAnonymous = await AccountController.isAnonymous();
    const currentCompanyCache = await CacheStore.get(COMPANY_ACTIVE);

    this.setState({
      isAnonymous,
    });

    const hasRegisters =
      currentCompanyCache &&
      currentCompanyCache.customer_company_accounts &&
      currentCompanyCache.customer_company_accounts.length > 0;

    console.log('this.props.valueLoadCart.load', this.props.valueLoadCart.load);

    if (!this.props.valueLoadCart.load) {
      if (!isAnonymous && !hasRegisters) {
        this.props.navigation.push(SCREEN_STORES.name);
      } else {
        const productsContextFromDb = await productContextController.allByContexts(AppConfig.contexts.filter((context) => context.screen === 'cart')[0].ids);

        const { sOrderActive } = this.props;

        const productsContext = mergeProductsWithOrder(
          productsContextFromDb,
          sOrderActive.activeOrder.payload.products,
        );

        this.setState({ productsContext: parseProducts(productsContext) });

        this.loadShippingMethods();

        this.props.dispatch(listProducts(0));
        this.props.dispatch(activeOrder());

        // const requestsFinish = await requestManager.isJobProcessing();
        // if (requestsFinish) {
        //   this.props.dispatch(processOrderStart());
        //   this.props.dispatch(listProducts(0));
        // } else {
        //   this.props.dispatch(listProducts(0));
        //   this.props.dispatch(activeOrder());
        // }
      }
    } else {
      this.props.dispatch(listProducts(0));
      this.props.dispatch(activeOrder());
    }
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (
        prevProps.sOrderActive.type === ORDER_UPDATE_ORDER_REQUEST &&
        this.props.sOrderActive.type === ORDER_UPDATE_ORDER_SUCCESS
      ) {
        this.setState({
          updatingCart: false,
        });
      }
    })();

    (() => {
      if (
        prevProps.sShippingMethods.type === '' &&
        this.props.sShippingMethods.type === SHIPPING_METHODS_FETCH_SUCCESS
      ) {
        this.loadShippingMethods();
      }
    })();

    (() => {
      if (this.props.sProcessOrder.type === prevProps.sProcessOrder.type) return;
      if (this.props.sProcessOrder.type === PROCESS_ORDER_START) return;
      if (prevProps.sProcessOrder.type !== PROCESS_ORDER_START) return;
      if (this.props.sProcessOrder.type !== PROCESS_ORDER_FINISH) return;
      this.props.dispatch(activeOrder());
    })();

    (() => {
      if (Immutable.is(prevProps.sOrder, this.props.sOrder)) return;
      const { products } = this.props.sOrder.getIn(['payload']).toJS();

      let disabled = true;

      if (products && products.length) {
        disabled = false;
      }
      this.props.navigation.setOptions({
        headerRight: () => (
            <Button
                title="Limpar"
                disabled={disabled}
                onPress={() => {
                  this.setState({ modalClearCart: true });
                  console.log(this.state)
                }}
            />
        ),
      });
    })();
  }

  onPressCancelModalClearCart = () => {
    console.log("AJAUAJAUA", this.props)
    this.setState({ modalClearCart: false });
  }
  onPressSeeWarnings = () => {
    this.props.navigation.navigate(SCREEN_WARNINGS.name, {
      messages: this.props.sOrderActive.activeOrder.payload.messages,
    });
  };

  onPressShippingChoice = () => {
    this.setState({ modalShippingChoiceVisible: !this.state.modalShippingChoiceVisible });
  };

  onPressShipping = async (shippingMethod) => {
    const cart = get(this.props.sOrderActive, 'activeOrder.payload');

    this.setState({
      modalShippingChoiceVisible: false,
    });

    cart.shipping_method = shippingMethod;

    this.setState({
      updatingCart: true,
    });

    try {
      /* await updateShipping({ ...cart });
      await updateOrderDataManager({ ...cart });

      const activeCartObject = get(this.props.sOrderActive, 'activeOrder.payload');
      const response = await activeCart(
        activeCartObject.company_id,
        activeCartObject.customer_id,
      );

      const orders = get(response, ['source', 'data']) || [];
      const dataManager = new DataManager();
      await dataManager.insertInCache(SchemaModels.CART, orders);
      this.props.dispatch(activeOrder()); */

      const response = await updateShipping({ ...cart });
      const order = get(response, ['source', 'data']) || [];
      await updateOrderDataManager({ ...order });
      const cartController = new CartController();
      await cartController.insertCartInCache([order]);
      this.props.dispatch(activeOrder());
    } catch (e) {
    } finally {
      this.setState({
        updatingCart: false,
      });
    }
  };

  onSaveBirthday = (birthday) => {
    const dataAccount = this.props.account;
    dataAccount.user.birthday = moment(birthday, 'DD/MM/YYYY').format('YYYY/MM/DD');

    CacheStore.set(DATA_ACCOUNT, dataAccount);
    this.props.dispatch(setCurrentAccount(dataAccount));
  };

  getProducts() {
    let productsAll = [];
    const { sOrderActive, sProductFetch } = this.props;
    let { products } = sOrderActive.activeOrder.payload;
    let productsList = sProductFetch.payload.products;

    if (products && productsList) {
      productsList = productsList.filter((product) => product.type === 'product');

      if (productsList && productsList.length > 0) {
        products = parseProducts(products);
        productsAll = mergeProductsOrderWithProducts(products, productsList);
      }
    }

    productsList = sProductFetch.payload.products;
    if (products && productsList) {
      productsList = productsList.filter((product) => product.type === 'matriz');

      if (productsList && productsList.length > 0) {
        products = parseProducts(products);
        productsAll = productsAll.concat(mergeProductsOrderWithProductsMatriz(products, productsList));
      }
    }

    return productsAll;
  }

  getBonus() {
    const { sOrderActive } = this.props;
    const { campaigns } = sOrderActive.activeOrder.payload;

    if (campaigns) {
      const campaignsCombine = [];
      campaigns.forEach((com) => {
        if (com.items && com.items.length > 0) {
          com.items.forEach((c) => campaignsCombine.push(c));
        }
      });
      return campaignsCombine;
    }

    return [];
  }

  onChangeMessage = (aditionalMessage) => {
    this.setState({ aditionalMessage });
  };

  loadShippingMethods = () => {
    const shippingMethods = get(this.props.sShippingMethods, 'payload.shippings', []);
    this.setState({
      shippingMethods,
    });
  };

  goToCheckout = async () => {
    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      Alert.alert('', 'Você está offline! Conecte a internet para concluir a compra.');
      return;
    }

    const isAnonymous = await AccountController.isAnonymous();
    if (isAnonymous) {
      Alert.alert(
        'Login necessário',
        'Você precisa estar logado para concluir a compra. \nDeseja entrar agora?',
        [
          {
            text: 'Continuar comprando',
            onPress: null,
          },
          {
            text: 'Entrar',
            onPress: this.enter,
          },
        ],
      );
    } else if (!this.props.sCustomerActive.approved) {
      Alert.alert(
        '',
        'Seu cadastro precisa ser aprovado para concluir a compra! Entre em contato com a empresa.',
      );
    } else {
      this.props.navigation.push(SCREEN_CHECKOUT.name,);
    }
  };

  enter = () => {
    this.props.navigation.push(SCREEN_LOGIN.name);
  };

  async afterSendOrder(cart) {
    const cartController = new CartController();
    try {
      await CacheStore.remove(INSTALLMENT_SELECTED);
      await cartController.clearCartCache();
      await SQLiteManager.clearAllTables();
      const newLogin = {
        type: '',
        auth: true,
      };
      const loadData = {
        type: '',
        load: true,
      };
      this.props.dispatch(setNewLogin(newLogin));
      this.props.dispatch(setLoadPromo(loadData));
      this.props.dispatch(setLoadCategories(loadData));
      this.props.dispatch(setLoadProdContext(loadData));
      this.props.dispatch(setLoadNoticies(loadData));
      this.props.dispatch(setLoadCart(loadData));
      await this.sync.sync();
      this.props.dispatch(setLoadAferSale(loadData));
      this.props.dispatch(activeOrder());

      this.setState({
        aditionalMessage: '',
      });
    } catch (error) {
      console.log(TAG, error);
    } finally {
      const loadData = {
        type: '',
        load: true,
      };
      this.props.dispatch(setLoadAferSale(loadData));
      this.setState({ sendingOrder: false }, () => {
        this.props.navigation.push(SCREEN_CONFIRMATION.name,{ cart });
      });
    }
  }

  clearCardConfirm = () => {
    this.clearCart().then(() =>
        this.onPressCancelModalClearCart()
    )
  }

  async clearCart() {
    try {
      this.setState({ updatingCart: true });
      const cart = get(this.props.sOrderActive, 'activeOrder.payload');
      const responseClearCart = await requestClearCart(cart.id);

      const orders = get(responseClearCart, ['source', 'data']) || [];
      const cartController = new CartController();
      await cartController.insertCartInCache(orders);
      this.props.dispatch(activeOrder());
      this.setState({ updatingCart: false });
    } catch (error) {
      console.log(error)
      this.setState({ updatingCart: false });
    }
  }

  confirmProceedWithCreditCardPayment = async (cart, location) => {
    const card = (await CacheStore.get(CREDIT_CARD_SELECTED)) || null;
    if (!card) {
      setTimeout(() => {
        Alert.alert('Atenção', 'Nenhum cartão de crédito selecionado', [
          {
            text: 'Ok',
            onPress: () => {
              this.goToCheckout();
            },
          },
        ]);
      }, 200);
    } else {
      const profile = this.props.account.user;
      let instalment = 1;
      const maxInstalments = get(cart, 'payment_type.max_instalments', 1);
      if (maxInstalments > 1) {
        const item = (await CacheStore.get(INSTALLMENT_SELECTED)) || {};
        instalment = item.value || 1;
      }

      const payment = {
        card,
        payment_method: card.brand,
        birth_date: profile.birthday,
        device: DeviceInfo.getUniqueId(),
        name: profile.name,
        email: profile.email,
        document: profile.nin,
        instalments: instalment,
        amount: cart.amount,
        latitude: location.latitude,
        longitude: location.longitude,
        observation: cart.observation,
        device_id: DeviceInfo.getUniqueId(),
        platform: Platform.OS,
        shipping_method: cart.shipping_method,
      };

      this.setState({ sendingOrder: true }, () => {
        PaymentResource.isipagPaymentRequest(cart, payment)
          .then((cart) => this.afterSendOrder(cart))
          .catch((err) => {
            this.setState({ loading: false, sendingOrder: false });
            let message = err.message;
            if (!message || message.trim() == '') {
              message = 'Ocorreu um erro ao enviar o pedido';
            }

            setTimeout(() => {
              Alert.alert('Atenção', message);
            }, 200);
          });
      });
    }
  };

  async navigationButtonPressed() {

  }


  proceedWithCreditCardPayment = (cart) => {
    const location = {
      latitude: 0,
      longitude: 0,
    };

    Geolocation.getCurrentPosition(
      (success) => {
        this.confirmProceedWithCreditCardPayment(cart, location);
      },
      (error) => {
        this.confirmProceedWithCreditCardPayment(cart, location);
      },
      {
        enableHighAccuracy: true,
        timeout: 500,
        maximumAge: 1000,
      },
    );
  };

  proceed = async (data) => {
    let cart = null;
    let activeCartObject = {};
    let message = null;

    try {
      this.setState({ sendingOrder: true });
      activeCartObject = get(this.props.sOrderActive, 'activeOrder.payload');

      const payload = {
        ...activeCartObject,
        observation: data.observation,
        device_id: DeviceInfo.getUniqueId(),
        platform: Platform.OS,
      };

      cart = await confirmOrder(payload);
    } catch (err) {
      console.log(TAG, err);
      message = get(err, 'source.data[0].message') || 'Ocorreu um erro ao enviar o pedido';
    } finally {
      if (cart) {
        this.afterSendOrder(cart);
      } else {
        try {
          const response = await activeCart(
            activeCartObject.company_id,
            activeCartObject.customer_id,
          );

          const orders = get(response, ['source', 'data']) || [];
          const cartController = new CartController();
          await cartController.insertCartInCache(orders);
          this.props.dispatch(activeOrder());
        } catch (e) {

        } finally {
          this.setState({ sendingOrder: false }, () => {
            toast.dismiss();

            setTimeout(() => {
              toast.info(message);
            }, 200);
          });
        }
      }
    }
  };

  confirmation = async () => {
    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      toast.error('Você está offline! Conecte a internet para continuar com a compra.');
      return;
    } else if (!this.props.sCustomerActive.approved) {
      toast.error('Seu cadastro precisa ser aprovado para concluir a compra! Entre em contato com a empresa.');
    }

    let cart = get(this.props.sOrderActive, 'activeOrder.payload');
    const objCart = {
      ...cart,
      observation: this.state.aditionalMessage,
      device_id: DeviceInfo.getUniqueId(),
      platform: Platform.OS,
    };
    cart = objCart;
    const logData = `Produtos: ${cart.products.map(p => p.name)}, valor total: R$ ${cart.amount}`;
    if (cart.payment_type.key === constantsIsipag.PAYMENT_TYPES.CREDIT_CARD) {
      this.proceedWithCreditCardPayment(cart);
    } else {
      await this.proceed(cart);
    }
  };

  render() {
    const {
      sOrderActive, sProcessOrder, currentNetInfo, valueLoadCart,
    } = this.props;

    console.log('sProcessOrder', sProcessOrder);

    console.log('CART valueLoadCart', valueLoadCart);

    const saleConditions = get(sOrderActive, 'activeOrder.payload.sale_conditions', []);
    const {
      isAnonymous,
      productsContext,
      modalShippingChoiceVisible,
      modalClearCart,
      shippingMethods,
      aditionalMessage,
      displayAddrSelect,
    } = this.state;

    const activeOrderObjt = { ...sOrderActive.activeOrder.payload, observation: aditionalMessage };

    return (
      <Cart
        {...this.props}
        products={this.getProducts()}
        campaigns={this.getBonus()}
        customerActive={this.props.sCustomerActive}
        saleConditions={saleConditions}
        messages={this.props.sOrderActive.activeOrder.payload.messages || []}
        processCart={sProcessOrder.payload}
        screenId={this.props.componentId}
        order={activeOrderObjt}
        goToCheckout={this.goToCheckout}
        onPressSeeWarnings={this.onPressSeeWarnings}
        productsContext={productsContext}
        shippingMethods={shippingMethods}
        onPressShippingChoice={this.onPressShippingChoice}
        onPressShipping={this.onPressShipping}
        onChangeMessage={this.onChangeMessage}
        modalShippingChoiceVisible={modalShippingChoiceVisible}
        updatingCart={this.state.updatingCart}
        user={this.props.account.user}
        confirmation={this.confirmation}
        onSaveBirthday={this.onSaveBirthday}
        sendingOrder={this.state.sendingOrder}
        enter={this.enter}
        currentNetInfo={currentNetInfo}
        isAnonymous={isAnonymous}
        loadCart={valueLoadCart.load}
        userBlocked={valueLoadCart.userBlocked}
        dataAddr={activeOrderObjt.delivery_address}
        displayAddrSelect={displayAddrSelect}
        clearCart={this.clearCardConfirm}
        modalClearCart={this.state.modalClearCart}
        onPressCancelModalClearCart={this.onPressCancelModalClearCart}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getProductsAndCategories = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_PRODUCTS_FETCH, 'list']).toJS());

const getOrderActive = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getProcessOrder = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_PROCESS_ORDER_FETCH]).toJS());

const getCustomerActive = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM, 'payload']).toJS());

const getOrderActiveImmutable = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder']));

const getNetInfo = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

const getAccount = createImmutableSelector(
  [(state) => state],
  (state) => state.getIn(['setCurrentAccount']).toJS().payload,
);

const getShippingMethods = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_SHIPPING_METHODS_FETCH]).toJS());

const getLoadCartState = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_LOAD_CART_STATE, 'payload']).toJS());

const getAddressState = createImmutableSelector([state => state], state =>
  state.getIn([SELECT_LOCATION_CHANGE_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    account: getAccount(state),
    sProductFetch: getProductsAndCategories(state),
    sOrderActive: getOrderActive(state),
    sProcessOrder: getProcessOrder(state),
    sCustomerActive: getCustomerActive(state),
    sOrder: getOrderActiveImmutable(state),
    currentNetInfo: getNetInfo(state),
    sShippingMethods: getShippingMethods(state),
    valueLoadCart: getLoadCartState(state),
    valueAddress: getAddressState(state),
  };
}

export default connect(mapStateToProps)(CartContainer);
