import Controller from '~/controllers/Controller';
import CompanyRepository from '~/database/repositories/CompanyRepository';

export default class CompanyController extends Controller {
  constructor() {
    super();
    this.repository = new CompanyRepository();
  }

  getCompany(companyId) {
    return this.repository.getCompany(companyId);
  }

  allCompany() {
    return this.repository.allCompany();
  }

  addCompany(company) {
    return this.repository.addCompany(company);
  }

  addCustomer(customer) {
    return this.repository.addCustomer(customer);
  }

  addCustomerCompanyAccounts(customer) {
    return this.repository.addCustomerCompanyAccounts(customer);
  }

  addImages(img) {
    return this.repository.addImages(img);
  }

  addAddress(addr) {
    return this.repository.addAddress(addr);
  }

  addPhones(phone) {
    return this.repository.addPhones(phone);
  }

  getByNin(nin) {
    return this.repository.getByNin(nin);
  }
}
