import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { PROMOTIONS_LIST_REQUEST, success, failure } from './action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import PromotionController from '~/controllers/PromotionController';

const listPromotionsDataManager = () => {
  const promotionController = new PromotionController();

  const promise = promotionController.getPromotions();
  return Observable.fromPromise(promise);
};

const promotionsList = (action$: any) =>
  action$
    .ofType(PROMOTIONS_LIST_REQUEST)
    .mergeMap(() => listPromotionsDataManager()
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(promotionsList);
