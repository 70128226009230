// @flow

import React, { Component } from 'react';
import Reinput from '~/modules-wrapper/reinput';
import { omit, isEqual } from 'underscore';
import Masked from 'vanilla-masker';
import { propTypes } from 'reinput/src/Input/props';
import { Props as TextInputProps } from './types';

import PersonDocument from './PersonDocument';
import CreditCardDate from './CreditCardDate';
import Date from './Date';
import Email from './Email';
import Phone from './Phone';
import Password from './Password';
import ZipCode from './ZipCode';

import { colors } from '~/values';
import TextUtils from '~/utils/TextUtils';

type Props = {
  ...propTypes,
  label?: string,
  value?: string,
  defaultValue?: string,
  onChangeText?: (text?: string) => void,
  autoFocus?: string,
  error?: string,
  format?: string | (() => string),
  inputRef?: () => void,
  keyboardType?: string,
  label?: string,
  maxLength?: number,
  name?: string,
  onBlur?: () => void,
  onChange?: () => void,
  onInputFocus?: () => void,
  onSuccess?: () => void,
  placeholder?: string,
  required?: boolean,
  returnKeyType?: () => void,
  secureTextEntry?: boolean,
  style?: {},
  isMoney?: boolean,
  isPhone?: boolean
};

export type InputProps = Props & TextInputProps;

class Input extends Component<InputProps> {
  static Email = Email;
  static CreditCardDate = CreditCardDate;
  static Date = Date;
  static PersonDocument = PersonDocument;
  static Password = Password;
  static Phone = Phone;
  static ZipCode = ZipCode;

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (typeof this.props.value === 'string') {
      this.setState({ value: this.format(this.props.value) });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    (() => {
      if (isEqual(nextProps.autoFocus, this.props.autoFocus)) return;
      if (nextProps.autoFocus) this.input.focus();
    })();

    (() => {
      if (nextProps.value === this.props.value) return;
      this.setState({ value: nextProps.value });
    })();
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value);
      if (this.props.required && !this.state.value) {
        this.setState({ error: 'Este campo não pode estar vazio' });
      }
    }
  };

  onChange = (entry: string) => {
    this.setState({
      value: this.format(entry),
      error: '',
    }, () => this.props.onChange(entry));
  };

  format(value: string) {
    const moneyFormatter = {
      precision: 2,
      separator: ',',
      delimiter: '.',
      unit: 'R$',
    };
    if (this.props.isMoney) {
      return Masked.toMoney(Masked.toNumber(value), moneyFormatter);
    } else if (this.props.secureTextEntry) {
      return value.replace(',', '').replace(/\./g, '').replace(/\?/g, '').replace(/!/g, '');
    } else if (this.props.format === '') {
      return value;
    } else if (this.props.isPhone) {
      return Masked.toPattern(value, '(99) 99999-9999');
    } else if (this.props.isLandline) {
      return Masked.toPattern(value, '(99) 9999-9999');
    } else if (this.props.acceptCNPJ) {
      const cleaned = TextUtils.cleanCNPJCPF(value);
      const mask = cleaned.length > 11 ? '99.999.999/9999-99' : '999.999.999-99';
      return Masked.toPattern(cleaned, mask);
    }
    return Masked.toPattern(value, this.props.format);
  }

  focus = () => {
    this.input.focus();
  };

  render() {
    return (
      <Reinput
        error={this.props.error || this.state.error}
        activeColor={colors.primaryColor}
        multiline={this.props.multiline}
        allowFontScaling={false}
        autoCapitalize="none"
        autoCorrect={false}
        spellCheck={false}
        onBlur={this.onBlur}
        onChangeText={this.onChange}
        onFocus={this.props.onInputFocus}
        placeholderTextColor={colors.primaryColorLightest}
        value={this.state.value}
        register={ref => {
          this.input = ref;
        }}
        {...omit(
          this.props,
          'onBlur',
          'onChange',
          'onChangeText',
          'onInputFocus',
          'inputRef',
          'value',
          'error',
        )}
      />
    );
  }
}

Input.defaultProps = {
  autoFocus: false,
  error: '',
  format: '',
  inputRef: () => {},
  onBlur: () => {},
  onChange: () => {},
  onInputFocus: () => {},
  onSuccess: () => {},
  value: '',
  multiline: true,
};

export default Input;
