import React, { Component } from 'react';
import { ActivityIndicator, FlatList, View, ScrollView } from 'react-native';

import { Categories } from '~/components';

import styles from './styles';
import colors from '~/values/color';
import Product from '~/components/Product/ProductContainer';
import EmptyView from '~/components/EmptyView';
import Images from '~/../assets/images';
import AlertBuyingFor from '~/components/AlertBuyingFor';
import ProductsAndCategoriesLoad from './ProductsAndCategoriesLoad';

type Props = {
  newLogin: boolean,
  products: [],
  categories: [],
  onPressCategory?: () => void,
  loading?: boolean,
  componentId: string,
};

class ProductsAndCategories extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      productSelect: new Map(),
    };
  }

  componentDidMount() {
    // this.props.navigation.setOptions({
    //   headerSearchBar: {
    //     onChangeText: (event) => {
    //
    //     },
    //   }
    // });
  }

  onProductSelect = (id: string) => {
    this.setState(state => {
      const productSelect = new Map(state.productSelect);
      productSelect.set(id, !productSelect.get(id));
      return { productSelect };
    });
  };

  keyExtractor = (item, index) => `${item.id}-${index}`;

  itemSeparator = () => <View style={styles.separator} />;

  renderCategory = (categories, onPressCategory) => {
    let categoriesView = null;
    if (categories && categories.length > 0) {
      categoriesView = (
        <View>
          <AlertBuyingFor navigation={this.props.navigation} />
          <ScrollView horizontal>
          <View style={styles.categories}>
            <Categories categories={categories} onPressItem={onPressCategory} />
          </View>
          </ScrollView>
        </View>
      );
    }
    return categoriesView;
  };

  renderItem = ({ item, index }) => (
    <Product
      id={`${item.id}-${index}`}
      hideQuantity={item.type === 'matriz'}
      onProductSelect={this.onProductSelect}
      isProductSelect={!!this.state.productSelect.get(`${item.id}-${index}`)}
      product={item}
      type="linear"
      screenId={this.props.componentId}
      navigation={this.props.navigation}
      numberOfDescriptionLines={3}
    />
  );

  emptyView = () => (
    <EmptyView icon={Images.iconList} message="Você ainda não possui produtos e categorias" />
  );

  render() {
    const {
      products, categories, onPressCategory, loading, newLogin,
    } = this.props;

    return (
      <View style={styles.dataContainer}>
        {loading || newLogin ? (
          <View>
            <ProductsAndCategoriesLoad />
          </View>
        ) : (
          <FlatList
            ListHeaderComponent={this.renderCategory(categories, onPressCategory)}
            data={products}
            extraData={this.state}
            keyExtractor={this.keyExtractor}
            refreshing={false}
            initialNumToRender={7}
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.itemSeparator}
            ListEmptyComponent={categories && categories.length === 0 ? this.emptyView : null}
          />
        )}
      </View>
    );
  }
}

export default ProductsAndCategories;
