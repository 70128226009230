import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  name: {
    color: colors.grayDark,
    marginTop: 4,
    marginBottom: 12,
  },
  optionsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 5,
  },
});

export default styles;
