// @flow

import React from 'react';
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native';

import Images from '~/../assets/images';
import styles from './styles';

export type BoxProps = {
  title?: string,
  onClose?: () => void,
  onPress?: () => void,
  children?: React.ReactChildren,
  style?: Object | number,
  boxStyle?: Object | number,
};

const ActionClose = ({ onClose }) => (
  <View style={styles.closeRootContainer}>
    <TouchableOpacity
      onPress={onClose}
    >
      <View style={styles.closeContainer}>
        <Image
          style={styles.iconClose}
          source={Images.iconClose}
        />
      </View>
    </TouchableOpacity>
  </View>
);

const Box = ({
  title, onClose, onPress, children, style, boxStyle,
}: BoxProps) => (
  <View style={[styles.rootContainer, style]}>
    <View style={styles.shadowContainer}>
      <View style={[styles.container, boxStyle]}>
        {
          title
            ? (
              <View style={[styles.topContainer, style]}>
                <View style={styles.titleDecoration} />
                <Text style={styles.title}>{title}</Text>
                {
                  onClose
                    ? (
                      <ActionClose onClose={onClose} />
                    )
                    : null
                }
              </View>
            )
            : null
        }
        <View style={styles.childrenContainer}>
          {children}
        </View>
      </View>
    </View>
  </View>
);

export default Box;
