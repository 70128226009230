import axios from 'axios';

import { URL_BASE } from '../index';
import AppConfig from '../../AppConfig';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AUTH_TOKEN } from '~/constants/cache';

export default async function detail() {
  const token = await AsyncStorage.getItem(AUTH_TOKEN);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${token}`,
      // 'x-Application-code': AppConfig.appCode,
    },
  };

  return new Promise((resolve, reject) =>
    axios.get(`${URL_BASE}/users/me/`, config)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data)));
}
