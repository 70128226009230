import VMasker from 'vanilla-masker';
import deburr from 'lodash/deburr';

export default class TextUtils {
  static slugfy(text) {
    return text.toLowerCase().trim().replace(/\s+/g, ' ');
  }

  static cleanCNPJCPF(entry) {
    const exp = /\.|\-|\//g;
    entry = entry.toString().replace(exp, '');

    return entry;
  }

  static formatCNPJorCPF(value) {
    const exp = /\.|\-|\//g;
    value = (value || '').toString().replace(exp, '');

    let formart = '999.999.999-99';

    if (value.length > 11) {
      formart = '99.999.999/9999-99';
    }

    return VMasker.toPattern(value, formart);
  }

  static validatePhone(phone, isLandline) {
    const exp = /\(|\-|\)/g;
    phone = phone.toString().replace(exp, '').replace(' ', '');
    const regX = /^(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[\.-\s]?)[9]?[1-9]\d{3}[\.-\s]?\d{4}$/;

    if (isLandline) {
      return phone.length <= 10 && regX.test(String(phone));
    }
    return phone.length > 10 && regX.test(String(phone));
  }

  static compareStringWithNumber(a, b) {
    let x = a.match(/(\d+)/g);
    let y = b.match(/(\d+)/g);

    if (x && y) {
      return Number(x[0]) - Number(y[0]);
    } else if (x) {
      return 1;
    } else if (y) {
      return -1;
    }
    x = deburr(a);
    y = deburr(b);
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  }

  static validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  static mtel(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
    v = v.replace(/(\d)(\d{4})$/, '$1-$2');
    return v;
  }

  static isEqualDigits(value) {
    let isEquals = true;
    for (let i = 0; i < value.length - 1; i++) {
      if (value.charAt(i) !== value.charAt(i + 1)) {
        isEquals = false;
        break;
      }
    }
    return isEquals;
  }

  static validateCPF(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    strCPF = strCPF.replace('-', '').replace('.', '');
    if (strCPF == '00000000000') return false;

    for (let i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  static validateCpfCnpj(value) {
    const exp = /\.|\-|\//g;
    value = value.toString().replace(exp, '');

    if (value.length === 11) {
      return this.validateCpf(value);
    } else if (value.length === 14) {
      return this.validateCnpj(value);
    }
    return false;
  }

  static validateCnpj(value) {
    const exp = /\.|\-|\//g;
    value = value.toString().replace(exp, '');

    if (!this.isEqualDigits(value)) {
      let cnpjChecker = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      let numbers = value.substring(0, 12);
      const digits = value.substring(12);
      let sum = 0;
      for (let i = 12; i > 0; i--) {
        sum += numbers.charAt(12 - i) * cnpjChecker[12 - i];
      }
      let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result.toString() !== digits.charAt(0)) {
        return false;
      }
      cnpjChecker = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      numbers = value.substring(0, 13);
      sum = 0;
      for (i = 13; i > 0; i--) {
        sum += numbers.charAt(13 - i) * cnpjChecker[13 - i];
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      return result.toString() === digits.charAt(1);
    }
    return false;
  }

  static validateCpf(value) {
    const exp = /\.|\-|\//g;
    value = value.toString().replace(exp, '');

    if (!this.isEqualDigits(value)) {
      let numbers = value.substring(0, 9);
      const digits = value.substring(9);
      let sum = 0;
      for (let i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }
      let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result.toString() !== digits.charAt(0)) {
        return false;
      }
      numbers = value.substring(0, 10);
      sum = 0;
      for (i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      return result.toString() === digits.charAt(1);
    }
    return false;
  }
}
