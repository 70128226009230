import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  list: {
    backgroundColor: '#FFF',
  },
  separator: {
    height: 3,
    backgroundColor: colors.grayContainer,
  },
});

export default styles;
