// import { Navigation } from 'react-native-navigation';
// import { SCREEN_HOME } from '~/screens/screens';

export default class NavigationHelper {

  static push(fromId, toId, fromSideMenu = false, props = null, title: string = '', options: {}) {
    // Navigation.push(fromId, {
    //   component: {
    //     name: toId,
    //     passProps: props,
    //     options: {
    //       topBar: {
    //         ...(title ? {
    //           title: {
    //             text: title,
    //           },
    //         } : {}),
    //         backButton: {
    //           title: '',
    //           showTitle: false,
    //         },
    //       },
    //       ...options,
    //     },
    //   },
    // });
    //
    // if (fromSideMenu) {
    //   this.hideSideMenu();
    // }
  }

  static showModal(name: string, title: string, props: {}) {
    // Navigation.showModal({
    //   stack: {
    //     children: [{
    //       component: {
    //         name,
    //         passProps: {
    //           ...props,
    //         },
    //         options: {
    //           topBar: {
    //             title: {
    //               text: title,
    //             },
    //           },
    //         },
    //       },
    //     }],
    //   },
    // });
  }

  static pop(componentId) {
    // return Navigation.pop(componentId);
  }

  static popTo(componentIdTarget) {
    // return Navigation.popTo(componentIdTarget);
  }

  static hideSideMenu() {
    // Navigation.mergeOptions(SCREEN_HOME.name, {
    //   sideMenu: {
    //     left: {
    //       visible: false,
    //     },
    //   },
    // });
  }

  static popRootTab(indexTab, screenTab) {
    // return Navigation.events()
    //   .registerBottomTabSelectedListener(({ selectedTabIndex }) => {
    //     if (selectedTabIndex === indexTab) {
    //       Navigation.popToRoot(screenTab);
    //     }
    //   });
  }

  static switchTab(componentId, currentTabIndex) {
    // Navigation.mergeOptions(componentId, {
    //   bottomTabs: { currentTabIndex },
    // });
  }
}
