import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: sizes.verticalSpaceSeparatorNormal,
  },
  title: {
    marginLeft: 10,
    marginTop: 20,
    color: colors.grayDark,
    fontSize: sizes.textBig,
    fontWeight: '600',
  },
});

export default styles;
