import axios from 'axios';

import { URL_BASE, getConfig } from '../index';

const TAG = 'activeCart';

export default async function activeCart(company, customer) {
  const config = await getConfig();

  return new Promise((resolve, reject) =>
    axios.get(`${URL_BASE}/cart/${company}/?customer_id=${customer}`, config)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(TAG, error);
        reject(error);
      }));
}
