import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  container: {

  },
  title: {
    top: 14,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 2,
  },
  description: {
    paddingTop: 16,
  },
  boxContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
  },
  div: {
    paddingLeft: '10%',
    paddingRight: '22%',
    paddingTop: 6,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // borderColor: '#999',
    // borderWidth: 1,
    // borderRadius: 4,
  },
  iconText: {
    marginLeft: 10,
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
    alignItems: 'baseline',
    textTransform: "uppercase"

  },
  cancel: {
    textAlign: 'center',
    color: '#f00',
    fontSize: 20,
    marginBottom: 20,
  },
  appButtonContainer: {
    elevation: 8,
    // backgroundColor: colors.primaryColor,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  }

});


export default styles;
