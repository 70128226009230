/* @flow */

import { Map, List } from 'immutable';

import {
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_LIST_FAILURE,
  PRODUCT_NOTIFY_REQUEST,
  PRODUCT_NOTIFY_SUCCESS,
  PRODUCT_NOTIFY_FAILURE,
  PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST
} from "./action";

const initialState = Map({
  list: Map({
    type: '', categoryCode: 0, loading: false, payload: Map({}), messages: List([]),
  }),
  notifyMe: Map({
    type: '', loading: false, messages: List([]),
  }),
});

export default function reducer(state = initialState, action): any {
  const { type, payload } = action;

  if (type === PRODUCTS_LIST_REQUEST ||
    type === PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST) {
    return state.updateIn(['list', 'type'], () => type).updateIn(['list', 'loading'], () => true)
      .updateIn(['list', 'payload'], () => payload);
  }

  if (type === PRODUCTS_LIST_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['list', 'type'], () => type)
      .updateIn(['list', 'loading'], () => false)
      .updateIn(['list', 'messages'], () => List([]))
      .setIn(['list', 'payload'], Map(payload));
  }

  if (type === PRODUCTS_LIST_FAILURE) {
    const { payload } = action;
    return state.updateIn(['list', 'type'], () => type).updateIn(['list', 'loading'], () => false).setIn(['list', 'messages'], List(payload));
  }


  if (type === PRODUCT_NOTIFY_REQUEST) {
    return state.updateIn(['notifyMe', 'type'], () => type).updateIn(['notifyMe', 'loading'], () => true);
  }

  if (type === PRODUCT_NOTIFY_SUCCESS || type === PRODUCT_NOTIFY_FAILURE) {
    const { payload } = action;

    return state.updateIn(['notifyMe', 'type'], () => type).updateIn(['notifyMe', 'loading'], () => false)
      .setIn(['list', 'payload'], Map(payload));
  }

  return state;
}
