import React, { Component } from 'react';
import {
  ActivityIndicator,
  Platform,
  ProgressBarAndroid,
  ProgressViewIOS,
  ScrollView,
  Text,
  View,
  RefreshControl,
} from 'react-native';
import { Button, DeliveryAddress, SearchBar } from '~/components';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import appStyles from '~/appStyles';
import styles from './styles';
import Banners from '~/components/Banners';
import Categories from '~/components/Categories';
import Carousel from '~/components/Carousel';
import Product from '~/components/Product/ProductContainer';
import type { IProduct } from '../../entities/Product';
import AlertBuyingFor from '~/components/AlertBuyingFor';
import Images from '~/../assets/images';
import EmptyView from '~/components/EmptyView';
import colors from '~/values/color';
import PickerModal from '~/components/PickerModal';
import HomeContentLoader from './HomeContentLoader';
import Notices from '../../components/Notices/Notices';
import HomeBanners from './HomeBanners';
import HomeCategories from './HomeCategories';
import HomeProducts from './HomeProducts';
import HomeNoticies from './HomeNoticies';

type Props = {
  products: Array<IProduct>,
  categories: Array<any>,
  banners: Array<any>,
  notices: Array<any>,
  getDynamicViews: () => void,
  onPressCategory: () => void,
  onPressBanner: () => void,
  onFocus: () => void,
  onPressNotice: () => void,
  name: string,
  loadData: boolean,
  loadPromo: boolean,
  loadCat: boolean,
  loadProdContext: boolean,
  loadNoticies: boolean,
  componentId: string,
  refreshing: boolean,
  onRefresh: () => void,
  onPressCompanyChoice: () => {},
  onPressCompany: () => {},
  modalCompanyChoiceVisible: boolean,
  companyChoiceData: [],
  companyChosen: string,
};

class Home extends Component<Props> {
  onBlurSearchBar() {
    if (this.searchBar) {
      this.searchBar.onBlur();
    }
  }

  onPressCompanyChoice = () => {
    if (this.props.onPressCompanyChoice) this.props.onPressCompanyChoice();
  };

  getProgress(progress) {
    const progressDecimal = progress / 100;

    return (
      <View>
        <Text style={{ marginTop: 16 }}>
          {'Carregando seus dados...'} {`${Math.floor(progress)}%`}
        </Text>
        {Platform.OS === 'ios' ? (
          <ProgressViewIOS progress={parseFloat(progressDecimal.toFixed(2))} />
        ) : (
          <ProgressBarAndroid
            styleAttr="Horizontal"
            indeterminate={false}
            progress={parseFloat(progressDecimal.toFixed(2))}
          />
        )}
      </View>
    );
  }

  separatorView = () => <View style={{ width: 1 }} />;

  renderItem = ({ item }) => (
    <Product
      type="grid"
      product={item}
      screenId={this.props.componentId}
      navigation={this.props.navigation}
    />
  );

  renderNoticesItem = ({ item }) => {
    const expirationDate = moment(item.expires_at).unix();
    const today = moment().unix();
    if (today <= expirationDate) {
      return (<Notices
        onPress={this.props.onPressNotice}
        notices={item}
        screenId={this.props.componentId}
      />
      );
    }
    return (<></>);
  }

  renderBanners = (banners, onPressBanner, loading) => {
    if (loading) {
      return (
        <View>
          <View style={{ marginTop: 10 }}>
            <HomeBanners />
          </View>
        </View>
      );
    }
    return (
      <Banners
        fullWidth
        showFullImage={false}
        banners={banners}
        onPressItem={onPressBanner}
        loading={loading}
      />
    );
  }

  renderCategories = (categories, onPressCategory, loading) => {
    if (loading) {
      return (
        <HomeCategories />
      );
    }
    return (
      <ScrollView horizontal>
      <Categories loading={loading} categories={categories} onPressItem={onPressCategory} />
      </ScrollView>
    );
  }


  renderProducts = (products, loading) => {
    const productGroup = groupBy(products, 'context_id');
    if (loading) {
      return <HomeProducts />;
    }

    return Object.keys(productGroup).map(context => {
      const title = productGroup[context][0].context_title || null;
      const id = productGroup[context][0].context_id || null;
      return (
        <Carousel
          key={id}
          title={title}
          data={productGroup[context]}
          renderItem={this.renderItem}
          ItemSeparatorComponent={this.separatorView}
          keyExtractor={(item, index) => `${item.id}-${index}`}
        />
      );
    });
  };

  renderNotices = (noticies, loading) => {
    if (loading) {
      return (
        <HomeNoticies />
      );
    }
    return (
      <Carousel
        title="Fique por dentro"
        data={noticies}
        renderItem={this.renderNoticesItem}
        ItemSeparatorComponent={this.separatorView}
        keyExtractor={(item, index) => `${item.id}-${index}`}
      />
    );
  }

  render() {

    const {
      name,
      banners,
      categories,
      products,
      notices,
      loadPromo,
      loadProdContext,
      loadCat,
      loadNoticies,
      onPressBanner,
      onPressCategory,
      refreshing,
      onRefresh,
      companyChoiceData,
      modalCompanyChoiceVisible,
      onPressCompany,
      companyChosen,
      errorCart,
      messageError,
    } = this.props;

    const hasBanners = banners && banners.length > 0;
    const hasCategories = categories && categories.length > 0;
    const hasProducts = products && products.length > 0;
    const hasNotices = notices && notices.length > 0;

    const hasNotData = !loadCat && (!categories || categories.length === 0);

    return (
      <ScrollView
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => onRefresh()} />}>
      <View style={appStyles.container}>
        <View style={styles.topContainer}>
          <View style={{ flexDirection: 'column' }}>
            <View style={[styles.greetingContainer]}>
              <Text style={styles.greetingHello}>Olá,</Text>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={[styles.greetingName]}>{`${name}!`}</Text>
              </View>
              {/* {companyChoiceData &&
                companyChoiceData.length > 1 && (
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button
                      style={{
                        paddingVertical: 0,
                        paddingHorizontal: 0,
                      }}
                      containerStyle={{ margin: 3, marginTop: 15, marginBottom: 15 }}
                      titleColor="#FFF"
                      title={companyChosen.text || 'Sem loja'}
                      iconRight={Images.iconExpandArrow}
                      flat
                      transparent
                      noUpperCase
                      big
                      onPress={this.onPressCompanyChoice}
                    />
                    <PickerModal
                      modalTitle="Escolha a loja"
                      options={companyChoiceData}
                      isVisible={modalCompanyChoiceVisible}
                      onPressItem={onPressCompany}
                      onClose={this.onPressCompanyChoice}
                    />
                  </View>
                )} */}
            </View>
          </View>
          {
            !(loadPromo || loadCat || loadProdContext) && !hasNotData ? (
                <View style={styles.searchContainer}>
                  <SearchBar
                    ref={ref => {
                      this.searchBar = ref;
                    }}
                    onFocus={this.props.onFocus}
                  />
                </View>
              )
              :
              null
          }
        </View>

        <AlertBuyingFor navigation={this.props.navigation} />

        {
          !(loadPromo || loadCat || loadProdContext) && errorCart && (
            <EmptyView
              icon={Images.iconAlert}
              message={messageError}
              actionTitle="Atualizar e Tentar novamente"
              action={onRefresh}
            />
          )
        }

        {
          !loadCat && !errorCart && hasNotData ? (
              <EmptyView
                icon={Images.iconSad}
                message="Você não possui dados na sua tela principal"
              />
            )
            :
            null
        }

        {(loadPromo || loadCat || loadProdContext) && <ActivityIndicator size="small" color={colors.primaryColor} />}

        {
          !errorCart && !hasNotData ?
            <View>
              <ScrollView>
                <View>
                  <View style={{ marginTop: 10 }}>
                    {this.renderBanners(banners, onPressBanner, loadPromo)}
                    {this.renderCategories(categories, onPressCategory, loadCat)}
                    {this.renderProducts(products, loadProdContext)}
                    {hasNotices && this.renderNotices(notices, loadNoticies)}
                  </View>
                </View>
              </ScrollView>
            </View>
            :
            null
        }
      </View>
      </ScrollView>
    );
  }
}

export default Home;
