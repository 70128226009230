import { StyleSheet } from 'react-native';
import {colors,sizes} from '~/values';

const size = 80;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primaryColor,
    padding: sizes.horizontalSpacing,
  },
  title: {
    color: '#FFF',
    fontWeight: '600',
    fontSize: sizes.textBig,
    textAlign: 'center',
    marginBottom: sizes.verticalSpaceSeparatorLarge,
  },
  optionContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    height: size,
    width: size,
    backgroundColor: colors.grayContainer,
    borderRadius: size / 2,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: size * 0.7,
    width: size * 0.7,
    resizeMode: 'contain',
  },
  optionText: {
    color: '#FFF',
    textAlign: 'center',
    marginTop: sizes.verticalSpaceSeparatorNormal,
    fontSize: 14,
    marginHorizontal: 8,
  },
  badge: {
    backgroundColor: colors.successColor,
    width: 20,
    height: 20,
    borderRadius: 10,
    position: 'absolute',
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCheck: {
    width: 15,
    height: 15,
    tintColor: '#fff',
  },
});

export default styles;
