import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const size = 40;
const margin = 4;
const iconContainerSize = size - (2 * margin);
const iconPadding = 6;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    paddingHorizontal: sizes.horizontalSpacing,
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  brandImage: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
    marginRight: sizes.horizontalSpacing,
    alignSelf: 'flex-start',
  },
  final: {
    fontWeight: '600',
  },
  finishedWith: {
    color: 'black',
  },
  mainContainer: {
    marginTop: sizes.verticalSpaceSeparatorSmall,
    backgroundColor: colors.primaryColorLightest,
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    alignSelf: 'flex-start',
  },
  mainText: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    color: colors.grayDarkest,
    fontWeight: '600',
    fontSize: sizes.textVerySmall,
  },
  rootIconContainer: {
    height: iconContainerSize,
    width: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    overflow: 'hidden',
    flex: 1,
    alignItems: 'flex-end',
    margin,
  },
  iconContainer: {
    height: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: iconContainerSize - (3 * iconPadding),
    width: iconContainerSize - (3 * iconPadding),
  },
});

export default styles;
