import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AppConfig from '~/AppConfig';
import Icon from '~/modules-wrapper/react-native-vector-icons/MaterialCommunityIcons';
import color from '~/values/color';
import RBSheetListItems from '~/components/RBSheetListItems';

type Props = {
  setRef: () => void,
  onSelectOption: () => void,
}

const styles = StyleSheet.create({
  gridContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridLabel: {
    fontSize: 13,
    paddingTop: 10,
    color: '#333',
  },
});

const icons = {
  phone: {
    icon: 'phone',
    color: color.primaryColorLight,
  },
  whatsapp: {
    icon: 'whatsapp',
    color: color.whatsappGreenLight,
  },
  facebook: {
    icon: 'facebook',
    color: color.facebookBlue,
  },
  instagram: {
    icon: 'instagram',
    color: color.blueIOS,
  },
  email: {
    icon: 'gmail',
    color: color.redBadge,
  },
};

const getData = () => {
  const contacts = AppConfig.contacts
    .filter(({ setup }) => setup[setup.length - 1].value !== '');
  return contacts;
};

const ContactUs = ({ setRef, onSelectOption }: Props) => (
  <RBSheetListItems
    showSearch={false}
    height={180}
    setRef={(ref) => {
      setRef(ref);
    }}
    containerStyle={{ justifyContent: 'space-around', flexDirection: 'row' }}
    renderLabel={({ type }) => (
      <View style={styles.gridContainer}>
        <Icon
          name={icons[type].icon}
          color={`${icons[type].color}`}
          size={30}
        />
        <Text style={styles.gridLabel}>{type.toUpperCase()}</Text>
      </View>
    )}
    onSelectOption={(item) => {
      onSelectOption(item);
    }}
    items={getData()}
    title="Contatos e Redes Sociais"
  />
);
export default ContactUs;
