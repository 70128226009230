/* @flow */

import { Map, List } from 'immutable';

import {
  SALES_CONDITIONS_LIST_REQUEST,
  SALES_CONDITIONS_LIST_FAILURE,
  SALES_CONDITIONS_LIST_SUCCESS,
} from './action';

const initialState = Map({
  type: '', loading: false, waitResponse: false, payload: Map({}), messages: List([]),
});

export default function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SALES_CONDITIONS_LIST_REQUEST) {
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => true);
  }

  if (type === SALES_CONDITIONS_LIST_SUCCESS) {
    const { payload, messages } = action;
    delete payload.messages;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => false)
      .updateIn(['messages'], () => List(messages))
      .setIn(['payload'], Map(payload));
  }

  if (type === SALES_CONDITIONS_LIST_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['loading'], () => false).setIn(['messages'], List(payload));
  }

  return state;
}
