// @flow

import React, { Component } from 'react';
import { View, TextInput, Image } from 'react-native';
import { TouchableOpacity } from 'react-native';

import styles from './styles';
import Images from '~/../assets/images';

type HeaderSearchProps = {
  placeholder?: string,
  onChangeText?: string => void,
  onEnter: () => void,
};

const Icon = ({ icon, onPress }) => (
  <View style={styles.rootIconContainer}>
    <TouchableOpacity onPress={onPress}>
      <View style={styles.iconContainer}>
        <Image style={styles.icon} source={icon} />
      </View>
    </TouchableOpacity>
  </View>
);

class HeaderSearch extends Component<HeaderSearchProps> {
  static defaultProps = {
    onChangeText: () => {},
  };

  state = {
    value: '',
  };

  onChangeText = (value: string) => {
    this.setState({ value });
    this.props.onChangeText(value);
  };

  onEnter = () => {
    this.props.onEnter(this.state.value);
  };

  render() {
    const { placeholder, onPressMicrophone, ...rest } = this.props;
    const { value } = this.state;
    return (
      <View style={styles.container}>
        <TextInput
          style={styles.textInput}
          value={value}
          autoCapitalize="none"
          placeholder={placeholder || 'Busque por produtos, marcas...'}
          placeholderTextColor="#D3D3D3"
          {...rest}
          onChangeText={this.onChangeText}
          onSubmitEditing={this.onEnter}
          rejectResponderTermination
          enablesReturnKeyAutomatically
          returnKeyType="search"
          returnKeyLabel="Buscar"
        />
        <Icon icon={Images.iconSearch} onPress={this.onEnter} />
      </View>
    );
  }
}

export default HeaderSearch;
