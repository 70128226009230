import Controller from "~/controllers/Controller";
import PromotionRepository from "~/database/repositories/PromotionRepository";

export default class PromotionController extends Controller {
  constructor() {
    super();
    this.repository = new PromotionRepository();
  }

  getPromotions() {
    return this.repository.getPromotions();
  }

  addPromotion(promos) {
    return this.repository.addPromotion(promos);
  }
}
