import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  ORDER_ADD_PRODUCT_REQUEST,
  ORDER_ADD_PRODUCT_RESPONSE_FAILURE,
  ORDER_ADD_PRODUCT_RESPONSE_SUCCESS,
  failureAddProductOrder,
  successAddProductOrder,
} from './action';

import SourceBuilder, { CONTEXT, METHODS } from '~/utils/SourceBuilder';
import RequestBuilder from '~/utils/RequestBuilder';
import requestManager from '~/utils/RequestManager';
import { activeOrderDataManager, updateOrderDataManager } from '../common';

import CartController from '~/controllers/CartController';

const updateBonus = (order, product, quantity) => {
  const { campaigns } = order;
  let requirementSelect = {};

  if (campaigns && campaigns.length > 0) {
    const indexCampaign = campaigns.findIndex(campaign => {
      const { requirements } = campaign;
      if (requirements && requirements.length > 0) {
        const requirementFound = requirements.find(req => `${req.id}` === product.id);
        if (requirementFound) {
          requirementSelect = requirementFound;
          return true;
        }

        return false;
      }

      return false;
    });

    if (indexCampaign !== -1) {
      const productFound = order.products.find(prod => prod.id === product.id);
      if (quantity < productFound.quantity) {
        const quantityBonus = parseInt(quantity / requirementSelect.quantity);
        if (quantityBonus === 0) {
          campaigns.splice(indexCampaign, 1);
        } else {
          campaigns[indexCampaign].items.forEach(item => item.quantity = quantityBonus);
        }
      }
    }
  }
};

const addProductOrderDataManager = ({ order, product, quantity }) => {
  const cartController = new CartController();
  const { id } = product;

  if (!order.products) order.products = [];
  const index = order.products.findIndex(prod => prod.id === id);
  if (quantity > 0) {
    const {
      name, code, ean, price,
    } = product;
    const total_price = price * quantity;
    const productOrder = {
      name, product_code: code, id, ean, quantity, unit_price: price, total_price,
    };
    if (index !== -1) {
      updateBonus(order, product, quantity);
      order.products[index] = productOrder;
    } else {
      order.products.push(productOrder);
    }
  } else {
    updateBonus(order, product, quantity);
    order.products.splice(index, 1);
  }

  order.amount = order.products.reduce((total, p) => total + p.total_price, 0);

  const promise = cartController.insertCartInCache([order]);
  return Observable.fromPromise(promise);
};

const addProductOrder = (action$: any) =>
  action$
    .ofType(ORDER_ADD_PRODUCT_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap((payload) => addProductOrderDataManager(payload)
      .flatMap(data => {
        const {
          order, product, quantity, isCombo,
        } = payload;
        const args = {
          product_code: product.id, quantity, idOrder: order.id,
        };

        if (isCombo) {
          args.price = product.price;
        }

        const source = SourceBuilder(METHODS.order.addProduct, args);
        const request = RequestBuilder(source, CONTEXT.addProductInOrder);
        requestManager.push(request);

        return Observable.of(successAddProductOrder(data[0]));
      })
      .catch(data => Observable.of(failureAddProductOrder(data))));

const addProductOrderResponse = (action$: any) =>
  action$
    .ofType(ORDER_ADD_PRODUCT_RESPONSE_SUCCESS)
    .map(({ payload }) => payload)
    .mergeMap(payload => updateOrderDataManager(payload)
      .mergeMap(() => activeOrderDataManager())
      .flatMap(data => {
        if (data[0].success) {
          return Observable.empty();
        }

        return Observable.of(successAddProductOrder(data[0]));
      })
      .catch(data => Observable.of(failureAddProductOrder(data))));

const addProductOrderResponseFailure = (action$: any) =>
  action$
    .ofType(ORDER_ADD_PRODUCT_RESPONSE_FAILURE)
    .map(({ payload }) => payload)
    .mergeMap(() => Observable.empty());

export default combineEpics(
  addProductOrder,
  addProductOrderResponse,
  addProductOrderResponseFailure,
);
