// @flow

import React from 'react';
import { View, Image, Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import Images from '~/../assets/images';

import appStyles from '~/appStyles';
import styles from './styles';
import { colors } from '~/values';

type ProfileAvatarProps = {
  user: {},
  image?: string,
  disabled?: boolean,
  onPress?: () => void,
  type?: 'light' | 'dark',
  tintColor?: string,
};

const ProfileAvatar = ({
  user, image, disabled, onPress, type, tintColor,
}: ProfileAvatarProps) => {

  const getImage = () => (
    <View style={user ? styles.imageContainer : styles.imageNoUserContainer}>
      <Image
        style={image ? styles.imageAvatar : [styles.iconAvatar, user ? { tintColor: colors.grayMid } : null]}
        source={image ? { uri: image } : user ? Images.iconAvatar : Images.iconLogin}
      />
    </View>
  );

  const getInfo = () => (
    <View>
      <Text
        style={[
          styles[`name_${type}`],
          tintColor ? { color: tintColor } : {},
        ]}
      >
        {(user && user.name) || 'Entrar'}
      </Text>
      {
          user && user.email
            ? (
              <Text
                style={[
                  styles[`email_${type}`],
                  tintColor ? { color: tintColor } : {},
                ]}
              >
                {user.email}
              </Text>
            )
            : null
        }
    </View>
  );

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={() => {
        if (typeof onPress === 'function') {
          onPress();
        }
      }}
    >
      <View style={appStyles.spaceBetween}>
        <View style={[appStyles.rowDirection, styles.container]}>
          {getImage()}
          {getInfo()}
        </View>
        {/*{*/}
        {/*  disabled*/}
        {/*    ? null*/}
        {/*    : (*/}
        {/*      <Image*/}
        {/*        style={[*/}
        {/*          styles[`iconMenuDown_${type}`],*/}
        {/*          tintColor ? { tintColor: tintColor } : {},*/}
        {/*        ]}*/}
        {/*        source={Images.iconMenuDown}*/}
        {/*      />*/}
        {/*    )*/}
        {/*}*/}
      </View>
    </TouchableOpacity>
  );
};

ProfileAvatar.defaultProps = {
  type: 'dark',
};

export default ProfileAvatar;
