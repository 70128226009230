import React, { Component } from 'react';
import { Image, View } from 'react-native';
import { Button } from '~/components';
import InputWebMask from '~/components/Input/unform/InputWebMask';

import Images from '~/../assets/images';
import styles from './styles';

import sizes from '~/values/sizes';
import colors from '~/values/color';
import AppConfig from '~/AppConfig';
import TextUtils from '~/utils/TextUtils';
import { Form } from '@unform/web';

type Props = {
  onPressEnter: ({
    email: string,
    password: string
  }) => void,
  requesting: boolean,
};

class ChooseStore extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      nin: '',
      error: '',
    };
    this.formChooseStore = React.createRef();
  }

  valid() {
    const isValid = TextUtils.validateCpfCnpj(this.state.nin);

    if (isValid) {
      this.setState({
        error: null,
      });
    } else {
      this.setState({
        error: 'Informe um número válido',
      });
    }
  }

  onChangeText(attribute){
    console.log('onChangeText');
    // this.setState({ [attribute]: value });
  };

  onEndEditing = () => () => {
    this.valid();
  };

  onPressEnter = () => {
    const isValid = TextUtils.validateCpfCnpj(this.state.nin);
    this.valid();

    if (isValid) {
      if (this.props.onPressEnter) this.props.onPressEnter({ nin: this.state.nin });
    }
  };

  render() {
    const { requesting } = this.props;

    return (
      <View style={styles.container}>
        <Image style={styles.logo} source={AppConfig.logoApp}/>
        <View style={styles.loginBoxInputs}>
          <Form ref={this.formChooseStore}>
          <InputWebMask 
            ref={input => this._nin = input}
            name="nin" 
            required="required"
            label="CPF/CNPJ" 
            placeholder="CPF/CNPJ" 
            mask={"999.999.999-999" || "99.999.999/0001-99"}
            maskChar=""
          /> 
          </Form>
        </View>
        <Button
          title={requesting ? 'Solicitando...' : 'Solicitar acesso'}
          big
          containerStyle={{ margin: sizes.horizontalSpacing }}
          style={[
            styles.loginButton,
            requesting ? { backgroundColor: colors.grayDark } : styles.loginButton,
          ]}
          onPress={this.onPressEnter}
        />
      </View>

    );
  }
}

export default ChooseStore;
