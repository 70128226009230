import React, { Component } from 'react';
import { Dimensions } from 'react-native';
import { TabView } from 'react-native-tab-view';

import { TabBar } from '~/components';
import { Added } from './screens';
import ProductDetails from '~/screens/Product/screens/ProductDetails';
import DataManager from '~/database/DataManager';

type Props = {
  onProductClick: () => {},
  onAttributeClicked: (attribute: string, value: string) => void,
  product: {},
  selectedAttributes: {},
  selectedProduct: {},
  added: boolean,
  loadingCondPrice: boolean,
  loadingImage: boolean,
  screenId: string,
};
class ProductPresentational extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      routes: [{ key: 'detail', title: 'Detalhe' }, { key: 'added', title: 'Adicionados' }],
    };
  }

  onPressLike = product => {
    const dataManager = new DataManager();
    dataManager.setProductFavorite(product.id, product.liked);
  };

  renderScene = ({ route, jumpTo }) => {
    switch (route.key) {
      case 'detail':
        const banners = this.props.selectedProduct ? this.props.selectedProduct.images : [];

        return (
          <ProductDetails
            {...this.props}
            loadingCondPrice={this.props.loadingCondPrice}
            loadingImage={this.props.loadingImage}
            banners={banners}
            onAttributeClicked={this.props.onAttributeClicked}
            selectedProduct={this.props.selectedProduct}
            selectedAttributes={this.props.selectedAttributes}
            product={this.props.product}
            isMatriz
            jumpTo={jumpTo}
            onPressLike={this.onPressLike}
            onProductClick={this.props.onProductClick}
            screenId={this.props.screenId}
          />
        );
      case 'added':
        return (<Added
          {...this.props}
          products={this.props.added}
          jumpTo={jumpTo}
          screenId={this.props.screenId}
        />);
      default:
        return null;
    }
  };

  render() {
    const {
      product, onProductClick, attributes, loadingCondPrice, loadingImage,
    } = this.props;

    const isMatriz = attributes && attributes.length > 0;
    return isMatriz ? (
      <TabView
        navigationState={this.state}
        renderScene={({ route, jumpTo }) => this.renderScene({ route, jumpTo })}
        removeClippedSubviews
        renderTabBar={props => <TabBar {...props} />}
        onIndexChange={index => this.setState({ index })}
        initialLayout={{ width: Dimensions.get('window').width }}
      />
    ) : (
      <ProductDetails
        {...this.props}
        loadingCondPrice={loadingCondPrice}
        loadingImage={loadingImage}
        banners={product.images}
        product={product}
        isMatriz={isMatriz}
        onPressLike={this.onPressLike}
        onProductClick={onProductClick}
      />
    );
  }
}

export default ProductPresentational;
