import axios from 'axios';
import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const shippingCalculate = async (cep, kind, cart) => {
  const COMPANY = await getCurrentCompany();
  const config = await getConfig();
  const result = await axios.get(`${URL_BASE}/cart/${COMPANY}/detail/${cart}/shipping_methods/calculate/?kind=${kind}&destination_cep=${cep}`, config);
  return result;
};

export default shippingCalculate;
