import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  outsideContainer: {
    marginBottom: 8,
    borderRadius: 24,
    marginHorizontal: 2,
    height: 28,
    minWidth: 28,
    overflow: 'hidden',
  },
  container: {
    borderRadius: 24,
    height: 28,
    minWidth: 28,
    justifyContent: 'center',
    borderColor: colors.grayContainerDark,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  attribute: {
    fontSize: 13,
    marginRight: 24,
    marginLeft: 10,
    color: colors.grayDark,
  },
  image: {
    width: 38,
    height: 38,
    borderColor: '#ddd',
    borderWidth: 0.5,
  },
  color: {
    width: 34,
    height: 34,
  },
  containerColor: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    height: 28,
    width: 28,
    overflow: 'hidden',
  },
  containerColor__withAttribute: {
    marginRight: 4,
    height: 20,
    width: 20,
    borderRadius: 15,
  },
  text: {
    fontSize: 16,
    marginHorizontal: 6,
    fontWeight: '300',
    color: '#000',
  },
  badge: {
    backgroundColor: colors.successColor,
    width: 12,
    height: 12,
    borderRadius: 7,
    position: 'absolute',
    right: 0,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCheck: {
    width: 10,
    height: 10,
    tintColor: '#fff',
  },
});

export default styles;
