import axios from 'axios';

import { getConfig, URL_BASE } from '../index';

export default async function request(nin, company) {
  const config = await getConfig();

  return new Promise((resolve, reject) => {
    try {
      let payload = {
        nin,
      };

      if (company) {
        payload.company_id = company.id;
      }

      axios
        .post(`${URL_BASE}/companies/request/`, payload, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    } catch (error) {
      reject(error);
    }
  });
}
