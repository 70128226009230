// @flow

import React from 'react';
import { View, Text } from 'react-native';

import styles from './styles';

type SideMenuSectionProps = {
  title?: string
};

const SideMenuSection = ({ title, children }: SideMenuSectionProps) => (
  <View style={styles.container}>
    {
        title
          ? <Text style={styles.title}>{title}</Text>
          : null
      }
    {children}
  </View>
);

export default SideMenuSection;
