import React, { Component } from 'react';
import { Image, View, Text, Platform, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Box } from '~/components';
import InputEmail from '~/components/Input/Email';
import InputPassword from '~/components/Input/Password';
import { Button } from '~/components';
import _ from 'lodash';
import { Form } from "@unform/web";
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import styles from './styles';
import sizes from '~/values/sizes';
import colors from '~/values/color';
// import ProgressView from '~/components/ProgressView';
// import CustomAlert from "~/components/CustomAlert";
import AppConfig from "~/AppConfig";
import CustomAlert from '~/components/CustomAlert';
import { toast } from 'react-toastify';
import TextUtils from '~/utils/TextUtils';
import { requestPassword } from '~/services/account';

type Props = {
  onPressSignIn: ({ email: string, password: string }) => void,
  onSignUpPress: () => void,
  onUsernameError: () => void,
  onUsernameChange: () => void,
  onPasswordChange: () => void,
  logging: boolean,
  email: string,
  password: string,
  progressVisible: boolean,
  textProgress: string,
  alertTitle: string,
};

class Login extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      showModalPasswordForget: false,
      email_password: '',
    };

    // this.onPressSignInDelay = _.throttle(props.onPressSignIn, 5000, {
    //   leading: true,
    //   trailing: false,
    // });
  }

  sendEmailToRecoverPassword = async () => {
    let email = this.state.email_password || '';

    if (TextUtils.validateEmail(email)) {
      this.setState({ loadingRecoverEmail: true });
      this.setState({ showModal: false });

      try {
        const response = await requestPassword(email);

        this.setState({
          showModalPasswordForget: false,
          loadingRecoverEmail: false,
          email_password: ''
        }, ()=> {
          setTimeout(()=> {
            toast.success('Acesse o link enviado para o email informado para alterar sua senha.');
          }, 500);
        });
      } catch(e) {
        if (e.status === 404) {
          this.setState({
            loadingRecoverEmail: false,
          }, ()=> {
            toast.warn('O email digitado não foi encontrado!');
          });
        } else {
          this.setState({ loadingRecoverEmail: false });
          toast.error( 'Ocorreu um erro. \n Por favor, tente novamente.');
        }
      }
    } else {
      toast.error( 'Por favor, digite um email válido');
    }
  };

  handleSubmit() {
    console.log('handleSubmit');
  }

  render() {
    const {
      email, password, logging, progressVisible, textProgress, alertTitle,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
          <View style={styles.container}>
            <Image style={styles.logo} source={AppConfig.logoApp} />
            {progressVisible && <ProgressView indeterminate text={textProgress} title={alertTitle} />}
            <Box style={{ marginTop: 40 }} title="LOGIN">
              <View style={styles.loginBoxInputs}>
                <InputEmail
                    label="Login"
                    value={email}
                    multiline={false}
                    onError={this.props.onUsernameError}
                    onChange={this.props.onUsernameChange}
                    returnKeyType="next"
                />
                <InputPassword
                    label="Senha"
                    value={password}
                    onChange={this.props.onPasswordChange}
                    />
                <Button
                    title={logging ? 'Autenticando...' : 'Entrar'}
                    big
                    containerStyle={{
                      marginTop: sizes.verticalSpaceSeparatorLarge,
                      alignItems: 'center',
                    }}
                    style={[
                      styles.loginButton,
                      logging ? { backgroundColor: colors.grayDark } : styles.loginButton,
                    ]}
                    onPress={this.props.onPressSignIn}
                />
              </View>

              <CustomAlert
                  showModal={this.state.showModalPasswordForget}
                  title="Recuperar senha"
                  modalLoading={this.state.loadingRecoverEmail}
                  disableButtons={this.state.loadingRecoverEmail}
                  cancelButtonTitle="Cancelar"
                  cancelButtonPress={() => this.setState({showModalPasswordForget: false})}
                  okButtonPress={() => this.sendEmailToRecoverPassword()}
              >
                <View style={{alignItems: 'center'}}>
                  <Text style={{marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center'}}>
                    Digite seu email. Iremos enviar um link para você alterar sua senha.
                  </Text>
                  <InputEmail
                    label="Email"
                    value={this.state.email_password}
                    multiline={false}
                    onChange={email => this.setState({email_password: email})}
                  />
                </View>
              </CustomAlert>

            </Box>
            <Button
                title="Novo cadastro"
                flat
                containerStyle={{ marginTop: sizes.verticalSpaceSeparatorLarge }}
                onPress={this.props.onSignUpPress}
            />
            <Button
                title="Esqueceu a senha?"
                flat
                onPress={() => this.setState({showModalPasswordForget: true})}
            />
          </View>
      </Form>
    );
  }
}

export default Login;
