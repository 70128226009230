export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAILURE = 'LOCATION_FAILURE';

export function success(payload) {
  return dispatch => dispatch({ type: LOCATION_SUCCESS, payload });
}

export function failure(payload) {
  return dispatch => dispatch({ type: LOCATION_FAILURE, payload });
}

export default function list() {
  return dispatch => dispatch({ type: LOCATION_REQUEST });
}
