// eslint-disable-next-line no-use-before-define
import React from 'react';
import { TouchableOpacity, Image, Text } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import {
    SCREEN_ADDRESS,
    SCREEN_COMPLETE_ADDRESS,
    SCREEN_CREDIT_CARD_LIST,
    SCREEN_FAVORITES,
    SCREEN_LIST_ADDRESS,
    SCREEN_MAP,
    SCREEN_ORDER_DETAILS,
    SCREEN_ORDERS,
    SCREEN_PROFILE,
    SCREEN_SIDEMENU,
    SCREEN_STORES,
    SCREEN_SIGNUP, SCREEN_ADD_CREDIT_CARD
} from '~/screens/screens';

import MapContainer from '~/screens/Map/MapContainer';
import Stores from '~/screens/Stores';
import Profile from '~/screens/Profile';
import SideMenu from '~/screens/SideMenu';
import CreditCardList from '~/screens/CreditCardList';
import Favorites from '~/screens/Favorites';
import Orders from '~/screens/Orders';
import AddCreditCard from '~/screens/AddCreditCard';
import ListAddress from '~/screens/Address/ListAddress/ListAddressContainer';
import CompleteAddressContainer from '~/screens/Address/CompleteAddress/CompleteAddressContainer';
import AddressContainer from '~/screens/Address/AddressContainer';
import { colors } from '~/values';
import OrderDetail from '~/screens/OrderDetail';
import AppConfig from '~/AppConfig';
import Images from '~/../assets/images';
import SignUpContainer from '~/screens/SignUp/SignUpContainer';

const SideMenuStack = createStackNavigator();

const SideMenuRoutes = () => (
  <SideMenuStack.Navigator screenOptions={{
    title: 'Conta',
    headerStyle: {
      backgroundColor: colors.primaryColor,
      textAlign: 'center',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',

  },
  }}>
    <SideMenuStack.Screen
      name={SCREEN_SIDEMENU.name}
      component={SideMenu}
    />
    <SideMenuStack.Screen
      name={SCREEN_PROFILE.name} component={Profile}
      options={({ navigation }) => ({
        title: 'Perfil',
        headerRight: () => {
          return(
            AppConfig.canEditRegister ?
              <TouchableOpacity onPress={() => navigation.navigate(SCREEN_SIGNUP.name, { isUpdate: true, title: 'Editar perfil' })}>
                <Image
                  source={Images.iconEdit}
                  style={{ tintColor: '#f0f0f0', height: 20, width: 20, right: 10 }}
                />

              </TouchableOpacity> : null
          )
        }
      })}
    />
    <SideMenuStack.Screen
      name={SCREEN_STORES.name} component={Stores}
      options={{
        title: 'Lojas'
      }}
    />
    <SideMenuStack.Screen
      name={SCREEN_CREDIT_CARD_LIST.name} component={CreditCardList}
    />
      <SideMenuStack.Screen
      name={SCREEN_ADD_CREDIT_CARD.name} component={AddCreditCard}
    />
    <SideMenuStack.Screen
      name={SCREEN_FAVORITES.name} component={Favorites}
      options={{
        title: 'Favoritos'
      }}
    />

    <SideMenuStack.Screen
      name={SCREEN_ORDERS.name} component={Orders}
      options={{
        title: SCREEN_ORDERS.title
      }}
    />

    <SideMenuStack.Screen
      name={SCREEN_ORDER_DETAILS.name} component={OrderDetail}
      options={{
        title: SCREEN_ORDER_DETAILS.title
      }}
    />

    <SideMenuStack.Screen
      name={SCREEN_SIGNUP.name} component={SignUpContainer}
      options={({route}) => ({title: route.params.title || SCREEN_SIGNUP.title})}
    />

    <SideMenuStack.Screen
      name={SCREEN_LIST_ADDRESS.name}
      component={ListAddress}/>

    <SideMenuStack.Screen
      name={SCREEN_MAP.name}
      options={{ title: SCREEN_MAP.title }}
      component={MapContainer}/>

    <SideMenuStack.Screen
      name={SCREEN_COMPLETE_ADDRESS.name}
      options={{ title: SCREEN_COMPLETE_ADDRESS.title }}
      component={CompleteAddressContainer}/>

    <SideMenuStack.Screen
      name={SCREEN_ADDRESS.name}
      options={{ title: SCREEN_ADDRESS.title }}
      component={AddressContainer}/>

    {/*<SideMenuStack.Screen name={SCREEN_ADD_CREDIT_CARD} component={AddCreditCard}/>*/}
  </SideMenuStack.Navigator>
);

export default SideMenuRoutes;
