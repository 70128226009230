import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import configureStore from '~/store';
import AppRoutes from '~/routes/index';
import AppProvider from '~/hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const store = configureStore();

export default function App() {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <AppProvider>
          <AppRoutes/>
          <ToastContainer
            draggable={false}
            hideProgressBar={true}
            position="bottom-center"
            theme={'dark'}
          />
        </AppProvider>
      </NavigationContainer>
    </Provider>
  );
}
