import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';
import type { ISource } from '../../entities/Source';

export default async function addBonusInOrder(source: ISource) {
  const config = await getConfig();
  const company = await getCurrentCompany();
  console.log('export default async function addBonusInOrder source', source);

  const { params } = source;
  const { idOrder } = params;
  delete params.idOrder;

  return new Promise((resolve, reject) =>
    axios.post(`${URL_BASE}/cart/${company}/detail/${idOrder}/campaigns/`, params, config)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data)));
}
