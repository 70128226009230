// @flow

import React, { PureComponent } from 'react';
import { View, Text, FlatList } from 'react-native';
import moment from 'moment';

import styles from './styles';

type StatusHistoryProps = {
  status: Array<{
    title: string,
    date: Date,
  }>
};

class StatusHistory extends PureComponent<StatusHistoryProps> {
  keyExtractor = (item, index) => `${item.code}-${index}`;

  renderItem = ({ item: { status_date, description }, index }) => {
    let time;
    if (status_date) {
      time = moment(status_date).format('DD/MM/YYYY HH:mm');
    }

    const isFirst = index === 0;
    return (
      <View>
        <View style={styles.container}>
          <View
            style={[
              styles.statusBadge,
              isFirst
                ? styles.statusBadge__success
                : {},
            ]}
          />
          <Text style={styles.title}>{description}</Text>
          {
            time
              ? <Text style={styles.date}>{time}</Text>
              : null
          }
        </View>
        {
          !(index === this.props.status.length - 1)
            ? <View style={styles.divider} />
            : null
        }
      </View>
    );
  };

  render() {
    return (
      <View>
        <FlatList
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
          data={this.props.status}
        />
      </View>
    );
  }
}

export default StatusHistory;
