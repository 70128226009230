import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const dateSize = 60;
const colorDate = colors.grayDark;
const defaultFont = {
  color: colorDate,
  fontSize: 12,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: sizes.horizontalSpace,
    alignItems: 'center',
  },
  dateContainer: {
    backgroundColor: colors.primaryColorTransparent,
    width: dateSize,
    height: dateSize,
    borderRadius: dateSize / 3,
    marginRight: sizes.horizontalSpace,
    alignItems: 'center',
    justifyContent: 'center',
  },
  day: {
    color: colorDate,
    fontSize: sizes.textLittleBig,
    fontWeight: '600',
  },
  text: {
    ...defaultFont,
  },
  client: {
    color: '#000',
    fontWeight: '600',
    fontSize: sizes.textLittleBig,
  },
  total: {
    ...defaultFont,
  },
  situation: {
    ...defaultFont,
    fontWeight: '600',
  },
  separator: {
    height: 1,
    marginHorizontal: sizes.horizontalSpacing,
    backgroundColor: colors.grayContainerDark,
  },
});

export default styles;
