import React from 'react';
import { View, Text } from 'react-native';
import { Placeholder, Line, Media } from 'rn-placeholder';

import colors from '../../values/color';
import styles from './styles';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeBannersItemStyle = {
  ...backgroundGray,
  height: 200,
  width: '100%',
  padding: 40,
  borderRadius: 20,
};

const homeCategoriesStyle = {
  ...backgroundGray, height: 80, width: 80, borderRadius: 80, marginRight: 5,
};

const homeProductMediaStyle = {
  ...backgroundGray,
  borderRadius: 10,
  height: 236,
  width: 130,
  margin: 4,
};

export default function HomeContentLoader() {
  return (
    <Placeholder animation="fade">
      <View>
        <View style={{ marginTop: 10, paddingLeft: 10 }}>
          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Media style={homeProductMediaStyle} />
            <Media style={homeProductMediaStyle} />
            <Media style={homeProductMediaStyle} />
            <Media style={homeProductMediaStyle} />
            <Media style={homeProductMediaStyle} />
          </View>
        </View>
      </View>
    </Placeholder>
  );
}
