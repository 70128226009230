import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  separator: {
    flex: 1,
    height: 2,
    backgroundColor: colors.grayContainer,
  },
});

export default styles;
