import LoginProcess from '../utils/Login';
import { updateOrder } from '../store/activeOrderFetch/updateOrder/action';
import setCurrentStoreItem, { syncCurrentStoreItem } from '../store/setCurrentStoreItem/action';

export default class CartHelper {
  constructor() {
    this.login = new LoginProcess();
  }
  async associateAnonymousToUser(customerCompany, dispatch, screen) {
    let order = {};
    try {
      order = await this.login.associateAnonymousToUser(customerCompany);
    } catch (error) {
      throw error;
    }
    if (order) {
      dispatch(setCurrentStoreItem(customerCompany));
      if (customerCompany.approved) {
        dispatch(updateOrder(order, screen.name));
      }

      dispatch(syncCurrentStoreItem(customerCompany));

      return true
    }
    return false
  }
}

