import Controller from '~/controllers/Controller';
import ShippingMethodsRepository from '~/database/repositories/ShippingMethodsRepository';

export default class ShippingMethodsController extends Controller {
  constructor() {
    super();
    this.repository = new ShippingMethodsRepository();
  }
  getShippingMethods() {
    return this.repository.getShippingMethods();
  }
  addShippingMethods(shipp) {
    return this.repository.addShippingMethods(shipp);
  }
}
