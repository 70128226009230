import Controller from '~/controllers/Controller';
import CartRepository from '~/database/repositories/CartRepository';

export default class CartController extends Controller {
  constructor() {
    super();
    this.repository = new CartRepository();
  }
  getCart(sorted, cache) {
    return this.repository.getCart(sorted, cache);
  }

  getCartInCache() {
    return this.repository.getCartInCache();
  }

  addCart(cart, update, cache) {
    return this.repository.addCart(cart, update, cache);
  }

  insertCartInCache(cart) {
    return this.repository.insertCartInCache(cart);
  }

  clearCartCache() {
    return this.repository.clearCartCache();
  }

  updateCart(cart, cache) {
    return this.repository.updateCart(cart, cache);
  }
}
