import React, { Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import History from './History';
import { COMPONENT_HEADER_SEARCH, SCREEN_SEARCH_RESULT } from '../screens';

function compare(a, b) {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateB - dateA;
}

import HeaderSearch from '~/components/HeaderSearch';

class HistoryContainer extends Component {
  state = {
    searchHistory: [],
    search: ''
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AsyncStorage.getItem('search_history').then(history => {
      if (history) {
        this.setState({ searchHistory: JSON.parse(history).sort(compare) });
      }
    });

    this.props.navigation.setOptions({
      headerTitle: () => {
        return <HeaderSearch onEnter={this.search.bind(this)} />;
      }
    });
  }

  setUpdateHistory(searchText) {
    const { searchHistory } = this.state;
    const searchObjIndex = searchHistory.map(o => o.text).indexOf(searchText);
    if (searchObjIndex !== -1) {
      searchHistory[searchObjIndex].date = new Date().toISOString();
    } else {
      searchHistory.push({
        text: searchText,
        date: new Date().toISOString(),
      });
    }
    AsyncStorage.setItem('search_history', JSON.stringify(searchHistory));
  }

  search = (searchText: string) => {
    let textTrim = searchText.trim();
    this.setUpdateHistory(textTrim);
    this.props.navigation.push(SCREEN_SEARCH_RESULT.name, {
      searchText: textTrim,
      navigation: this.props.navigation,
    });
  };

  onRemove = index => {
    if (index > -1) {
      const searchHistory = this.state.searchHistory;
      searchHistory.splice(index, 1);
      this.setState({ searchHistory });
      AsyncStorage.setItem('search_history', JSON.stringify(searchHistory));
    }
  };

  render() {
    return (
      <History
        onEnter={this.search}
        onRemove={this.onRemove}
        searchHistory={this.state.searchHistory}
        {...this.props}
      />
    );
  }
}

export default HistoryContainer;
