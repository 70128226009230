import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';
import get from "lodash/get";

const TAG = 'stores list';

export default async function listStores() {
  const config = await getConfig();

  const promise = new Promise(resolve =>
    axios
      .get(`${URL_BASE}/companies/`, {
        ...config,
      })
      .then(response => {
        const companies = get(response.data, ['source', 'data']) || [];
        resolve(companies);
      })
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));

  return await promise;
}
