import React, { Component } from 'react';
import { View, FlatList, ScrollView, RefreshControl } from 'react-native';

import BannerImage from '~/components/Banners/components/BannerImage';
import appStyles from '~/appStyles';
import styles from './styles';
import EmptyView from '~/components/EmptyView';
import Images from '~/../assets/images';

import PromotionsLoad from './PromotionsLoad';

type Props = {
  promotions: Array<any>,
  getDynamicViews?: () => void,
  onPromotionClick: (promotion: Object<any>) => void,
  refreshing: boolean,
  onRefresh: () => void,
  loadData: boolean,
};

class Promotions extends Component<Props> {
  keyExtractor = item => `${item.id}`;

  itemSeparator = () => <View style={styles.separator} />;

  emptyViewOrLoad = () => {
    const { loadData } = this.props;
    if (loadData) {
      return <PromotionsLoad />;
    }
    return <EmptyView icon={Images.iconDealFilled} message="Você ainda não possui promoções disponíveis" />;
  }

  renderFooter() {
    return this.props.getDynamicViews;
  }

  renderItem = ({ item }) => {
    const { loadData } = this.props;
    if (!item && item.length === 0) {
      return (
        <EmptyView icon={Images.iconDealFilled} message="Você ainda não possui promoções disponíveis" />
      );
    }
    if (loadData) {
      return <PromotionsLoad />;
    }
    return (
      <BannerImage
        resizeMode="contain"
        image={{ uri: item.image }}
        onPressItem={() => this.props.onPromotionClick(item)}
        fullWidth
        showFullImage={false}
      />
    );
  }

  render() {
    const { refreshing, onRefresh } = this.props;

    return (
      <ScrollView
        style={appStyles.container}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => onRefresh()} />}
      >
        <View style={appStyles.container}>
          <FlatList
            style={styles.list}
            data={this.props.promotions}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.itemSeparator}
            ListFooterComponent={this.renderFooter()}
            ListEmptyComponent={this.emptyViewOrLoad}
          />
        </View>
      </ScrollView>
    );
  }
}

export default Promotions;
