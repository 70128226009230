
class SQLite {

  enablePromise = () => {}

  flush = async () => {
    const promise = new Promise((resolve, reject)=> {
      resolve();
    })

    return promise;
  }

  clearAllInCache = async () => {
    const promise = new Promise((resolve, reject)=> {
      resolve();
    })

    return promise;
  }

  clearAllTables = async () => {
    const promise = new Promise((resolve, reject)=> {
      resolve();
    })

    return promise;
  }

  echoTest = async () => {
    const promise = new Promise((resolve, reject)=> {
      resolve();
    })

    return promise;
  }

}

export default new SQLite();
