// @flow

import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { Button } from '~/components';

import Images from '~/../assets/images';

import styles from './styles';
import { Touchable } from 'react-native-web';

type DeliveryAddressProps = {
  addressLine1: string,
  addressLine2?: string,
  phoneInfo?: string,
  onPressNewAddress: () => void,
  icon?: {},
  onPress: () => void,
};

const DeliveryAddress = ({
  onPressNewAddress,
  addressLine1,
  addressLine2,
  phoneInfo,
  icon,
  onPress,
}: DeliveryAddressProps) => (
  <TouchableOpacity
    onPress={onPress}
  >
    <View style={styles.container}>
      <Image style={styles.icon} source={icon || Images.iconDeliveryTruck} />
      <View style={styles.addressContainer}>
        <Text style={styles.addressLine1}>{addressLine1}</Text>
        {addressLine2 ? <Text style={styles.addressLine2}>{addressLine2}</Text> : null}
        {phoneInfo ? <Text style={styles.addressLine2}>{phoneInfo}</Text> : null}
        {onPressNewAddress ? (
          <Button
            containerStyle={{ marginTop: 16 }}
            title="Enviar para outro endereço"
            flat
            transparent
            noUpperCase
            small
            onPress={onPressNewAddress}
          />
        ) : null}
      </View>
    </View>
  </TouchableOpacity>
);

export default DeliveryAddress;
