import React, { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { DATA_ACCOUNT } from '~/constants/cache';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadStorageData() {
      const dataAccount = await CacheStore.get(DATA_ACCOUNT);

      if (dataAccount) {
        setData({
          accessToken: dataAccount.token,
          user: dataAccount.user,
          dataAccount,
        });
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  const signIn = useCallback(async () => {

    const dataAccount = await CacheStore.get(DATA_ACCOUNT);

    setData({
      accessToken: dataAccount.token,
      user: dataAccount.user,
      dataAccount: dataAccount,
    });

  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        accessToken: data.accessToken,
        dataAccount: data.dataAccount,
        loading,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
