import { StyleSheet } from 'react-native';
import { sizes, colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    borderBottomColor: colors.grayContainerDark,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: sizes.textSmall,
    color: colors.grayMid,
    marginHorizontal: sizes.horizontalSpacing,
    marginBottom: sizes.verticalSpaceSeparatorNormal,
    marginTop: sizes.horizontalSpacing,
  },
});

export default styles;
