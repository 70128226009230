import React, { Component } from 'react';
import {
  Alert,
  FlatList,
  Image,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import { SCREEN_LIST_ADDRESS } from '../screens';

import { toast } from 'react-toastify';
import { AlertBuyingFor, Button, DeliveryAddress, ProductBonus, Section } from '~/components';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import Product from '~/components/Product/ProductContainer';
import Images from '~/../assets/images';
import { sizes } from '~/values';
import appStyles from '~/appStyles';
import styles from './styles';
import { Money } from '../../common';
import InlineAlert from '../../components/InlineAlert';
import colors from '~/values/color';
import EmptyView from '~/components/EmptyView';
import ProgressView from '../../components/ProgressView';
import Carousel from '~/components/Carousel';
import DataManager from '../../database/DataManager';
import PickerModal from '~/components/PickerModal';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import find from 'lodash/find';
import AppConfig from '../../AppConfig';
import { TextInputMask } from 'react-native-masked-text';
import CustomAlert from '~/components/CustomAlert';
import constantsIsipag from '~/constants/isipag';
import AccountController from '~/controllers/AccountController';
import ComponentHelper from '~/components/ComponentHelper';
import ChosenAddress from '~/components/ChosenAddress/ChosenAddress';

import shippingCalculate from '~/services/shipping-calculate/shippingcalculate';

type Props = {
  loading: boolean,
  loadCart: boolean,
  userBlocked: boolean,
  screenId: string,
  messages: [],
  order: Object < any >,
  processCart: boolean,
  productsContext: [],
  getDynamicViews: () => void,
  onPressSeeWarnings: () => void,
  changePaymentOptions: () => void,
  onChangeMessage: () => void,
  dataAddr: any,
  displayAddrSelect: boolean,
  clearCart: () => void
};

class Cart extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      productSelect: new Map(),
      showModalBirthday: false,
      birthday: '',
      destination_cep: this.props.dataAddr ? this.props.dataAddr.postal_code : '',
    };

    this.dataManager = new DataManager();
  }

  onProductSelect = (id: string) => {
    this.setState(state => {
      const productSelect = new Map(state.productSelect);
      productSelect.set(id, !productSelect.get(id));
      return { productSelect };
    });
  };

  getTotal = async (cep, kind) => {
    cep = this.state.destination_cep;
    kind = 'PAC';
    const totalShipping = await shippingCalculate(cep, kind, this.props.order.id);
    this.setState({ totalShipping });
  }

  maskCEP = (value) => {
    const tempValue = value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');

    this.setState({ destination_cep: tempValue });
  };

  sectionPromotion() {
    const { campaigns } = this.props;

    if (campaigns && campaigns.length > 0) {
      return (
        <Section style={styles.sectionPromo}>
          <View style={styles.promoTopContainer}>
            <View style={styles.iconPromoContainer}>
              <Image style={styles.iconPromo} source={Images.iconStar} />
            </View>
            <Text style={styles.textPromo}>BÔNUS</Text>
          </View>
          {this.renderBonus(campaigns)}
        </Section>
      );
    }

    return null;
  }

  sectionResume() {
    let amount_shipping;
    let amount_tax;
    let amount_items;
    let shippingComponent = null;

    const { order, products, processCart } = this.props;
    const { amount, subtotals } = order;
    const { showTaxes } = AppConfig.screenSettings.cart;

    if (subtotals) {
      ({ amount_items, amount_shipping, amount_tax } = subtotals);
    }
    const totalItens = products.length;

    const shippingConfig = this.dataManager.getConfigValue('shipping');
    if (shippingConfig) {
      shippingComponent = (
        <View style={appStyles.spaceBetween}>
          <Text style={styles.infoText}>Frete</Text>
          <Text style={styles.infoText}>{amount_shipping ? Money(amount_shipping || 0) : 'Gratis'}</Text>
        </View>
      );
    }

    return (
      <Section>
        { (!!amount_items || !!amount) && !processCart && (
          <View style={appStyles.spaceBetween}>
            <Text style={styles.infoText}>Produtos ({totalItens})</Text>
            <Text style={styles.infoText}>{Money(amount_items || amount || 0)}</Text>
          </View>)}
        {!!amount_tax && showTaxes && (
          <View style={appStyles.spaceBetween}>
            <Text style={styles.infoText}>Taxas</Text>
            <Text style={styles.infoText}>{Money(amount_tax || 0)}</Text>
          </View>)}
        {shippingComponent}
        <View style={styles.divider} />
        <View style={appStyles.spaceBetween}>
          <Text style={styles.totalLabel}>Total</Text>
          <Text style={styles.totalValue}>{Money(amount || 0)}</Text>
        </View>
      </Section>
    );
  }

  renderHeader() {
    const { messages, screenId } = this.props;
    return (
      <View>
        <AlertBuyingFor navigation={this.props.navigation} />
        {messages && messages.length > 0 ? (
          <View style={{ padding: 16 }}>
            <InlineAlert
              type="warning"
              onPress={this.props.onPressSeeWarnings}
              icon={Images.iconAlert}
              text={'Alguns itens não foram inseridos. \nToque aqui para ver.'}
            />
          </View>
        ) : null}
      </View>
    );
  }

  renderBonus(campaigns) {
    const productBonusComponents = campaigns.map(campaign => (
      <ProductBonus key={`${campaign.id}`} product={campaign} />
    ));
    return <View>{productBonusComponents}</View>;
  }

  renderItem = ({ item }) => (
    <Product
      id={item.id}
      onProductSelect={this.onProductSelect}
      isProductSelect={!!this.state.productSelect.get(item.id)}
      product={item}
      showPopupMenu
      type="linear"
      numberOfDescriptionLines={3}
      navigation={this.props.navigation}
    />
  );

  renderProductContextItem = ({ item }) => (
    <Product
      type="grid"
      product={item}
      navigation={this.props.navigation}
    />
  );

  sectionCarouselProductsContext = () => {
    const { productsContext: products } = this.props;
    const productGroup = groupBy(products, 'context_id');

    return Object.keys(productGroup).map(context => {
      const title = productGroup[context][0].context_title || null;
      const id = productGroup[context][0].context_id || null;
      return (
        <Carousel
          key={id}
          title={title}
          data={productGroup[context]}
          renderItem={this.renderProductContextItem}
          ItemSeparatorComponent={this.separatorView}
          keyExtractor={(item, index) => `${item.id}-${index}`}
        />
      );
    });
  };

  onPressShippingChoice = () => {
    if (this.props.onPressShippingChoice) this.props.onPressShippingChoice();
  };

  sectionShipping(shippingMethods) {
    const currentShippingMethod = get(this.props.order, 'shipping_method', {});
    const dataToPicker = shippingMethods.map(shippingMethod => ({
      ...shippingMethod,
      key: (shippingMethod.code || shippingMethod.ord),
      text: (shippingMethod.description || shippingMethod.valor),
    }));

    return (
      <View>
        <DeliveryAddress
          addressLine1="Frete"
          addressLine2={currentShippingMethod.description || 'Selecione o frete'}
          icon={Images.iconDeliveryTruck}
          onPress={this.onPressShippingChoice}
        />

        <PickerModal
          modalTitle="Escolha o frete"
          options={dataToPicker}
          isVisible={this.props.modalShippingChoiceVisible && shippingMethods.length > 1}
          onPressItem={this.props.onPressShipping}
          onClose={this.onPressShippingChoice}
        />
      </View>
    );
  }

  renderFooter() {
    const {
      saleConditions, shippingMethods, isAnonymous, processCart, userBlocked,
    } = this.props;
    const option = find(saleConditions, { key: 'meio' }) || {};
    const term = find(saleConditions, { key: 'prazo' }) || {};
    const currentShippingMethod = get(this.props.order, 'shipping_method', {});

    return (
      <View>
        {this.sectionPromotion()}
        {this.sectionResume()}
        {this.sectionCarouselProductsContext()}

        <Section style={{ padding: 10 }}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text style={[styles.totalLabel, { marginBottom: 10 }]}>Forma de pagamento</Text>
            <Text style={styles.totalValue}>
              {option.value_description} - {term.value_description}
            </Text>
            {!isAnonymous && !userBlocked ? (
              <Button
                containerStyle={{ margin: 0 }}
                title="Alterar"
                flat
                iconLeft={Images.iconEdit}
                onPress={this.props.goToCheckout}
              />
            ) : null}
          </View>
        </Section>

        {
          !isAnonymous && AppConfig.has_observation &&
          <Section>
            <Text style={styles.aditionalTitle}>
              Deseja enviar alguma mensagem adicional? Escreva abaixo...
            </Text>
            <View style={{ padding: 5 }}>
              <TextInput
                style={styles.aditionalInput}
                maxLength={AppConfig.limitCharactersInObservation}
                multiline
                placeholder="MENSAGEM"
                onChangeText={(msg) => this.props.onChangeMessage(msg)}
              />
            </View>
          </Section>
        }

        {AppConfig.shipping &&
        !isAnonymous &&
        !!shippingMethods.length &&
        this.sectionShipping(this.props.shippingMethods)}
        {AppConfig.registerAddress && this.props.displayAddrSelect && this.props.dataAddr && <ChosenAddress componentId={this.props.componentId} dataAddr={this.props.dataAddr} />}
        {currentShippingMethod.description == ('PAC' || 'SEDEX') && <View style={{ height: 1, marginTop: 1 }} />}
        {AppConfig.registerAddress && currentShippingMethod.description == ('PAC' || 'SEDEX') &&
        <View style={{ flexDirection: 'row', ...styles.containerCalc }}>
          <TextInput
            style={{
              margin: 5,
              height: 30,
              width: '50%',
              borderBottomWidth: 1,
              borderColor: colors.primaryColor,
              fontWeight: 'bold',
            }}
            underlineColorAndroid="transparent"
            placeholder="CEP"
            keyboardType="numeric"
            placeholderTextColor="#000"
            autoCapitalize="none"
            onChangeText={(text) => {
              this.maskCEP(text);
            }}
            value={this.state.destination_cep}
          />
          <TouchableOpacity
            onPress={this.getTotal}
            style={{
              margin: 5,
           backgroundColor: colors.primaryColor,
           textAlign: 'center',
           width: '50%',
           height: 30,
           borderRadius: 2,
         }}
          >
            <Text style={{
              color: '#fff',
              textAlign: 'center',
              fontWeight: 'bold',
              top: 4,
            }}
            >
              Calcular frete
            </Text>
          </TouchableOpacity>
        </View>}
        {/*{this.props.getDynamicViews()}*/}
        <View>
          {processCart ? (
            <View style={{ paddingHorizontal: 24, paddingVertical: 16 }}>
              <Text style={styles.textSync}>Sincronizando...</Text>
            </View>
          ) : (
            <Button
              containerStyle={{ margin: sizes.horizontalSpacing }}
              title="Finalizar pedido"
              disabled={processCart}
              big
              onPress={AppConfig.registerAddress ? this.validSelectAddr : this.onPressConfirm}
            />
          )}
        </View>
      </View>
    );
  }

  renderEmptyView = () => (
    <EmptyView icon={Images.iconCart} message="Seu carrinho ainda está vazio" />
  );

  separatorView = () => <View style={{ width: 1 }} />;

  onCloseModalBirthday = () => {
    this.setState({ birthday: '', showModalBirthday: false });
  };

  onSaveBirthday = () => {
    this.setState({ birthday: '', showModalBirthday: false });

    this.props.onSaveBirthday(this.state.birthday);
    this.props.confirmation();
  };

  validSelectAddr = async () => {
    const addrSelect = await CacheStore.get('ADDRSELECT');
    if (AppConfig.registerAddress && addrSelect !== null) {
      this.onConfirm();
    } else {
      this.setState({
        showModalSelectAddress: true,
      });
    }
  }

  onConfirm = async () => {
    const { user } = this.props;

    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      Alert.alert('', 'Você está offline! Conecte a internet para concluir a compra.');
      return;
    }

    const MESSAGE_UPLOAD_REQUEST_QUEUE = {
      type: 'info',
      message: 'Aguarde! Estamos sincronizando os itens do seu carrinho.',
    };

    // const requestsFinish = await requestManager.isJobProcessing();
    // if (requestsFinish) {
    //   Alert.alert('', MESSAGE_UPLOAD_REQUEST_QUEUE.message);
    //   return;
    // } else if (requestManager.getItemToProcess().length > 0) {
    //   await requestManager.execProcessItem();
    //   Alert.alert('', MESSAGE_UPLOAD_REQUEST_QUEUE.message);
    //   return;
    // }

    const isAnonymous = await AccountController.isAnonymous();
    if (isAnonymous) {
      Alert.alert(
        'Login necessário',
        'Você precisa estar logado para concluir a compra. \nDeseja entrar agora?',
        [
          {
            text: 'Continuar comprando',
            onPress: null,
          },
          {
            text: 'Entrar',
            onPress: this.props.enter,
          },
        ],
      );
    } else if (!this.props.customerActive.approved) {
      toast.error('Seu cadastro precisa ser aprovado para concluir a compra! Entre em contato com a empresa.');
    } else {
      this.setState({
        showModalConfirmPayment: true
      });
    }
  };

  onPressConfirm = ComponentHelper.debounce(this.onConfirm);

  keyExtractor = (item, index) => `${item.id}-${index}`;

  render() {
    const { loading, products, loadCart, processCart } = this.props;
    const productsDuplicationFree = uniq(products);

    return (
      <ScrollView style={appStyles.container}>
        {this.props.sendingOrder && <ProgressView indeterminate text={'Enviando pedido...'} title={''} />}

        {this.props.updatingCart && <ProgressView indeterminate text={'Atualizando carrinho...'} title={''} />}

         {/*{processCart && (*/}
         {/* <ProgressView*/}
         {/*   indeterminate*/}
         {/*   text="Seu carrinho está sendo processado..."*/}
         {/*   title="Processamento do Carrinho"*/}
         {/* />*/}
         {/*)}*/}

        {loading || loadCart ? (
          <View style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 200,
          }}
          >
            <EmptyView icon={Images.iconCart} message="Carregando..." />
          </View>
        ) : (
          <View style={appStyles.container}>
            {
              <FlatList
                style={styles.list}
                data={sortBy(productsDuplicationFree, 'name')}
                extraData={this.state}
                keyExtractor={this.keyExtractor}
                renderItem={this.renderItem}
                ItemSeparatorComponent={this.separatorView}
                ListHeaderComponent={this.renderHeader()}
                ListFooterComponent={this.renderFooter()}
                ListEmptyComponent={this.renderEmptyView}
              />
            }
          </View>
        )}

        <CustomAlert
            showModal={this.props.modalClearCart}
            title="Atenção"
            message="Deseja limpar o carrinho?"
            cancelButtonTitle="Cancelar"
            cancelButtonPress={() => this.props.onPressCancelModalClearCart()}
            okButtonTitle={"Sim"}
            okButtonPress={() => {this.props.clearCart()}}
        />

        <CustomAlert
          showModal={this.state.showModalBirthday}
          title="Confirmar pagamento"
          cancelButtonTitle="Cancelar"
          cancelButtonPress={this.onCloseModalBirthday}
          okButtonPress={this.onSaveBirthday}
        >
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
              Para sua segurança é necessário informar sua data de nascimento
            </Text>
            <Text style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
              Digite a data de nascimento.
            </Text>
            <TextInputMask
              placeholder="Ex: 01/01/1990"
              value={this.state.birthday}
              focus
              onChangeText={birthday => {
                this.setState({
                  birthday,
                });
              }}
              style={{
                marginTop: 10,
                borderRadius: Platform.OS == 'ios' ? 5 : 0,
                borderWidth: Platform.OS == 'ios' ? 1 : 0,
                borderColor: Platform.OS == 'ios' ? '#bababa' : 'transparent',
                padding: 5,
              }}
              type="datetime"
              options={{
                format: 'DD/MM/YYYY',
              }}
            />
          </View>
        </CustomAlert>

        <CustomAlert
          showModal={this.state.showModalSelectAddress}
          title="Selecionar endereço"
          cancelButtonTitle="Cancelar"
          cancelButtonPress={()=> {
            this.setState({
              showModalSelectAddress: false,
            });
          }}
          okButtonPress={()=> {
            this.setState({
              showModalSelectAddress: false,
            }, ()=> {
              this.props.navigation.push(SCREEN_LIST_ADDRESS.name);
            });
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
              Selecione um endereço para entrega
            </Text>
          </View>
        </CustomAlert>

        <CustomAlert
          showModal={this.state.showModalConfirmPayment}
          title="Confirmar?"
          okButtonTitle={'Sim'}
          cancelButtonTitle="Não"
          cancelButtonPress={()=> {
            this.setState({
              showModalConfirmPayment: false,
            });
          }}
          okButtonPress={()=> {
            this.setState({
              showModalConfirmPayment: false,
            }, ()=> {
              const cart = this.props.order;
              const isCreditCard =
                get(cart, 'payment_type.key', null) === constantsIsipag.PAYMENT_TYPES.CREDIT_CARD;
              if (isCreditCard && !user.birthday) {
                this.setState({
                  showModalBirthday: true,
                });
              } else {
                this.props.confirmation();
              }
            });
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
              Deseja finalizar o pedido?
            </Text>
          </View>
        </CustomAlert>
      </ScrollView>
    );
  }
}

export default Cart;
