import { Form } from '@unform/web';
import React, { createRef, useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Image } from 'react-native';
import * as Yup from 'yup';
import InputWeb from '~/components/Input/unform/InputWeb';
import InputWebMask from '~/components/Input/unform/InputWebMask';
import listStates from '~/services/states';
import Images from '~/../assets/images';
import Select from 'react-select';
import { colors } from '~/values';
import { Scope } from '@unform/core';
import { ScrollView } from 'react-native-web';
import "./styles.css";
import ProgressView from '~/components/ProgressView';


const SignUPWeb = ({ logging, onPressSave, isUpdate, form }) => {

    const formRef = createRef();
    const [pws, setPws] = React.useState(false);
    const [loadingState, setLoadingState] = React.useState(false);
    const [states, setStates] = React.useState([]);
    const [onEntrySuccess, setOnEntrySuccess] = React.useState(null);
    const [cities, setCities] = React.useState([]);
    const [stateSelected, setStateSelected] = React.useState(null);
    const [citySelected, setCitySelected] = React.useState(null);
    const [disabledCities, setDisabledCities] = React.useState(true);
    const [gender, setGender] = React.useState([
      { label: 'Masculino', value: 0 },
      { label: 'Feminino', value: 1 },
      { label: 'Prefiro não dizer', value: 2 },
    ]);
    const [genderSelected, setGenderSelected] = React.useState(null);
    const [valueNin, setValueNin] = React.useState('');

    const handleChangeState = (state) => {
        console.log('STATE select: ', state);
        setStateSelected(state);
        // loadCities();
        setDisabledCities(false);
        // console.log('STATE SELECTED: ', stateSelected);
       }

    const loadCities = () => {
        if(stateSelected && stateSelected.value) {
          for(let i = 0; i < states.length; i++){
            if (states[i].value === stateSelected.value){
              const options = states[i].cities.map(city => ({
                "value" : city.name,
                "label" : city.name,
                // "cities": state.cities,
              }));
               setCities(options);
            }
          }
        }
    }

    const handleChangeCity = (city) => {
      console.log('CITY select: ', city);
      setCitySelected(city);
     }

     const handleChangeGender = (gender) => {
      console.log('GENDER select: ', gender);
      setGenderSelected(gender);
     }


       const customStyles = {
        control: (provided, state) => ({
          ...provided,
          // backgroundColor: 'transparent',
          borderColor: '1px solid #696969',
        }),
        option: (provided , state)=> ({
          ...provided,
          // borderBottom: '2px dotted green',
          // color: state.isSelected ? 'yellow' : 'black',
          // backgroundColor: state.isSelected ? colors.primaryColor : 'white'
        }),
        placeholder: provided => ({
            ...provided,
            color: '#BEBEBE',
        }),
      }

    const loadStates = async () => {
        setLoadingState(true);
        try {
            const resultStates = await listStates();
            const options = resultStates.map(state => ({
                "value" : state.uf,
                "label" : state.name,
                "cities": state.cities,
              }));
            setStates(options);
        } catch (e) {
            console.log('ERROR LOAD STATES: ', e);
        } finally {
          setLoadingState(false);
        }
      }

    useEffect(() => {
      if(isUpdate) {
        formRef.current.setData({
          name: form.name,
          display_name: form.display_name,
          email: form.email,
          // birthday: form.birthday,
          phones: {
            main: form.phones.main,
            fixed_phone: form.phones.secondary,
          },
          address: {
            address: form.address.address,
            number: form.address.number,
            complement: form.address.complement,
            postal_code: form.address.postal_code,
            district: form.address.district,
          }
        });
      }

        console.log('logging: ', logging);
        console.log('isUpdate: ', isUpdate);
        console.log('form: ', form);
        console.log('onPressSave: ', onPressSave);
        loadStates();
        loadCities();
    }, [stateSelected]);


    const showPwd = () => {
      setPws(!pws)
    }

    const handleSubmit = async (data, { reset }) => {

      const allData = formRef.current.getData();

      console.log('ALL DATA: ', allData);

      const dataFinal = {
        "loadingState": false,
        "personDocument": allData.nin,
        "name": allData.name,
        "exhibitionName": allData.display_name,
        "genre": genderSelected ? genderSelected.label : (isUpdate ? form.genre : ''),
        "birthday": allData.birthday,
        "email": allData.email,
        "phone": allData.phones.main,
        "landline": allData.phones.fixed_phone,
        "password": allData.password,
        "confirmationPassword": allData.password_confirmation,
        "zipCode": allData.address.postal_code,
        "street": allData.address.address,
        "addressNumber": allData.address.number,
        "district": allData.address.district,
        "state": stateSelected ? stateSelected.value : (isUpdate ? form.address.state : ''),
        "city": citySelected ? citySelected.value : (isUpdate ? form.address.city : ''),
        "complement": allData.address.complement,
      };
      onPressSave(dataFinal,reset);
      console.log('FINAL  DATA: ', dataFinal);
      /* const phoneRegex = /^(\({0,1}\d{0,2}\){0,1} {0,1})(\d{4,5}) {0,1}-{0,1}(\d{4})$/;
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().email('Digite um e-mail válido').required('O e-mail é obrigatório'),
          phones: Yup.object().shape({
            main: Yup.string().matches(phoneRegex, 'Celular inválido').max(11, 'Número de dígitos ultrapassado').required('O telefone é obrigatório'),
          }),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
      reset();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          console.log('ERROR: ', error);
          const errorMessages = {};
          error.inner.forEach(err => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
      }
      }*/
    }

    return (
        <ScrollView>
           { logging && <ProgressView indeterminate text={'Salvando....'} title={'Informação'} />}

            <Form ref={formRef} onSubmit={handleSubmit}>
                &nbsp;
                { !isUpdate &&
                <fieldset>
                  <legend>Identidade</legend>
                    <InputWebMask
                      name="nin"
                      required="required"
                      label="CPF/CNPJ"
                      placeholder="CPF/CNPJ"
                      mask={valueNin.length < 15 ? "999.999.999-999" : "99.999.999/0001-99"}
                      maskChar=""
                      value={valueNin}
                      onChange={(e) => setValueNin(e.target.value)}
                      />
                </fieldset>
                }
                &nbsp;
                <fieldset>
                  <legend>Informações básicas</legend>
                <InputWeb name="name" required="required" label="Seu nome" placeholder="Nome"  />
                <InputWeb name="display_name" required="required" label="Nome de exibição" placeholder="Nome de exibição" />
                <Select name="gender" styles={customStyles} placeholder={'Gênero'} options={gender} onChange={handleChangeGender}/>
                &nbsp;
                <InputWebMask name="birthday" label="Data de nascimento" placeholder="Data de nascimento" mask="99/99/9999"/>
                <InputWeb name="email" type="email" label="E-mail" placeholder="E-mail" />
                <Scope path="phones">
                    <InputWebMask
                      name="main"
                      required="required"
                      label="Celular"
                      placeholder="Celular"
                      mask="(99) 9 9999-9999"
                      />
                    <InputWeb type="tel" name="fixed_phone" label="Telefone fixo" placeholder="Telefone fixo"/>
                </Scope>
                </fieldset>
                &nbsp;
                {!isUpdate && <fieldset>
                  <legend>Senha</legend>
                <InputWeb type={ pws ? "text" : "password"} name="password" label="Senha" placeholder="Senha" />
                <InputWeb type={ pws ? "text" : "password"} name="password_confirmation" label="Confirmar senha" placeholder="Confirmar senha" />
                <fieldset>
                  <legend>Exibir senha</legend>
                  <input type="checkbox" onChange={showPwd}/>
                  </fieldset>
                </fieldset>}
                &nbsp;
                <fieldset>
                  <legend>Endereço</legend>
                <Scope path="address">
                  <InputWebMask name="postal_code" label="CEP" placeholder="CEP" mask="99999-999" />
                  <InputWeb name="address" label="Rua" placeholder="Rua" />
                  <InputWeb name="number" label="Número" placeholder="Número"/>
                  <InputWeb name="district" label="Bairro" placeholder="Bairro"/>
                  <Select styles={customStyles} placeholder={'--- Selecione o Estado ---'} options={states} onChange={handleChangeState}/>
                  &nbsp;
                  <Select isDisabled={disabledCities} styles={customStyles} placeholder={'--- Selecione a Cidade ---'} options={cities} onChange={handleChangeCity}/>
                  &nbsp;
                  <InputWeb  name="complement" label="Complemento" placeholder="Complemento"/>
                </Scope>
                </fieldset>
                <div class="container">
                  <div class="vertical-center">
                    <button className="button" type="submit">Salvar</button>
                  </div>
                </div>
          </Form>
        </ScrollView>
    );
};

export default SignUPWeb;
