// @flow

import React, { Component, ReactNode, ReactNodeArray } from 'react';
import { View, Text, Platform, Image, Keyboard, TextInput, TouchableOpacity } from 'react-native';
import isEmpty from 'lodash/isEmpty';

import { Money } from '~/common';
import Images from '~/../assets/images';
import { Touchable } from 'react-native-web';
import { Button } from '~/components';
import type { IProduct } from '~/entities/Product';
import Quantity from '../Quantity';

import styles from './styles';
import atrStyles from '../../screens/Product/components/Attribute/styles';
import Option from '../../screens/Product/components/Option';
import appStyles from '../../appStyles';
import PopupMenu from '~/components/PopupMenu';
import ImageComponent from '~/components/Image';

import StockerProductResource from '../../services/resources/StockerProductResource';
import CustomAlert from '../CustomAlert';
import { validateQuantity } from '~/utils/product';
import Masked from 'vanilla-masker';
import imagesProduct from '../../services/products/imagesProduct';
import DataManager from '../../database/DataManager';
import color from '../../values/color';
import AppConfig from '../../AppConfig';
import { setLikedProduct } from '~/utils/product';

const REMOVE_ITEM = 'Remover item';

export type Props = {
  type: 'linear' | 'grid',
  error?: boolean,
  hideQuantity?: boolean,
  showPrice?: boolean,
  canOpenDetail?: boolean,
  product: IProduct,
  showDetail: boolean,
  showLetMeKnow: boolean,
  showPopupMenu: boolean,
  showQuantity: boolean,
  onPress: (product: IProduct) => void,
  onPressLike: (product: IProduct) => void,
  onPressError: (product: IProduct) => void,
  onRemoveItem: (product: IProduct) => void,
  onAddProductInOrder: (product: IProduct) => void,
  onNotifyMe: (quantity: number, product: IProduct) => void,
  FooterComponent: ReactNode | ReactNodeArray,
  isAnonymous: boolean,
  numberOfDescriptionLines: number,
};

class Product extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
      image: null,
      quantityNotifyMe: 0,
      showModalNotifyMe: false,
      actions: [REMOVE_ITEM],
    };

    this.dataManager = new DataManager();
  }

  async UNSAFE_componentWillMount() {
    const product = await setLikedProduct(this.props.product);
    if (product) {
      this.setState({ liked: product.liked });
    }
  }

  async componentDidMount() {
    const { product } = this.props;
    if (product) {
      const imageStockerConfig = this.dataManager.getConfigValue('imageStocker');
      if (imageStockerConfig) {
        const image = StockerProductResource.getThumbImage(product.ean);
        this.setState({ image });
      } else {
        const ean = encodeURIComponent(product.ean);
        try {
          const images = await imagesProduct(ean);
          this.setState({ image: images[0] });
        } catch (er) {
          this.setState({ image: Images.iconNoImageBig });
        }
      }
    }
  }

  onPressLike(product) {
    if (!this.props.isAnonymous) {
      const liked = !this.state.liked;
      this.setState({ liked });
      if (this.props.onPressLike) this.props.onPressLike({ ...product, liked });
    }

    if (this.props.onPressLike) this.props.onPressLike({ ...product });
  }

  onRemove() {
    const { product } = this.props;
    if (this.props.onRemoveItem) this.props.onRemoveItem(product);
  }

  onAddProductInOrder = quantity => {
    const { product } = this.props;
    this.props.onAddProductInOrder(product, quantity);
  };

  onPressError = () => {
    if (this.props.onPressError) {
      this.props.onPressError(this.props.product);
    }
  };

  onPress(product) {
    if (this.props.onPress && !this.state.showModalNotifyMe) {
      this.props.onPress(product, product.liked);
    }
  }

  renderNameLike() {
    const {
      product, type, showPopupMenu, showDetail, numberOfDescriptionLines,
    }: Props = this.props;

    return (
      <View style={styles[`nameLikeContainer__${type}`]}>
        <Text
          numberOfLines={numberOfDescriptionLines || 2}
          style={[styles[`name__${type}`], showDetail ? { fontWeight: '600' } : null]}
        >
          {product.name}
        </Text>
        {type === 'linear' &&
          !product.attributesValues && (
            <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button
                flat
                iconButton
                iconLeft={this.state.liked ? Images.iconHeart : Images.iconHeartOutline}
                onPress={() => this.onPressLike(product)}
              />
            </View>
          )}
        {type === 'linear' &&
          showPopupMenu && <PopupMenu item={product} onRemoveItem={this.props.onRemoveItem} />}
      </View>
    );
  }

  renderPrice() {
    const { product, type } = this.props;
    const { descriptionPrice } = AppConfig;

    let renderPrice = null;
    if (product.type === 'product') {
      renderPrice = (
        <View>
          {descriptionPrice.show && product.price !== -1 && product.minPrice &&
          product.price === product.price_base &&
          (
            <Text style={styles[`startingAt__${type}`]}>
              {`${descriptionPrice.text} ${Money(product.minPrice)}`}
            </Text>
          )}
          <Text style={styles.contentText}>
            {product.price !== -1 ? Money(product.price) : 'Preço indisponível'}
          </Text>
        </View>
      );
    } else if (product.type === 'matriz') {
      renderPrice = (
        <View>
          {product.minPrice && <Text style={styles[`startingAt__${type}`]}>a partir de</Text>}
          <Text style={styles.contentText}>
            {product.minPrice !== -1 ? Money(product.minPrice) : 'Preço indisponível'}
          </Text>
        </View>
      );
    }

    return <View style={styles[`priceContainer__${type}`]}>{renderPrice}</View>;
  }

  onChangeQuantity = quantity => {
    let quantityNotifyMe = quantity;
    if (quantity) {
      quantityNotifyMe = Number.parseInt(Masked.toNumber(quantity));
    } else {
      quantityNotifyMe = 0;
    }

    this.setState({ quantityNotifyMe });
  };

  onEndEditingQuantity = () => {
    const quantityNotifyMe = validateQuantity(
      this.state.quantityNotifyMe,
      this.props.product.multiple,
      0,
      0,
    );
    this.setState({ quantityNotifyMe });
  };

  onOpenModalNotifyMe = () => {
    this.setState({ quantityNotifyMe: 0, showModalNotifyMe: true });
  };

  onCloseNotifyMe = () => {
    this.setState({ quantityNotifyMe: 0, showModalNotifyMe: false });
  };

  onNotifyMe = () => {
    this.setState({ quantityNotifyMe: 0, showModalNotifyMe: false });
    this.props.onNotifyMe(this.state.quantityNotifyMe, this.props.product);
  };

  onCancelNotifyMe = () => {
    this.props.onNotifyMe(0, this.props.product);
  };

  renderPriceQuantity() {
    const { showModalNotifyMe, quantityNotifyMe } = this.state;
    const {
      type, product, showQuantity, hideQuantity, showPrice,
    } = this.props;
    const {
      name, quantity, multiple, stock, notifyMe, price,
    } = product;

    const multipleText = multiple === 0 ? '1' : multiple;

    let content = null;
    if (stock === 0) {
      let functioNotify = this.onCancelNotifyMe;
      let title = 'Não notificar mais';
      const style = {};

      if (notifyMe !== 1) {
        functioNotify = this.onOpenModalNotifyMe;
        title = 'Avise-me quando chegar';
        style.backgroundColor = '#00a4ff';
      }

      content = (
        <View style={{ alignItems: 'center' }}>
          <CustomAlert
            showModal={showModalNotifyMe}
            title="Adicionar a lista de espera"
            cancelButtonTitle="Cancelar"
            cancelButtonPress={this.onCloseNotifyMe}
            okButtonPress={this.onNotifyMe}
          >
            <View style={{ alignItems: 'center' }}>
              <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
                Esse produto será adicionado ao seu carrinho quando estiver disponível.
              </Text>
              <Text style={{ textAlign: 'center' }}>Digite a quantidade desejada.</Text>
              <Text style={{ textAlign: 'center', paddingBottom: 15 }}>
                {`Atenção: Esse produto só pode ser adicionado em quantidade ${multipleText} em ${multipleText}`}.
              </Text>
              <TextInput
                value={`${quantityNotifyMe}`}
                label="Quantidade"
                keyboardType="numeric"
                style={styles.inputModal}
                onChangeText={this.onChangeQuantity}
                onEndEditing={this.onEndEditingQuantity}
                onSubmitEditing={Keyboard.dismiss}
              />
            </View>
          </CustomAlert>
          <Text style={styles.contentText}>Produto indisponível</Text>
          <Button title={title} small onPress={functioNotify} style={style} />
        </View>
      );
    } else if (price === -1) {
      content = (
        <View style={{ alignItems: 'center' }} />
      );
    } else if (showPrice && !hideQuantity) {
      content = (
        <Quantity
          name={name}
          maxQuantity={stock}
          quantity={quantity}
          multiple={multiple}
          disabled={!showQuantity}
          inputStyle={type === 'linear' ? { maxWidth: 50, minWidth: 50 } : {}}
          onChangeQuantity={this.onAddProductInOrder}
        />
      );
    }

    return (
      <View style={styles[`priceQuantityContainer__${type}`]}>
        {stock > 0 && showPrice && this.renderPrice()}
        {content}
      </View>
    );
  }

  renderWarningBadge() {
    const { error } = this.props;
    if (!error) return null;
    return (
      <View style={styles.badgeErrorOverflow}>
        <TouchableOpacity
          foreground={()=> {}}
          onPress={this.onPressError}
        >
          <View style={styles.badgeError}>
            <Image style={styles.iconError} source={Images.iconAlert} />
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    const {
      type, product, showLetMeKnow, FooterComponent, showDetail, canOpenDetail,
    } = this.props;
    const { liked, image } = this.state;

    return (
      <View style={styles[`shadowContainer__${type}`]}>
        <View style={styles[`overflowContainer__${type}`]}>
          <TouchableOpacity
            foreground={()=> {}}
            onPress={() => {
              if (canOpenDetail) this.onPress({ ...product, liked });
            }}
          >
            <View>
              <View style={styles[`container__${type}`]}>
                {!showDetail ? (
                  <View>
                    <ImageComponent style={styles[`image__${type}`]} source={image || null} />
                    {this.renderWarningBadge()}
                  </View>
                ) : null}
                <View style={styles[`infoContainer__${type}`]}>
                  {this.renderNameLike()}
                  {showLetMeKnow ? null : this.renderPriceQuantity()}
                </View>
              </View>
              {!isEmpty(product.attributesValues) ? (
                <View>
                  <View style={[appStyles.divider, { marginLeft: 16 }]} />
                  <View style={atrStyles.optionsContainer}>
                    {product.attributesValues.map(attribute => (
                      <Option
                        key={attribute.code}
                        attribute={attribute.label}
                        selected={false}
                        clickButton={false}
                        option={attribute.value}
                        type={attribute.type}
                      />
                    ))}
                  </View>
                </View>
              ) : null}
              {FooterComponent && type === 'linear' ? FooterComponent : null}
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

Product.defaultProps = {
  type: 'linear',
  onPress: () => {},
  onPressError: () => {},
  onPressLike: () => {},
  showLetMeKnow: false,
  showPopupMenu: false,
  canOpenDetail: true,
};

export default Product;
