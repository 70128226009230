import Repository from './Repository';
import { CATEGORIES_RELATIONSHIPS_SCHEMA } from '~/database/schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'CategoriesRelationshipsRepository';

export default class CategoriesRelationshipsRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = CATEGORIES_RELATIONSHIPS_SCHEMA;
  }

  addCategoryRel(categorys) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCategoryRel(categorys).then(categoryRel => {
        resolve(categoryRel);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
