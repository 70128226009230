import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  failure,
  PRODUCT_NOTIFY_REQUEST,
  PRODUCT_NOTIFY_RESPONSE_FAILURE,
  PRODUCT_NOTIFY_RESPONSE_SUCCESS,
  PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST,
  PRODUCTS_LIST_REQUEST,
  success,
  successNotifyProduct
} from './action';

import { parseProducts } from '~/utils/product';
import { addIconsCategories } from '~/utils/category';
import SourceBuilder, { CONTEXT, METHODS } from '~/utils/SourceBuilder';
import RequestBuilder from '~/utils/RequestBuilder';
import requestManager from '~/utils/RequestManager';
import CategoryController from '~/controllers/CategoryController';
import ProductController from '~/controllers/ProductController';

const listProductsDataManager = (data) => {
  const promises = [];
  const categoryController = new CategoryController();
  const productController = new ProductController();

  const {
    categoryCode,
    productsLimit
  } = data.payload;

  let promiseProducts;
  let promiseCategories;

  console.log('PROMISES promises categoryCode', categoryCode);

  if (categoryCode === 0) {
    if (productsLimit) {
      promiseProducts = productController.getProdsByLimit(5);
    } else {
      console.log('PROMISES promises allProds start');
      promiseProducts = productController.allProds();
    }

    console.log('PROMISES promises productsLimit', productsLimit);

    promises.push(promiseProducts);

    promiseCategories = categoryController.allRoot();
    promises.push(promiseCategories);
  } else {
    promiseProducts = productController.getAllProductsByCategoryCode(categoryCode);
    promises.push(promiseProducts);

    promiseCategories = categoryController.allCategoryRelationships();
    promises.push(promiseCategories);
  }

  Promise.all(promises)
    .then((result) => {
      console.log('GLEGLE categoryCode result', result);
    })
    .catch((error) => {
      console.log('GLEGLE categoryCode error', error);
    });

  return Observable.fromPromise(Promise.all(promises))
    .map((payload) =>
      ({
        products: parseProducts(payload[0]),
        categories: addIconsCategories(payload[1], categoryCode),
      }));
};

const getProduct = ({ product }) => {
  const productController = new ProductController();
  const promiseProduct = productController.getProductById(product.id);
  return Observable.fromPromise(promiseProduct);
};

const setProductNotify = (product, quantity) => {
  const productController = new ProductController();

  if (quantity && quantity > 0) {
    product.notify_me = 1;
  } else {
    product.notify_me = 0;
  }

  const p = productController.updateProduct(product);
  return Observable.fromPromise(p);
};

const cancelProductNotify = (product) => {
  const productController = new ProductController();

  product.notify_me = 0;
  const p = productController.updateProduct(product);
  return Observable.fromPromise(p);
};

// Product list
const productsList = (action$: any) =>
  action$
    .ofType(PRODUCTS_LIST_REQUEST, PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST)
    .mergeMap((payload) => listProductsDataManager(payload)
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

// Notify me product
const notifyProduct = (action$: any, state$) =>
  action$
    .ofType(PRODUCT_NOTIFY_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap((payload) => getProduct(payload)
      .flatMap(product => setProductNotify(product, payload.quantity))
      .flatMap((productInsert) => {

        console.log('notifyProduct productInsert', productInsert);

        const list = state$.getState()
          .toJS().productsFetch.list.payload;

        console.log('notifyProduct productInsert list', list);

        const index = list.products.findIndex(p => p.id === productInsert.id);
        list.products[index].notifyMe = productInsert.notify_me;

        const {
          quantity,
          product,
          customerId
        } = payload;
        const {
          id,
          name,
          product_code,
          ean,
        } = product;

        const args = {
          amount: quantity,
          product: {
            id,
            name,
            product_code,
            ean,
          },
          customer: {
            id: customerId,
            name: customerId
          },
        };

        const source = SourceBuilder(METHODS.products.notifyMe, args);
        const request = RequestBuilder(source, CONTEXT.notifyMe);
        requestManager.push(request);

        return Observable.of(successNotifyProduct(list));
      }));

const notifyProductResponse = (action$: any) =>
  action$
    .ofType(PRODUCT_NOTIFY_RESPONSE_SUCCESS)
    .map(({ payload }) => payload)
    .mergeMap(payload => getProduct({ product: payload.productNotify })
      .flatMap(product => setProductNotify(product, payload.amount))
      .flatMap(() => Observable.empty()));

const notifyProductResponseFailure = (action$: any) =>
  action$
    .ofType(PRODUCT_NOTIFY_RESPONSE_FAILURE)
    .map(({ payload }) => payload)
    .mergeMap(payload => getProduct({ product: payload.productNotify })
      .flatMap(product => cancelProductNotify(product))
      .flatMap(() => Observable.empty()));

export default combineEpics(
  productsList,
  notifyProduct,
  notifyProductResponse,
  notifyProductResponseFailure,
);
