import Controller from '~/controllers/Controller';
import CategoriesRelationshipsRepository from '~/database/repositories/CategoriesRelationshipsRepository';

export default class CategoriesRelationshipsController extends Controller {
  constructor() {
    super();
    this.repository = new CategoriesRelationshipsRepository();
  }

  addCategoryRel(categorys) {
    return this.repository.addCategoryRel(categorys);
  }

  allByParentId(categoryParentId) {
    return this.repository.getAllByParentId(categoryParentId);
  }

  insertAll(data, update = false) {
    return this.repository.insertAll(data, update);
  }
}
