import axios from 'axios';
import get from 'lodash/get';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'delivery addresses list';

export default async function list(cartId, customerId) {
  const config = await getConfig();
  const company = await getCurrentCompany();
  const params = {
    customer_id: customerId,
  };

  if (!cartId) {
    return null;
  }

  const url = `${URL_BASE}/cart/${company}/detail/${cartId}/delivery_addresses/`;

  return new Promise((resolve, reject) =>
    axios
      .get(url, config, params)
      .then((response) => {
        const addresses = get(response.data, 'source.data.enderecos', []).map((address) => ({
          code: address.indice,
          district: address.bairro,
          postal_code: address.cep,
          city: address.cidade,
          complement: address.complemento,
          address: address.logradouro,
          number: address.numero,
          state: address.uf,
        }));

        const shippings = get(response.data, 'source.data.fretes', []).map((shipping) => ({
          code: shipping.ord,
          description: shipping.valor,
          price: shipping.preco,
          estimated_time: '',
          amount: 0,
        }));

        resolve({ addresses, shippings });
      })
      .catch(error => {
        console.log(TAG, error);
        reject();
      }));
}
