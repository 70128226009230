import CacheStore from '~/modules-wrapper/react-native-cache-store';

import Sync from '~/utils/Sync/index';

import { Observable } from 'rxjs';
import { concat } from 'rxjs/observable/concat';
import { combineEpics } from 'redux-observable';

import { UPDATE_STORE_REQUEST, failureChangeStore, successChangeStore } from './action';

import {
  updateOrder,
  ORDER_UPDATE_ORDER_RESPONSE_SUCCESS,
} from '../../store/activeOrderFetch/updateOrder/action';

import { store } from '~/App.web';
import { syncCurrentStoreItem } from '~/store/setCurrentStoreItem/action';
import { STORE_ITEM_ACTIVE } from '~/constants/cache';

import DataManager from '../../database/DataManager';
import SQLiteManager from '../../database/SQLiteManager';

const changeStoreSync = () => new Promise(async (resolve, reject) => {
  const sync = new Sync();

  await CacheStore.remove('CART_ID');
  await SQLiteManager.clearAllTables();
  await sync.sync();
  resolve();
}).catch(error => {
  console.log(error);
});

const updateItemStore = () => new Promise((resolve, reject) => {
  const storeItem = CacheStore.get(STORE_ITEM_ACTIVE);
  store.dispatch(syncCurrentStoreItem(storeItem));
  resolve();
}).catch(error => {
  console.log(error);
});

const updateStore = (action$: any) =>
  action$
    .ofType(UPDATE_STORE_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap((payload) =>
      concat(
        Observable.of(updateOrder(payload.order, payload.screen)),
        action$
          .ofType(ORDER_UPDATE_ORDER_RESPONSE_SUCCESS)
          .map(({ payload }) => payload)
          .mergeMap(() => Observable.fromPromise(changeStoreSync())
            .flatMap(() => Observable.fromPromise(updateItemStore()))
            .flatMap(() => Observable.of(successChangeStore()))
            .catch(() => failureChangeStore())),
      ));

export default combineEpics(updateStore);
