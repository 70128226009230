import { SET_CURRENT_COMPANY } from '~/store/setCurrentCompany/action';
import { Map } from 'immutable';

const initialState = Map({
  type: '',
  payload: Map({}),
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SET_CURRENT_COMPANY) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;
