import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  topContainer: {
    backgroundColor: colors.primaryColor,
    padding: 16,
  },
  userHello: {
    fontSize: sizes.textVeryBig,
    color: '#FFF',
    fontWeight: '300',
  },
  userHello__bold: {
    fontWeight: '600',
  },
  searchContainer: {
    marginTop: 10,
  },
  greetingContainer: {
    flexDirection: 'column',
  },
  greetingHello: {
    color: '#fff',
    fontSize: sizes.textBig,
    fontWeight: '300',
  },
  greetingName: {
    color: '#fff',
    fontSize: 22,
    fontWeight: '600',
  },
});

export default styles;
