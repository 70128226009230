import React, { Component } from 'react';
import { View, Text, Image, Alert, TouchableOpacity, Platform } from 'react-native';
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { Touchable } from 'react-native-web';
import Images from '~/../assets/images';
import colors from '~/values/color';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';

// import { Navigation } from 'react-native-navigation';

import { SCREEN_MAP } from '~/screens/screens';

type Props = {
  componentId: string,
};

class Places extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onPressSearchMap = () => {
    /* Navigation.push(this.props.componentId, {
      component: {
        name: SCREEN_MAP.name,
      },
    }); */
    console.log('THIS PROPS: ', this.props);
  }

  render() {
    return (<GooglePlacesAutocomplete 
              apiOptions={{ language: 'pt-BR', region: 'pt-BR' }}
              selectProps={{
                isClearable: true,
                placeholder: 'Endereço e número',
                onChange: (provided) => {
                  console.log('PROVIDE: ', provided)
                  if (provided !== null && provided !== '') {
                    const details = null;
                    if (provided.value.description.match(/\d/)){
                      console.log('TEM NUMERO');
                      // this.props.parentCallback([provided.value, details]);
                      // this.onPressSearchMap();

                      geocodeByAddress(provided.value.description)
                      .then(results => getLatLng(results[0]))
                      .then(({ lat, lng }) => this.props.parentCallback([{lat, lng}, details]), this.onPressSearchMap());

                      /*geocodeByAddress(provided.value.description)
                      .then(results => this.props.parentCallback([provided.value, details = null]))
                      .then(({ lat, lng }) => console.log('Successfully got latitude and longitude', { lat, lng }))*/
                    } else {
                      alert('Insira o número do endereço');
                    }
                    
                  } 
                },
                onclick: console.log('CLICKED'),
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: colors.primaryColor,
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: colors.primaryColor,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: colors.primaryColor,
                  }),
                },
              }}
              
            />);
    /* return (
      <GooglePlacesAutocomplete
      placeholder="Endereço e número"
      onPress={(data, details = null) => {
        if (!data.description.match(/\d/)) {
          Alert.alert('Insira o número do endereço');
        } else {
          this.props.parentCallback([data, details]);
        }
      }}
      autoFillOnNotFound
      onFail={error => console.log('ERROR LOCAL: ', error)}
      query={{
        key: 'AIzaSyBAiOE4eoO-ghGyC8YjuYgNU1YHbX8q2Nc',
        language: 'pt-br',
        components: 'country:br',
      }}
      fetchDetails
      listEmptyComponent={() => (
        <View>
          <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
            <Text style={{ color: '#000', fontWeight: 'bold' }}> Não encontramos seu endereço </Text>
            <Text style={{ color: '#000', flex: 1, flexWrap: 'wrap' }}>
              {`Verifique o que você digitou e então tente novamente. 
                  Você também pode buscar pelo mapa.`}
            </Text>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 80 }}>
            <TouchableOpacity
              onPress={this.onPressSearchMap}
              style={{ alignSelf: 'center', textAlignVertical: 'center' }}
            >
              <Text style={{ color: colors.primaryColor, textAlign: 'center', fontSize: 16, paddingTop: 0, paddingBottom: 0 }}> Buscar pelo mapa </Text>
            </TouchableOpacity>
          </View>
        </View>)}
      renderRow={results => (
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: 100 }}>
          <Image source={Images.iconPlace} style={{ width: 15, height: 20, marginRight: 10 }} />
          <Text>{results.description}</Text>
        </View>
      )}
    />
    );*/
  }
}

export default Places;
