import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { PRODUCTS_CONTEXT_LIST_REQUEST, success, failure } from './action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import ProductsContextController from '~/controllers/ProductContextController';

const listProductsContextDataManager = () => {
  const productContextController = new ProductsContextController();

  const promise = productContextController.allContexts();
  return Observable.fromPromise(promise);
};

const productContextList = (action$: any) =>
  action$
    .ofType(PRODUCTS_CONTEXT_LIST_REQUEST)
    .mergeMap(() => listProductsContextDataManager()
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(productContextList);
