import Repository from './Repository';
import { SHIPPING_METHODS_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

export default class ShippingMethodsRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = SHIPPING_METHODS_SCHEMA;
  }

  getShippingMethods() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getShippingMethods().then(shippMethods => {
        resolve(shippMethods);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addShippingMethods(shipp) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addShippingMethods(shipp).then(shippMethods => {
        resolve(shippMethods);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
