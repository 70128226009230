import React from 'react';
import { View } from 'react-native-web';

const Slider = () => {
  return (
    <View>

    </View>
  )
}


export default Slider;
