function timeDifference(previous) {
  const current = new Date();

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return `Há ${Math.round(elapsed / 1000)} segundos atrás`;
  } else if (elapsed < msPerHour) {
    return `Há ${Math.round(elapsed / msPerMinute)} minutos atrás`;
  } else if (elapsed < msPerDay) {
    return `Há ${Math.round(elapsed / msPerHour)} horas atrás`;
  } else if (elapsed < msPerMonth) {
    return `Há ${Math.round(elapsed / msPerDay)} dias atrás`;
  } else if (elapsed < msPerYear) {
    return `Há ${Math.round(elapsed / msPerMonth)} meses atrás`;
  }


  return `Há ${Math.round(elapsed / msPerYear)} anos atrás`;
}

export default timeDifference;
