import Repository from './Repository';
import { SALES_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'SalesRepository';

export default class SalesRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = SALES_SCHEMA;
  }

  addSales(sales) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addSales(sales).then(dataSales => {
        resolve(dataSales);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllSales() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllSales().then(dataSales => {
        resolve(dataSales);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
