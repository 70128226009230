import Crypto from './../security/Crypto';
import FileSystem from './FileSystem';
import { Platform } from 'react-native';

const TAG = 'WalletService';

class WalletService {
  async createWallet(password, profileId) {
    const wallet = {
      id: profileId,
      created_at: new Date(),
      cards: [],
    };

    if (profileId) {
      try {
        const content = Crypto.encrypt(JSON.stringify(wallet), password);
        if(Platform.OS !== 'web')
          return FileSystem.create(profileId, content);
        else
          localStorage.setItem(profileId, content)
      } catch (err) {
        console.log(TAG, err);
        throw new Error('Erro ao criar carteira');
      }
    } else {
      throw new Error('Não foi possível criar sua carteira.');
    }
  }

  async getCards(password, profileId) {
    if (Platform.OS !== 'b') {
      try {
        const wallet = await this.getWallet(password, profileId);
        return wallet.cards;
      } catch (err) {
        console.log(TAG, 'Erro ao carregar a carteira. Uma nova será criada e usada');
        await this.removeWallet(profileId);
        const newWallet = await this.createWallet(password, profileId);
        return newWallet;
      }
    } else {
      return [];
    }
  }

  async addNewCard(password, profileId, card) {
    try {
      const wallet = await this.getWallet(password, profileId);

      // TODO: Verificar cartão duplicado
      const filteredCards = wallet.cards.filter(obj => obj.identifier === card.identifier) || [];

      if (filteredCards.length) {
        throw new Error('Esse cartão já está adicionado na sua carteira');
      }

      wallet.cards.push(card);
      const cards = wallet.cards;
      const items = cards.map(item => {
        if (card.identifier !== item.identifier && card.main) item.main = false;

        return item;
      });

      wallet.cards = items;

      const content = Crypto.encrypt(JSON.stringify(wallet), password);
      if(Platform.OS !== 'web')
        FileSystem.create(profileId, content);
      else
        localStorage.setItem(profileId, content)
      return true;
    } catch (err) {
      console.log(TAG, err.message);
      throw new Error(`Não foi possível adicionar o cartão: ${err.message}`);
    }
  }

  async removeCard(password, profileId, card) {
    try {
      const wallet = await this.getWallet(password, profileId);

      const filteredCards = wallet.cards.filter(obj => obj.identifier !== card.identifier) || [];

      if (filteredCards.length === wallet.cards.length) {
        throw new Error('Esse cartão não existe na sua carteira');
      } else {
        if (card.main && filteredCards.length > 0) {
          filteredCards[0].main = true;
        }

        wallet.cards = filteredCards;
        const content = Crypto.encrypt(JSON.stringify(wallet), password);
        if(Platform.OS !== 'web')
          FileSystem.create(profileId, content);
        else
          localStorage.setItem(profileId, content)
        return true;
      }
    } catch (err) {
      throw new Error(`Não foi possível remove o cartão: ${err.message}`);
    }
  }

  async getWallet(password, profileId) {
    let content;
    if(Platform.OS !== 'web')
      content = await FileSystem.read(profileId);
    else
      content = localStorage.getItem(profileId)
    return JSON.parse(Crypto.decrypt(content, password));
  }

  async removeWallet(profileId) {
    try {
      const removed = FileSystem.delete(profileId);
      return true;
    } catch (err) {
      return false;
    }
  }

  async walletExists(profileId) {
    try {
      const file = await FileSystem.read(profileId);
      return !!file;
    } catch (err) {
      return false;
    }
  }
}

export default new WalletService();
