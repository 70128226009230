// @flow

import React from 'react';
import { TabBar } from 'react-native-tab-view';
import colors from '../../values/color';

type TabBarProps = {

};

const MyTabBar = (props: TabBarProps) => {
  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: colors.primaryColor }}
    />
  );
};

export default MyTabBar;
