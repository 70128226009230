import React, { Component } from 'react';
import { Alert } from 'react-native';
import { connect } from 'react-redux';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import get from 'lodash/get';
import find from 'lodash/find';
import listCompanies from '~/services/companies/index';

import ChooseStore from './ChooseStore';
import { request } from '~/services/companies';
import DataManager from '~/database/DataManager';
import setCurrentStoreItem from '~/store/setCurrentStoreItem/action';
import Sync from '~/utils/Sync';
import { DATA_ACCOUNT } from '~/constants/cache';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_CHOOSE_STORE, SCREEN_LOGIN, SCREEN_STORES } from '~/screens/screens';
import {
  ORDER_UPDATE_ORDER_RESPONSE_SUCCESS,
  ORDER_UPDATE_ORDER_SUCCESS,
  updateOrder,
} from '../../store/activeOrderFetch/updateOrder/action';
import setCurrentAccount from '../../store/setCurrentAccount/action';
import Login from '~/utils/Login';
import { syncCurrentStoreItem } from '../../store/setCurrentStoreItem/action';
import { SchemaModels } from '../../database/utils/DBUtils';
import setCurrentCompany from '../../store/setCurrentCompany/action';
import { setCompanyCustomerActiveFromCompany } from '~/utils/CompanyCustomer';
import { CART_ANONYMOUS, STORE_ITEM_ACTIVE } from '~/constants/cache';
import { STORE_ACTIVE_ORDER_FETCH, STORE_SET_CURRENT_NET_INFO } from '../../store/storesConstants';
import CartHelper from '../../components/CartHelper';
import CompanyController from '~/controllers/CompanyController';
import CartController from '~/controllers/CartController';
import AppConfig from '~/AppConfig';
import { toast } from 'react-toastify';
import ChooseStoreWeb from './ChooseStoreWeb';
import alert from '~/components/AlertWeb';

type Props = {
  componentId: string,
  currentNetInfo: any,
  chosenStore: {},
  dispatch: () => void,
  sOrderActive: any,
};

const TAG = 'ChooseStoreContainer';

class ChooseStoreContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.sync = new Sync();
    this.state = {
      requesting: false,
    };
    this.login = new Login();
    this.cartHelper = new CartHelper();

    this.props.navigation.setOptions({
      title: SCREEN_CHOOSE_STORE.title,
    })

  }

  onPressEnter = async data => {
    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      toast.error('Você está offline! Conecte a internet para se associar ao cliente');
      return;
    }

    this.setState({ requesting: true });
    const account = await CacheStore.get(DATA_ACCOUNT);
    if (account.isAnonymous) {
      this.setState({ requesting: false });
      alert(
        'Ops...',
        'É preciso fazer login para solicitar acesso!',
        [
          {
            text: 'Cancelar',
            style: 'cancel',
          },
          {
            text: 'Fazer login',
            onPress: () => this.props.navigation.navigate(SCREEN_LOGIN.name),
          },
        ],
        { cancelable: false },
      );
    } else {
      let responseBody = {};
      const { nin } = data;

      console.log('nin', nin);

      try {
        responseBody = await request(nin, this.props.company);
      } catch (error) {
        const errorMsg = get(error, 'data.message', null);
        console.log(TAG, error);
        this.showError(errorMsg);
        this.setState({ requesting: false });
        return;
      }

      if (responseBody && responseBody.success) {
        const { message, customer_id, company_id } = responseBody;

        this.showSuccess(message);

        try {
          const response = await listCompanies();
          const companies = get(response, ['source', 'data']) || [];
          const company = find(companies, { id: company_id });
          const customerCompanyAccount = find(company.customer_company_accounts, { customer_id });

          const companyController = new CompanyController();
          await companies.forEach((item) => {
            companyController.addCompany(item);

            item.customer_company_accounts.forEach((customerInside) => {
              companyController.addCustomerCompanyAccounts(customerInside);
              companyController.addCustomer(customerInside.customer);
            });
          });

          const cartAnonymous = await CacheStore.get(CART_ANONYMOUS);
          const products = get(cartAnonymous, 'products', []);
          if (
            cartAnonymous !== null &&
            products.length &&
            cartAnonymous.company_id === company_id &&
            customerCompanyAccount.approved
          ) {
            await this.cartHelper.associateAnonymousToUser(
              customerCompanyAccount,
              this.props.dispatch,
              SCREEN_CHOOSE_STORE,
            );
          }
        } finally {
          if (AppConfig.startFromStore) {
            NavigationHelper.pop(this.props.componentId);
          } else {
            setTimeout(async () => {
              // NavigationHelper.switchTab(this.props.componentId, 0);
              this.props.navigation.navigate('Home')
            }, 200);

            this.setState({ requesting: false });
          }
        }
      } else {
        const message = responseBody && responseBody.message ? responseBody.message : null;
        this.showError(message);
      }
    }
  };

  showError(msg) {
    const errorMsg = 'Erro ao solicitar acesso. Contate o suporte.';
    this.setState({ requesting: false });
    toast.error(msg || errorMsg);
    console.log(TAG, msg || errorMsg);
  }

  showSuccess(msg) {
    const successMsg = 'Solicitação enviada com sucesso';
    this.setState({ requesting: false });
    toast.success(msg || successMsg);
  }

  async associateAnonymousToUser(customerCompany) {
    let order = {};
    try {
      order = await this.login.associateAnonymousToUser(customerCompany);
    } catch (error) {
      throw error;
    }

    this.props.dispatch(updateOrder(order, SCREEN_CHOOSE_STORE.name));

    this.props.dispatch(setCurrentStoreItem(customerCompany));
    this.props.dispatch(syncCurrentStoreItem(customerCompany));

    return true;
  }

  async processLoginAnonymous() {
    let dataAccount = {};

    try {
      dataAccount = await this.login.processLoginAnonymous();
    } catch (erro) {
      throw erro;
    }

    this.props.dispatch(setCurrentAccount(dataAccount));

    const companyController = new CompanyController();
    const cartController = new CartController();
    const companies = await companyController.allCompany();
    let orderActive = await cartController.getCart(null, true);
    orderActive = orderActive[0];

    const currentCompany = companies.find(com => com.id === orderActive.company_id);
    const companyCustomer = currentCompany.customer_company_accounts[0];

    await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);
    this.props.dispatch(setCurrentCompany(currentCompany));
    this.props.dispatch(setCurrentStoreItem(companyCustomer));
    this.props.dispatch(syncCurrentStoreItem(companyCustomer));

    return true;
  }

  render() {
    // return <ChooseStore requesting={this.state.requesting} onPressEnter={this.onPressEnter} />;
    return <ChooseStoreWeb requesting={this.state.requesting} onPressEnter={this.onPressEnter} />;
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    currentNetInfo: getNetInfo(state),
  };
}

export default connect(mapStateToProps)(ChooseStoreContainer);
