import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { success, SALES_CONDITIONS_LIST_REQUEST } from './action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import PaymentTermController from '~/controllers/PaymentTermController';
import PaymentOptionController from '~/controllers/PaymentOptionController';
import PaymentCombineKeyController from '~/controllers/PaymentCombineKeyController';

const salesConditionsDataManager = () => {
  const promises = [];
  const paymentTermController = new PaymentTermController();
  const paymentOptionController = new PaymentOptionController();
  const paymentCombineKeyController = new PaymentCombineKeyController();

  const promisePaymentOptions = paymentOptionController.getAllPaymentOption();
  promises.push(promisePaymentOptions);

  const promisePaymentTerms = paymentTermController.getAllPaymentTerm();
  promises.push(promisePaymentTerms);

  const promisePaymentCombineKeys = paymentCombineKeyController.getAllPaymentCombineKey();
  promises.push(promisePaymentCombineKeys);

  return Observable.fromPromise(Promise.all(promises))
    .map((payload) => ({
      payment_options: payload[0],
      payment_terms: payload[1],
      payment_combine_keys: payload[2],
    }));
};

const saleConditionsList = (action$: any) =>
  action$
    .ofType(SALES_CONDITIONS_LIST_REQUEST)
    .mergeMap(() => salesConditionsDataManager()
      .flatMap(data => {
        return Observable.of(success(data));
      }));

export default combineEpics(saleConditionsList);
