// @flow

import React from 'react';
import { View, FlatList, Text } from 'react-native';
import type { Props as FlatListProps } from 'react-native/Libraries/Lists/FlatList';
import { Button } from '~/components';

import commonStyles from '~/appStyles';
import styles from './styles';

export type CarouselAction = {
  text: string,
  onPress: () => void,
};

type Props<ItemT> = {
  data: Array<ItemT>,
  renderItem: ({ item: ItemT, index: any }) => React.ReactNode,
  title?: string,
  action?: CarouselAction,
  containerStyle?: {},
  listStyle?: {},
  titleStyle?: {},
};

export type CarouselProps<ItemT> = Props<ItemT> & FlatListProps<ItemT>;

const Carousel = ({
  data,
  renderItem,
  title,
  action,
  titleStyle,
  containerStyle,
  listStyle,
  ...rest
}: CarouselProps) => (
    <View style={containerStyle}>
      <View style={[commonStyles.spaceBetween, styles.titleContainer]}>
        {!!title && <Text style={[styles.title, titleStyle]}>{title}</Text>}
        {!!action && <Button title={action.text} onPress={action.onPress} flat />}
      </View>
      <FlatList
        style={[listStyle, { marginBottom: 12, paddingLeft: 8 }]}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        data={data}
        refreshing={false}
        showsHorizontalScrollIndicator={false}
        horizontal
        {...rest}
      />
    </View>
  );

export default Carousel;
