// @flow

import React, { Component } from 'react';
import { View } from 'react-native';
import Carousel, { Pagination } from '~/modules-wrapper/react-native-snap-carousel';

import { itemWidth, sliderWidth } from './components/styles';
import { itemWidth as itemFullWidth, sliderWidth as sliderFullWidth } from './components/stylesFullWidth';
import BannerImage from './components/BannerImage';
import Images from '~/../assets/images';

import styles from './styles';
import stylesFullWidth from './stylesFullWidth';
import colors from '~/values/color';

export type BannersProps = {
  banners: Array<any>,
  fullWidth?: boolean,
  loop?: boolean,
  autoplay?: boolean,
  showFullImage?: boolean,
  autoplayDelay?: number,
  autoplayInterval?: number,
};

class Banners extends Component<BannersProps> {
  state = {
    slider1ActiveSlide: 0,
  };

  onPressItem(item) {
    const { onPressItem } = this.props;
    if (typeof onPressItem === 'function') {
      this.props.onPressItem(item);
    }
  }

  renderItemWithParallax = ({ item, index }) => {
    const { fullWidth, showFullImage } = this.props;
    const img = item.image || item.uri;

    return (<BannerImage
      resizeMode="contain"
      fullWidth={fullWidth}
      image={img ? { uri: img } : Images.iconNoImageBig}
      index={index}
      onPressItem={() => this.onPressItem(item)}
      showFullImage={showFullImage}
    />);
  };

  render() {
    const { banners, fullWidth, ...rest } = this.props;
    const { slider1ActiveSlide } = this.state;
    const bannersStyles = fullWidth ? stylesFullWidth : styles;

    return (
      <View>
        <Carousel
          onPressItem={this.props.onPressItem}
          ref={c => { this.slider1Ref = c; }}
          data={banners}
          renderItem={this.renderItemWithParallax}
          sliderWidth={fullWidth ? sliderFullWidth : sliderWidth}
          itemWidth={fullWidth ? itemFullWidth : itemWidth}
          firstItem={0}
          inactiveSlideScale={fullWidth ? 1 : 0.93}
          inactiveSlideOpacity={0.8}
          containerCustomStyle={bannersStyles.slider}
          contentContainerCustomStyle={bannersStyles.sliderContentContainer}
          onSnapToItem={(index) => this.setState({ slider1ActiveSlide: index })}
          {...rest}
        />
        <Pagination
          dotsLength={banners.length}
          activeDotIndex={slider1ActiveSlide}
          containerStyle={bannersStyles.paginationContainer}
          dotColor={colors.primaryColor}
          dotStyle={bannersStyles.paginationDot}
          dotContainerStyle={bannersStyles.dotContainerStyle}
          inactiveDotColor="rgba(0, 0, 0, 0.4)"
          inactiveDotOpacity={1}
          inactiveDotScale={0.6}
          carouselRef={this.slider1Ref}
          tappableDots={!!this.slider1Ref}
        />
      </View>
    );
  }
}

Banners.defaultProps = {
  autoPlay: false,
  loop: true,
  autoplayDelay: 500,
  autoplayInterval: 3000,
};

export default Banners;
