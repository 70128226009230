import React, { Component } from 'react';
import Orders from '~/screens/Orders/Orders';
import { SCREEN_ORDER_DETAILS, SCREEN_ORDERS } from '~/screens/screens';
import ComponentHelper from '~/components/ComponentHelper';
import { SchemaModels } from '~/database/utils/DBUtils';
import DataManager from '~/database/DataManager';
import SalesController from '~/controllers/SalesController';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { DATA_ACCOUNT } from '~/constants/cache';

type Props = {
  componentId: any,
};

const TAG = 'OrdersContainer';

class OrdersContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const salesController = new SalesController();
    const account = await CacheStore.get(DATA_ACCOUNT);
    if (account.isAnonymous) {
      this.setState({ loading: false });
    } else {
      salesController.getAllSales()
        .then(sales => {
          this.setState({ sales, loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(TAG, error);
        });
    }
  }

  doPressOrder = order => {
    this.props.navigation.push(SCREEN_ORDER_DETAILS.name, { order });
  };

  onPressOrder = ComponentHelper.debounce(this.doPressOrder);

  render() {
    const { sales, loading } = this.state;

    return <Orders orders={sales} loading={loading} onPressOrder={this.onPressOrder} />;
  }
}

export default OrdersContainer;
