/* @flow */

import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, Image, TouchableOpacity } from 'react-native';
import { colors, defaultShadow } from '~/values';

type Props = {
  onPress: () => void,
  title?: string,
  iconButton?: boolean,
  style?: {},
  containerStyle?: {},
  big?: boolean,
  disabled?: boolean,
  small?: boolean,
  flat?: boolean,
  outline?: boolean,
  iconLeft?: any,
  iconRight?: any,
  transparent?: boolean,
  noUpperCase?: boolean,
  titleColor?: string,
}

const styles = StyleSheet.create({
  outsideContainer: {
    borderRadius: 50,
    overflow: 'hidden',
    margin: 6,
  },
  outsideContainer_flat: {
    borderRadius: 0,
  },
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primaryColor,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 50,
    elevation: 2,
  },
  container_big: {
    backgroundColor: colors.primaryColor,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  container_small: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  container_flat: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  container_outline: {
    backgroundColor: 'transparent',
    borderColor: colors.primaryColor,
    borderWidth: 1,
    elevation: 0,
  },
  disabled: {
    backgroundColor: colors.grayContainerDark,
  },
  title_disabled: {
    color: colors.grayMid,
    fontWeight: '400',
  },
  title_flat: {
    color: colors.primaryColor,
    fontWeight: '800',
  },
  title_big: {
    fontSize: 16,
  },
  title_small: {
    fontSize: 13,
  },
  title: {
    fontWeight: '700',
    color: '#FFF',
  },
  icon: {
    tintColor: '#fff',
    width: 20,
    height: 20,
    marginHorizontal: 8,
  },
});

function getIconPadding(big, small) {
  if (big) return 16;
  if (small) return 4;
  return 8;
}

class Button extends Component<Props, void> {
  static defaultProps = {
    title: 'Continuar',
  };
  state = {};

  onPress = () => {
    // if (Platform.OS === 'android') {
    //   setTimeout(() => {
    //
    //   }, 150);
    // } else if (this.props.onPress) this.props.onPress();
    if (this.props.onPress && !this.props.disabled) this.props.onPress();
  };

  render() {
    const {
      title,
      big,
      titleColor,
      noUpperCase,
      iconButton,
      disabled, small, flat, style, outline, transparent,
      containerStyle, iconLeft, iconRight,
    } = this.props;

    return (
      <View style={[
        styles.outsideContainer,
        flat ? styles.outsideContainer_flat : {},
        containerStyle,
      ]}
      >
        <TouchableOpacity onPress={this.onPress}>
          <View style={[
            styles.container,
            big ? styles.container_big : {},
            small ? styles.container_small : {},
            flat ? styles.container_flat : {},
            disabled ? styles.disabled : {},
            flat && disabled ? { backgroundColor: 'transparent' } : {},
            transparent ? { backgroundColor: 'transparent' } : {},
            outline ? styles.container_outline : {},
            outline && big ? { borderWidth: 2 } : {},
            iconButton ? { paddingHorizontal: getIconPadding(big, small) } : {},
            style,
          ]}
          >
            { iconLeft ?
              <Image
                style={[
                  styles.icon,
                  flat ? { tintColor: colors.primaryColor } : {},
                  titleColor ? { tintColor: titleColor } : {},
                  iconButton ? { marginHorizontal: 0 } : {},
                  ]
                }
                source={iconLeft}
              />
              :
              null
            }
            {
              title && !iconButton &&
              <Text
                ellipsize="tail"
                numberOfLines={1}
                style={[
                  styles.title,
                  flat || outline ? styles.title_flat : {},
                  big ? styles.title_big : {},
                  small ? styles.title_small : {},
                  disabled ? styles.title_disabled : {},
                  titleColor ? { color: titleColor } : {},
                ]}
              >
                {noUpperCase ? (title || 'Entrar') : (title.toUpperCase() || 'ENTRAR')}
              </Text>
            }
            { iconRight !== undefined ?
              <Image
                style={[
                  styles.icon,
                  flat ? { tintColor: colors.primaryColor } : {},
                  titleColor ? { tintColor: titleColor } : {},
                  iconButton ? { margin: 0 } : {},
                ]
                }
                source={iconRight}
              />
              :
              null
            }
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default Button;
