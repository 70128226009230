/* @flow */

/**
 * @return {boolean}
 */
 export default function CNPJCheck(value) {
  let exp = /\.|\-|\//g;
  value = value.toString().replace(exp, '');

  let isEquals = true;
  for (let i = 0; i < value.length - 1; i++) {
    if (value.charAt(i) !== value.charAt(i + 1)) {
      isEquals = false;
      break;
    }
  }

  if (!isEquals) {
    let cnpjChecker = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let numbers = value.substring(0, 12);
    let digits = value.substring(12);
    let sum = 0;
    for (let i = 12; i > 0; i--) {
      sum += numbers.charAt(12 - i) * cnpjChecker[12 - i];
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result.toString() !== digits.charAt(0)) {
      return false;
    }
    cnpjChecker = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    numbers = value.substring(0, 13);
    sum = 0;
    for (i = 13; i > 0; i--) {
      sum += numbers.charAt(13 - i) * cnpjChecker[13 - i];
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    return result.toString() === digits.charAt(1);
  } else {
    return false;
  }
}