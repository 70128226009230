import React, { Component } from 'react';
import { View, FlatList } from 'react-native';

import { Category } from '~/components';

import styles from './styles';

type Props = {
  categories: [],
  onPressCategory: (code: string) => void,
};

class Categories extends Component<Props> {
  onPressCategory = (code) => () => {
    this.props.onPressCategory(code);
  };

  keyExtractor = (item, index) => `${item.category_code}-${index}`;

  itemSeparator = () => <View style={styles.separator} />;

  renderItem = ({ item }) => (
    <Category
      name={item.description}
      onPress={this.onPressCategory(item)}
    />
  );

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          data={this.props.categories}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderItem}
          removeClippedSubviews
          ItemSeparatorComponent={this.itemSeparator}
        />
      </View>
    );
  }
}

export default Categories;
