import React, { Component } from 'react';
import { View, Alert, Platform } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_MAP } from '~/screens/screens';
import axios from 'axios';
import AccountController from '~/controllers/AccountController';
import ListAddress from './ListAddress';

import Geolocation from '@react-native-community/geolocation';
import { StackActions } from '@react-navigation/native';

class ListAddressContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      dataAddr: [],
      finalLocation: {},
      requestData: false,
    };
  }

  async componentDidMount() {
    const isAnonymous = await AccountController.isAnonymous();
    if (isAnonymous) {
      this.props.navigation.dispatch(StackActions.popToTop());
    }
    if (!isAnonymous && Platform.OS === 'ios') {
      Geolocation.requestAuthorization();
    }
  }

  getUserPosition = () => {
    console.log('DATA GETUSERPOS');
    Geolocation.getCurrentPosition(async (position) => {
      const data = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyDRvXD60ZyXFCdcXee6NmDSCRRYmoiVcKU`);
      const leength = data.data.results[0].address_components.length;
      console.log('DATA ADDR: ', data);
      let location = {};
      location = {
        ...location,
        latitude: data.data.results[0].geometry.location.lat,
        longitude: data.data.results[0].geometry.location.lng,
      };
      console.log('DATA LOCATION: ', location);
      for (let i = 0; i < leength; i += 1) {
        if (data.data.results[0].address_components[i].types[0] === 'street_number') {
          location = {
            ...location,
            street_number: data.data.results[0].address_components[i].long_name,
          };
        }
        if (data.data.results[0].address_components[i].types[0] === 'route') {
          location = {
            ...location,
            street: data.data.results[0].address_components[i].short_name,
          };
        }
        if (data.data.results[0].address_components[i].types[1] === 'sublocality' || data.data.results[0].address_components[i].types[2] === 'sublocality_level_1') {
          location = {
            ...location,
            district: data.data.results[0].address_components[i].long_name,
          };
        }
        if (data.data.results[0].address_components[i].types[0] === 'administrative_area_level_2') {
          location = {
            ...location,
            city: data.data.results[0].address_components[i].long_name,
          };
        }
        if (data.data.results[0].address_components[i].types[0] === 'administrative_area_level_1') {
          location = {
            ...location,
            state_city: data.data.results[0].address_components[i].short_name,
          };
        }
        if (data.data.results[0].address_components[i].types[0] === 'country') {
          location = {
            ...location,
            country: data.data.results[0].address_components[i].long_name,
          };
        }
        if (data.data.results[0].address_components[i].types[0] === 'postal_code') {
          location = {
            ...location,
            postal_code: data.data.results[0].address_components[i].long_name,
          };
        }
      }
      console.log('DATA LOCATION FINAL: ', location);
      if (Object.keys(location).length !== 0) {
        console.log('DATA CAIU AQUI NO IF');
        this.props.navigation.navigate(
          SCREEN_MAP.name,
          {
            place: [
              [
                {
                  description: '',
                  matched_substrings: [
                    {
                      length: 0,
                      offset: 0,
                    },
                  ],
                  place_id: '',
                  reference: '',
                  structured_formatting: {
                    main_text: '',
                    main_text_matched_substrings: [
                      {
                        length: 0,
                        offset: 0,
                      },
                    ],
                    secondary_text: '',
                  },
                  terms: [
                    {
                      offset: 0,
                      value: '',
                    },
                    {
                      offset: 0,
                      value: '',
                    },
                    {
                      offset: 0,
                      value: '',
                    },
                    {
                      offset: 0,
                      value: '',
                    },
                    {
                      offset: 0,
                      value: '',
                    },
                  ],
                  types: [
                    '',
                    '',
                  ],
                },
                {
                  address_components: [
                    {
                      long_name: '0',
                      short_name: '0',
                      types: [
                        '',
                      ],
                    },
                    {
                      long_name: '',
                      short_name: '',
                      types: [
                        '',
                      ],
                    },
                    {
                      long_name: '',
                      short_name: '',
                      types: [
                        '',
                        '',
                        '',
                      ],
                    },
                    {
                      long_name: '',
                      short_name: '',
                      types: [
                        '',
                        '',
                      ],
                    },
                    {
                      long_name: '',
                      short_name: '',
                      types: [
                        '',
                        '',
                      ],
                    },
                    {
                      long_name: '',
                      short_name: '',
                      types: [
                        '',
                        '',
                      ],
                    },
                  ],
                  adr_address: '',
                  icon: '',
                  name: '',
                  place_id: '',
                  reference: '',
                  types: [
                    '',
                  ],
                  url: '',
                  utc_offset: 0,
                  vicinity: '',
                },
              ],
              {
                geometry: {
                  location: {
                    lat: location.latitude,
                    lng: location.longitude,
                  },
                  viewport: {
                    northeast: {
                      lat: 0,
                      lng: 0,
                    },
                    southwest: {
                      lat: 0,
                      lng: 0,
                    },
                  },
                },
              },
            ],
          },
        );
      } else {
        console.log('CAIU AQUI NO ELSE');
        this.props.navigation.navigate(SCREEN_MAP.name);
      }
    },
    (error) => Alert.alert('Error', JSON.stringify(error)),
    // { enableHighAccuracy: true, timeout: 5000, maximumAge: 5000 },
    );
  };

  findCoordinates = async () => {
    console.log('DATA FIND COORD');
    this.setState({ requestData: true });
    this.getUserPosition();
  };

  render() {
    const { dataAddr, finalLocation, requestData } = this.state;
    return (
      <View>
        <ListAddress
          componentId={this.props.componentId}
          listAddr={dataAddr}
          finalLocation={finalLocation}
          findCoordinates={this.findCoordinates}
          requestData={requestData}
          {...this.props}
        />
      </View>
    );
  }
}

export default ListAddressContainer;
