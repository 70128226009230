// @flow

import React from 'react';
import { Image, Text, View, TouchableOpacity } from 'react-native';
import { Touchable } from 'react-native-web';
import Images from '~/../assets/images';

import styles from './styles';

export type AlertBuyingForProps<ItemT> = {
    storeName: string,
    onPress: (data: ItemT) => void,
    showIcon?: boolean,
    loadData: boolean,
};

const AlertBuyingFor = ({
  storeName, onPress, showIcon, loadData,
} : AlertBuyingForProps) => {
  if (!loadData && storeName) {
    return (
      <TouchableOpacity
        foreground={()=>{}}
        onPress={() => onPress()}
      >
        <View style={styles.container}>
          <Image
            style={styles.icon}
            source={Images.iconStore}
          />
          <Text style={styles.text}>
          Comprando para <Text style={styles.textStore}>{storeName}</Text>
          </Text>
          {
        showIcon && <Image
          style={styles.icon}
          source={Images.iconChevronRight}
        />
      }
        </View>
      </TouchableOpacity>
    );
  }
  return null;
};

AlertBuyingFor.defaultProps = {
  showIcon: true,
};

export default AlertBuyingFor;
