import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { Section, Quantity } from '~/components';
import BannerImage from '~/components/Banners/components/BannerImage';

import styles from './styles';

type Props = {
  showQuantity: boolean,
  promotion: Object<any>,
  onAddBonusInOrder: (quantity: number) => void,
};

class PromotionDetail extends Component<Props> {
  onAddBonusInOrder = (quantity: number) => {
    this.props.onAddBonusInOrder(quantity);
  };

  render() {
    const { promotion, showQuantity } = this.props;
    const {
      image, title, description, quantity,
    } = promotion;

    return (
      <ScrollView>
        <View style={styles.container}>
          <BannerImage
            image={{
              uri: image,
            }}
            index={0}
            fullWidth
            resizeMode="contain"
            showFullImage
          />
          <Section>
            <Text style={styles.name}>{title}</Text>
            <View style={styles.quantityContainer}>
              <Quantity
                quantity={quantity}
                disabled={!showQuantity}
                onChangeQuantity={this.onAddBonusInOrder}
              />
            </View>
          </Section>
          <Section title="Descrição">
            <Text style={styles.description}>{description}</Text>
          </Section>
        </View>
      </ScrollView>
    );
  }
}

export default PromotionDetail;
