import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    minHeight: 40,
    backgroundColor: colors.primaryColor,
  },
  containerBreadcrumb: {
    minHeight: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.5,
  },
  containerBreadcrumb__active: {
    opacity: 1,
  },
  icon: {
    tintColor: '#FFF',
    width: 18,
    height: 18,
  },
  text: {
    color: '#FFF',
    marginHorizontal: 6,
    fontSize: 13,
    flex: 1,
  },
  text__active: {
    fontWeight: '600',
  },
});

export default styles;
