import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { isNaN } from 'underscore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LIKED_PRODUCTS } from '~/constants/cache';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

export const parseOnlyProduct = product => ({
  ...product,
  id: product.id,
  code: product.product_code,
  type: product.type,
  name: product.name,
  description: product.description,
  ean: product.ean,
  quantity: product.quantity || 0,
  multiple: product.volume_factor,
  price: product.unit_price || product.price || product.min_price,
  minPrice: product.min_price,
  stock: product.stock,
  liked: product.liked,
  notifyMe: product.notify_me,
  attributesNames: product.attributes ? Array.from(product.attributes) : [],
  children: product.children
    ? Array.from(product.children).map(child => ({
      id: child.id,
      type: child.type,
      price: child.price,
      product_code: child.product_code,
      multiple: child.volume_factor,
      stock: child.stock,
      liked: child.liked,
      attributesValues: Array.from(child.attributes_values || []),
    }))
    : [],
  taxes: undefined,
  image: undefined,
});

export const getFavoritesByStoreActive = async () => {
  const activeStore = await CacheStore.get('STORE_ITEM_ACTIVE');
  if (activeStore) {
    const { company_id = '', customer_id = '' } = activeStore;
    if (company_id && customer_id) {
      const likedCache = `${LIKED_PRODUCTS}_${company_id}_${customer_id}`;
      return likedCache;
    }
  }
  return '';
};

export async function setLikedProduct(product) {
  const likedByStore = await getFavoritesByStoreActive();
  const favorites = await AsyncStorage.getItem(likedByStore);
  const parsedFavorites = favorites ? JSON.parse(favorites) : [];
  const favorite = parsedFavorites.length > 0 ?
    parsedFavorites.find(({ id }) => id === product.id) : {};
  const liked = favorite && favorite.liked ? favorite.liked : false;
  return { ...product, liked };
}

export const parseProducts = products => products.map(product => parseOnlyProduct(product));

export function mergeOnlyProductWithOrder(product, orderProducts) {
  let productInOrder = find(orderProducts, { id: product.id });
  if (!productInOrder) {
    productInOrder = find(orderProducts, { product_code: product.product_code });
  }

  let { price, quantity } = product;
  let price_base = 0;
  if (productInOrder) {
    price = productInOrder.unit_price;
    price_base = productInOrder.price_base;
    quantity = productInOrder.quantity;
  } else {
    price_base = price;
  }

  const stockProd = product.stock && product.stock > 0 ? product.stock : 0;

  return {
    ...product,
    quantity,
    price,
    price_base,
    stock: stockProd,
  };
}

export function mergeOnlyProductChildrenWithOrder(product, orderProducts) {
  let quantity = 0;

  if (isEmpty(product)) {
    return product;
  }

  if (product) {
    const productInOrder = orderProducts.find(p => p.id === product.id);
    if (productInOrder) {
      quantity = productInOrder.quantity;
    }
  }

  return { ...product, quantity };
}

export function mergeProductsWithOrder(products, orderProducts) {
  if (!products) {
    return [];
  } else if (!orderProducts) {
    return products;
  }

  return products.map(product => mergeOnlyProductWithOrder(product, orderProducts));
}

export function sortByProductsNoStock(productsArray) {
  const productsNoStock = filter(productsArray, ({ stock }) => stock === 0);
  const productsWithStock = filter(productsArray, ({ stock }) => stock !== 0);
  const sortProductsWithStock = orderBy(productsWithStock, ['name'], ['asc']);

  return [...sortProductsWithStock, ...productsNoStock];
}

export function mergeProductsOrderWithProducts(productsOrder, products) {
  if (!products) {
    return [];
  }

  return productsOrder.map(product => {
    const productFound = products.find(p => p.id === product.id);

    if (productFound) {
      const {
        type, description, multiple, minPrice, stock, liked,
      } = productFound;
      return {
        ...product,
        type,
        description,
        multiple,
        minPrice,
        stock,
        liked,
      };
    }

    return product;
  });
}

export function mergeProductsOrderWithProductsMatriz(productsOrder, products) {
  const productsChildres = [];

  productsOrder.forEach(product => {
    products.forEach(prod => {
      const productFound = prod.children.find(p => p.product_code === product.id);
      if (productFound) {
        const {
          id, type, price, stock, liked, attributesValues,
        } = productFound;
        const {
          name, description, multiple, minPrice,
        } = prod;
        const p = {
          ...product,
          id,
          name,
          type,
          description,
          multiple,
          price,
          minPrice,
          stock,
          liked,
          attributesValues,
        };

        productsChildres.push(p);
      }
    });
  });

  return productsChildres;
}

export function validateQuantity(quantity, multiple, minQuantity, maxQuantity) {
  let qty = quantity;

  if (maxQuantity && qty > maxQuantity) {
    qty = maxQuantity;
  }

  if (qty < minQuantity) {
    qty = minQuantity;
    if (multiple > minQuantity) {
      qty = multiple;
    }
  }

  if (qty !== 0 && multiple !== 0) {
    const qtyDiv = qty % multiple;
    if (!isNaN(qtyDiv) && qtyDiv !== 0) {
      qty = Math.trunc(qty / multiple) * multiple + multiple;
    }
  }

  return qty;
}
