// @flow

import React, { Component } from 'react';
import { View, TextInput, Image, TouchableOpacity } from 'react-native';
import { Touchable } from 'react-native-web';

import styles from './styles';
import Images from '~/../assets/images';

type SearchBarProps = {
  placeholder?: string,
  onChangeText?: string,
  onPressMicrophone?: () => void,
};

const Icon = ({ icon, onPress }) => (
  <View style={styles.rootIconContainer}>
    <TouchableOpacity
      foreground={()=> {}}
      onPress={onPress}
    >
      <View style={styles.iconContainer}>
        <Image
          style={styles.icon}
          source={icon}
        />
      </View>
    </TouchableOpacity>
  </View>
);

class SearchBar extends Component<SearchBarProps> {
  static defaultProps = {
    onChangeText: () => {},
  };

  state = {
    value: '',
  };

  onChangeText = (value: string) => {
    this.setState({ value });
    this.props.onChangeText(value);
  };

  onPressClose = () => {
    this.setState({ value: '' });
    this.props.onChangeText('');
  };

  onBlur = () => {
    if (this.input) {
      this.input.blur();
    }
  };

  render() {
    const { placeholder, onPressMicrophone, ...rest } = this.props;
    const { value } = this.state;
    return (
      <View style={styles.container}>
        <Icon icon={Images.iconSearch} />
        <TextInput
          ref={ref => {
            this.input = ref;
          }}
          style={styles.textInput}
          value={value}
          placeholder={placeholder || 'Busque por produtos, marcas...'}
          {...rest}
          onChangeText={this.onChangeText}
        />
        {
          onPressMicrophone && !value
            ? (
              <Icon
                onPress={onPressMicrophone}
                icon={Images.iconMicrophone}
              />
            )
            : (
              !!value &&
              <Icon
                onPress={this.onPressClose}
                icon={Images.iconClose}
              />
            )
        }
      </View>
    );
  }
}


export default SearchBar;
