import { SchemaModels } from '~/database/utils/DBUtils';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import AppConfig from '../AppConfig';
import AsyncStorage from '@react-native-async-storage/async-storage';

const TAG = 'DataManager';

export default class DataManager {
  getConfigValue(key) {
    // TODO: buscar do SettingRepository após implementado
    return AppConfig[key];
  }

  insertInCache(schemaModel, data) {
    return new Promise((resolve, reject) => {
      CacheStore.set(SchemaModels.properties[schemaModel].name, data)
        .then(() => {
          resolve(data);
        })
        .catch(error => {
          console.log(TAG, error);
          reject(error);
        });
    });
  }

  clearInCache(schemaModel) {
    return new Promise((resolve, reject) => {
      CacheStore.remove(SchemaModels.properties[schemaModel].name)
        .then(() => {
          resolve();
        })
        .catch(error => {
          console.log(TAG, error);
          reject(error);
        });
    });
  }

  async clearAllInCache() {
    const allKeys = await AsyncStorage.getAllKeys();
    const promise = new Promise((resolve, reject) => {
      if (!allKeys.length) {
        resolve();
      }

      CacheStore.flush()
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject();
        });
    });

    return await promise;
  }
}
