import React, { Component } from 'react';
import { Modal, Dimensions, TouchableWithoutFeedback, View, Text, Image, ActivityIndicator, TouchableOpacity } from 'react-native';
import axios from 'axios';
import { Touchable } from 'react-native-web';
// import { Navigation } from 'react-native-navigation';
import Images from '~/../assets/images';
import { colors } from '~/values';
import styles from './styles';

import { URL_BASE, getConfig } from '~/services/index';
import RegisterAddressController from '~/controllers/RegisterAddressController';
import { SCREEN_COMPLETE_ADDRESS } from '~/screens/screens';
// import NavigationHelper from '~/screens/NavigationHelper';
import alert from '~/components/AlertWeb';
import { StackActions } from '@react-navigation/native';

type Props = {
  addr: any,
};

const deviceHeight = Dimensions.get('window').height;

class ModalAddress extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sendData: false,
    };
    this.registerAddrController = new RegisterAddressController();
  }

  show = () => {
    this.setState({ show: true });
  }

  close = () => {
    this.setState({ show: false });
  }

  removeLocation = async (item) => {
    let apiRes = null;
    const config = await getConfig();

    alert(
      'Olá!',
      'Deseja remover esse endereço?',
      [
        {
          text: 'OK',
          onPress: async () => {
            this.setState({ sendData: true });
            try {
              apiRes = await axios.delete(`${URL_BASE}/address/${item.id}/`, config);
              console.log('API RES DELETE: ', apiRes);
              if (apiRes !== null && apiRes.status === 200) {
                await this.registerAddrController.deleteRegisterAddress(item.id);
                this.setState({ sendData: false });
                // NavigationHelper.pop(this.props.componentId);
                this.props.navigation.dispatch(StackActions.popToTop());
              }
            } catch (error) {
              alert(
                `Erro: ${error.response.status}`,
                'Contate o suporte',
                [
                  {
                    text: 'OK',
                    onPress: () => {
                    // NavigationHelper.switchTab(this.props.componentId, 0);
                      this.setState({ sendData: false });
                    },
                  },
                ],
                { cancelable: false },
              );
            }
          }
        },
      ],
      { cancelable: false },
    );

    /*alert(
      'Olá!',
      'Deseja remover esse endereço?',
      [
        {
          text: 'Cancelar',
          onPress: null,
        },
        {
          text: 'OK',
          onPress: async () => {
            this.setState({ sendData: true });
            try {
              apiRes = await axios.delete(`${URL_BASE}/address/${item.id}/`, config);
              console.log('API RES DELETE: ', apiRes);
              if (apiRes !== null && apiRes.status === 200) {
                await this.registerAddrController.deleteRegisterAddress(item.id);
                this.setState({ sendData: false });
                // NavigationHelper.pop(this.props.componentId);
                this.props.navigation.dispatch(StackActions.popToTop());
              }
            } catch (error) {
              alert(
                `Erro: ${error.response.status}`,
                'Contate o suporte',
                [
                  {
                    text: 'OK',
                    onPress: () => {
                    // NavigationHelper.switchTab(this.props.componentId, 0);
                      this.setState({ sendData: false });
                    },
                  },
                ],
                { cancelable: false },
              );
            }
          },
        },
      ],
      { cancelable: false },
    );*/
  }

  editLocation = (item) => {
    console.log('THIS PROPS: ', this.props);
    this.props.navigation.navigate(SCREEN_COMPLETE_ADDRESS.name, { ...this.props, finalLocation: {
      id: item.id,
      account_id: item.account_id,
      street_number: item.street_number.toString(),
      street: item.street,
      district: item.district,
      city: item.city,
      state_city: item.state_city,
      country: item.country,
      postal_code: item.postal_code,
      complement: item.complement,
      reference_point: item.reference_point,
      latitude: JSON.parse(item.latitude),
      longitude: JSON.parse(item.longitude),
      created_at: item.created_at,
      updated_at: item.updated_at,
      deleted_at: item.deleted_at,
      surname: item.surname,
      kind: item.kind,
    } });
    this.close();
  }

  renderOutsideTouchable(onTouch) {
    const view = <View style={{ flex: 1, width: '100%' }} />;
    if (!onTouch) return view;

    return (
      <TouchableWithoutFeedback onPress={onTouch} style={{ flex: 1, width: '100%' }}>
        {view}
      </TouchableWithoutFeedback>
    );
  }

  renderBottomModal = () => {
    const {
      street_number, street, district, city, state_city, country, postal_code, select, complement, reference_point
    } = this.props.addr;
    return (
      this.state.sendData ?
        <ActivityIndicator size="large" color={colors.primaryColor} /> :
        <View>
          <View style={{ alignItems: 'center' }}>
            <Text style={styles.title}>
              {city}
            </Text>
            <Text style={styles.description}>{street}, {street_number} - {district}</Text>
          </View>
          <View style={styles.boxContainer}>
            <TouchableOpacity onPress={() => { this.removeLocation(this.props.addr); }} style={styles.appButtonContainer}>
              <View style={styles.div}>
                <Image
                  source={Images.iconTrash}
                  style={{ tintColor: colors.grayDark, height: 25, width: 25 }}
                />

                <Text style={styles.iconText}>Excluir</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.editLocation(this.props.addr); }} style={styles.appButtonContainer}>
              <View style={styles.div}>
                <Image
                  source={Images.iconEdit}
                  style={{ tintColor: colors.grayDark, height: 18, width: 18 }} 
                />
                <Text style={styles.iconText}>Editar</Text>
              </View>
            </TouchableOpacity>
          </View>

          <TouchableOpacity onPress={this.close}>
            <Text style={styles.cancel}>Cancelar</Text>
          </TouchableOpacity>
        </View>
    );
  }

  render() {
    const { show } = this.state;
    return (
      <Modal
        animationType="fade"
        transparent
        visible={show}
        onRequestClose={this.close}
        ref={this.props.popupRef}
      >
        <TouchableWithoutFeedback onPress={this.close} style={{ flex: 1, width: '100%' }}>
          <View
            style={{
          flex: 1,
          backgroundColor: '#000000AA',
          justifyContent: 'flex-end',
        }}
          >
            {this.renderOutsideTouchable()}

            <View style={{
            backgroundColor: '#FFFFFF',
            width: '100%',
            borderRadius: 8,
            paddingHorizontal: 10,
            maxWidth: deviceHeight * 0.7,
            marginBottom: 30,
            left: "20%",
          }}
            >

              {this.renderBottomModal()}
            </View>

          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

export default ModalAddress;
