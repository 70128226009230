// @flow
import React from 'react';
import { View, Text } from 'react-native';

import styles from '~/components/Product/styles';
import Banners from '../../../../components/Banners';

function ProductUnavailable() {
  return (
    <View>
      <Banners fullWidth banners={[
        require('~/../assets/img/no_image_big.png')
      ]} />
        <View style={styles['container__linear']}>
          <View style={styles['infoContainer__linear']}>
            <View style={styles['nameLikeContainer__linear']}>
              <Text
                numberOfLines={2}
                style={[styles['name__linear'], { fontWeight: '600' }, { textAlign: 'center' }]}
              >
                Produto Indisponível
              </Text>
            </View>
          </View>
        </View>
    </View>
  );
}

export default ProductUnavailable;
