import axios from 'axios';

import { Platform } from 'react-native';
import { URL_BASE } from '../index';
import DeviceInfo from 'react-native-device-info';
import AppConfig from '~/AppConfig';
import { v4 as uuid } from 'uuid';

const TAG = 'Login Anonymous';

export default function loginAnonymous() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-Application-code': AppConfig.appCode,
      'Cache-Control': 'no-cache',
    },
  };

  return new Promise((resolve, reject) => {
    try {
      const application_code = AppConfig.appCode;
      const device_uuid = Platform.OS === 'web' ? uuid() : DeviceInfo.getUniqueId();
      axios.post(`${URL_BASE}/auth/anonymous/`, { device_uuid, application_code }, config)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          console.log(TAG, error);
          reject(error.response);
        });
    } catch (error) {
      console.log(TAG, error);
      reject(error);
    }
  });
}
