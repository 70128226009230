import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'sales conditions list';

export default async function list(cartId) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  if (!cartId) {
    return null;
  }

  return new Promise((resolve) =>
    axios
      .get(`${URL_BASE}/cart/${company}/detail/${cartId}/sale_conditions/`, config)
      .then(response => resolve(response.data))
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));
}
