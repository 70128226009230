import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import { Touchable } from 'react-native-web';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import Images from '~/../assets/images';
import { colors } from '~/values';

import { Location } from '~/components';
import { SCREEN_ADDRESS } from '../../screens';
import styles from './styles';

import RegisterAddressController from '~/controllers/RegisterAddressController';

type Props = {
    componentId: string,
    finalLocation: any,
    listAddr: any,
    findCoordinates: any,
    requestData: boolean,
};

class ListAddress extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      dataAddr: [],
      addrSelect: '',
    };
    this.registerAddrController = new RegisterAddressController();
  }

  async componentDidMount() {
    const addrSelect = await CacheStore.get('ADDRSELECT');
    this.setState({ addrSelect });
    this.getData();
    console.log('DATA PROPS LISTADDR VIEW: ', this.props);
  }


  onPress = () => {
    /*Navigation.push(this.props.componentId, {
      component: {
        name: SCREEN_ADDRESS.name,
        passProps: {
          text: '',
        },
        options: {
          topBar: {
            title: {
              text: 'Pesquise pelo seu endereço',
            },
          },
        },
      },
    });*/
    console.log('THIS PROPS LISTADDR VIEW: ', this.props);
    this.props.navigation.navigate(SCREEN_ADDRESS.name, {...this.props});
  };

  getData = async () => {
    const data = await this.registerAddrController.getRegisterAddress();
    this.setState({ dataAddr: data });
  }

  render() {
    return (
      <View style={{ height: '100%' }}>
        <View style={{ backgroundColor: '#fff' }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={this.onPress}
              style={{
                height: 35,
                width: '80%',
                marginTop: 3,
                marginLeft: '10%',
                borderWidth: 1,
                backgroundColor: '#e5e5e5',
                //borderRadius: 3,
                borderColor: colors.gray,
                // elevation: 8,
                // backgroundColor: colors.primaryColor,
                borderRadius: 10,
                // paddingVertical: 10,
                // paddingHorizontal: 12
        }}
            >
              <View style={{ bottom: 7 }}>
                <Image
                  source={Images.iconSearch}
                  style={{ width: 20, height: 16,  tintColor: colors.primaryColor, top: 15, left: 5 }} />
                <Text style={{ color: '#b2b2b2', textAlign: 'center', fontSize: 16, marginLeft: 25 }}>
                  {'Endereço e número'}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        {/*<TouchableOpacity
          style={{ backgroundColor: '#fff' }}
          onPress={this.props.findCoordinates}>
          <View style={{ flexGrow: 1 }}>
            <View style={{ marginLeft: 20 }}>
              <View style={styles.icon}>
                <Image source={Images.iconGpsLocation} style={{ width: 20, height: 20, marginLeft: 10, marginTop: 5, tintColor: colors.grayMid }} />
              </View>
              <View style={styles.item}>
                <View style={styles.content}>
                  <View style={{ flexDirection: 'row' }}>
                    {this.props.requestData ? <Text style={{ marginRight: 5, fontSize: 18, fontWeight: 'bold' }}>Carregando...</Text> : this.props.finalLocation && typeof this.props.finalLocation.latitude !== 'undefined' ? <Text style={{ marginRight: 5, fontSize: 18, fontWeight: 'bold' }}>Completar Endereço</Text> : <Text style={{ marginRight: 5, fontSize: 18, fontWeight: 'bold' }}>Usar localização atual</Text>}
                  </View>
                  {this.props.requestData ? <Text style={{ marginRight: 5, fontSize: 16 }}>Carregando...</Text> : this.props.finalLocation && typeof this.props.finalLocation.city !== 'undefined' ? <Text style={{ marginRight: 5, fontSize: 16 }}>{`${this.props.finalLocation && this.props.finalLocation.street} - ${this.props.finalLocation && this.props.finalLocation.district}, ${this.props.finalLocation && this.props.finalLocation.city} - ${this.props.finalLocation && this.props.finalLocation.state_city}`}</Text> : <Text>Ativar localização</Text>}
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity> */}
        <View style={{ flex: 1 }}>
          <Location dataAddr={this.state.dataAddr || []} addrSelect={this.state.addrSelect} {...this.props} />
        </View>
      </View>
    );
  }
}

export default ListAddress;
