import React, { Component } from 'react';
import { Alert } from 'react-native';
// import { Navigation } from 'react-native-navigation';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import isEmpty from 'lodash/isEmpty';

import PromotionDetail from './PromotionDetail';
import { SCREEN_PROMOTION_DETAIL } from '../screens';
import { addBonusOrder, ORDER_ADD_BONUS_FAILURE } from '../../store/activeOrderFetch/addBonus/action';
import { STORE_ACTIVE_ORDER_FETCH, STORE_PRODUCTS_FETCH } from '../../store/storesConstants';
import type { IProduct } from '../../entities/Product';
import listProducts from '../../store/productsFetch/action';
import activeOrder from '../../store/activeOrderFetch/activeOrder/action';

type Props = {
  componentId: string,
  promotion: Object<any>,
  sOrderActive: Object<any>,
  sAddBonusInOrder: Object<any>,
  sProducts: Array<IProduct>,
  dispatch: () => void,
};

class PromotionDetailContainer extends Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { sProducts, sOrderActive } = this.props;
    const { products } = sProducts.payload;
    if (!products) {
      this.props.dispatch(listProducts(0));
    }

    if (isEmpty(sOrderActive.activeOrder.payload)) {
      this.props.dispatch(activeOrder());
    }

    const { promotion } = this.props.route.params
    this.props.navigation.setOptions({
      title: promotion.title,
    })
  }

  componentDidUpdate({ sOrderActive }) {
    (() => {
      if (sOrderActive.type === this.props.sOrderActive.type) return;
      if (this.props.sOrderActive.type !== ORDER_ADD_BONUS_FAILURE) return;
      Alert.alert('', this.props.sOrderActive.activeOrder.messages[0].message);
    })();
  }

  onAddBonusInOrder = (quantity: number) => {
    const { promotion } = this.props.route.params
    const { sOrderActive, sProducts } = this.props;
    const { products } = sProducts.payload;

    if (products && products.length > 0) {
      promotion.requirements = this.parseRequirements(promotion.requirements, products);
    }

    this.props.dispatch(addBonusOrder(sOrderActive.activeOrder.payload, promotion, quantity));
  };

  parseRequirements(requirements, products) {
    return requirements.map(req => {
      const product = products.find(prod => prod.code === req.product_code);
      if (product) {
        const { ean, name, price } = product;
        const { id, product_code, quantity } = req;
        return {
          ean,
          name,
          quantity,
          unit_price: price,
          total_price: (price * quantity),
          id: `${id}`,
          product_code,
        };
      }

      return req;
    });
  }

  getQuantity(promotion) {
    const { activeOrder } = this.props.sOrderActive;
    let quantity = 0;

    if (activeOrder && activeOrder.payload && activeOrder.payload.campaigns && activeOrder.payload.campaigns.length > 0) {
      const camFound = activeOrder.payload.campaigns.find(cam => cam.campaign_id === `${promotion.id}`);

      if (camFound) {
        quantity = camFound.quantity;
      }
    }

    return quantity;
  }

  render() {
    const { sAddBonusInOrder } = this.props;
    const { promotion } = this.props.route.params

    console.log('PromotionDetail this.props', this.props);

    promotion.quantity = this.getQuantity(promotion);

    return (
      <PromotionDetail
        promotion={promotion}
        // TODO: passandp temporariamente a propriedade showQuantity como true, depois que estiver
        // inserindo no cache deixa essa lógica
        // showQuantity={!sAddBonusInOrder.loading}
        showQuantity={true}
        onAddBonusInOrder={this.onAddBonusInOrder}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getAddBonusInOrder = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'addBonus']).toJS());

const getProductsFetch = createImmutableSelector([state => state], state =>
  state.getIn([STORE_PRODUCTS_FETCH, 'list']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    sAddBonusInOrder: getAddBonusInOrder(state),
    sProducts: getProductsFetch(state),
  };
}

export default connect(mapStateToProps)(PromotionDetailContainer);
