import React from 'react';
import { View } from 'react-native';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as CarouselWeb } from 'react-responsive-carousel';

const Pagination = () => {
  return (
    <View>

    </View>
  )
}

const ParallaxImage = () => {
  return (
    <View>

    </View>
  )
}

const Carousel = ({data, onPressItem}) => {

  console.log('Carousel', data);

  return (
    <CarouselWeb showStatus={false} renderThumbs={()=> {
      return null;
    }} onClickItem={(index)=> {
      if(onPressItem)
        onPressItem(data[index]);
    }}>

      {
        data.map((banner)=> {
          return (
            <div style={banner.uri ?{maxWidth: 250, height: 250, margin: 'auto'} : {}}>
              <img src={banner.image || banner.uri} />
            </div>
          )
        })
      }
    </CarouselWeb>
  )
}

export { Pagination, ParallaxImage }
export default Carousel;
