export default {
  TOKEN_KEY: 'auth_token',
  TIME_VERSION_CHECKED: 'time_version_checked',
  NOTIFICATION_TOKEN_KEY: 'notification_token',
  CLIENT_ID: 'c4f85113029a4433b3d04b6a1f1c7d2f',
  CLIENT_SECRET: '2449be3b2523469abfab61cefd4deae2',
  GRANT_TYPE: 'password',
  RESPONSE_TYPE_TOKEN: 'token',
  PAYMENT_TYPES: {
    CREDIT_CARD: 'credit_card',
  },
  TOKEN_STOCKER_IMAGES: 'CANT4jZjjhuMNbf5A8ysCAbm0A62O5eW',
  COMPANY_ID: 'cant',
};
