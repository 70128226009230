import React, { Component } from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import OrderDetail from './OrderDetail';
import { STORE_SET_CURRENT_ACCOUNT, STORE_SET_CURRENT_STORE_ITEM } from '~/store/storesConstants';
import { SCREEN_ORDER_DETAILS } from '~/screens/screens';

type Props = {
  order: {},
  componentId: string
}

class OrderDetailContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      addressDelivery: {},
    };
  }

  async componentDidMount() {
    const { sCurrentAccount = {}, sCurrentStoreItem = {} } = this.props;
    const customer = get(sCurrentStoreItem, 'payload.customer', {});
    const customerAddress = get(customer, 'address', {});
    const accountAddress = get(sCurrentAccount, 'user.address', {});
    const company = await CacheStore.get('COMPANY_ACTIVE') || {};
    const filteredCustomerCompany = filter(
      company.customer_company_accounts,
      (el) => el.customer.nin === customer.nin,
    ) || [];
    const customerCompany = filteredCustomerCompany.length > 0 ? filteredCustomerCompany[0] : {};
    const isPreRegister = get(customerCompany, 'authorized_by', '') === 'pre_register';
    const addressDelivery = isPreRegister ? accountAddress : customerAddress;

    this.setState({ addressDelivery });
  }

  render() {
    const { order } = this.props.route.params;
    const { addressDelivery } = this.state;

    return (
      <OrderDetail order={order} addressDelivery={addressDelivery} />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getCurrentStoreItem = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM]).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentStoreItem: getCurrentStoreItem(state),
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(OrderDetailContainer);
