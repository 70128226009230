import React from 'react';
import { View } from 'react-native-web';

const Reinput = () => {
  return (
    <View>

    </View>
  )
}

export default Reinput;
