/* eslint-disable no-undef */
import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';

import CreditCardList from './CreditCardList';
import { SCREEN_ADD_CREDIT_CARD } from '../screens';
import WalletController from '~/controllers/WalletController';
import {Alert, Platform, Text} from 'react-native';
import _ from "lodash";
import {View} from "react-native-web";
import colors from "~/values/color";
import CustomAlert from "~/components/CustomAlert";
import ReactDOM from "react-dom";

type Props = {
  dispatch: void,
  componentId: string,
};

const TAG = 'CreditCardListContainer';

class CreditCardListContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      showModal: false,
      selectedCard: null
    };

    console.log('logs', props)
  }

  onPressNewCard = () => {
    this.props.navigation.navigate(SCREEN_ADD_CREDIT_CARD.name, {
      title: SCREEN_ADD_CREDIT_CARD.title
    })

  };

  onPressRemove = (card) => {
    this.setState({showModal: true})
    this.setState({selectedCard: card})
    console.log(card)
  };

  onClose = (password) => {
    this.setState({ password }, () => {
      this.getCards();
    });
  };

  getCards() {
    WalletController.getCards()
      .then((cards) => {
        this.setState({ cards: cards });
      })
      .catch((err) => {
        console.log(TAG, err);
      });
  }

  remove(card) {
    WalletController.remove(card)
      .then((result) => {
        if (result) {
          this.getCards();
        }
        this.setState({showModal: false})
        this.setState({selectedCard: null})
      })
      .catch((err) => {
        console.log(TAG, err);
        Alert.alert('Ops...', 'Não foi possível remover seu cartão. Tente novamente.');
      });
  }

  componentDidMount = () => {
    this.getCards();
  };

  componentWillUnmount() {
    this.getCards();
  }

  render() {
    const cards = _.orderBy(this.state.cards, ['main'], ['desc']);
    return (
        <View>
          <CustomAlert
              showModal={this.state.showModal}
              okButtonTitle={"Sim"}
              cancelButtonTitle="Não"
              cancelButtonPress={() => this.setState({showModal: false})}
              okButtonPress={() => this.remove(this.state.selectedCard)}
              title="Atenção">
            <View style={{ alignItems: 'center' }}>
              <Text style={{
                fontSize: 18,
                color: Platform.OS === 'ios' ? '#000' : colors.primaryColor,
                textAlign: Platform.OS === 'ios' ? 'center' : 'left',
              }}>
                Você deseja realmente remover o cartão com final: <b>{this.state.selectedCard ? this.state.selectedCard.mask : ''}</b>?
              </Text>
            </View>
          </CustomAlert>
          <CreditCardList
              creditCards={cards}
              onPressNewCard={this.onPressNewCard}
              onRemove={this.onPressRemove}
          />
        </View>
    );
  }
}

export default CreditCardListContainer;
