import axios from 'axios';
import get from 'lodash/get';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

export default async function addProductInOrder(items) {
  const config = await getConfig();
  const company = await getCurrentCompany();
  const params = Array.isArray(items) ? items.map((item) => item.params) : [items.params];

  const idOrder = params[0].idOrder;

  return new Promise((resolve, reject) =>
    axios.post(`${URL_BASE}/cart/${company}/detail/${idOrder}/products/`, params, config)
      .then(response => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(get(error, 'response.data', {}));
      }));
}
