import React, { Component } from 'react';
import { View, FlatList, Text, Image } from 'react-native';

import styles from './styles';
import Option from './Option';
import { colors } from '~/values';
import Images from '~/../assets/images';

type Props = {
  options: Array<{
    text: string,
    onPress: () => void,
  }>,
  title?: string,
};

class CheckoutOptions extends Component<Props> {
  renderItem = ({ item }) => {
    return (
      <Option
        onPress={item.onPress}
        image={item.image}
        text={item.text}
        selected={item.selected}
      />
    );
  };

  render() {
    const { options, title } = this.props;
    return (
      <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        <FlatList
          keyExtractor={(item, index) => index.toString()}
          columnWrapperStyle={{ marginBottom: 16 }}
          numColumns={2}
          data={options}
          renderItem={this.renderItem}
        />
      </View>
    );
  }
}

export default CheckoutOptions;
