import { StyleSheet } from 'react-native';

const size = 40;

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    minWidth: size,
    maxWidth: size,
    color: 'black',
    textAlign: 'center',
  },
  buttonQuantity: {
    width: 26,
    height: 26,
  },
});

export default styles;
