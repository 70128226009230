import { StyleSheet } from 'react-native';
import colors from "~/values/color";

export default StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  scrollview: {
    flex: 1,
  },
  slider: {
    overflow: 'visible',
  },
  sliderContentContainer: {
  },
  paginationContainer: {
    paddingVertical: 8,
    backgroundColor: colors.grayContainer,
  },
  dotContainerStyle: {
    marginHorizontal: 2,
  },
  paginationDot: {
    width: 15,
    height: 4,
    borderRadius: 4,
  },
});
