import Controller from '~/controllers/Controller';
import ComboRepositoryRepository from '~/database/repositories/ComboRepository';

export default class NotificationController extends Controller {
  constructor() {
    super();
    this.repository = new ComboRepositoryRepository();
  }

  addCombos(combos) {
    return this.repository.addCombos(combos);
  }
  getProductsCombos(coddesconto, all) {
    return this.repository.getProductsCombos(coddesconto, all);
  }
  getCampaignsCombos() {
    return this.repository.getCampaignsCombos();
  }
}
