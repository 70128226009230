import type { ISetup } from '../../entities/Setup';

const sourcesWS = {
  listProduct: {
    setup: {
      url: 'http://.../',
      method: 'getAllProducts',
    },
  },
  listProductContext: {
    setup: {
      url: 'http://.../',
      method: 'listProductContext',
      args: [
        { key: 'code', value: '0', from_context: true },
      ],
    },
  },
  notifyMe: {
    setup: {
      url: 'http://.../',
      method: 'notifyMe',
      args: [
        { key: 'amount', value: '0', from_context: true },
        { key: 'product', value: {}, from_context: true },
        { key: 'customer', value: {}, from_context: true },
      ],
    },
  },
  addProduct: {
    setup: {
      url: 'http://.../',
      method: 'addProductInOrder',
      args: [
        { key: 'product_code', value: '0', from_context: true },
        { key: 'quantity', value: 0, from_context: true },
        { key: 'idOrder', value: 0, from_context: true },
        { key: 'price', value: 0, from_context: true },
      ],
    },
  },
  addBonus: {
    setup: {
      url: 'http://.../',
      method: 'addBonusInOrder',
      args: [
        { key: 'campaign_id', value: 0, from_context: true },
        { key: 'quantity', value: 0, from_context: true },
        { key: 'idOrder', value: 0, from_context: true },
      ],
    },
  },
  updateOrder: {
    setup: {
      url: 'http://.../',
      method: 'updateOrder',
      args: [
        { key: 'idOrder', value: '0', from_context: true },
        { key: 'companyId', value: '0', from_context: true },
        { key: 'customer_id', value: '0', from_context: true },
        { key: 'device_id', value: '0', from_context: true },
        { key: 'platform', value: '0', from_context: true },
        { key: 'sale_conditions', value: {}, from_context: true },
      ],
    },
  },
};

export default function getSource(id: string): ISetup {
  const setup = sourcesWS[id];
  return { type: 'ws', ...setup };
}
