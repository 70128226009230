import React from 'react';
import { View, Text, Image } from 'react-native';
import Images from '~/../assets/images';
import { Touchable } from 'react-native-web';

import styles from './styles';

export type Props = {
  attribute: string,
  option: string,
  type: string,
  onPress: () => void,
  selected: boolean,
}

type OptionProp = {
  value: string,
  attribute: string,
}


const ImageOption = ({ value }: OptionProp) => (
  <Image
    source={{
        uri: value,
      }}
    style={styles.image}
  />
);

const ColorOption = ({ value, attribute }: OptionProp) => (
  <View style={[styles.containerColor, attribute ? styles.containerColor__withAttribute : {}]}>
    <View style={[styles.color, { backgroundColor: value }]} />
  </View>
);

const TextOption = ({ value }: OptionProp) => (
  <Text style={styles.text}>{value}</Text>
);

function getAttributeValue(value, type, attribute) {
  switch (type) {
    case 'image':
      return <ImageOption value={value} />;
    case 'color':
      return <ColorOption value={value} attribute={attribute} />;
    case 'text':
    default:
      return <TextOption value={value} />;
  }
}

function getBodyOption(attribute, option, type) {
  return (
    <View style={styles.container}>
      {
        attribute ?
          <Text style={styles.attribute}>{attribute}</Text>
          :
          null
      }
      {getAttributeValue(option, type, attribute)}
    </View>
  );
}

const Option = ({
  attribute, option, type, onPress, selected, clickButton,
} : Props) => (
  <View>
    <View
      style={[styles.outsideContainer]}
    >
      { clickButton ?
        <Touchable
          onPress={() => onPress(option)}
        >
          {getBodyOption(attribute, option, type)}
        </Touchable>
        :
        <View>
          {getBodyOption(attribute, option, type)}
        </View>
      }
    </View>
    {
      selected ?
        <View
          style={styles.badge}
        >
          <Image
            style={styles.iconCheck}
            source={Images.iconCheck}
          />
        </View>
        :
        null
    }
  </View>
);

Option.defaultProps = {
  onPress: () => {},
};

export default Option;
