// eslint-disable-next-line no-use-before-define
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import ProductsAndCategories from '~/screens/ProductsAndCategories';
import Product from '~/screens/Product';
import {
  SCREEN_PRODUCT,
  SCREEN_PRODUCTS_AND_CATEGORIES,
  SCREEN_SEARCH_HISTORY, SCREEN_SEARCH_RESULT, SCREEN_STORES
} from '~/screens/screens';
import { colors } from '~/values';
import History from '~/screens/History/HistoryContainer';
import SearchResult from '~/screens/SearchResult/SearchResultContainer';
import Stores from '~/screens/Stores';

const ProductsAndCategoriesStack = createStackNavigator();

const ProductsAndCategoriesRoutes = () => (
  <ProductsAndCategoriesStack.Navigator screenOptions={{
    title: 'Produtos e Categorias',
    headerStyle: {
      backgroundColor: colors.primaryColor,
      textAlign: 'center',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }}>
    <ProductsAndCategoriesStack.Screen name={SCREEN_PRODUCTS_AND_CATEGORIES.name} component={ProductsAndCategories}/>
    <ProductsAndCategoriesStack.Screen name={SCREEN_PRODUCT.name} component={Product}/>

    <ProductsAndCategoriesStack.Screen
      name={SCREEN_SEARCH_HISTORY.name} component={History}
    />
    <ProductsAndCategoriesStack.Screen
      name={SCREEN_SEARCH_RESULT.name} component={SearchResult}
    />

    <ProductsAndCategoriesStack.Screen name={SCREEN_STORES.name} component={Stores}/>

  </ProductsAndCategoriesStack.Navigator>
);

export default ProductsAndCategoriesRoutes;
