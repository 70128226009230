import get from 'lodash/get';
import { getConfig, URL_BASE, getCurrentCompany } from '../../../services/index';
import listProducts from '../../../services/products';
import { SchemaModels } from '../../../database/utils/DBUtils';

import listBase from '../../../services/listBase';

function getDataResponse(response, arg) {
  const data = get(response, ['source', 'data', ...arg]) || [];
  return data;
}

export default async function downloadProducts() {
  const config = await getConfig();
  const company = await getCurrentCompany();
  const dataDownload = [];
  let response = [];
  try {
    response = await listBase(`${URL_BASE}/products/${company}/`, config);
  } finally {
    dataDownload[SchemaModels.PRODUCT] = getDataResponse(response, ['products']) || [];
    dataDownload[SchemaModels.CATEGORY] = getDataResponse(response, ['categories']) || [];
    dataDownload[SchemaModels.PRODUCTS_CATEGORIES] = getDataResponse(response, ['products_categories']) || [];
    dataDownload[SchemaModels.CATEGORIES_RELATIONSHIPS] = getDataResponse(response, ['categories_relationships']) || [];

    return dataDownload;
  }
}
