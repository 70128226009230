import React from 'react';
import { View } from 'react-native';
import {Placeholder, PlaceholderMedia } from 'rn-placeholder';

import colors from '../../values/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeBannersItemStyle = {
  ...backgroundGray,
  height: 200,
  width: '100%',
  padding: 40,
  top: 10,
  bottom: 10,
  borderRadius: 5,
};

export default function PromotionsLoad() {
  return (
    <Placeholder animation="fade">
      <View style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10 }}>
        <PlaceholderMedia hasRadius style={homeBannersItemStyle} />
      </View>
      <View style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10 }}>
        <PlaceholderMedia hasRadius style={homeBannersItemStyle} />
      </View>
      <View style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10 }}>
        <PlaceholderMedia hasRadius style={homeBannersItemStyle} />
      </View>
    </Placeholder>
  );
}
