import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, TouchableOpacity, View, } from 'react-native';
import Images from '~/../assets/images';
import colors from '~/values/color';

const styles = StyleSheet.create({
  imageItem: {
    height: '100%',
  },
  defaultImage: {
    width: 70,
    height: 70,
    marginTop: 4,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ImageComponent = (props) => {

  const [source, setSource] = useState(props.source);
  const [hideProgress, setHideProgress] = useState(props.source ? false : true);
  const [errorImage, setErrorImage] = useState(props.source ? false : true);

  useEffect(() => {
    setSource(props.source);
    setErrorImage(props.source ? false : true);
    setHideProgress(props.source ? false : true);
  }, [props.source]);

  const {
    style,
    styleError,
    defaultImage,
  } = props;

  const loadImage =
    hideProgress ? null : (
      <View style={styles.loading}>
        <ActivityIndicator/>
      </View>
    );

  const styleImage = defaultImage && errorImage ? styles.defaultImage : style || styles.imageItem;

  let image = (
    <Image
      source={defaultImage || Images.iconNoImageBig}
      style={[styleImage]}
      resizeMode={'stretch'}
      tintColor={colors.grayDark}
    />
  );

  if (!errorImage) {
    image = (
      <Image
        onError={() => {
          setHideProgress(true);
          setErrorImage(true);
        }}
        onLoad={() => {
          setHideProgress(true);
          setErrorImage(false);
        }}
        resizeMode={'contain'}
        style={styleImage}
        source={source || Images.iconNoImageBig}
      />
    );
  }

  return (
    <View>
      <TouchableOpacity style={[styleImage]}>
        {image}
      </TouchableOpacity>

      {loadImage}

    </View>
  );
};

export default ImageComponent;
