import { StyleSheet } from 'react-native';
import AppConfig from '~/AppConfig';
import { colors, sizes, defaultShadow } from '~/values';

const size = 80;

const baseContainer = {
  height: size,
  width: size,
  borderRadius: size / 2,
};

const styles = StyleSheet.create({
  rootContainer: {
    alignItems: 'center',
    marginHorizontal: 8,
    marginTop: 8,
  },
  shadowContainer: {
    ...baseContainer,
    ...defaultShadow,
    backgroundColor: '#FFF',
  },
  overflowContainer: {
    ...baseContainer,
    overflow: 'hidden',
  },
  container: {
    ...baseContainer,
    backgroundColor: '#FFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: AppConfig.categoriesIconConfig,
  title: {
    fontSize: sizes.textVerySmall,
    color: colors.grayDark,
    marginTop: 10,
  },
});

export default styles;
