/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { ScrollView, View, Text } from 'react-native';
import isEmpty from 'lodash/isEmpty';

import appStyles from '~/appStyles';
import colors from '~/values/color';
import { Section, SimpleInfo } from '~/components';
import type { IProduct } from '~/entities/Product';
import Banners from '../../../../components/Banners';
import { Attribute } from '~/screens/Product/components';
import Carousel from '~/components/Carousel';
import ImageComponent from '~/components/Image';
import Product from '~/components/Product/ProductContainer';
import ProductUnavailable from './ProductUnavailable';
import { Money } from '~/common';
import AppConfig from '../../../../AppConfig';

import Images from '~/../assets/images';

import DataManager from '../../../../database/DataManager';
import imagesProduct from '../../../../services/products/imagesProduct';
import StockerProductResource from '../../../../services/resources/StockerProductResource';

type ProductDetailProps = {
  attributes?: any,
  product: IProduct,
  selectedProduct: any,
  banners: [],
  loadingCondPrice: boolean,
  loadingImage: boolean,
  isMatriz: boolean,
  onPressLike?: (product: IProduct) => void,
  selectedAttributes: any,
  filteredAttributes: any,
  onAttributeClicked: any,
  isAnonymous: boolean,
  screenId: string,
};

class ProductDetails extends Component<ProductDetailProps> {
  constructor(props) {
    super(props);
    this.dataManager = new DataManager();

    this.state = {
      liked: false,
      image: StockerProductResource.getThumbImage(props.product.ean),
      images: null,
      opened: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { product } = this.props;
    this.setState({ liked: product.liked || false });
  }

  async componentDidMount() {
    this.setState({ opened: true });
    const { product } = this.props;
    if (product) {
      const imageStockerConfig = this.dataManager.getConfigValue('imageStocker');
      if (imageStockerConfig) {
        const images = await StockerProductResource.getImages(product.ean) || [];
        const image = images ? images[0] : StockerProductResource.getThumbImage(product.ean);
        this.setState({ image, images });
      } else {
        const ean = encodeURIComponent(product.ean);
        try {
          const images = await imagesProduct(ean);
          this.setState({ image: images[0], images });
        } catch (er) {
          this.setState({ image: Images.iconNoImageBig });
        }
      }
    }
  }

  onPressLike(product, onPressLike) {
    const liked = !this.state.liked;
    this.setState({ liked });
    onPressLike({ ...product, liked });
  }

  keyExtractor = item => item.id;

  separatorView = () => <View style={{ width: 1 }} />;

  renderItem = ({ item }) => (
    <Product
      type="grid"
      product={item}
      onPressLike={this.onPressLike(item, this.props.onPressLike)}
      screenId={this.props.screenId}
      canOpenDetail={this.state.opened}
    />
  );

  render() {
    const {
      product,
      banners,
      attributes,
      isMatriz,
      selectedProduct,
      loadingCondPrice,
      loadingImage,
      isAnonymous,
    } = this.props;

    const { prodSuggestions } = product;
    const { showPricesToAnonymous } = AppConfig;

    return (
      <ScrollView style={appStyles.container}>
        <View style={appStyles.container}>
          { this.state.images === null ? (
            <View style={appStyles.containerBanner}>
              <ImageComponent style={appStyles.imgBanner} source={this.state.image} />
            </View>
          ) : <Banners fullWidth banners={this.state.images} />}

          {!isEmpty(product) ? (
            <Product
              selectedProduct={selectedProduct}
              product={product}
              showDetail
              type="linear"
              onPressLike={this.props.onPressLike}
              screenId={this.props.screenId}
              numberOfDescriptionLines={3}
              canOpenDetail={this.state.opened}
            />
          ) : (
            <ProductUnavailable />
          )}

          {(product.ean || product.code) && (
            <Section title="Informações">
              <View style={appStyles.spaceBetween}>
                {<SimpleInfo label="Código de barras" value={product.ean || 'Carregando...'} />}
                {<SimpleInfo label="Código interno" value={product.code || 'Carregando...'} />}
              </View>
            </Section>
          )}

          {(!isAnonymous || showPricesToAnonymous) && loadingCondPrice && (
            <Section title="Condições de preço">
              <Text>Carregando condições de preço...</Text>
            </Section>
          )}

          {(!isAnonymous || showPricesToAnonymous) && product.price_conditions &&
            product.price_conditions.length > 0 && (
              <Section title="Condições de preço">
                {product.price_conditions.map((condition, index) => {
                  const lineStyle = {
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    paddingHorizontal: 16,
                    paddingVertical: 10,
                  };

                  if (index % 2 === 0) {
                    lineStyle.backgroundColor = colors.grayContainer;
                  }

                  return (
                    <View style={lineStyle} key={index}>
                      <Text style={appStyles.charValue}>{condition.description}</Text>
                      <Text style={appStyles.charValue}>
                        { Money(condition.price || 0)}
                      </Text>
                    </View>
                  );
                })}
              </Section>
            )}

          {product.description !== '' && (
            <Section title="Informações complementares">
              <View style={appStyles.spaceBetween}>
                <SimpleInfo value={product.description || 'Carregando...'} />
              </View>
            </Section>
          )}

          {isMatriz && (
            <Section title="Opções">
              {attributes &&
                attributes.map(item => (
                  <Attribute
                    key={item.code}
                    selectedAttributes={this.props.selectedAttributes}
                    filteredAttributes={this.props.filteredAttributes}
                    onPressAttribute={this.props.onAttributeClicked}
                    attribute={item}
                  />
                ))}
            </Section>
          )}

          {prodSuggestions &&
            prodSuggestions.length > 0 && (
              <Carousel
                title="Quem procura este produto, procura por"
                keyExtractor={this.keyExtractor}
                data={prodSuggestions}
                renderItem={this.renderItem}
                ItemSeparatorComponent={this.separatorView}
              />
            )}
          {/* {this.props.getDynamicViews()} */}
        </View>
      </ScrollView>
    );
  }
}

export default ProductDetails;
