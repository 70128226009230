import React, { Component } from 'react';
import { Alert, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
// import { Navigation } from 'react-native-navigation';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import Home from '~/screens/Home/Home';
import requestManager from '~/utils/RequestManager';
import {
  SCREEN_HOME,
  SCREEN_PRODUCTS_AND_CATEGORIES,
  SCREEN_PROMOTION_DETAIL,
  SCREEN_SEARCH_HISTORY,
  SCREEN_LOGIN, SCREEN_SIGNUP,
} from '~/screens/screens';
import NavigationHelper from '~/screens/NavigationHelper';
import Sync from '~/utils/Sync';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_SET_CURRENT_ACCOUNT,
  STORE_SET_CURRENT_COMPANY,
  STORE_SET_CURRENT_NET_INFO,
  STORE_SET_CURRENT_STORE_ITEM,
  STORE_LOGIN_STATE,
  STORE_LOAD_PROMOTIONS_STATE,
  STORE_LOAD_CATEGORIES_STATE,
  STORE_LOAD_PROD_CONTEXT_STATE,
  STORE_LOAD_NOTICIES_STATE,
  STORE_CHANGE_STORE_STATE,
  STORE_ERROR_COMPANYCUSTOMER,
  STORE_LOAD_CART_STATE,
  STORE_SET_LOAD_AFTER_SALE_STATE,
  STORE_CHANGE_ICONS_STATE,
} from '~/store/storesConstants';
import activeOrder from '~/store/activeOrderFetch/activeOrder/action';
import loadShippingMethods from '~/store/shippingMethodsFetch/action';
import { mergeProductsWithOrder, sortByProductsNoStock } from '~/utils/product';
import DataManager from '~/database/DataManager';
import SQLiteManager from '../../database/SQLiteManager';
import { SchemaModels } from '~/database/utils/DBUtils';
import ComponentHelper from '~/components/ComponentHelper';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { AUTH_TOKEN, CART_ERROR, COMPANY_ACTIVE, DATA_ACCOUNT, STORE_ITEM_ACTIVE } from '~/constants/cache';
import { parseProducts } from '~/utils/product';
import { loginAnonymous } from '~/services/account';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import setCurrentCompany from '~/store/setCurrentCompany/action';
import setNewLogin from '../../store/newLogin/action';
import setLoadPromotions from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setIconsChange from '../../store/loadIcons/action';
import setErrorCompanyCustomer from '../../store/setErrorCompanyCustomer/action';
import { setCompanyCustomerActiveFromCompany } from '~/utils/CompanyCustomer';
import setCurrentStoreItem, { SYNC_CURRENT_STORE_ITEM } from '../../store/setCurrentStoreItem/action';
import detail from '../../services/account/detail';
import AppConfig from '../../AppConfig';
import { addIconsCategories } from '~/utils/category';
import { SCREEN_NOTICES, SCREEN_STORES } from '../screens';
import setCurrentNetInfo from '~/store/setCurrentNetInfo/action';
import Images from '~/../assets/images';
import AccountController from '~/controllers/AccountController';
import CartController from '~/controllers/CartController';
import ProductContextController from '~/controllers/ProductContextController';
import CategoryController from '~/controllers/CategoryController';
import CompanyController from '~/controllers/CompanyController';
import PromotionController from '~/controllers/PromotionController';

import CustomAlert from '~/components/CustomAlert';
import { store } from '~/App.web';
import setLoadCart from '~/store/setLoadCart/action';
import setLoadAferSale from '../../store/setLoadAfterSale/action';
import setAction from '~/utils/setAction';
import NoticesController from '~/controllers/NoticesController';

const TAG = 'HomeContainer';

const TIMEOUT_CONNECTION = 3 * 1000;

type Props = {
  componentId: string,
  dispatch: () => void,
  currentNetInfo: {
    type: '',
    is_connected: null,
  },
  valueIconsChange: any,
  valueLogin: any,
  valueLoadCart: boolean,
  valueLoadAfterSale: boolean,
  valueErrorCompanyCustomer: any,
  valueLoadPromo: any,
  valueLoadCategories: any,
  valueLoadProdContext: any,
  valueLoadNoticies: any,
  valueChangeStore: any,
  sCurrentAccount: {
    isAnonymous: boolean,
    user: {
      id: string,
    },
  },
  sCurrentCompany: {
    id: string,
  },
  sOrderActive: {
    type: string,
    activeOrder: {
      payload: {
        products: [],
      },
    },
  },
  sCurrentStoreItem: Object<any>,
};

let cartErrorCached = '';

class HomeContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      progressDownload: 0,
      promotions: [],
      categories: [],
      productsByContext: [],
      notices: [],
      refreshing: false,
      modalCompanyChoiceVisible: false,
      companyChosen: 'Sem loja',
      companyChoiceData: [],
      errorCart: false,
      storeInvalid: false,
      errorSync: false,
    };

    this.sync = new Sync(this.onUpdateProgress);

    this.isSideDrawerVisible = false;
  }

  async componentDidMount() {
    CacheStore.get(DATA_ACCOUNT)
      .then(async account => {
        if (account) {
          if (account.sync) {
            this.dispatchAllData();
          }
          this.doSync();
        } else {
          const authVersionOld = await AsyncStorage.getItem(AUTH_TOKEN);
          if (authVersionOld) {
            this.loginOldVersion();
          } else {
            this.loginAnonymous();
          }
        }
      })
      .catch(error => {
        console.log(TAG, error);
        this.dispatchAllData();
        this.doSync();
      });

    this.props.navigation.addListener('focus', async () => {
      const isAnonymous = await AccountController.isAnonymous();
      if (isAnonymous) {
        this.props.navigation.setOptions({
          title: AppConfig.appName,
          headerRight: () => {
            return (
              <TouchableOpacity onPress={() => this.props.navigation.navigate(SCREEN_LOGIN.name)}>
                <Image
                  source={Images.iconLogin}
                  style={{ tintColor: '#f0f0f0', height: 20, width: 20, right: 10 }}
                />
              </TouchableOpacity>
            )
          }
        });
      } else {
        this.props.navigation.setOptions({
          title: AppConfig.appName,
        })
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot): void {
    (() => {
      if (this.props.valueIconsChange.load === true) {
        this.dispatchAllData();
        const changeIcons = {
          type: '',
          load: false,
        };
        this.props.dispatch(setIconsChange(changeIcons));
      }
    })();

    (() => {
      if (this.props.valueErrorCompanyCustomer.error === true) {
        this.dispatchAllData();
        const errorCompanyCustomer = {
          type: '',
          error: false,
        };
        this.props.dispatch(setErrorCompanyCustomer(errorCompanyCustomer));
      }
      if (this.props.valueErrorCompanyCustomer.notStore === true) {
        this.dispatchAllData();

        this.props.navigation.push(SCREEN_STORES.name);

        const noStore = {
          type: '',
          error: false,
          notStore: false,
        };
        this.props.dispatch(setErrorCompanyCustomer(noStore));
      }
    })();
    (() => {
      if (this.props.valueLoadCart.condSalesError === true) {
        const dataManager = new DataManager();
        this.dispatchAllData();
        dataManager.clearInCache(SchemaModels.CART);
        dataManager.clearInCache(SchemaModels.PAYMENT_COMBINE_KEYS);
        dataManager.clearInCache(SchemaModels.PAYMENT_OPTIONS);
        dataManager.clearInCache(SchemaModels.PAYMENT_TERMS);
        const errorLoadedCart = {
          type: '',
          load: false,
          userBlocked: false,
          condSalesError: false,
        };
        this.props.dispatch(setLoadCart(errorLoadedCart));
      }
      if (this.props.valueLoadCart.permission_denied === true) {
        this.dispatchAllData();
        const errorLoadedCart = {
          type: '',
          load: false,
          userBlocked: false,
          condSalesError: false,
          permission_denied: false,
        };
        this.props.dispatch(setLoadCart(errorLoadedCart));
      }
      if (this.props.valueLoadCart.userBlocked === true) {
        this.dispatchAllData();
        const errorLoadedCart = {
          type: '',
          load: false,
          userBlocked: false,
          condSalesError: false,
          permission_denied: false,
        };
        this.props.dispatch(setLoadCart(errorLoadedCart));
      }
    })();
    (() => {
      if (this.props.valueLoadAfterSale.load === true) {
        this.dispatchAllData();
        const loadData = {
          type: '',
          load: false,
        };
        this.props.dispatch(setLoadAferSale(loadData));
      }
    })();
    (() => {
      if (prevProps.sCurrentStoreItem.type === this.props.sCurrentStoreItem.type) return;
      if (this.props.sCurrentStoreItem.type !== SYNC_CURRENT_STORE_ITEM) return;
      if (isEmpty(this.props.sCurrentStoreItem.payload)) {
        this.doSync();
      } else {
        this.dispatchData();
      }
    })();
  }

  onFocus = () => {
    this.view.onBlurSearchBar();
    this.props.navigation.navigate(SCREEN_SEARCH_HISTORY.name);
  };

  onUpdateProgress = value => {
    this.progress(value);
  };

  onRefresh = async () => {
    this.setState({ refreshing: true });
    CacheStore.remove(CART_ERROR);
    this.doSync();
  };

  doPressCategory = category => {
    const props = {
      sourceScreen: SCREEN_HOME.name,
      categoryCode: category.category_code,
      title: category.description,
    };

    this.props.navigation.navigate(SCREEN_PRODUCTS_AND_CATEGORIES.name, {...props});
  };
  onPressCategory = ComponentHelper.debounce(this.doPressCategory);

  doPressBanner = banner => {
    const props = { promotion: banner };
    this.props.navigation.push(SCREEN_PROMOTION_DETAIL.name, { ...props });
  };

  onPressBanner = ComponentHelper.debounce(this.doPressBanner);

  loginOldVersion() {
    detail()
      .then(async response => {
        const token = await AsyncStorage.getItem(AUTH_TOKEN);
        const { data } = response.source;
        delete data.birthday;
        delete data.companies;

        const dataAccount = { token, user: { ...data, application_code: AppConfig.appCode } };
        CacheStore.set(DATA_ACCOUNT, dataAccount);
        this.props.dispatch(setCurrentAccount(dataAccount));
      })
      .catch(error => console.log(TAG, error))
      .finally(() => this.doSync());
  }

  loginAnonymous() {
    loginAnonymous()
      .then(accountAnonymous => {
        const dataAccount = { ...accountAnonymous, isAnonymous: true, sync: false };
        CacheStore.set(DATA_ACCOUNT, dataAccount);
        this.props.dispatch(setCurrentAccount(dataAccount));
      })
      .catch(error => {
        console.log(TAG, error);
      })
      .finally(() => this.doSync());
  }

  async dispatchAllData() {
    const cartController = new CartController();
    const productContextController = new ProductContextController();
    const categoryController = new CategoryController();
    const companyController = new CompanyController();
    const promotionController = new PromotionController();
    const noticesController = new NoticesController();

    const noticesPromise = noticesController.getNotices();
    const companiesPromise = companyController.allCompany();
    const promotionsPromise = promotionController.getPromotions();
    const categoriesPromise = categoryController.allRoot();
    const productsContextPromise = productContextController.allByContexts(AppConfig.contexts.filter(context => context.screen === 'home')[0].ids);

    companiesPromise
      .then(async data => {
        cartErrorCached = await CacheStore.get(CART_ERROR);
        let orderActive = await cartController.getCart(null, true);

        if (!cartErrorCached && orderActive && orderActive.length && orderActive[0].success) {
          this.setState({
            errorCart: false,
          });

          orderActive = orderActive[0];

          const company = await CacheStore.get(COMPANY_ACTIVE) || null;
          const storeItem = await CacheStore.get(STORE_ITEM_ACTIVE) || null;

          this.props.dispatch(setCurrentCompany(company));
          this.props.dispatch(setCurrentStoreItem(storeItem));

          const loadedData = {
            type: '',
            auth: false,
          };
          this.props.dispatch(setNewLogin(loadedData));

          await this.setState(
            {
              companyChoiceData: [],
              companyChosen: [],
              refreshing: false,
            },
            () => {
              const { sCurrentAccount } = this.props;
              if (AppConfig.shipping && !sCurrentAccount.isAnonymous) {
                const params = { cartId: orderActive.id, customerId: orderActive.customer_id };
                this.props.dispatch(loadShippingMethods(params));
              }
            },
          );
        } else {
          this.setState({
            refreshing: false,
            errorCart: true,
          });

          const errorLoadedCart = {
            type: '',
            load: false,
          };
          store.dispatch(setLoadCart(errorLoadedCart));

          // const cartErroMsg = cartErrorCached || 'Ocorreu um erro ao carregar seu carrinho';
        }
      })
      .catch(error => {
        const loadedData = {
          type: '',
          auth: false,
        };
        this.props.dispatch(setNewLogin(loadedData));
        this.setState({
          refreshing: false,
        });
        console.log(TAG, error);
      });

    promotionsPromise.then(async promo => {
      const loadedPromo = {
        type: '',
        load: false,
      };
      this.setState({ promotions: promo || [] });
      this.props.dispatch(setLoadPromotions(loadedPromo));
    }).catch(error => {
      const loadedPromo = {
        type: '',
        load: false,
      };
      this.props.dispatch(setLoadPromotions(loadedPromo));
      this.setState({
        refreshing: false,
      });
      console.log(TAG, error);
    });

    categoriesPromise.then(async data => {
      const newArr = [];
      for (let i = 0; i < data.length; i++) {
        const parsedCat = {
          category_code: data[i].category_code,
          description: data[i].description,
          is_root: JSON.parse(data[i].is_root),
          type: data[i].type,
        }
        newArr.push(parsedCat);
      }

      const loadedData = {
        type: '',
        load: false,
      };

      this.setState({
        categories: addIconsCategories(newArr, 0) || []
      }, ()=> {
        this.props.dispatch(setLoadCategories(loadedData));
      });
    }).catch(error => {
      const loadedData = {
        type: '',
        load: false,
      };
      this.props.dispatch(setLoadCategories(loadedData));
      this.setState({
        refreshing: false,
      });
      console.log(TAG, error);
    });

    productsContextPromise.then(dataProductsContext => {
      const loadedData = {
        type: '',
        load: false,
      };

      const productsByContext = uniqBy(parseProducts([...dataProductsContext]), 'id');
      const productsWithoutNotifyMe = productsByContext.filter(item => item.stock > 0);

      this.setState({ productsByContext: [...productsWithoutNotifyMe] });
      this.props.dispatch(setLoadProdContext(loadedData));
    }).catch(error => {
      const loadedData = {
        type: '',
        load: false,
      };
      this.props.dispatch(setLoadProdContext(loadedData));
      console.log(TAG, error);
    });

    noticesPromise.then(async data => {
      const loadedData = {
        type: '',
        load: false,
      };
      this.setState({ notices: data || [] });
      this.props.dispatch(setLoadNoticies(loadedData));
    }).catch(error => {
      const loadedData = {
        type: '',
        load: false,
      };
      this.props.dispatch(setLoadNoticies(loadedData));
      console.log(TAG, error);
    });
  }

  dispatchData() {
    this.props.dispatch(activeOrder());
    this.dispatchAllData();
  }

  eventListenConnection() {
    setTimeout(
      () =>
        NetInfo.addEventListener(async state => {
          if (state.isConnected) {
            await requestManager.process();
          }
        }),
      TIMEOUT_CONNECTION,
    );
  }

  progress(value) {
    this.setState({ progressDownload: value });
  }

  doSync = () => {
    this.setState({ errorCart: false });

    this.sync
      .sync()
      .then(async () => {
        this.setState({ errorCart: false });

        const dataAccount = await CacheStore.get(DATA_ACCOUNT);
        dataAccount.sync = true;
        await CacheStore.set(DATA_ACCOUNT, dataAccount);
      })
      .catch(error => {
        if (error.messages) {
          Alert.alert('', error.messages[0].message);
        }
      })
      .finally(() => {
        this.dispatchData();
        this.eventListenConnection();
      });
  };

  onPressCompanyChoice = () => {
    this.setState({ modalCompanyChoiceVisible: !this.state.modalCompanyChoiceVisible });
  };

  onPressCompany = async company => {
    const companyActive = await CacheStore.get(COMPANY_ACTIVE);
    if (
      (companyActive && companyActive.id !== company.value) ||
      (this.state.companyChosen && this.state.companyChosen.value !== company.value)
    ) {
      const netInfo = this.props.currentNetInfo;
      if (!netInfo.is_connected) {
        Alert.alert('', 'Você está offline! Conecte a internet para trocar de loja.');
        return;
      }

      const companyController = new CompanyController();
      const currentCompany = await companyController.getCompany(company.value);

      let companyCustomer = null;
      const companyCustomers = currentCompany.customer_company_accounts;
      if (companyCustomers && companyCustomers.length > 0) {
        companyCustomer = companyCustomers[0];
      }
      if (companyCustomer) {
        await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);

        this.props.dispatch(setCurrentCompany(currentCompany));
        this.props.dispatch(setCurrentStoreItem(companyCustomer));
        const loadedData = {
          type: '',
          auth: true,
        };
        this.props.dispatch(setNewLogin(loadedData));
        this.setState(
          {
            companyChosen: company,
            modalCompanyChoiceVisible: false,
          },
          async () => {
            await SQLiteManager.clearAllTables();
            this.doSync();
          },
        );
      } else {
        this.setState({
          modalCompanyChoiceVisible: false,
          storeInvalid: true,
        });
      }
    } else {
      this.setState({
        modalCompanyChoiceVisible: false,
      });
    }
  };

  doPressNotice = async (notice) => {
    const props = { ...notice, componentId: this.props.componentId };
    setAction(props);
  }

  onPressNotice = ComponentHelper.debounce(this.doPressNotice);

  render() {
    const {
      sCurrentAccount, sOrderActive, valueLogin, valueLoadPromo,
      valueLoadCategories, valueLoadProdContext, valueLoadNoticies,
    } = this.props;

    console.log('productsByContext', productsByContext);

    const {
      promotions,
      categories,
      productsByContext,
      notices,
      progressDownload,
      refreshing,
      modalCompanyChoiceVisible,
      companyChosen,
      companyChoiceData,
      errorCart,
      storeInvalid,
    } = this.state;

    const homeProducts = mergeProductsWithOrder(
      productsByContext,
      sOrderActive.activeOrder.payload.products,
    );

    const allHomeProductsSorted = AppConfig.sortByProductsNoStock ?
      sortByProductsNoStock(homeProducts) :
      sortBy(homeProducts, 'name');

    let name;
    if (!sCurrentAccount.isAnonymous && sCurrentAccount.user) {
      name = sCurrentAccount.user.display_name;
    }

    if (!cartErrorCached && storeInvalid) {
      return (
        <CustomAlert
          showModal={this.state.storeInvalid}
          title="Acesso negado"
          message="Seu acesso nessa loja não é permitido! Caso queira comprar nessa loja, por favor solicite acesso."
          modalLoading={false}
          disableButtons={false}
          okButtonPress={async () => {
            this.setState({ storeInvalid: false });
            NavigationHelper.push(this.props.componentId, SCREEN_STORES.name);
          }}
        />
      );
    }

    return (
      <Home
        {...this.props}
        ref={ref => {
          this.view = ref;
        }}
        loadData={valueLogin.auth}
        loadPromo={valueLoadPromo.load}
        loadCat={valueLoadCategories.load}
        loadProdContext={valueLoadProdContext.load}
        loadNoticies={valueLoadNoticies.load}
        componentId={this.props.componentId}
        name={name || 'Bem-vindo'}
        banners={promotions}
        categories={categories}
        products={allHomeProductsSorted || []}
        notices={notices || []}
        onFocus={this.onFocus}
        onPressBanner={this.onPressBanner}
        onPressCategory={this.onPressCategory}
        onPressNotice={this.onPressNotice}
        progressDownload={progressDownload}
        refreshing={refreshing}
        onRefresh={this.onRefresh}
        onPressCompanyChoice={this.onPressCompanyChoice}
        onPressCompany={this.onPressCompany}
        companyChoiceData={companyChoiceData}
        modalCompanyChoiceVisible={modalCompanyChoiceVisible}
        companyChosen={companyChosen}
        messageError={cartErrorCached || 'Ocorreu um erro ao carregar seu carrinho'}
        errorCart={errorCart}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

const getCurrentCompany = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_COMPANY, 'payload']).toJS());

const getCurrentStoreItem = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM]).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

const getLoginState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOGIN_STATE, 'payload']).toJS());

const getLoadPromoState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_PROMOTIONS_STATE, 'payload']).toJS());

const getLoadCatState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_CATEGORIES_STATE, 'payload']).toJS());

const getLoadProdContextState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_PROD_CONTEXT_STATE, 'payload']).toJS());

const getLoadNoticiesState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_NOTICIES_STATE, 'payload']).toJS());

const getChangeStore = createImmutableSelector([state => state], state =>
  state.getIn([STORE_CHANGE_STORE_STATE]).toJS());

const getErrorCompanyCustomer = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ERROR_COMPANYCUSTOMER, 'payload']).toJS());

const getLoadCartState = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_LOAD_CART_STATE, 'payload']).toJS());

const getLoadAfterSaleState = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_SET_LOAD_AFTER_SALE_STATE, 'payload']).toJS());

const getChangeIconState = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_CHANGE_ICONS_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentAccount: getAccount(state),
    sOrderActive: getOrderActive(state),
    sCurrentCompany: getCurrentCompany(state),
    sCurrentStoreItem: getCurrentStoreItem(state),
    currentNetInfo: getNetInfo(state),
    valueLogin: getLoginState(state),
    valueLoadPromo: getLoadPromoState(state),
    valueLoadCategories: getLoadCatState(state),
    valueLoadProdContext: getLoadProdContextState(state),
    valueLoadNoticies: getLoadNoticiesState(state),
    valueChangeStore: getChangeStore(state),
    valueErrorCompanyCustomer: getErrorCompanyCustomer(state),
    valueLoadCart: getLoadCartState(state),
    valueLoadAfterSale: getLoadAfterSaleState(state),
    valueIconsChange: getChangeIconState(state),
  };
}

export default connect(mapStateToProps)(HomeContainer);
