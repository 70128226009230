import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SQLite from '~/modules-wrapper/react-native-sqlite-storage';
import { SchemaModels } from '~/database/utils/DBUtils';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import AppConfig from '../AppConfig';
import * as schema from './schemas';
import { database_version } from './schemas';

// SQLite.DEBUG(true);
SQLite.enablePromise(true);

const database_name = `${AppConfig.appName}.db`;
const db_version_key = 'db_version';
const database_displayname = AppConfig.appName;
const database_size = 200000;

const parseDBVersionToNumber = parseFloat(database_version);

class SQLiteManager {
  constructor() {
    this.type = 'SingletonDefaultExportInstance';

    try {
      this.db = null;
      this.webSQL = window.openDatabase(
        'PolicommerceWEB',
        database_version,
        database_displayname,
        database_size, function(db) {
          console.log('DATABASE: ', db);
          console.log("Database opened Successfully! Or created for the first time !");
        });
      if (Platform.OS === 'web') {
        this.initDB();
      }
    } catch (e) {
      console.log('SQLiteManager SWL ERROR ', e.message);
    }
  }

  successCB() {
    console.log('SQL executed fine');
  }

  initDB() {
    console.log('INIT DB');
    let db;
    if (Platform.OS === 'ios') {
      return new Promise((resolve) => {
        SQLite.echoTest()
          .then(() => {
            SQLite.openDatabase({
              name: 'Policommerce.db', localtion: 'default', createFromLocation: 1, success: this.openCB,
            })
              .then((DB) => {
                this.db = DB;
                db = DB;
                db.executeSql('SELECT 1 FROM Product LIMIT 1')
                  .then(() => {
                    //
                    AsyncStorage.getItem(db_version_key)
                      .then((data) => {
                        const dataToNumber = data !== null ? parseFloat(data) : null;
                        if (dataToNumber === null || dataToNumber < parseDBVersionToNumber) {
                          this.schemaMigration(db);
                        }
                      });
                  })
                  .catch((error) => {
                    db.transaction((tx) => {
                      for (const name in schema.Tables) {
                        this.createTable(tx, schema.Tables[name], name);
                      }
                    })
                      .then(() => {
                        //
                      })
                      .catch((err) => {
                        //
                      });
                  });
                resolve(db);
              })
              .catch((error) => {
                //
              });
          }) // end then
          .catch((error) => {
            //
          });
      });
    }

    if (Platform.OS === 'web') {
      return new Promise((resolve) => {
        const t = this.createDatabaseWeb();
        console.log('WEB');
        const conn = this.webSQL;

        console.log('PROMISES promises conn', conn);

        this.db = conn;
        db = conn;

        resolve(conn);

        // conn.transaction((tx) => {
        //   console.log('INSIDE TRANS: ', tx);
        //   tx.executeSql(
        //     `SELECT 1 FROM ${schema.CATEGORY_SCHEMA} LIMIT 1`,
        //     [],
        //     function (transaction, result) {
        //       console.log('PROMISES promises RESULT: ', result);
        //
        //       resolve(conn);
        //     }, function (transaction, error) {
        //       console.log('PROMISES promises error', error);
        //       t;
        //     });
        // });
      });
      // this.createDatabaseWeb();
    }

    if (Platform.OS === 'android') {
      return new Promise((resolve) => {
        SQLite.echoTest()
          .then(() => {
            SQLite.openDatabase(
              database_name,
              parseDBVersionToNumber,
              database_displayname,
              database_size,
            )
              .then((DB) => {
                this.db = DB;
                db = DB;
                db.executeSql(`SELECT 1 FROM ${schema.PRODUCT_SCHEMA} LIMIT 1`)
                  .then(() => {
                    //
                    AsyncStorage.getItem(db_version_key)
                      .then((data) => {
                        const dataToNumber = data !== null ? parseFloat(data) : null;
                        if (dataToNumber === null || dataToNumber < parseDBVersionToNumber) {
                          this.schemaMigration(db);
                        }
                      });
                  })
                  .catch((error) => {
                    db.transaction((tx) => {
                      for (const name in schema.Tables) {
                        this.createTable(tx, schema.Tables[name], name);
                      }
                    })
                      .then(() => {
                        //
                      })
                      .catch((err) => {
                        //
                      });
                  });
                resolve(db);
              })
              .catch((error) => {
                //
              });
          })
          .catch((error) => {
            //
          });
      });
    }

  }

  createDatabaseWeb() {
    console.log('CALLED CREATE TABLEE');
    const db = this.webSQL;
    // console.log('DB: ', db);
    for (const name in schema.Tables) {
      this.createTablee(db, schema.Tables[name], name);
    }
  }

  createTablee(db, table, tableName) {
    // console.log('DB: ', db);
    // console.log('TABLE: , table');
    // console.log('TABLE NAME: ', tableName);
    db.transaction(function(tx) {
      let sql = `CREATE TABLE IF NOT EXISTS ${tableName} `;
      const createColumns = [];
      for (const key in table) {
        createColumns.push(`${key} ${table[key].type.type} ${
          table[key].primary_key ? ' PRIMARY KEY NOT NULL' : ''
        } default ${table[key].default_value}`);
      }
      sql += `(${createColumns.join(', ')});`;
      // console.log('SQL: ', sql);
      tx.executeSql(
        sql,
        [],
        () => {
          //
        },
        () => {
          //
        },
      );
    }, this.transError, this.transSuccess);
}

  schemaMigration(db) {
    const existingTables = {};
    for (const name in schema.Tables) {
      existingTables[name] = [];
      db.transaction((tx) => {
        tx.executeSql(`PRAGMA table_info(${name})`, [], (tx, results) => {
          for (let i = 0; i < results.rows.length; i++) {
            existingTables[name].push(results.rows.item(i).name);
          }
          this.alterTable(tx, schema.Tables[name], name, existingTables[name]);
        }, (err) => {
          console.log('PRAGMA ERROR', err);
        });
      });
    }
    AsyncStorage.setItem(db_version_key, `${parseDBVersionToNumber}`);
  }

  alterTable(tx, table, tableName, existingColumns) {
    const sql = `ALTER TABLE ${tableName} ADD COLUMN `;
    for (const key in table) {
      if (existingColumns && !existingColumns.includes(key)) {
        const alterColumnSql = `${sql}${key} ${table[key].type.type} ${table[key].primary_key ? ' PRIMARY KEY NOT NULL' : ''} default ${table[key].default_value}`;
        tx.executeSql(alterColumnSql, [], (tx, results) => {
        }, (err) => {
          console.log('ERROR ERROR ALTERING TABLE', err);
        });
      }
    }
  }

  closeDatabase(db) {
    if (db) {
      db.close()
        .then((status) => {
          //
        })
        .catch((error) => {
          this.errorCB(error);
        });
    } else {
      //
    }
  }

  addProduct(prods) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < prods.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.PRODUCT_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    prods[i].id,
                    prods[i].type,
                    prods[i].price,
                    prods[i].min_price,
                    prods[i].name,
                    prods[i].description,
                    prods[i].product_code,
                    prods[i].company_id,
                    prods[i].ean,
                    prods[i].volume_factor,
                    prods[i].stock,
                    JSON.stringify(prods[i].attributes),
                    JSON.stringify(prods[i].children),
                    prods[i].notify_me,
                    JSON.stringify(prods[i].liked),
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < prods.length; i++) {
          let attributes = null;
          if (prods[i].attributes) {
            attributes = JSON.stringify(prods[i].attributes);
          }

          let children = null;
          if (prods[i].children) {
            children = JSON.stringify(prods[i].children);
          }

          let liked = null;
          if (prods[i].liked) {
            liked = JSON.stringify(prods[i].liked)
          }

        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PRODUCT_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            prods[i].id,
            prods[i].type,
            prods[i].price,
            prods[i].min_price,
            prods[i].name,
            prods[i].description,
            prods[i].product_code,
            prods[i].company_id,
            prods[i].ean,
            prods[i].volume_factor,
            prods[i].stock,
            attributes,
            children,
            prods[i].notify_me,
            liked,
          ],
          function (transaction, result) {
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
        }
      })
    });

  }

  updateProduct(prod) {
    const {
      id,
      type,
      price,
      min_price,
      name,
      description,
      product_code,
      company_id,
      ean,
      volume_factor,
      stock,
      attributes,
      children,
      notify_me,
      liked,
    } = prod;

    if (Platform.OS === 'web') {
      return new Promise((resolve) => {
        this.webSQL.transaction((tx) => {

          let likedParse = null;
          if (liked) {
            likedParse = JSON.stringify(liked);
          }

          let childrenParse = null;
          if (children) {
            childrenParse = JSON.stringify(children);
          }

          console.log('updateProduct results', childrenParse);

          tx.executeSql(
            `UPDATE ${schema.PRODUCT_SCHEMA} set id=?, type=?, price=?, min_price=?, name=?, description=?, product_code=?, company_id=?, ean=?, volume_factor=?, stock=?, attributes=?, children=?, notify_me=?, liked=? WHERE product_code=?`,
            [
              id,
              type,
              price,
              min_price,
              name,
              description,
              product_code,
              company_id,
              ean,
              volume_factor,
              stock,
              attributes,
              childrenParse,
              notify_me,
              likedParse,
              product_code,
            ],
            (transaction, results) => {
              if (results.rowsAffected > 0) {
                resolve(prod);
              }
            }
          );
        })
      });
    }

    return new Promise((resolve) => {
      this.db
        .transaction((tx) => {
          tx.executeSql(
              `UPDATE ${schema.PRODUCT_SCHEMA} set id=?, type=?, price=?, min_price=?, name=?, description=?, product_code=?, company_id=?, ean=?, volume_factor=?, stock=?, attributes=?, children=?, notify_me=?, liked=? WHERE product_code=?`,
              [
                id,
                type,
                price,
                min_price,
                name,
                description,
                product_code,
                company_id,
                ean,
                volume_factor,
                stock,
                attributes,
                JSON.stringify(children),
                notify_me,
                JSON.stringify(liked),
                product_code,
              ],
            ).then(([tx, results]) => {
              if (results.rowsAffected > 0) {
                resolve(prod);
              }
            }).catch((err) => {
              //
            });
        })
        .then((result) => {
          //
        })
        .catch((err) => {
          //
        });
    });
  }

  getPromotions() {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const promos = [];

              const callbackSuccess = (results) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  const parsePromo = {
                    id: row.id,
                    code: row.code,
                    group_code: row.group_code,
                    image: row.image,
                    title: row.title,
                    description: row.description,
                    requirements: JSON.parse(row.requirements),
                    items: JSON.parse(row.items),
                  };
                  promos.push(parsePromo);
                }
                resolve(promos);
              }

              if (Platform.OS === 'web') {
                const query = `SELECT * FROM ${schema.PROMOTION_SCHEMA}`;

                console.log('PROMOTIONS query', query);

                tx.executeSql(query, [], (transaction, results)=> {
                  callbackSuccess(results);
                });
              } else {
                tx.executeSql(`SELECT * FROM ${schema.PROMOTION_SCHEMA}`, [])
                  .then(([tx, results]) => {
                    callbackSuccess(results)
                  })
                  .catch((err) => {
                    //
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const promos = [];
        tx.executeSql(
          `SELECT * FROM ${schema.PROMOTION_SCHEMA}`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
            for (let i = 0; i < len; i++) {
              const row = result.rows.item(i);
              const parsePromo = {
                id: row.id,
                code: row.code,
                group_code: row.group_code,
                image: row.image,
                title: row.title,
                description: row.description,
                requirements: JSON.parse(row.requirements),
                items: JSON.parse(row.items),
              };
              promos.push(parsePromo);
            }
            resolve(promos);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addPromotion(promos) {
    console.log('PROMOS ADD: ', promos);
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < promos.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.PROMOTION_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    promos[i].id,
                    promos[i].code,
                    promos[i].group_code,
                    promos[i].image,
                    promos[i].title,
                    promos[i].description,
                    JSON.stringify(promos[i].requirements),
                    JSON.stringify(promos[i].items),
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    //
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < promos.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PROMOTION_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            promos[i].id,
            promos[i].code,
            promos[i].group_code,
            promos[i].image,
            promos[i].title,
            promos[i].description,
            JSON.stringify(promos[i].requirements),
            JSON.stringify(promos[i].items),
          ],
          function (transaction, result) {
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
        }
      })
    });

  }

  addNotices(notices) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < notices.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.NOTICES_SCHEMA} VALUES (?, ?, ?, ?, ?)`,
                  [
                    notices[i].id,
                    notices[i].title,
                    notices[i].description,
                    notices[i].image,
                    JSON.stringify(notices[i].action),
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    console.log('error 1', error);
                  });
              }
            })
              .then((result) => {

              })
              .catch((err) => {
                console.log('error 2', err);
              });
          })
          .catch((err) => {
            console.log('error 3', err);
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < notices.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.NOTICES_SCHEMA} VALUES (?, ?, ?, ?, ?)`,
          [
            notices[i].id,
            notices[i].title,
            notices[i].description,
            notices[i].image,
            JSON.stringify(notices[i].action),
          ],
          function (transaction, result) {
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
        }
      })
    });

  }

  getNotices() {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const notices = [];
              tx.executeSql(`SELECT * FROM ${schema.NOTICES_SCHEMA}`, [])
                .then(([tx, results]) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    const parseNotices = {
                      id: row.id,
                      title: row.title,
                      description: row.description,
                      image: row.image,
                      action: JSON.parse(row.action),
                    };
                    notices.push(parseNotices);
                  }
                  resolve(notices);
                })
                .catch((err) => {
                  //
                });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const notices = [];
        tx.executeSql(
          `SELECT * FROM ${schema.NOTICES_SCHEMA}`,
          [],
          function (transaction, result) {
            console.log('TRANSACTION NOTICES');
            const len = result.rows.length;
            for (let i = 0; i < len; i++) {
              const row = result.rows.item(i);
              const parseNotices = {
                id: row.id,
                title: row.title,
                description: row.description,
                image: row.image,
                action: JSON.parse(row.action),
              };
              notices.push(parseNotices);
            }
            // console.log(notices);
            // console.log('Table created Successfully!');
            resolve(notices);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  getRegisterAddress() {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.REGISTER_ADRESS_SCHEMA};`, []).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            })
              .then((result) => {})
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const allData = [];
        tx.executeSql(
          `SELECT * FROM ${schema.REGISTER_ADRESS_SCHEMA};`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = result.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addRegisterAddress(addresses) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < addresses.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.REGISTER_ADRESS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    addresses[i].id,
                    addresses[i].account_id,
                    addresses[i].street_number,
                    addresses[i].street,
                    addresses[i].district,
                    addresses[i].city,
                    addresses[i].state_city,
                    addresses[i].country,
                    addresses[i].postal_code,
                    addresses[i].complement,
                    addresses[i].reference_point,
                    addresses[i].latitude,
                    addresses[i].longitude,
                    addresses[i].created_at,
                    addresses[i].updated_at,
                    addresses[i].deleted_at,
                    addresses[i].surname,
                    addresses[i].kind,
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    console.log('ERROR', error);
                  });
              }
            })
              .then((result) => {

              })
              .catch((err) => {
                console.log('error 2', err);
              });
          })
          .catch((err) => {
            console.log('error 3', err);
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < addresses.length; i++) {
          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.REGISTER_ADRESS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    addresses[i].id,
                    addresses[i].account_id,
                    addresses[i].street_number,
                    addresses[i].street,
                    addresses[i].district,
                    addresses[i].city,
                    addresses[i].state_city,
                    addresses[i].country,
                    addresses[i].postal_code,
                    addresses[i].complement,
                    addresses[i].reference_point,
                    addresses[i].latitude,
                    addresses[i].longitude,
                    addresses[i].created_at,
                    addresses[i].updated_at,
                    addresses[i].deleted_at,
                    addresses[i].surname,
                    addresses[i].kind,
                  ],
            function(transaction, result) {
              // console.log(result);
              // console.log('Table created Successfully!');
              resolve(result);
          }, function(transaction, error) {
              console.log(error);
          });
        }
      })
    });

  }

  updateRegisterAddr(addr) {
    if (Platform.OS !== 'web') {
    return new Promise((resolve) => {
      this.db
        .transaction((tx) => {
          tx.executeSql(
            `UPDATE ${schema.REGISTER_ADRESS_SCHEMA} set id=?, account_id=?, street_number=?, street=?, district=?, city=?, state_city=?, country=?, postal_code=?, complement=?, reference_point=?, latitude=?, longitude=?, created_at=?, updated_at=?, deleted_at=?, surname=?, kind=? WHERE id=?`,
            [
              addr.id,
              addr.account_id,
              addr.street_number,
              addr.street,
              addr.district,
              addr.city,
              addr.state_city,
              addr.country,
              addr.postal_code,
              addr.complement,
              addr.reference_point,
              addr.latitude,
              addr.longitude,
              addr.created_at,
              addr.updated_at,
              addr.deleted_at,
              addr.surname,
              addr.kind,
              addr.id,
            ],
          ).then(([tx, results]) => {
            if (results.rowsAffected > 0) {
              resolve(addr);
            } else {
              console.log('ERROR: ', results);
            }
          }).catch((err) => {
            //
          });
        })
        .then((result) => {
          //
        })
        .catch((err) => {
          //
        });
    });
  }

  return new Promise((resolve) => {
    this.webSQL.transaction((tx) => {
        tx.executeSql(
          `UPDATE ${schema.REGISTER_ADRESS_SCHEMA} set id=?, account_id=?, street_number=?, street=?, district=?, city=?, state_city=?, country=?, postal_code=?, complement=?, reference_point=?, latitude=?, longitude=?, created_at=?, updated_at=?, deleted_at=?, surname=?, kind=? WHERE id=?`,
          [
            addr.id,
            addr.account_id,
            addr.street_number,
            addr.street,
            addr.district,
            addr.city,
            addr.state_city,
            addr.country,
            addr.postal_code,
            addr.complement,
            addr.reference_point,
            addr.latitude,
            addr.longitude,
            addr.created_at,
            addr.updated_at,
            addr.deleted_at,
            addr.surname,
            addr.kind,
            addr.id,
          ],
          function(transaction, result) {
            if (result.rowsAffected > 0) {
              resolve(addr);
            } else {
              console.log('ERROR: ', result);
            }
        }, function(transaction, error) {
            console.log(error);
        });
    })
  });

  }

  deleteRegisterAddress(id) {
    if (Platform.OS !== 'web') {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            tx.executeSql(`DELETE FROM ${schema.REGISTER_ADRESS_SCHEMA} WHERE id = ?`, [id]).then(([tx, results]) => {
              resolve(results);
            });
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  return new Promise((resolve) => {
    this.webSQL.transaction((tx) => {
      tx.executeSql(
        `DELETE FROM ${schema.REGISTER_ADRESS_SCHEMA} WHERE id = ?`,
        [id],
        function (transaction, result) {
          resolve(result);
        }, function (transaction, error) {
          console.log(error);
        });
    })
  });

  }

  addCombos(combos) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < combos.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.COMBO_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    combos[i].pkey,
                    combos[i].campanha,
                    combos[i].coddesconto,
                    combos[i].codgrupo,
                    combos[i].codproduto,
                    combos[i].descgrupo,
                    combos[i].descricao,
                    combos[i].descricao_unidade,
                    combos[i].ean,
                    combos[i].estoque,
                    combos[i].faixa,
                    combos[i].id,
                    combos[i].multiplo_venda,
                    combos[i].ordem,
                    combos[i].periodo,
                    combos[i].preco,
                    combos[i].produto,
                    combos[i].tipo,
                    combos[i].unidade,
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < combos.length; i++) {
          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.COMBO_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    combos[i].pkey,
                    combos[i].campanha,
                    combos[i].coddesconto,
                    combos[i].codgrupo,
                    combos[i].codproduto,
                    combos[i].descgrupo,
                    combos[i].descricao,
                    combos[i].descricao_unidade,
                    combos[i].ean,
                    combos[i].estoque,
                    combos[i].faixa,
                    combos[i].id,
                    combos[i].multiplo_venda,
                    combos[i].ordem,
                    combos[i].periodo,
                    combos[i].preco,
                    combos[i].produto,
                    combos[i].tipo,
                    combos[i].unidade,
                  ],
            function(transaction, result) {
              // console.log(result);
              // console.log('Table created Successfully!');
              resolve(result);
          }, function(transaction, error) {
              console.log(error);
          });
        }
      })
    });

  }

  getProductsCombos(coddesconto, all = false) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (all) {
              const combos = [];
              tx.executeSql(`SELECT * FROM ${schema.COMBO_SCHEMA} WHERE tipo != 'combo'`, [])
                .then(([tx, results]) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    combos.push(row);
                  }
                  resolve(combos);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              const combos = [];
              tx.executeSql(`SELECT * FROM ${schema.COMBO_SCHEMA} WHERE coddesconto='${coddesconto}' AND tipo != 'combo'`, [])
                .then(([tx, results]) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    combos.push(row);
                  }
                  resolve(combos);
                })
                .catch((err) => {
                  reject(err);
                });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getCampaignsCombos() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            const combos = [];
            tx.executeSql(`SELECT * FROM ${schema.COMBO_SCHEMA} WHERE tipo='combo'`, [])
              .then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  combos.push(row);
                }
                resolve(combos);
              })
              .catch((err) => {
                reject(err);
              });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addProductCtx(prods) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < prods.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.PRODUCT_CONTEXT_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    prods[i].id,
                    prods[i].type,
                    prods[i].context_title,
                    prods[i].context_id,
                    prods[i].price,
                    prods[i].min_price,
                    prods[i].name,
                    prods[i].description,
                    prods[i].product_code,
                    prods[i].company_id,
                    prods[i].ean,
                    prods[i].volume_factor,
                    prods[i].stock,
                    JSON.stringify(prods[i].extra_info),
                    JSON.stringify(prods[i].categories),
                    JSON.stringify(prods[i].price_conditions),
                  ],
                )
                  .then(([tx, results]) => {
                    //
                    resolve(results);
                  })
                  .catch((error) => {
                    //
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < prods.length; i++) {
          let extra_info = null;
          if (prods[i].extra_info) {
            extra_info = JSON.stringify(prods[i].extra_info);
          }

          let categories = null;
          if (prods[i].categories) {
            categories = JSON.stringify(prods[i].categories);
          }

          let price_conditions = null;
          if (prods[i].price_conditions) {
            price_conditions = JSON.stringify(prods[i].price_conditions);
          }

          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.PRODUCT_CONTEXT_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
            [
              prods[i].id,
              prods[i].type,
              prods[i].context_title,
              prods[i].context_id,
              prods[i].price,
              prods[i].min_price,
              prods[i].name,
              prods[i].description,
              prods[i].product_code,
              prods[i].company_id,
              prods[i].ean,
              prods[i].volume_factor,
              prods[i].stock,
              extra_info,
              categories,
              price_conditions,
            ],
            function(transaction, result) {
              // console.log(result);
              // console.log('Table created Successfully!');
              resolve(result);
          }, function(transaction, error) {
              console.log(error);
          });
        }
      })
    });

  }

  addCategory(categorys) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < categorys.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.CATEGORY_SCHEMA} VALUES (?, ?, ?, ?)`,
                  [
                    categorys[i].category_code,
                    categorys[i].type,
                    categorys[i].description,
                    categorys[i].is_root,
                  ],
                ).then(([tx, results]) => {
                  resolve(results);
                });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < categorys.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.CATEGORY_SCHEMA} VALUES (?, ?, ?, ?)`,
          [
            categorys[i].category_code,
            categorys[i].type,
            categorys[i].description,
            categorys[i].is_root,
          ],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      }
      })
    });

  }

  addCategoryRel(categorys) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < categorys.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} VALUES (?, ?, ?)`,
                  [
                    categorys[i].id,
                    categorys[i].parent_code,
                    categorys[i].category_code,
                  ],
                ).then(([tx, results]) => {
                  //
                  resolve(results);
                });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < categorys.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} VALUES (?, ?, ?)`,
          [
            i + 1,
            categorys[i].parent_code,
            categorys[i].category_code,
          ],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      }
      })
    });

  }

  addProductsCategories(prods) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < prods.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.PRODUCTS_CATEGORIES_SCHEMA} VALUES (?, ?, ?, ?)`,
                  [
                    prods[i].id,
                    prods[i].product_id,
                    prods[i].category_code,
                    prods[i].product_code,
                  ],
                ).then(([tx, results]) => {
                  //
                  resolve(results);
                });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < prods.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PRODUCTS_CATEGORIES_SCHEMA} VALUES (?, ?, ?, ?)`,
          [
            i + 1,
            prods[i].product_id,
            prods[i].category_code,
            prods[i].product_code,
          ],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      }
      })
    });

  }

  insertCartInCache(cart) {
    return new Promise((resolve, reject) => {
      CacheStore.set(SchemaModels.properties[1].name, cart)
        .then(() => {
          resolve(cart);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  clearCartCache() {
    return new Promise((resolve, reject) => {
      CacheStore.remove(SchemaModels.properties[1].name)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addCart(cart, update = false, cache = false) {
    console.log('ADD CART SQLITE: ', cart);
    console.log('UPDATE: ', update);
    console.log('CACHE: ', cache);
    const {
      id,
      cart_code,
      amount,
      success,
      company_id,
      customer_id,
      account_id,
      shipping_method,
      delivery_address,
      sale_conditions,
      products,
      messages,
      campaigns,
      payment_type,
      total_paid,
      subtotals,
    } = cart[0];


    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            if (cache) {
              CacheStore.set(SchemaModels.properties[1].name, cart);
            }
            if (update) {
              db.transaction((tx) => {
                tx.executeSql(
                  `UPDATE ${schema.CART_SCHEMA} set id=?, cart_code=? , amount=?, success=?, company_id=?, customer_id=?, account_id=?, shipping_method=?, delivery_address=?, sale_conditions=?, products=?, messages=?, campaigns=?, payment_type=?, total_paid=?, subtotals=? where cart_code=?`,
                  [
                    id,
                    cart_code,
                    amount,
                    success,
                    company_id,
                    customer_id,
                    account_id,
                    JSON.stringify(shipping_method),
                    JSON.stringify(delivery_address),
                    JSON.stringify(sale_conditions),
                    JSON.stringify(products),
                    JSON.stringify(messages),
                    JSON.stringify(campaigns),
                    JSON.stringify(payment_type),
                    total_paid,
                    JSON.stringify(subtotals),
                    cart_code,
                  ],
                  (tx, results) => {
                    if (results.rowsAffected > 0) {
                      resolve(results);
                    }
                  },
                );
              });
            } else {
              db.transaction((tx) => {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.CART_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    id,
                    cart_code,
                    amount,
                    success,
                    company_id,
                    customer_id,
                    account_id,
                    JSON.stringify(shipping_method),
                    JSON.stringify(delivery_address),
                    JSON.stringify(sale_conditions),
                    JSON.stringify(products),
                    JSON.stringify(messages),
                    JSON.stringify(campaigns),
                    JSON.stringify(payment_type),
                    total_paid,
                    JSON.stringify(subtotals),
                  ],
                ).then(([tx, results]) => {
                  resolve(results);
                });
              })
                .then((result) => {
                  //
                })
                .catch((err) => {
                  //
                });
            }
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      if (cache) {
        console.log('CAHE TRUE');
        CacheStore.set(SchemaModels.properties[1].name, cart);
      }

      if (update) {
        this.webSQL.transaction((tx) => {
          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.CART_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
            [
              id,
              cart_code,
              amount,
              success,
              company_id,
              customer_id,
              account_id,
              JSON.stringify(shipping_method),
              JSON.stringify(delivery_address),
              JSON.stringify(sale_conditions),
              JSON.stringify(products),
              JSON.stringify(messages),
              JSON.stringify(campaigns),
              JSON.stringify(payment_type),
              total_paid,
              JSON.stringify(subtotals),
            ],
            function(transaction, result) {
              if (result.rowsAffected > 0) {
                resolve(result);
              }
          }, function(transaction, error) {
              console.log(error);
          });
        })
      } else {
        console.log('UPDATE FALSE');
        this.webSQL.transaction((tx) => {
          console.log('THIS WEBSQL TRANS: ', this.webSQL);
          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.CART_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
            [
              id,
              cart_code,
              amount,
              success,
              company_id,
              customer_id,
              account_id,
              JSON.stringify(shipping_method),
              JSON.stringify(delivery_address),
              JSON.stringify(sale_conditions),
              JSON.stringify(products),
              JSON.stringify(messages),
              JSON.stringify(campaigns),
              JSON.stringify(payment_type),
              total_paid,
              JSON.stringify(subtotals),
            ],
            function(transaction, result) {
              // console.log('RESULT ADD CART: ', result);
              resolve(result);
          }, function(transaction, error) {
              console.log('ADD CART ERROR: ', error);
          });
        })
      }
    });

  }

  updateCart(cart, cache = false) {
    const {
      id,
      cart_code,
      amount,
      success,
      company_id,
      customer_id,
      account_id,
      shipping_method,
      delivery_address,
      sale_conditions,
      products,
      messages,
      campaigns,
      payment_type,
      total_paid,
      subtotals,
    } = cart[0];

    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          if (cache) {
            CacheStore.set(SchemaModels.properties[1].name, cart);
          }
          db.transaction((tx) => {
            tx.executeSql(
              `UPDATE ${schema.CART_SCHEMA} set id=?, cart_code=? , amount=?, success=?, company_id=?, customer_id=?, account_id=?, shipping_method=?, delivery_address=?, sale_conditions=?, products=?, messages=?, campaigns=?, payment_type=?, total_paid=?, subtotals=? where cart_code=?`,
              [
                id,
                cart_code,
                amount,
                success,
                company_id,
                customer_id,
                account_id,
                JSON.stringify(shipping_method),
                JSON.stringify(delivery_address),
                JSON.stringify(sale_conditions),
                JSON.stringify(products),
                JSON.stringify(messages),
                JSON.stringify(campaigns),
                JSON.stringify(payment_type),
                total_paid,
                JSON.stringify(subtotals),
                cart_code,
              ],
              (tx, results) => {
                if (results.rowsAffected > 0) {
                  resolve(results);
                }
              },
            );
          });
        })
        .catch((err) => {
          //
        });
    });
  }

  addSales(sales) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < sales.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.SALES_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                  [
                    sales[i].id,
                    sales[i].customer_id,
                    sales[i].customer_name,
                    sales[i].account_id,
                    sales[i].account_name,
                    JSON.stringify(sales[i].account_phone),
                    JSON.stringify(sales[i].shipping_address),
                    JSON.stringify(sales[i].billing_address),
                    JSON.stringify(sales[i].sale_conditions),
                    JSON.stringify(sales[i].integration_status),
                    JSON.stringify(sales[i].history_integration_status),
                    JSON.stringify(sales[i].payment_status),
                    JSON.stringify(sales[i].products),
                    JSON.stringify(sales[i].messages),
                    sales[i].order_code,
                    sales[i].amount,
                    sales[i].sent_date,
                    sales[i].observation,
                  ],
                )
                  .then(([tx, results]) => {
                    //
                    resolve(results);
                  })
                  .catch((error) => {
                    //
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < sales.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.SALES_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            sales[i].id,
            sales[i].customer_id,
            sales[i].customer_name,
            sales[i].account_id,
            sales[i].account_name,
            sales[i].account_phone ? JSON.stringify(sales[i].account_phone) : null,
            sales[i].shipping_address ? JSON.stringify(sales[i].shipping_address) : null,
            sales[i].billing_address ? JSON.stringify(sales[i].billing_address) : null,
            sales[i].sale_conditions ? JSON.stringify(sales[i].sale_conditions) : null,
            sales[i].integration_status ? JSON.stringify(sales[i].integration_status) : null,
            sales[i].history_integration_status ? JSON.stringify(sales[i].history_integration_status) : null,
            sales[i].payment_status ? JSON.stringify(sales[i].payment_status) : null,
            sales[i].products ? JSON.stringify(sales[i].products) : null,
            sales[i].messages ? JSON.stringify(sales[i].messages) : null,
            sales[i].order_code,
            sales[i].amount,
            sales[i].sent_date,
            sales[i].observation ? sales[i].observation : null,
          ],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      }
      })
    });

  }

  addCustomer(customer) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.CUSTOMER_SCHEMA} VALUES (?, ?, ?, ?, ?, ?)`,
                [
                  customer.id,
                  customer.name,
                  customer.trading_name,
                  customer.nin,
                  JSON.stringify(customer.address),
                  JSON.stringify(customer.phones),
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.CUSTOMER_SCHEMA} VALUES (?, ?, ?, ?, ?, ?)`,
          [
            customer.id,
            customer.name,
            customer.trading_name,
            customer.nin,
            JSON.stringify(customer.address),
            JSON.stringify(customer.phones),
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addAddress(addr) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.ADDRESS_SCHEMA} VALUES (?, ?, ?, ?, ?)`,
                [
                  addr.address,
                  addr.district,
                  addr.postal_code,
                  addr.city,
                  addr.state,
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.ADDRESS_SCHEMA} VALUES (?, ?, ?, ?, ?)`,
          [
            addr.address,
            addr.district,
            addr.postal_code,
            addr.city,
            addr.state,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addPhones(phone) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(`INSERT OR REPLACE INTO ${schema.PHONES_SCHEMA} VALUES (?)`, [
                phone.main,
              ]).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PHONES_SCHEMA} VALUES (?)`,
          [
            phone.main,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addImages(img) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(`INSERT OR REPLACE INTO ${schema.IMAGES_SCHEMA} VALUES (?)`, [
                img.brand,
              ]).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.IMAGES_SCHEMA} VALUES (?)`,
          [
            img.brand,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addSaleConditions(saleCond) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < saleCond.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.SALE_CONDITIONS_SCHEMA} VALUES (?, ?, ?, ?)`,
                  [
                    saleCond[i].key,
                    saleCond[i].description,
                    saleCond[i].value_code,
                    saleCond[i].value_description,
                  ],
                ).then(([tx, results]) => {
                  resolve(results);
                });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < saleCond.length; i++) {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.SALE_CONDITIONS_SCHEMA} VALUES (?, ?, ?, ?)`,
          [
            saleCond[i].key,
            saleCond[i].description,
            saleCond[i].value_code,
            saleCond[i].value_description,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
        }
      })
    });

  }

  addShippingMethods(shipp) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              for (let i = 0; i < shipp.length; i++) {
                tx.executeSql(
                  `INSERT OR REPLACE INTO ${schema.SHIPPING_METHODS_SCHEMA} VALUES (?, ?)`,
                  [
                    JSON.stringify(shipp[i].enderecos),
                    JSON.stringify(shipp[i].fretes),
                  ],
                )
                  .then(([tx, results]) => {
                    resolve(results);
                  })
                  .catch((error) => {
                    //
                  });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        for (let i = 0; i < shipp.length; i++) {
          let enderecos = null;
          if (shipp[i].enderecos) {
            enderecos = JSON.stringify(shipp[i].enderecos);
          }

          let fretes = null;
          if (shipp[i].fretes) {
            fretes = JSON.stringify(shipp[i].fretes);
          }

          tx.executeSql(
            `INSERT OR REPLACE INTO ${schema.SHIPPING_METHODS_SCHEMA} VALUES (?, ?)`,
            [
              enderecos, fretes,
            ],
            function (transaction, result) {
              // console.log(result);
              // console.log('Table created Successfully!');
              resolve(result);
            }, function (transaction, error) {
              console.log(error);
            });
        }
      })
    });

  }

  addDeliveryAddr(delivery) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.DELIVERY_ADDRESS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                [
                  delivery.code,
                  delivery.description,
                  delivery.district,
                  delivery.postal_code,
                  delivery.complement,
                  delivery.number,
                  delivery.city,
                  delivery.state,
                  delivery.address,
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.DELIVERY_ADDRESS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            delivery.code,
            delivery.description,
            delivery.district,
            delivery.postal_code,
            delivery.complement,
            delivery.number,
            delivery.city,
            delivery.state,
            delivery.address,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addIntegrationStatus(intStatus) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            for (let i = 0; i < intStatus.length; i++) {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.INTEGRATION_STATUS_SCHEMA} VALUES (?, ?, ?)`,
                [
                  intStatus[i].code,
                  intStatus[i].description,
                  intStatus[i].status_date,
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addMessages(msg) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            for (let i = 0; i < msg.length; i++) {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.MESSAGES_SCHEMA} VALUES (?, ?, ?, ?, ?)`,
                [
                  msg[i].id,
                  msg[i].content,
                  msg[i].sent_at,
                  msg[i].sender_class,
                  msg[i].sender_id,
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addIcons(icon) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            for (let i = 0; i < icon.length; i++) {
              tx.executeSql(`INSERT OR REPLACE INTO ${schema.ICON_SCHEMA} VALUES (?, ?)`, [
                icon[i].source,
                icon[i].content,
              ]).then(([tx, results]) => {
                resolve(results);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addCustomerCompanyAccounts(customer) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.CUSTOMER_COMPANY_ACCOUNTS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?)`,
                [
                  customer.company_id,
                  customer.customer_id,
                  customer.account_id,
                  customer.authorized_by,
                  customer.approved,
                  JSON.stringify(customer.customer),
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.CUSTOMER_COMPANY_ACCOUNTS_SCHEMA} VALUES (?, ?, ?, ?, ?, ?)`,
          [
            customer.company_id,
            customer.customer_id,
            customer.account_id,
            customer.authorized_by,
            customer.approved,
            JSON.stringify(customer.customer),
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addExtraInfo(extrainfo) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            tx.executeSql(`INSERT OR REPLACE INTO ${schema.EXTRA_INFO_SCHEMA} VALUES (?, ?, ?)`, [
              extrainfo.content,
              JSON.stringify(extrainfo.position),
              extrainfo.title,
            ]).then(([tx, results]) => {
              resolve(results);
            });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addProdCatCtx(prodCtx) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            tx.executeSql(
              `INSERT OR REPLACE INTO ${schema.CATEGORY_PRODUCT_CONTEXT_SCHEMA} VALUES (?, ?)`,
              [prodCtx.code, prodCtx.description],
            ).then(([tx, results]) => {
              resolve(results);
            });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addProdSales(prodSales) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.PRODUCT_SALES_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
                [
                  prodSales.id,
                  JSON.stringify(prodSales.unit_price),
                  prodSales.name,
                  prodSales.product_code,
                  JSON.stringify(prodSales.total_price),
                  prodSales.ean,
                  JSON.stringify(prodSales.quantity),
                  JSON.stringify(prodSales.original_total_price),
                  JSON.stringify(prodSales.original_quantity),
                  JSON.stringify(prodSales.original_unit_price),
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PRODUCT_SALES_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            prodSales.id,
            JSON.stringify(prodSales.unit_price),
            prodSales.name,
            prodSales.product_code,
            JSON.stringify(prodSales.total_price),
            prodSales.ean,
            JSON.stringify(prodSales.quantity),
            JSON.stringify(prodSales.original_total_price),
            JSON.stringify(prodSales.original_quantity),
            JSON.stringify(prodSales.original_unit_price),
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addPaymentTerm(pay) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(`INSERT OR REPLACE INTO ${schema.PAYMENT_TERM_SCHEMA} VALUES (?, ?, ?)`, [
                pay.id,
                pay.description,
                pay.display_order,
              ]).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PAYMENT_TERM_SCHEMA} VALUES (?, ?, ?)`,
          [
            pay.id,
            pay.description,
            pay.display_order || 1,
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  getAllPaymentTerm() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_TERM_SCHEMA};`, [], (transaction, results) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            } else {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_TERM_SCHEMA};`, []).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addPaymentCombineKey(pay) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.PAYMENT_COMBINE_KEY_SCHEMA} VALUES (?, ?, ?)`,
                [pay.id, pay.payment_option_id, pay.payment_term_id],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PAYMENT_COMBINE_KEY_SCHEMA} VALUES (?, ?, ?)`,
          [
            pay.id,
            pay.payment_option_id,
            pay.payment_term_id
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  getAllPaymentCombineKey() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_COMBINE_KEY_SCHEMA};`, [], (tx, results) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            } else {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_COMBINE_KEY_SCHEMA};`, []).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  addPaymentOption(pay) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.PAYMENT_OPTION_SCHEMA} VALUES (?, ?, ?)`,
                [pay.id, pay.description, pay.kind],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.PAYMENT_OPTION_SCHEMA} VALUES (?, ?, ?)`,
          [
            pay.id,
            pay.description,
            pay.kind
          ],
          function (transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  addAppConfig(config) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.APP_CONFIG_SCHEMA} VALUES (?, ?)`,
                [config.id, JSON.stringify(config)],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.APP_CONFIG_SCHEMA} VALUES (?, ?)`,
                [config.id, JSON.stringify(config)],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      })
    });

  }

  getAppConfig() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            tx.executeSql(`SELECT * FROM ${schema.APP_CONFIG_SCHEMA}`, []).then(([tx, results]) => {
              resolve(results);
            });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getAllPaymentOption() {
    if (Platform.OS !== 'web') {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {

            const callbackSuccess = () => {
              const allData = [];
              const len = results.rows.length;
              for (let i = 0; i < len; i++) {
                const row = results.rows.item(i);
                allData.push(row);
              }
              resolve(allData);
            }

            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_OPTION_SCHEMA};`, [], (tx, results) => {
                callbackSuccess(results);
              });
            } else {
              tx.executeSql(`SELECT * FROM ${schema.PAYMENT_OPTION_SCHEMA};`, []).then(([tx, results]) => {
                callbackSuccess(results);
              });
            }
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  return new Promise((resolve) => {
    this.webSQL.transaction((tx) => {
      const allData = [];
      tx.executeSql(
        `SELECT * FROM ${schema.PAYMENT_OPTION_SCHEMA};`,
        [],
        function (transaction, result) {
          const len = result.rows.length;
              for (let i = 0; i < len; i++) {
                const row = result.rows.item(i);
                allData.push(row);
              }
              resolve(allData);
        }, function (transaction, error) {
          console.log(error);
        });
    })
  });

  }

  getAllSales() {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
  
              const callbackSuccess = (results) => {
                const allData = [];
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  const parseSales = {
                    customer_id: row.customer_id,
                    account_id: row.account_id,
                    account_name: row.account_name,
                    billing_address: JSON.parse(row.billing_address),
                    history_integration_status: JSON.parse(row.history_integration_status),
                    order_code: row.order_code,
                    sent_date: row.sent_date,
                    observation: row.observation,
                    id: row.id,
                    customer_name: row.customer_name,
                    account_phone: JSON.parse(row.account_phone),
                    shipping_address: JSON.parse(row.shipping_address),
                    sale_conditions: JSON.parse(row.sale_conditions),
                    integration_status: JSON.parse(row.integration_status),
                    payment_status: JSON.parse(row.payment_status),
                    products: JSON.parse(row.products),
                    messages: JSON.parse(row.messages),
                    amount: row.amount,
                  };
                  allData.push(parseSales);
                  resolve(allData);
                }
              }
  
              if (Platform.OS === 'web') {
                tx.executeSql(`SELECT * FROM ${schema.SALES_SCHEMA} ORDER BY sent_date DESC;`, [], (transaction, results)=> {
                  console.log('tx.executeSql results',results);
                  callbackSuccess(results);
                });
              } else {
                tx.executeSql(`SELECT * FROM ${schema.SALES_SCHEMA} ORDER BY sent_date DESC;`, []).then(([tx, results]) => {
                  callbackSuccess(results);
                });
              }
            })
              .then(() => {})
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const allData = [];
        tx.executeSql(
          `SELECT * FROM ${schema.SALES_SCHEMA} ORDER BY sent_date DESC;`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
              for (let i = 0; i < len; i++) {
                const row = result.rows.item(i);
                const parseSales = {
                  customer_id: row.customer_id,
                  account_id: row.account_id,
                  account_name: row.account_name,
                  billing_address: JSON.parse(row.billing_address),
                  history_integration_status: JSON.parse(row.history_integration_status),
                  order_code: row.order_code,
                  sent_date: row.sent_date,
                  observation: row.observation,
                  id: row.id,
                  customer_name: row.customer_name,
                  account_phone: JSON.parse(row.account_phone),
                  shipping_address: JSON.parse(row.shipping_address),
                  sale_conditions: JSON.parse(row.sale_conditions),
                  integration_status: JSON.parse(row.integration_status),
                  payment_status: JSON.parse(row.payment_status),
                  products: JSON.parse(row.products),
                  messages: JSON.parse(row.messages),
                  amount: row.amount,
                };
                allData.push(parseSales);
              }
              resolve(allData);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });
  }

  addCompany(company) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(
                `INSERT OR REPLACE INTO ${schema.COMPANY_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
                [
                  company.id,
                  company.name,
                  company.trading_name,
                  company.display_name,
                  JSON.stringify(company.address),
                  JSON.stringify(company.phones),
                  JSON.stringify(company.images),
                  JSON.stringify(company.customer_company_accounts),
                ],
              ).then(([tx, results]) => {
                resolve(results);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `INSERT OR REPLACE INTO ${schema.COMPANY_SCHEMA} VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
          [
            company.id,
            company.name,
            company.trading_name,
            company.display_name,
            JSON.stringify(company.address),
            JSON.stringify(company.phones),
            JSON.stringify(company.images),
            JSON.stringify(company.customer_company_accounts),
          ],
          function(transaction, result) {
            // console.log(result);
            // console.log('Table created Successfully!');
            resolve(result);
        }, function(transaction, error) {
            console.log(error);
        });
      })
    });
  }

  allByContexts = (contexts) => {
    if (Platform.OS !== 'web') {
      new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const prod_ctx = [];
              for (const context of contexts) {
                tx.executeSql(
                  `SELECT * FROM ${schema.PRODUCT_CONTEXT_SCHEMA} WHERE context_id='${context}';`,
                  [],
                ).then(([tx, results]) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    const parseCtx = {
                      id: row.id,
                      type: row.type,
                      context_title: row.context_title,
                      context_id: row.context_id,
                      price: row.price,
                      min_price: row.min_price,
                      name: row.name,
                      description: row.description,
                      product_code: row.product_code,
                      company_id: row.company_id,
                      ean: row.ean,
                      volume_factor: row.volume_factor,
                      stock: row.stock,
                      extra_info:
                        row.extra_info !== '' ? JSON.parse(row.extra_info) : [],
                      categories:
                        row.categories !== '' ? JSON.parse(row.categories) : [],
                      price_conditions:
                        row.price_conditions !== ''
                          ? JSON.parse(row.price_conditions)
                          : [],
                    };
                    prod_ctx.push(parseCtx);
                  }
                  resolve(prod_ctx);
                });
              }
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
          const prod_ctx = [];
          for (const context of contexts) {
              tx.executeSql(
                  `SELECT * FROM ${schema.PRODUCT_CONTEXT_SCHEMA} WHERE context_id='${context}';`,
                  [],
                  function(transaction, result) {
                      const len = result.rows.length;
                      for (let i = 0; i < len; i++) {
                          const row = { ...result.rows.item(i) };
                          // console.log('ROW: ', row);
                          const parseCtx = {
                              id: row.id,
                              type: row.type,
                              context_title: row.context_title,
                              context_id: row.context_id,
                              price: row.price,
                              min_price: row.min_price,
                              name: row.name,
                              description: row.description,
                              product_code: row.product_code,
                              company_id: row.company_id,
                              ean: row.ean,
                              volume_factor: row.volume_factor,
                              stock: row.stock,
                              extra_info: row.extra_info ? JSON.parse(row.extra_info) : [],
                              categories: row.categories ? JSON.parse(row.categories) : [],
                              price_conditions: row.price_conditions ? JSON.parse(row.price_conditions) : [],
                          };
                          prod_ctx.push(parseCtx);
                      }

                      if (contexts[contexts.length - 1] === context) {
                        resolve([...prod_ctx]);
                      }
                  },
                  function(transaction, error) {
                      console.log(error);
                  });
          }
      })
  });

  }


  allContexts() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            tx.executeSql(`SELECT * FROM ${schema.PRODUCT_CONTEXT_SCHEMA}`, []).then(([tx, results]) => {
              resolve(results);
            });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  all(table) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {

          console.log('PROMISES promises db', db);

          db.transaction((tx) => {
            const allData = [];

            const callbackSuccess = (results) => {
              const len = results.rows.length;
              for (let i = 0; i < len; i++) {
                const row = results.rows.item(i);
                allData.push(row);
              }
              resolve(allData);
            }

            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT * FROM ${table};`, [], (transaction, results)=> {
                callbackSuccess(results);
              });
            } else {
              tx.executeSql(`SELECT * FROM ${table};`, []).then(([tx, results]) => {
                callbackSuccess(results);
              });
            }
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getCartInCache() {
    return new Promise((resolve, reject) => {
      CacheStore.get(SchemaModels.properties[1].name)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getCart(sorted = null, cache = false) {
    if (Platform.OS !== 'web') {
      return new Promise((resolve, reject) => {
        this.initDB()
          .then((db) => {
            if (cache) {
              CacheStore.get(SchemaModels.properties[1].name)
                .then((data) => {
                  resolve(data);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              db.transaction((tx) => {

                const callbackSuccess = (results) => {
                  const allData = [];
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    const cartNormalize = row !== '' && {
                      success: row.success,
                      products: JSON.parse(row.products),
                      account_id: row.account_id,
                      amount: row.amount,
                      cart_code: row.cart_code,
                      id: row.id,
                      messages: JSON.parse(row.messages),
                      sale_conditions: JSON.parse(row.sale_conditions),
                      delivery_address: JSON.parse(row.delivery_address),
                      shipping_method: JSON.parse(row.shipping_method),
                      customer_id: row.customer_id,
                      company_id: row.company_id,
                    };
                    allData.push(cartNormalize);
                  }
                  resolve(allData);
                }

                if (Platform.OS === 'web') {
                  tx.executeSql(`SELECT * FROM ${schema.CART_SCHEMA};`, [], (transaction, results)=> {
                    callbackSuccess(results);
                  });
                } else {
                  tx.executeSql(`SELECT * FROM ${schema.CART_SCHEMA};`, []).then(([tx, results]) => {
                    callbackSuccess(results);
                  });
                }
              })
                .then((result) => {
                  //
                })
                .catch((err) => {
                  //
                });
            }
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      if (cache) {
        CacheStore.get(SchemaModels.properties[1].name)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
      this.webSQL.transaction((tx) => {
        const allData = [];
        tx.executeSql(
          `SELECT * FROM ${schema.CART_SCHEMA};`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
            for (let i = 0; i < len; i++) {
              const row = result.rows.item(i);
              const cartNormalize = row !== '' && {
                success: row.success,
                products: JSON.parse(row.products),
                account_id: row.account_id,
                amount: row.amount,
                cart_code: row.cart_code,
                id: row.id,
                messages: JSON.parse(row.messages),
                sale_conditions: JSON.parse(row.sale_conditions),
                delivery_address: JSON.parse(row.delivery_address),
                shipping_method: JSON.parse(row.shipping_method),
                customer_id: row.customer_id,
                company_id: row.company_id,
              };
              allData.push(cartNormalize);
            }
            // console.log(allData);
            // console.log('Table created Successfully!');
            resolve(allData);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    }
    });

  }

  get(table, id) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT * FROM ${table} WHERE id='${id}';`, [], (tx, results) => {
                resolve(results);
              });
            } else {
              tx.executeSql(`SELECT * FROM ${table} WHERE id='${id}';`, []).then(([tx, results]) => {
                resolve(results);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getCompany = (id) =>
    new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {

            const callbackSuccess = (results) => {
              const row = results.rows.item(0);
              const companyParsed = {
                id: row.id,
                name: row.name,
                trading_name: row.trading_name,
                display_name: row.display_name,
                address: JSON.parse(row.address),
                phones: JSON.parse(row.phones),
                images: JSON.parse(row.images),
                customer_company_accounts: JSON.parse(row.customer_company_accounts),
              };
              resolve(companyParsed);
            }

            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT * FROM ${schema.COMPANY_SCHEMA} WHERE id='${id}';`, [], (tx, results) => {
                callbackSuccess(results);
              });
            } else {
              tx.executeSql(`SELECT * FROM ${schema.COMPANY_SCHEMA} WHERE id='${id}';`, []).then(([tx, results]) => {
                callbackSuccess(results);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });

  allRoot() {
    let sql = `SELECT * FROM ${schema.CATEGORY_SCHEMA} WHERE is_root=1;`;
    let sqlWeb = `SELECT * FROM ${schema.CATEGORY_SCHEMA} WHERE is_root=${true};`;
    if (AppConfig.sortCategoriesRootByDescription) {
      sql = `SELECT * FROM ${schema.CATEGORY_SCHEMA} WHERE is_root=1 ORDER BY description;`;
      sqlWeb = `SELECT * FROM ${schema.CATEGORY_SCHEMA} WHERE is_root=${true} ORDER BY description;`;
    }
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const categories = [];
              tx.executeSql(sql, [])
                .then(([tx, results]) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    categories.push(row);
                  }
                  resolve(categories);
                })
                .catch((err) => {
                  //
                });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const categories = [];
        tx.executeSql(
          `SELECT * FROM ${schema.CATEGORY_SCHEMA} WHERE is_root="true"`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
            for (let i = 0; i < len; i++) {
              const row = result.rows.item(i);
              categories.push(row);
            }
            resolve(categories);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  getByLimit(table, limit) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            const allData = [];
            tx.executeSql(`SELECT * FROM ${table} LIMIT ${limit};`, []).then(([tx, results]) => {
              const len = results.rows.length;
              for (let i = 0; i < len; i++) {
                const row = results.rows.item(i);
                allData.push(row);
              }
              resolve(allData);
            });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getAllCategoryRelationships() {
    const allData = [];
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {

            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT b.parent_code, b.category_code, a.description
            FROM ${schema.CATEGORY_SCHEMA} a 
            INNER JOIN ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} b
            ON a.category_code=b.category_code;`,
                [], (transaction, results) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    allData.push(row);
                  }
                  resolve(allData);
                });
            } else {
              tx.executeSql(`SELECT b.parent_code, b.category_code, a.description
            FROM ${schema.CATEGORY_SCHEMA} a 
            INNER JOIN ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} b
            ON a.category_code=b.category_code;`,
                []).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getAllCategoriesByCategoryCode(code) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {

            if (Platform.OS === 'web') {
              const allData = [];
              tx.executeSql(
                `
          with RECURSIVE arvore_categorias (codigo_categoria) as (
            VALUES("${code}")
          union
            SELECT cr.category_code
            FROM ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} cr, arvore_categorias
            WHERE cr.parent_code = arvore_categorias.codigo_categoria
          )
          SELECT DISTINCT c.description, cr.*
          FROM arvore_categorias ac 
		      INNER JOIN ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} cr ON (ac.codigo_categoria = cr.parent_code)
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (cr.category_code = c.category_code);`,
                [],
                (transaction, results) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    allData.push(row);
                  }
                  resolve(allData);
                }
              );
            } else {
              const allData = [];
              tx.executeSql(
                `
          with RECURSIVE arvore_categorias (codigo_categoria) as (
            VALUES("${code}")
          union
            SELECT cr.category_code
            FROM ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} cr, arvore_categorias
            WHERE cr.parent_code = arvore_categorias.codigo_categoria
          )
          SELECT DISTINCT c.description, cr.*
          FROM arvore_categorias ac 
		      INNER JOIN ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} cr ON (ac.codigo_categoria = cr.parent_code)
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (cr.category_code = c.category_code);`,
                [],
              ).then((tx, results) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getAllProductsByCategoryCode(code) {
    console.log('EPIC categoryCode getAllProductsByCategoryCode', code);

    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {

          console.log('EPIC categoryCode getAllProductsByCategoryCode db', db);

          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              const allData = [];
              console.log('EPIC categoryCode getAllProductsByCategoryCode web');

              tx.executeSql(
                `with arvore_categorias as (
                  SELECT cr.category_code
                  FROM CategoryRelationships cr
                  WHERE cr.parent_code = "${code}"
                  )
                  SELECT DISTINCT p.*
                  FROM arvore_categorias ac 
				          INNER JOIN Category c on (ac.category_code = c.category_code)
                  INNER JOIN ProductCategories pc ON (c.category_code = pc.category_code)
                  INNER JOIN Product p ON (p.id = pc.product_id);`,
                [], (transaction, results) => {

                  console.log('EPIC categoryCode results',results);

                  if (results.rows.length === 0) {
                    console.log('EPIC ITS ZERO')
                    tx.executeSql(`SELECT DISTINCT p.*
                    FROM ProductCategories pc
                    INNER JOIN Product p ON (p.id = pc.product_id)
                    WHERE pc.category_code="${code}"`, [], (transaction, results) => {
                      console.log('EPIC SECOND IF RES: ', results);
                      const len = results.rows.length;
                      for (let i = 0; i < len; i++) {
                        const row = results.rows.item(i);
                        allData.push(row);
                  }
                    });
                  }

                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    allData.push(row);
                  }

                  resolve(allData);
                }, (transaction, error) => {
                  console.log('EPIC categoryCode error', error);
                }
              );
            } else {
              const allData = [];
              tx.executeSql(
                `
          with RECURSIVE arvore_categorias (codigo_categoria) as (
            VALUES("${code}")
          union
            SELECT cr.category_code
            FROM ${schema.CATEGORIES_RELATIONSHIPS_SCHEMA} cr, arvore_categorias
            WHERE cr.parent_code = arvore_categorias.codigo_categoria
          )
          SELECT DISTINCT p.*
          FROM arvore_categorias ac 
          INNER JOIN ${schema.CATEGORY_SCHEMA} c on (ac.codigo_categoria = c.category_code)
          INNER JOIN ${schema.PRODUCTS_CATEGORIES_SCHEMA} pc ON (c.category_code = pc.category_code)
          INNER JOIN ${schema.PRODUCT_SCHEMA} p ON (p.id = pc.product_id);`,
                [],
              ).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              console.log('GLEGLE categoryCode getAllProductsByCategoryCode err', err);
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  allCategoriesBySearch(value) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              const allData = [];
              tx.executeSql(
                `SELECT DISTINCT c.*
          FROM ${schema.PRODUCTS_CATEGORIES_SCHEMA}
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (${schema.PRODUCTS_CATEGORIES_SCHEMA}.category_code = c.category_code)
          INNER JOIN ${schema.PRODUCT_SCHEMA} p on (p.product_code = ${schema.PRODUCTS_CATEGORIES_SCHEMA}.product_code) WHERE p.name like '%${value}%';`,
                [], (transaction, results) => {

                  console.log('results', results);

                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    allData.push(row);
                  }
                  resolve(allData);
                }
              );

            } else {
              const allData = [];
              tx.executeSql(
                `SELECT DISTINCT c.*
          FROM ${schema.PRODUCTS_CATEGORIES_SCHEMA}
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (${schema.PRODUCTS_CATEGORIES_SCHEMA}.category_code = c.category_code)
          INNER JOIN ${schema.PRODUCT_SCHEMA} p on (p.product_code = ${schema.PRODUCTS_CATEGORIES_SCHEMA}.product_code) WHERE p.name like '%${value}%';`,
                [],
              ).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  allProductsContexts() {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            const productsCtx = [];
            tx.executeSql(`SELECT * FROM ${schema.PRODUCT_CONTEXT_SCHEMA};`, [])
              .then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  productsCtx.push(row);
                }
                resolve(productsCtx);
              })
              .catch((err) => {
                //
              });
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  allProductsBySearch(value) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              const allData = [];
              tx.executeSql(
                `SELECT DISTINCT p.*
          FROM ${schema.PRODUCTS_CATEGORIES_SCHEMA}
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (${schema.PRODUCTS_CATEGORIES_SCHEMA}.category_code = c.category_code)
          INNER JOIN ${schema.PRODUCT_SCHEMA} p on (p.product_code = ${schema.PRODUCTS_CATEGORIES_SCHEMA}.product_code) WHERE p.name like '%${value}%';`,
                [], (transaction, results) => {
                  const len = results.rows.length;
                  for (let i = 0; i < len; i++) {
                    const row = results.rows.item(i);
                    allData.push(row);
                  }
                  resolve(allData);
                }
              );
            } else {
              const allData = [];
              tx.executeSql(
                `SELECT DISTINCT p.*
          FROM ${schema.PRODUCTS_CATEGORIES_SCHEMA}
		      INNER JOIN ${schema.CATEGORY_SCHEMA} c on (${schema.PRODUCTS_CATEGORIES_SCHEMA}.category_code = c.category_code)
          INNER JOIN ${schema.PRODUCT_SCHEMA} p on (p.product_code = ${schema.PRODUCTS_CATEGORIES_SCHEMA}.product_code) WHERE p.name like '%${value}%';`,
                [],
              ).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  allData.push(row);
                }
                resolve(allData);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getProductById(id) {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            if (Platform.OS === 'web') {
              tx.executeSql(`SELECT * FROM ${schema.PRODUCT_SCHEMA} WHERE id='${id}';`, [], (tx, results) => {
                const row = results.rows.item(0);
                resolve(row);
              });
            } else {
              tx.executeSql(`SELECT * FROM ${schema.PRODUCT_SCHEMA} WHERE id='${id}';`, []).then(([tx, results]) => {
                const row = results.rows.item(0);
                resolve(row);
              });
            }
          })
            .then((result) => {
              //
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  getShippingMethods() {
    if (Platform.OS !== 'web') {
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            const allData = [];
            let addresses = null;
            let shippings = null;
            tx.executeSql(`SELECT * FROM ${schema.SHIPPING_METHODS_SCHEMA};`, []).then(([tx, results]) => {
              const len = results.rows.length;
              for (let i = 0; i < len; i++) {
                const row = results.rows.item(i);
                addresses = JSON.parse(row.enderecos);
                shippings = JSON.parse(row.fretes);
              }
              resolve({ addresses, shippings });
            });
          })
            .then((result) => {})
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  return new Promise((resolve) => {
    this.webSQL.transaction((tx) => {
      const allData = [];
      let addresses = null;
      let shippings = null;
      tx.executeSql(
        `SELECT * FROM ${schema.SHIPPING_METHODS_SCHEMA};`,
        [],
        function (transaction, result) {
          const len = result.rows.length;
              for (let i = 0; i < len; i++) {
                const row = result.rows.item(i);
                addresses = JSON.parse(row.enderecos);
                shippings = JSON.parse(row.fretes);
              }
              resolve({ addresses, shippings });
          resolve(allData);
        }, function (transaction, error) {
          console.log(error);
        });
    })
  });

  }

  allCompany() {
    if (Platform.OS !== 'web') {
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              const allData = [];
              tx.executeSql(`SELECT * FROM ${schema.COMPANY_SCHEMA};`, []).then(([tx, results]) => {
                const len = results.rows.length;
                for (let i = 0; i < len; i++) {
                  const row = results.rows.item(i);
                  const parseCompany = {
                    customer_company_accounts: JSON.parse(row.customer_company_accounts),
                    images: JSON.parse(row.images),
                    phones: JSON.parse(row.phones),
                    address: JSON.parse(row.address),
                    display_name: row.display_name,
                    trading_name: row.trading_name,
                    name: row.name,
                    id: row.id,
                  };
                  allData.push(parseCompany);
                }
                resolve(allData);
              });
            })
              .then((result) => {
                //
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        const allData = [];
        tx.executeSql(
          `SELECT * FROM ${schema.COMPANY_SCHEMA};`,
          [],
          function (transaction, result) {
            const len = result.rows.length;
            for (let i = 0; i < len; i++) {
              const row = result.rows.item(i);
              const parseCompany = {
                customer_company_accounts: JSON.parse(row.customer_company_accounts),
                images: JSON.parse(row.images),
                phones: JSON.parse(row.phones),
                address: JSON.parse(row.address),
                display_name: row.display_name,
                trading_name: row.trading_name,
                name: row.name,
                id: row.id,
              };
              allData.push(parseCompany);
            }
            // console.log('ALL DATA COMPANY: ', allData);
            // console.log('Table created Successfully!');
            resolve(allData);
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  async clearAllInCache() {
    const allKeys = await AsyncStorage.getAllKeys();
    const promise = new Promise((resolve, reject) => {
      if (!allKeys.length) {
        resolve();
      }

      CacheStore.flush()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });

    return promise;
  }

  clearTable(tableName) {
    if (Platform.OS !== 'web') {
      const res = [];
      return new Promise((resolve) => {
        this.initDB()
          .then((db) => {
            db.transaction((tx) => {
              tx.executeSql(`DELETE FROM ${tableName};`)
                .then(([tx, results]) => {
                  // res.push(results);
                })
                .catch(() => {
                  //
                });
              resolve();
            })
              .then((result) => {
                // this.closeDatabase(db);
                resolve();
              })
              .catch((err) => {
                //
              });
          })
          .catch((err) => {
            //
          });
      });
    }

    return new Promise((resolve) => {
      this.webSQL.transaction((tx) => {
        tx.executeSql(
          `DELETE FROM ${tableName};`,
          [],
          function (transaction, result) {
            resolve();
          }, function (transaction, error) {
            console.log(error);
          });
      })
    });

  }

  clearAllTables() {
    const res = [];
    return new Promise((resolve) => {
      this.initDB()
        .then((db) => {
          db.transaction((tx) => {
            for (const name in schema.Tables) {
              if (Platform.OS === 'web') {
                this.clearTable(name);
              } else {
                tx.executeSql(`DELETE FROM ${name};`)
                  .then(([tx, results]) => {
                    // res.push(results);
                  })
                  .catch(() => {
                    //
                  });
              }
            }
            resolve();
          })
            .then((result) => {
              // this.closeDatabase(db);
              resolve();
            })
            .catch((err) => {
              //
            });
        })
        .catch((err) => {
          //
        });
    });
  }

  createTablesFromSchema() {
    if (this.db) {
      this.db.transaction((tx) => {
        for (const name in schema.Tables) {
          this.createTable(tx, schema.Tables[name], name);
        }
      });
    }
  }

  dropDatabase() {
    return new Promise((resolve, reject) => {
      SQLite.deleteDatabase(database_name)
        .then(() => {
          SQLite.openDatabase(
            database_name,
            database_version,
            database_displayname,
            database_size,
          );
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    }).catch((error) => {
      //
    });
  }

  createTable(tx, table, tableName) {
    let sql = `CREATE TABLE IF NOT EXISTS ${tableName} `;
    const createColumns = [];
    for (const key in table) {
      createColumns.push(`${key} ${table[key].type.type} ${
        table[key].primary_key ? ' PRIMARY KEY NOT NULL' : ''
      } default ${table[key].default_value}`);
    }
    sql += `(${createColumns.join(', ')});`;
    tx.executeSql(
      sql,
      [],
      () => {
        //
      },
      () => {
        //
      },
    );
  }
}

export default new SQLiteManager();
