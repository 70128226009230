import { Platform, Text, View, ProgressBarAndroid, ProgressViewIOS } from 'react-native';
import React, { Component } from 'react';
import CustomAlert from '~/components/CustomAlert';

type Props = {
    progress: number,
    title: string,
    text: string,
    indeterminate: boolean,
}

export default class ProgressView extends Component<Props> {
  render() {
    const {
      title, progress, text, indeterminate,
    } = this.props;

    return (
      <CustomAlert showModal title={title || 'Aguarde'} noButtons>
        <View>
          <Text style={{ marginTop: 16 }}>{text} { !indeterminate ? `${Math.floor(progress)}%` : null }
          </Text>
          { Platform.OS === 'ios' && (progress ? <ProgressViewIOS progress={0.5} /> : <ProgressViewIOS />)}
          { Platform.OS === 'android' &&
        (progress ?
          <ProgressBarAndroid styleAttr="Horizontal" indeterminate={indeterminate} progress={progress / 100} />
             : <ProgressBarAndroid styleAttr="Horizontal" indeterminate={indeterminate} />)}
        </View>
      </CustomAlert>
    );
  }
}
