export const SchemaModels = {
  CART: 1,
  CATEGORIES_RELATIONSHIPS: 2,
  CATEGORY: 3,
  COMPANY: 4,
  NOTIFICATION: 5,
  PRODUCT: 6,
  PRODUCTS_CATEGORIES: 7,
  PROMOTION: 8,
  PRODUCT_CONTEXT: 9,
  SALES: 10,
  TITLES: 11,
  PAYMENT_OPTIONS: 12,
  PAYMENT_TERMS: 13,
  PAYMENT_COMBINE_KEYS: 14,
  NOTICES: 15,
  SHIPPING_METHODS: 16,
  COMBOS: 17,
  APP_CONFIG: 18,
  REGISTER_ADDRESS: 19,
  properties: {
    1: { name: 'cart', value: 1 },
    2: { name: 'categories_relationships', value: 2 },
    3: { name: 'category', value: 3 },
    4: { name: 'company', value: 4 },
    5: { name: 'notification', value: 5 },
    6: { name: 'product', value: 6 },
    7: { name: 'products_categories', value: 7 },
    8: { name: 'promotion', value: 8 },
    9: { name: 'product_context', value: 9 },
    10: { name: 'sales', value: 10 },
    11: { name: 'title', value: 11 },
    12: { name: 'payment_options', value: 12 },
    13: { name: 'payment_terms', value: 13 },
    14: { name: 'payment_combine_keys', value: 14 },
    15: { name: 'notices', value: 15 },
    16: { name: 'shipping_methods', value: 16 },
    17: { name: 'combos', value: 17 },
    // 18: { name: 'appconfig', value: 18 },
    19: { name: 'register_address', value: 19 },
  },
};
