import axios from 'axios';

import { getConfig, URL_BASE, getCurrentCompany } from '../index';

const TAG = 'combos list';

export default async function list() {
  const configCompaniesList = await getConfig();
  const company = await getCurrentCompany();

  return [];

  // return new Promise((resolve) =>
  //   axios.get(`${URL_BASE}/companies/${company}/combos/`, configCompaniesList)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       console.log(TAG, error);
  //       resolve([]);
  //     }));
}
