// eslint-disable-next-line no-use-before-define
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Home from '~/screens/Home';
import History from '~/screens/History';
import SearchResult from '~/screens/SearchResult';
import {
  SCREEN_CHOOSE_STORE,
  SCREEN_HOME,
  SCREEN_SIGNUP,
  SCREEN_PRODUCT,
  SCREEN_PRODUCTS_AND_CATEGORIES, SCREEN_PROMOTION_DETAIL,
  SCREEN_SEARCH_HISTORY,
  SCREEN_SEARCH_RESULT, SCREEN_STORES
} from '~/screens/screens';
import { colors } from '~/values';
import ProductsAndCategories from '~/screens/ProductsAndCategories/ProductsAndCategoriesContainer';
import Product from '~/screens/Product/ProductContainer';
import Stores from '~/screens/Stores';
import ChooseStore from '~/screens/ChooseStore';
import PromotionDetail from '~/screens/PromotionDetail/PromotionDetailContainer';
import SignUpContainer from '~/screens/SignUp/SignUpContainer';

const HomeStack = createStackNavigator();

const HomeRoutes = () => (
  <HomeStack.Navigator screenOptions={{
    title: 'Home',
    headerStyle: {
      backgroundColor: colors.primaryColor,
      textAlign: 'center',
    },
    headerTitleAlign: 'center',
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }}>
    <HomeStack.Screen
      name={SCREEN_HOME.name} component={Home}
      options={{
        title: SCREEN_HOME.title,
      }}
    />
    <HomeStack.Screen
      name={SCREEN_SIGNUP.name} component={SignUpContainer}
      options={({route}) => ({title: route.params.title || SCREEN_SIGNUP.title})}
    />
    <HomeStack.Screen
      name={SCREEN_SEARCH_HISTORY.name} component={History}
    />
    <HomeStack.Screen
      name={SCREEN_SEARCH_RESULT.name} component={SearchResult}
    />
    <HomeStack.Screen name={SCREEN_PRODUCTS_AND_CATEGORIES.name} component={ProductsAndCategories}/>
    <HomeStack.Screen name={SCREEN_PRODUCT.name} component={Product}/>
    <HomeStack.Screen name={SCREEN_STORES.name} component={Stores}/>
    <HomeStack.Screen name={SCREEN_CHOOSE_STORE.name} component={ChooseStore}/>
    <HomeStack.Screen name={SCREEN_PROMOTION_DETAIL.name} component={PromotionDetail}/>
  </HomeStack.Navigator>
);

export default HomeRoutes;
