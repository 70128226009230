import Repository from './Repository';
import { CATEGORY_SCHEMA } from '~/database/schemas';
import SQLiteManager from '../SQLiteManager';

export default class CategoryRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = CATEGORY_SCHEMA;
  }

  addCategory(categorys) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCategory(categorys).then(category => {
        resolve(category);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  allRoot() {
    return new Promise((resolve, reject) => {
      SQLiteManager.allRoot().then(root => {
        resolve(root);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  allCategoriesBySearch(value) {
    return new Promise((resolve, reject) => {
      SQLiteManager.allCategoriesBySearch(value).then(categorySearch => {
        resolve(categorySearch);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllCategoriesByCategoryCode(code) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllCategoriesByCategoryCode(code).then(categoryByCode => {
        resolve(categoryByCode);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllCategoryRelationships() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllCategoryRelationships().then(categoriesRel => {
        resolve(categoriesRel);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
