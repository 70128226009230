import Controller from '~/controllers/Controller';
import AppConfigRepository from '~/database/repositories/AppConfigRepository';

export default class AppConfigController extends Controller {
  constructor() {
    super();
    this.repository = new AppConfigRepository();
  }

  getAppConfig() {
    return this.repository.getAppConfig();
  }

  addAppConfig(config) {
    return this.repository.addAppConfig(config);
  }
}
