import { StyleSheet } from 'react-native';
import { sizes, colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  label: {
    fontSize: sizes.textNormal,
    color: colors.grayDark,
    fontWeight: '600',
  },
  value: {
    fontSize: sizes.textSmall,
    color: colors.grayDarkest,
    fontWeight: '300',
  },
});

export default styles;
