import { Dimensions, StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const { width } = Dimensions.get('window');

const defaultContainer = {
  backgroundColor: '#FFF',
  borderWidth: 1,
  borderColor: colors.grayContainer,
  padding: 16,
};

const defaultText = {
  color: colors.grayDarkest,
};

const gridSize = (width / 3);

const styles = StyleSheet.create({
  container__grid: {
    ...defaultContainer,
    width: gridSize,
    height: gridSize,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container__linear: {
    ...defaultContainer,
    minHeight: 56,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon__grid: {
    width: gridSize / 3,
    height: gridSize / 3,
    resizeMode: 'contain',
    marginBottom: sizes.verticalSpaceSeparatorSmall,
  },
  icon__linear: {
    width: 24,
    height: 24,
    marginRight: sizes.horizontalSpacing,
  },
  text__grid: {
    ...defaultText,
    fontSize: sizes.textSmall,
    textAlign: 'center',
  },
  text__linear: {
    ...defaultText,
    flex: 1,
    fontSize: sizes.textNormal,
  },
  iconChevronRight: {
    width: 15,
    height: 15,
    tintColor: colors.grayMid,
  },
});

export default styles;
