// @flow

import React, { Component } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import { ParallaxImage } from '~/modules-wrapper/react-native-snap-carousel';

import styles from './styles';
import stylesFullWidth from './stylesFullWidth';

import Images from '~/../assets/images';

// import Image from 'react-native-android-image-polyfill';
import { Image } from 'react-native';
import colors from '~/values/color';
import ImageComponent from '../../Image';

type BannerImageProps = {
  index: number,
  fullWidth?: boolean,
  onPressItem?: (item: ImageSource, index: number) => void,
  onImageClick?: () => void,
  even?: boolean,
  parallax?: boolean,
  showFullImage?: boolean,
};

class BannerImage extends Component<BannerImageProps> {
  constructor(props) {
    super(props);
    this.state = {
      modalExpandImageVisible: false,
    };
  }

  onPressItem = () => {
    if (this.props.onPressItem) {
      this.props.onPressItem(this.props.image, this.props.index);
    }
    this.setState({ modalExpandImageVisible: true });
  };

  getImage() {
    const {
      even, parallax, index, image, onPressItem, fullWidth, ...rest
    } = this.props;

    const imageStyles = fullWidth ? stylesFullWidth : styles;

    return (parallax ? (
      <ParallaxImage
        source={image || Images.iconNoImageBig}
        containerStyle={[imageStyles.imageContainer, even ? imageStyles.imageContainerEven : {}]}
        style={imageStyles.image}
        parallaxFactor={0.35}
        showSpinner
        spinnerColor={even ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.25)'}
        onError={this.loadOnError}
        {...rest}
      />
    ) : (image === Images.iconNoImageBig ?
      <Image
        source={image}
        tintColor={colors.grayDark}
        {...rest}
      /> :
      <Image
        source={image || Images.iconNoImageBig}
        style={imageStyles.image}
        onError={this.loadOnError}
        {...rest}
      />
    ));
  }

  loadOnError = () => {
    this.setState({ image: Images.iconNoImageBig });
  };

  render() {
    const { image } = this.state;
    const {
      even, onPressItem, fullWidth, showFullImage,
    } = this.props;
    const imageStyles = fullWidth ? stylesFullWidth : styles;

    const { modalExpandImageVisible } = this.state;

    const modalIsVisible = modalExpandImageVisible &&
      showFullImage && image !== Images.iconNoImageBig;

    return (
      <View style={image === Images.iconNoImageBig ?
        imageStyles.overflowNoImageContainer : imageStyles.overflowContainer}
      >
        <TouchableOpacity
          disabled={!onPressItem}
          onPress={this.onPressItem}
        >
          <View style={[imageStyles.imageContainer, even ? imageStyles.imageContainerEven : { alignItems: 'center' }]}>
            {this.getImage()}
          </View>
        </TouchableOpacity>

        <Modal
          animationType="slide"
          transparent
          visible={modalIsVisible}
          onRequestClose={() => { this.setState({ modalExpandImageVisible: false }); }}
        >
          <TouchableOpacity onPress={() => {
            this.setState({ modalExpandImageVisible: false });
          }}
          >
            <View style={{
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
            >
              {this.getImage()}
            </View>
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

BannerImage.defaultProps = {
  showFullImage: true,
};

export default BannerImage;
