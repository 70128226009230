import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { Button } from '~/components';
import styles from './styles';
import appStyles from '~/appStyles';
import AppConfig from '~/AppConfig';

class Confirmation extends Component {
  render() {
    return (
      <View style={appStyles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Seu pedido foi realizado com sucesso!</Text>
        </View>
        <View style={styles.messageContainer}>
          <Text style={styles.message}>
            {AppConfig.messageConfirm}
          </Text>
        </View>
        <View style={styles.actionsContainer}>
          <Button title="Ir para o início" big flat transparent onPress={this.props.goHome} />
        </View>
      </View>
    );
  }
}

export default Confirmation;
