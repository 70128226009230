/* eslint-disable react/jsx-indent */
import React from 'react';
import { View } from 'react-native';
import { Placeholder, PlaceholderMedia } from 'rn-placeholder';

import colors from '../../values/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeCategoriesStyle = {
  ...backgroundGray,
  height: 80,
  width: 80,
  borderRadius: 80,
  marginRight: 5,
};

const homeProductMediaStyle = {
  ...backgroundGray,
  borderRadius: 10,
  height: 150,
  width: '97%',
  margin: 4,
};

export default function ProductsAndCategoriesLoad() {
  return (
    <View>
      <View style={{
        marginTop: 10,
        marginBottom: 0
      }}>
        <Placeholder animation="fade">
          <View style={{
            marginTop: 10,
            paddingLeft: 10
          }}>
            <View style={{ flexDirection: 'row' }}>
              <PlaceholderMedia hasRadius style={homeCategoriesStyle}/>
              <PlaceholderMedia hasRadius style={homeCategoriesStyle}/>
              <PlaceholderMedia hasRadius style={homeCategoriesStyle}/>
              <PlaceholderMedia hasRadius style={homeCategoriesStyle}/>
              <PlaceholderMedia hasRadius style={homeCategoriesStyle}/>
            </View>
          </View>
        </Placeholder>
      </View>
      <View>
        <Placeholder animation="fade">
          <View style={{
            marginTop: 10,
            paddingLeft: 10
          }}>
            <View style={{
              flexDirection: 'row',
              marginTop: 15
            }}>
              <PlaceholderMedia style={homeProductMediaStyle}/>
            </View>
          </View>
        </Placeholder>
      </View>
      <View>
        <Placeholder animation="fade">
          <View style={{
            marginTop: 10,
            paddingLeft: 10
          }}>
            <View style={{
              flexDirection: 'row',
              marginTop: 15
            }}>
              <PlaceholderMedia style={homeProductMediaStyle}/>
            </View>
          </View>
        </Placeholder>
      </View>
      <View>
        <Placeholder animation="fade">
          <View style={{
            marginTop: 10,
            paddingLeft: 10
          }}>
            <View style={{
              flexDirection: 'row',
              marginTop: 15
            }}>
              <PlaceholderMedia style={homeProductMediaStyle}/>
            </View>
          </View>
        </Placeholder>
      </View>
      <View>
        <Placeholder animation="fade">
          <View style={{
            marginTop: 10,
            paddingLeft: 10
          }}>
            <View style={{
              flexDirection: 'row',
              marginTop: 15
            }}>
              <PlaceholderMedia style={homeProductMediaStyle}/>
            </View>
          </View>
        </Placeholder>
      </View>
    </View>
  );
}
