import { StyleSheet } from 'react-native';
import { sizes, colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: colors.grayContainer,
    justifyContent: 'center',
    flexDirection: 'row',
    padding: sizes.verticalSpaceSeparatorNormal,
  },
  label: {
    fontSize: sizes.textNormal,
    color: colors.grayDark,
    fontWeight: '600',
    textAlign: 'center',
  },
});

export default styles;
