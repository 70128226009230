// @flow

import React from 'react';
import { View, Text, Image } from 'react-native';
import { getCreditCardBrandImage } from '~/common';
import {Touchable, TouchableOpacity} from 'react-native-web';
import styles from './styles';
import Images from '~/../assets/images';

export type CreditCardProps = {
  main?: boolean,
  final: string,
  brand: string,
  onRemove?: any
};

const Icon = ({ icon, onRemove, item }) => (
  <TouchableOpacity style={styles.rootIconContainer} onPress={() => onRemove(item)} >
      <View style={styles.iconContainer}>
        <Image style={styles.icon} source={icon} />
      </View>
  </TouchableOpacity>
);

const CreditCard = ({
  main, final, brand, onRemove, item,
}: CreditCardProps) => (
    <View style={styles.container}>
      <Image
        style={styles.brandImage}
        source={getCreditCardBrandImage(brand)}
      />
      <View>
        <Text style={styles.finishedWith}>Terminado em
        <Text style={styles.final}> {final}</Text>
        </Text>
        {
          main &&
          (
            <View style={styles.mainContainer}>
              <Text style={styles.mainText}>PRINCIPAL</Text>
            </View>
          )
        }
      </View>
      <Icon icon={Images.iconClose} style={styles.iconClose} onRemove={onRemove} item={item} />
    </View>
  );

export default CreditCard;
