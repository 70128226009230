import React, { Component } from 'react';
import { FlatList, RefreshControl, Text, View } from 'react-native';
import get from 'lodash/get';

import { Button } from '~/components';

import appStyles from '~/appStyles';
import styles from './styles';
import Images from '~/../assets/images';
import IconText from '~/components/IconText';
import { colors, sizes } from '~/values';
import { TouchableOpacity } from 'react-native';
import AlertBuyingFor from '~/components/AlertBuyingFor';
import ImageComponent from '~/components/Image';
import EmptyView from '~/components/EmptyView';
import ProgressView from '~/components/ProgressView';

import TextUtils from '~/utils/TextUtils';
import AppConfig from "~/AppConfig";

class Stores extends Component<any> {
  emptyView = () => (
    <EmptyView icon={Images.iconShop} message="Você ainda não possui lojas disponíveis" />
  );

  footerView = () => (
    <Button
      title="Solicitar novo acesso"
      big
      containerStyle={{
        margin: sizes.verticalSpaceSeparatorLarge,
        alignItems: 'center',
      }}
      style={[
        styles.loginButton,
        // logging ? { backgroundColor: colors.grayDark } : styles.loginButton,
      ]}
      onPress={() => {
        this.props.requestNewRegister();
      }}
    />
  );

  renderStoreItem = ({ item }) => {
    const { storeItemActive } = this.props;
    const { customer } = item;
    const {
      id, name, address, phones, nin,
    } = customer;
    const fullAddress = get(address, 'address') || '';
    const phone = get(phones, 'main') || '';

    let storeItemIsActive = false;
    if (storeItemActive) {
      storeItemIsActive = id === storeItemActive.customer_id;
    }

    const active = !AppConfig.startFromStore && storeItemIsActive;

    return (
      <TouchableOpacity
        onPress={() => this.props.onRegisterItemPress(item)}
      >
        <View
          style={[styles.container, { flexDirection: 'column', padding: sizes.horizontalSpacing }]}
        >
          <Text
            style={[
              styles.storeItemTitle,
              active ? { color: colors.primaryColor } : null,
            ]}
          >
            {name || 'Sem nome'}
          </Text>
          <IconText
            imageSrc={Images.iconStore}
            imageStyle={{ tintColor: active ? colors.primaryColor : colors.grayDark }}
            textStyle={{
              fontSize: 12,
              color: active ? colors.primaryColor : colors.grayDark,
            }}
            text={fullAddress}
          />
          <IconText
            imageSrc={Images.iconId}
            imageStyle={{ tintColor: active ? colors.primaryColor : colors.grayDark }}
            textStyle={{
              fontSize: 12,
              color: active ? colors.primaryColor : colors.grayDark,
            }}
            text={`${TextUtils.formatCNPJorCPF(nin)}`}
          />
          <IconText
            imageSrc={Images.iconPhone}
            imageStyle={{ tintColor: active ? colors.primaryColor : colors.grayDark }}
            textStyle={{
              fontSize: 12,
              color: active ? colors.primaryColor : colors.grayDark,
            }}
            text={phone}
          />
        </View>
      </TouchableOpacity>
    );
  };

  renderItem = ({ item }) => {
    const { currentAccount, needRequestAccess, storeItemActive } = this.props;
    const {
      id, display_name, name, address, phones, images, customer_company_accounts,
    } = item;
    const fullAddress = get(address, 'address') || '';
    const phone = get(phones, 'main') || '';
    const image = get(images, 'brand') || '';

    const showCustomersCompanies =
      needRequestAccess &&
      currentAccount &&
      !currentAccount.isAnonymous &&
      customer_company_accounts &&
      customer_company_accounts.length > 0;

    const showRequestAccess = needRequestAccess && currentAccount && !currentAccount.isAnonymous;

    const storeActive = storeItemActive && storeItemActive.company_id === id;

    return (
      <TouchableOpacity
        onPress={needRequestAccess ? () => null : this.props.onRegisterItemPress(item)}
      >
        <View style={styles.loginBoxContainer}>
          <View style={styles.loginBoxInputs}>
            <View style={styles.container}>
              <ImageComponent style={styles.logo} source={{ uri: image }} />
              <View style={[styles.container, { flexDirection: 'column' }]}>
                <Text
                  style={[
                    styles.storeTitle,
                    !showRequestAccess && storeActive ? { color: colors.primaryColor } : null,
                  ]}
                >
                  {display_name || name}
                </Text>
                <IconText
                  imageSrc={Images.iconStore}
                  imageStyle={{ tintColor: colors.grayDark }}
                  text={fullAddress}
                />
                <IconText
                  imageSrc={Images.iconPhone}
                  imageStyle={{ tintColor: colors.grayDark }}
                  text={phone}
                />
              </View>
            </View>
          </View>
          {showCustomersCompanies && <View style={styles.separator} />}
          {showCustomersCompanies && (
            <View style={{ margin: 3 }}>
              <Button
                containerStyle={{ alignItems: 'flex-start' }}
                title="Cadastros"
                iconRight={
                  item.registersVisible ? Images.iconCollapseArrow : Images.iconExpandArrow
                }
                flat
                transparent
                noUpperCase
                small
                onPress={() => this.props.onRegisterPress(item)}
              />
              {item.registersVisible && <View style={styles.separator} />}
              {item.registersVisible && (
                <FlatList
                  keyExtractor={item => item.customer_id}
                  data={item.customer_company_accounts}
                  renderItem={this.renderStoreItem}
                  ItemSeparatorComponent={() => <View style={styles.separator} />}
                />
              )}
            </View>
          )}
          {AppConfig.startFromStore && <View style={styles.separator} />}
          {AppConfig.startFromStore && (
            <View style={{ margin: 3 }}>
              <Button
                containerStyle={{ alignItems: 'flex-start' }}
                titleColor={colors.blueBadge}
                title={
                  !!item.customer_company_accounts.length ? '+ Solicitar novo acesso' : '+ Solicitar acesso'
                }
                onPress={() => {
                  this.props.requestNewRegister(item);
                }}
                flat
                transparent
                noUpperCase
                small
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      stores, refreshing, onRefresh, textProgress, progressVisible, alertTitle,
    } = this.props;

    return (
      <View style={appStyles.container}>
        {
          !AppConfig.startFromStore && (
            <AlertBuyingFor
              navigation={this.props.navigation}
              pressEnabled={false}
              showIcon={false}
            />
          )
        }
        {progressVisible && <ProgressView indeterminate text={textProgress} title={alertTitle} />}
        <FlatList
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => onRefresh()} />}
          keyExtractor={item => item.id}
          style={{ elevation: 10 }}
          data={stores}
          renderItem={this.renderItem}
          ListEmptyComponent={this.emptyView}
          ListFooterComponent={AppConfig.startFromStore ? null : this.footerView}
        />
      </View>
    );
  }
}

export default Stores;
