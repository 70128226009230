export const ORDER_ACTIVE_REQUEST = 'ORDER_ACTIVE_REQUEST';
export const ORDER_ACTIVE_SUCCESS = 'ORDER_ACTIVE_SUCCESS';
export const ORDER_ACTIVE_FAILURE = 'ORDER_ACTIVE_FAILURE';

export const FINALLY_ORDER_REQUEST = 'FINALY_ORDER_REQUEST';

export function success(payload) {
  return dispatch => dispatch({ type: ORDER_ACTIVE_SUCCESS, payload });
}

export function failure(payload) {
  return dispatch => dispatch({ type: ORDER_ACTIVE_FAILURE, payload });
}

export default function activeOrder() {
  return dispatch => dispatch({ type: ORDER_ACTIVE_REQUEST });
}

export function finallyOrder() {
  return dispatch => dispatch({ type: FINALLY_ORDER_REQUEST });
}
