import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  ORDER_ADD_BONUS_REQUEST,
  ORDER_ADD_BONUS_RESPONSE_SUCCESS,
  ORDER_ADD_BONUS_RESPONSE_FAILURE,
  successAddBonusOrder,
  failureAddBonusOrder,
} from './action';
import SourceBuilder, { CONTEXT, METHODS } from '~/utils/SourceBuilder';
import RequestBuilder from '~/utils/RequestBuilder';
import requestManager from '~/utils/RequestManager';
import { activeOrderDataManager, updateOrderDataManager } from '../common';
import DataManager from '../../../database/DataManager';
import { SchemaModels } from '../../../database/utils/DBUtils';

import CartController from '~/controllers/CartController';

const updateProductsInOrder = (order, bonus, quantity) => {
  bonus.requirements.forEach((req) => {
    const indexProd = order.products.findIndex((prod) => prod.product_code === req.product_code);
    const quantityProd = req.quantity * quantity;
    const totalPriceProd = req.total_price * quantity;

    if (indexProd !== -1) {
      const product = order.products[indexProd];
      if (product.quantity + quantityProd === 0) {
        order.products.splice(indexProd, 1);
      } else {
        product.quantity += quantityProd;
        product.total_price += totalPriceProd;
      }
    } else {
      order.products.push({
        ...req,
        quantity: quantityProd,
        total_price: totalPriceProd,
      });
    }
  });
};

const getCampaign = (order, bonus, quantity) => {
  const campaign = {};
  campaign.quantity = quantity;
  campaign.campaign_id = `${bonus.id}`;
  campaign.items = bonus.items.map((item) => {
    const {
      id, description, product_code, ean,
    } = item;
    return {
      id,
      description,
      item_code: product_code,
      ean,
      quantity,
    };
  });

  campaign.requirements = bonus.requirements.map((req) => {
    const {
      name, product_code, id, quantity,
    } = req;
    return {
      description: name,
      ean: null,
      item_code: product_code,
      id: parseInt(id),
      quantity,
    };
  });

  return campaign;
};

const addBonusOrderDataManager = ({ order, bonus, quantity }) => {
  if (!order.products) order.products = [];

  const index = order.campaigns.findIndex((camp) => camp.campaign_id === `${bonus.id}`);
  if (quantity > 0) {
    if (index !== -1) {
      const difQuantity = quantity - order.campaigns[index].quantity;
      updateProductsInOrder(order, bonus, difQuantity);
      order.campaigns[index] = getCampaign(order, bonus, quantity);
    } else {
      updateProductsInOrder(order, bonus, quantity);
      const campaign = getCampaign(order, bonus, quantity);
      order.campaigns.push(campaign);
    }
  } else {
    const difQuantity = quantity - order.campaigns[index].quantity;
    updateProductsInOrder(order, bonus, difQuantity);
    order.campaigns.splice(index, 1);
  }

  order.amount = order.products.reduce((total, p) => total + p.total_price, 0);

  const cartController = new CartController();
  const promise = cartController.insertCartInCache([order]);
  return Observable.fromPromise(promise);
};

const addBonusOrder = (action$: any) =>
  action$
    .ofType(ORDER_ADD_BONUS_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap((payload) =>
      addBonusOrderDataManager(payload)
        .mergeMap((data) => {
          const { order, bonus, quantity } = payload;
          const args = { campaign_id: bonus.id, quantity, idOrder: order.id };

          const source = SourceBuilder(METHODS.order.addBonus, args);
          const request = RequestBuilder(source, CONTEXT.addBonusInOrder);
          requestManager.push(request);

          return Observable.of(successAddBonusOrder(data[0]));
        })
        .catch((data) => Observable.of(failureAddBonusOrder(data))));

const getBonusCache = (order) => {
  const cartController = new CartController();
  const promise = cartController.getCart(null, true);
  return Observable.fromPromise(promise).map((ordersCache) => ({
    orderCache: ordersCache[0],
    order,
  }));
};

const updateQuantityBonusInCache = ({ orderCache, order }) => {
  if (orderCache && orderCache.campaigns) {
    const campaignsActual = orderCache.campaigns;
    if (order.campaigns) {
      order.campaigns.forEach((campaign) => {
        const campaignFound = campaignsActual.find((cam) => cam.campaign_id === campaign.campaign_id);
        if (campaignFound) {
          campaign.quantity = campaignFound.quantity;
        }
      });
    }
  }
  return updateOrderDataManager({ ...orderCache, ...order });
};

const addBonusOrderResponse = (action$: any) =>
  action$
    .ofType(ORDER_ADD_BONUS_RESPONSE_SUCCESS)
    .map(({ payload }) => payload)
    .mergeMap((payload) => getBonusCache(payload[0]))
    .mergeMap((payload) =>
      updateQuantityBonusInCache(payload)
        .mergeMap(() => activeOrderDataManager())
        .flatMap((data) => Observable.of(successAddBonusOrder(data[0] || [])))
        .catch((data) => Observable.of(failureAddBonusOrder(data))));

const addBonusOrderResponseFailure = (action$: any) =>
  action$
    .ofType(ORDER_ADD_BONUS_RESPONSE_FAILURE)
    .map(({ payload }) => payload)
    .mergeMap((payload) => Observable.of(failureAddBonusOrder(payload)));

export default combineEpics(addBonusOrder, addBonusOrderResponse, addBonusOrderResponseFailure);
