import { StyleSheet } from 'react-native';
import { colors, defaultShadow } from '~/values';

const size = 40;
const margin = 4;
const iconContainerSize = size - (2 * margin);
const iconPadding = 6;
const iconColor = colors.grayDarkest;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF',
    ...defaultShadow,
    height: size,
    borderRadius: size / 2,
    justifyContent: 'space-between',
  },
  rootIconContainer: {
    height: iconContainerSize,
    width: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    overflow: 'hidden',
    margin,
  },
  iconContainer: {
    height: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: iconContainerSize - (2 * iconPadding),
    width: iconContainerSize - (2 * iconPadding),
    tintColor: iconColor,
  },
  textInput: {
    height: size - (margin * 2),
    alignItems: 'center',
    flex: 1,
    paddingVertical: 0,
    paddingHorizontal: 6,
  },
});

export default styles;
