// @flow

import React from 'react';
import Slider from '~/modules-wrapper/rn-range-slider';
import PropTypes from 'prop-types';

import { colors } from '~/values';

const RangeSlider = (props: RangeSlider.propTypes) => (
  <Slider
    selectionColor={colors.primaryColor}
    blankColor="#ddd"
    {...props}
  />
);

RangeSlider.propTypes = {
  rangeEnabled: PropTypes.bool,
  gravity: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialLowValue: PropTypes.number,
  initialHighValue: PropTypes.number,
  lineWidth: PropTypes.number,
  thumbRadius: PropTypes.number,
  thumbBorderWidth: PropTypes.number,
  labelStyle: PropTypes.string,
  labelGapHeight: PropTypes.number,
  labelTailHeight: PropTypes.number,
  labelFontSize: PropTypes.number,
  labelBorderWidth: PropTypes.number,
  labelPadding: PropTypes.number,
  labelBorderRadius: PropTypes.number,
  textFormat: PropTypes.string,
  blankColor: PropTypes.string,
  selectionColor: PropTypes.string,
  thumbColor: PropTypes.string,
  thumbBorderColor: PropTypes.string,
  labelTextColor: PropTypes.string,
  labelBackgroundColor: PropTypes.string,
  labelBorderColor: PropTypes.string,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onValueChanged: PropTypes.func,
};

export default RangeSlider;
