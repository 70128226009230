/* @flow */

import { combineReducers } from 'redux-immutable';

import productsFetch from './productsFetch/reducer';
import saleConditionsFetch from './saleConditionsFetch/reducer';
import setCurrentAccount from './setCurrentAccount/reducer';
import location from './location/reducer';
import activeOrderFetch from './activeOrderFetch/reducer';
import processOrder from './processOrder/reducer';
import promotionsFetch from './promotionsFetch/reducer';
import productsContextFetch from './productsContextFetch/reducer';
import titlesFetch from './titlesFetch/reducer';
import setCurrentStoreItem from './setCurrentStoreItem/reducer';
import setCurrentCompany from './setCurrentCompany/reducer';
import setCurrentNetInfo from './setCurrentNetInfo/reducer';
import shippingMethodsFetch from './shippingMethodsFetch/reducer';
import setNewLogin from './newLogin/reducer';
import setLoadPromotions from './setLoadPromotions/reducer';
import setLoadCategories from './setLoadCategories/reducer';
import setLoadProdContext from './setLoadProdContext/reducer';
import setLoadNoticies from './setLoadNoticies/reducer';
import setChangeStore from './setChangeStore/reducer';
import setInfoUser from './setInfoUser/reducer';
import changeStore from './changeStore/reducer';
import setLoadCart from './setLoadCart/reducer';
import setErrorCompanyCustomer from './setErrorCompanyCustomer/reducer';
import setLoadAferSale from './setLoadAfterSale/reducer';
import setIconsChange from './loadIcons/reducer';
import setSelectLocationChange from './selectLocation/reducer';

module.exports = combineReducers({
  saleConditionsFetch,
  productsFetch,
  setCurrentAccount,
  activeOrderFetch,
  processOrder,
  location,
  promotionsFetch,
  productsContextFetch,
  titlesFetch,
  setCurrentStoreItem,
  setCurrentCompany,
  setCurrentNetInfo,
  shippingMethodsFetch,
  setNewLogin,
  setLoadPromotions,
  setLoadCategories,
  setLoadProdContext,
  setLoadNoticies,
  setChangeStore,
  setInfoUser,
  changeStore,
  setLoadCart,
  setErrorCompanyCustomer,
  setLoadAferSale,
  setIconsChange,
  setSelectLocationChange,
});
