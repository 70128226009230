const defaultMenu = {
  sections: [
    {
      id: 'section_0',
      order: 0,
      items: [
        // {
        //   id: 'home',
        //   title: 'Home',
        //   icon: 'home',
        //   order: 0,
        //   action: {},
        // },
        {
          id: 'credit_card_list',
          title: 'Meus cartões',
          order: 1,
          icon: 'wallet',
          action: {},
        },
      ],
    },
    {
      id: 'section_1',
      order: 2,
      items: [
        // {
        //   id: 'notifications',
        //   title: 'Notificações',
        //   icon: 'message',
        //   order: 1,
        //   action: {},
        // },
        {
          id: 'favorites',
          title: 'Produtos Favoritos',
          icon: 'favorites',
          order: 0,
          action: {},
        },
      ],
    },
    {
      id: 'section_2',
      order: 3,
      items: [
        {
          id: 'orders',
          title: 'Pedidos',
          icon: 'order',
          order: 0,
          action: {},
        },
        /* {
          id: 'titles',
          title: 'Títulos',
          icon: 'titles',
          order: 1,
          action: {},
        }, */
      ],
    },
    {
      id: 'section_3',
      order: 4,
      items: [
        // {
        //   id: 'about',
        //   title: 'Sobre o app',
        //   icon: 'about',
        //   order: 1,
        //   action: {},
        // },
        {
          id: 'stores',
          title: 'Lojas',
          icon: 'stores',
          order: 0,
          action: {},
        },
      ],
    },
    {
      id: 'section_4',
      order: 5,
      items: [{
        id: 'contact',
        title: 'Fale Conosco',
        icon: 'phone',
        order: 0,
        action: {},
      }],
    },
    {
      id: 'section_5',
      order: 7,
      items: [
        {
          id: 'address',
          title: 'Endereço de Entrega',
          icon: 'place',
          order: 0,
          action: {},
        },
      ],
    },
    /* {
      id: 'section_6',
      order: 8,
      items: [
        {
          id: 'denounce',
          title: 'Violência contra mulher',
          icon: 'alert',
          order: 0,
          action: {},
        },
      ],
    }, */
    {
      id: 'section_9',
      order: 9,
      items: [
        {
          id: 'logout',
          title: 'Sair',
          icon: 'logout',
          order: 0,
          action: {},
        },
      ],
    },
  ],
};


export const DENOUNCE_OPTION = {
  id: 'section_6',
  order: 8,
  items: [
    {
      id: 'denounce',
      title: 'Violência contra mulher',
      icon: 'alert',
      order: 0,
      action: {},
    },
  ],
};

export default defaultMenu;
