import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';

const TAG = 'Product detail';

export default async function detail(productId) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  return new Promise((resolve, reject) =>
    axios
      .get(`${URL_BASE}/products/${company}/detail/${productId}/`, config)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(TAG, error);
        reject(error);
      }));
}
