import { store } from '~/App.web';

import setNewLogin from '../../store/newLogin/action';
import setLoadPromo from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setLoadCart from '../../store/setLoadCart/action';

export const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE';

export function successChangeStore(payload) {
  return dispatch => dispatch({ type: UPDATE_STORE_SUCCESS, payload });
}

export function failureChangeStore(payload) {
  return dispatch => dispatch({ type: UPDATE_STORE_FAILURE, payload });
}

export function changeStore(order, screen) {
  const changeStoreLoad = {
    type: '',
    auth: true,
  };
  const loadData = {
    type: '',
    load: true,
  };
  store.dispatch(setNewLogin(changeStoreLoad));
  store.dispatch(setLoadPromo(loadData));
  store.dispatch(setLoadCategories(loadData));
  store.dispatch(setLoadProdContext(loadData));
  store.dispatch(setLoadNoticies(loadData));
  store.dispatch(setLoadCart(loadData));
  return dispatch => dispatch({
    type: UPDATE_STORE_REQUEST,
    payload: { order, screen },
  });
}

