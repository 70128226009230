import isEmpty from 'lodash/isEmpty';

import SourceInterpreter from '~/utils/SourceInterpreter';
import { ERROR_REPONSE_DEFAULT } from '~/constants/messages';

export const ORDER_ADD_PRODUCT_REQUEST = 'ORDER_ADD_PRODUCT_REQUEST';
export const ORDER_ADD_PRODUCT_SUCCESS = 'ORDER_ADD_PRODUCT_SUCCESS';
export const ORDER_ADD_PRODUCT_FAILURE = 'ORDER_ADD_PRODUCT_FAILURE';
export const ORDER_ADD_PRODUCT_RESPONSE_FAILURE = 'ORDER_ADD_PRODUCT_RESPONSE_FAILURE';
export const ORDER_ADD_PRODUCT_RESPONSE_SUCCESS = 'ORDER_ADD_PRODUCT_RESPONSE_SUCCESS';

export function successAddProductOrder(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_PRODUCT_SUCCESS, payload });
}

export function failureAddProductOrder(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_PRODUCT_FAILURE, payload });
}

function responseAddProductInOrderFailure(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_PRODUCT_RESPONSE_FAILURE, payload });
}

function responseAddProductInOrderSuccess(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_PRODUCT_RESPONSE_SUCCESS, payload });
}

export function addProductOrder(order, product, quantity, isCombo = false) {
  return dispatch => dispatch({
    type: ORDER_ADD_PRODUCT_REQUEST,
    payload: {
      order, product, quantity, isCombo,
    },
  });
}

export function responseAddProductInOrder(response) {
  const data = SourceInterpreter(response);
  const emptySource = isEmpty(response.source);

  if (emptySource) {
    const errors = data ? data.messages : ERROR_REPONSE_DEFAULT;
    return responseAddProductInOrderFailure(errors);
  }

  return responseAddProductInOrderSuccess(data);
}
