// @flow

import React from 'react';
import { View, Image, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-web';

import styles from './styles';

export type CategoryProps = {
  name: string,
  description?: string,
  onPress?: () => void,
};

const Category = ({
  name, description, image, onPress,
}: CategoryProps) => {
  const curDescription = description || name;
  const curName = description ? name : null;
  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <View style={styles.container}>
        {
          !!image &&
          <Image
            style={styles.image}
            source={image}
          />
        }
        <View style={styles.nameContainer}>
          {
            !!curName &&
            <Text style={styles.name}>{curName}</Text>
          }
          <Text style={styles.description}>{curDescription}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Category;
