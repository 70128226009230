import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  FlatList,
} from 'react-native';
import React, { Component } from 'react';
import { Input } from '~/components/index';
import RBSheet from '~/modules-wrapper/react-native-raw-bottom-sheet';
import filter from 'lodash/filter';
import TextUtils from '~/utils/TextUtils';
import KeyboardAwareScrollView from '~/modules-wrapper/react-native-keyboard-aware-scroll-view';

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    padding: 25,
  },
  listTitle: {
    fontSize: 16,
    marginBottom: 20,
    color: '#666',
    fontWeight: '600',
  },
  listButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  listIcon: {
    fontSize: 26,
    color: '#666',
    width: 60,
  },
  listLabel: {
    fontSize: 16,
  },
  separator: {
    height: 0.3,
    backgroundColor: '#e3e3e3',
  },
});

class RBSheetListItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allItems: [],
      items: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        allItems: [...nextProps.items || []],
        items: [...nextProps.items || []],
      });
    }
  }

  renderItem = ({ item }) => {
    const { onSelectOption, renderLabel } = this.props;

    return (
      <TouchableOpacity
        style={styles.listButton}
        onPress={() => onSelectOption(item)}
      >
        {
          renderLabel ? renderLabel(item) : <Text style={styles.listLabel}>{item.name}</Text>
        }
      </TouchableOpacity>
    );
  };

  render() {
    const {
      title, setRef, showSearch, height, containerStyle,
    } = this.props;
    const { allItems, items } = this.state;

    return (
      <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={{ flex: 1 }}>
        <View style={{
          flex: 1,
        }}
        >
          <RBSheet
            ref={ref => {
              setRef(ref);
              this.RBSheet = ref;
            }}
            height={height || 450}
          >
            <View style={styles.listContainer}>
              <Text style={styles.listTitle}>{title}</Text>

              {showSearch && (
                <View>
                  <Input
                    label="Buscar..."
                    style={styles.inputStyle}
                    onChange={(search) => {
                      const data = filter(allItems, (item) => {
                        console.log('onChange item', item);

                        return TextUtils.slugfy(item.name)
                          .includes(TextUtils.slugfy(search));
                      });

                      this.setState({
                        items: data,
                      });
                    }}
                  />
                </View>
              )}

              <FlatList
                keyboardShouldPersistTaps="always"
                keyExtractor={(item, index) => index.toString()}
                data={items}
                extraData={this.props}
                renderItem={this.renderItem}
                contentContainerStyle={containerStyle || {}}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
              />
            </View>
          </RBSheet>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}

export default RBSheetListItems;
