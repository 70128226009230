import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayContainer,
  },
  separator: {
    flex: 1,
    height: 2,
    backgroundColor: colors.grayContainer,
  },
  addContainer: {
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});

export default styles;
