import { responseProducts, responseNotifyProduct } from '../../store/productsFetch/action';
import { responseAddProductInOrder } from '../../store/activeOrderFetch/addProduct/action';
import { responseAddBonusInOrder } from '../../store/activeOrderFetch/addBonus/action';
import { responseUpdateOrder } from '~/store/activeOrderFetch/updateOrder/action';

const methodsWS = {
  getAllProducts: responseProducts,
  notifyMe: responseNotifyProduct,
  addProductInOrder: responseAddProductInOrder,
  addBonusInOrder: responseAddBonusInOrder,
  updateOrder: responseUpdateOrder,
};

export default function requestsWS(method: string): Function {
  return methodsWS[method];
}
