/* @flow */

import React, { Component } from 'react';
import { Keyboard } from 'react-native';
import s from 'underscore.string';
import TextUtils from '~/utils/TextUtils';

import Input from './Input';

type Props = {
  onError: () => void,
  onSuccess: () => void,
  label?: string,
  value?: string,
  defaultValue?: string,
  error?: string,
  onChangeText?: (text?: string) => void,
  autoFocus?: string,
  error?: string,
  format?: string | (() => string),
  inputRef?: () => void,
  keyboardType?: string,
  label?: string,
  maxLength?: number,
  name?: string,
  onBlur?: () => void,
  onChange?: () => void,
  onInputFocus?: () => void,
  onSuccess?: () => void,
  placeholder?: string,
  required?: boolean,
  returnKeyType?: () => void,
  secureTextEntry?: boolean,
  style?: {},
  isMoney?: boolean,
  isPhone?: boolean
};

class Phone extends Component<void, Props, void> {
  state = {
    error: '',
  };

  onBlur = (entry: string) => {
    const errorMsg = 'Digite um número válido.';
    if (!TextUtils.validatePhone(entry, this.props.isLandline)) {
      this.setState({
        error: errorMsg,
      });
      this.props.onError(errorMsg);
    }
  };

  onChange = (entry: string) => {
    if (TextUtils.validatePhone(entry, this.props.isLandline)) {
      this.setState({
        error: '',
      });
      this.props.onError('');
    }
    this.props.onSuccess(entry);
  };

  render() {
    return (
      <Input
        {...this.props}
        error={this.state.error}
        onBlur={this.onBlur}
        onChange={this.onChange}
        keyboardType="numeric"
        format={this.props.format}
      />
    );
  }
}

Phone.defaultProps = {
  onError: () => {},
  onSuccess: () => {},
};

export default Phone;
