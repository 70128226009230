import React, { Component } from 'react';
import Address from './Address';


class AddressContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Address {...this.props} />
    );
  }
}

export default AddressContainer;
