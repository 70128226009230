// @flow

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import type { IProduct } from '~/entities/Product';
import { Money } from '~/common';

import styles from './styles';

export type Props = {
  product: {
    name: string,
    total_price: number,
  },
  onPress?: (product: IProduct) => void,
};

class ProductOrder extends Component<Props> {
  render() {
    const { onPress, product } = this.props;
    return (
      <TouchableOpacity
        onPress={() => onPress(product)}
      >
        <View style={styles.container}>
          <Text style={styles.name}>
            {product.quantity}x {product.name}
          </Text>
          <Text style={styles.total}>{Money(product.total_price)}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

ProductOrder.defaultProps = {
  onPress: () => {},
};

export default ProductOrder;
