/* @flow */
export const SHIPPING_METHODS_FETCH_REQUEST = 'SHIPPING_METHODS_FETCH_REQUEST';
export const SHIPPING_METHODS_FETCH_SUCCESS = 'SHIPPING_METHODS_FETCH_SUCCESS';
export const SHIPPING_METHODS_FETCH_FAILURE = 'SHIPPING_METHODS_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: SHIPPING_METHODS_FETCH_SUCCESS,
      loading: false,
      payload,
    });
  };
}

export function failure({ resource }) {
  return dispatch => {
    dispatch({
      type: SHIPPING_METHODS_FETCH_FAILURE,
      loading: false,
      payload: resource,
    });
  };
}

export default function list(payload) {
  return dispatch => {
    dispatch({
      type: SHIPPING_METHODS_FETCH_REQUEST,
      loading: true,
      payload,
    });
  };
}
