import WalletService from '~/security/WalletService';

import Crypto from '~/security/Crypto';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { DATA_ACCOUNT } from '~/constants/cache';

const CryptoJS = require('crypto-js');

class WalletController {

  static getPassword(id) {
    return Crypto.sha256(id).toString(CryptoJS.enc.Hex);
  }

  static async create(data) {
    const profileId = data.user.id;

    const password = this.getPassword(profileId);
    return WalletService.createWallet(password, profileId);
  }

  static async getCards() {
    const data = await CacheStore.get(DATA_ACCOUNT);
    const profileId = data.user.id;
    const password = this.getPassword(profileId);
    return WalletService.getCards(password, profileId);
  }

  static async addNewCard(card) {
    const data = await CacheStore.get(DATA_ACCOUNT);
    const profileId = data.user.id;

    const password = this.getPassword(profileId);

    return WalletService.addNewCard(password, profileId, card);
  }

  static async remove(card) {
    const data = await CacheStore.get(DATA_ACCOUNT);
    const profileId = data.user.id;

    const password = this.getPassword(profileId);

    return WalletService.removeCard(password, profileId, card);
  }
}

export default WalletController;
