import { Platform } from 'react-native';
import axios from 'axios';
import base64 from 'base-64';
import get from 'lodash/get';

import constants from '~/constants/isipag';

const PRODUCTION_ISIPAG_URL = 'https://gateway.isilist.com.br';

const STAGING_ISIPAG_URL = 'https://gateway-dev.isilist.com.br';

const PRODUCTION_CLIENT_ID = Platform.OS === 'android' ? 'wmUonyQCovjKzvTlX66U3Tr8OqcS7UXKzSDZEpx4' : 'oFKcdm6F7fG2w2ScDPGhXPYrhbGhHyaNKzts6XEg';
const PRODUCTION_CLIENT_SECRET = Platform.OS === 'android' ? '4j2ORYdp78RJxkRByGDPeCEGjkq7rrMNCdSLo8UvAHwi831faze8zvf9MmXTJkEITMU9hE9sHmIDmVYb1P4i5tdQq4KX7fVHS5wtCEehIUMU1LPRWn3weKsVckRPXOLV' : 'NyWoS7h45qdikCR1QviFJ3NhWlCdWCpweRsk29SLNLTuCh2sFRwJB8dDeRMTnmfirkxTNFkRZUrMqciryFBqNUt5YcOrtuh0jzDtvtLmMeQIDXvJFGC0OSihD4etuT0G';

const STAGING_CLIENT_ID = 'sjXNLrz5LOiz6fhnZK3T71YXDHOPxk0cnEmMmvfM';
const STAGING_CLIENT_SECRET = 'BNAEnG7ZJ0kI7vjAOB2wFIkT6XzhhLfufJvWMrIckzxrnKQxkO2vx0yeDct8yb9azSOKkLRDVUQfnx1iX6l6ctkEnB7jLNxRu7Io9GPuBGU4Xwt5i5FYZHXQ2kZ7bRuv';

class IsipagController {
  constructor() {
    this.baseUrl = PRODUCTION_ISIPAG_URL;
    this.clientId = PRODUCTION_CLIENT_ID;
    this.clientSecret = PRODUCTION_CLIENT_SECRET;
  }

  getUserProfile() {
    return this.get('v1/users/me?');
  }

  makeBaseAuth(user, password) {
    const tok = `${user}:${password}`;
    const hash = base64.encode(tok);
    return `Basic ${hash}`;
  }

  async getAccessToken() {
    const authorization = this.makeBaseAuth(this.clientId, this.clientSecret);
    const body = JSON.stringify({
      grant_type: 'client_credentials',
    });
    const objRequest = {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/x-www-form-urlencoded',
        Authorization: authorization,
      },
    };

    const url = `${this.baseUrl}/oauth/token/?grant_type=client_credentials`;
    const response = await axios.post(url, body, objRequest);
    if (response && response.status === 200) {
      return response.data;
    }
    throw new Error('Não foi possível gerar o token de acesso');
  }

  async registerNewCard(fullCreditCard) {
    try {
      const token = await this.getAccessToken();
      if (!token) {
        throw new Error('Token de acesso não disponível');
      }

      const authorization = `${token.token_type} ${token.access_token}`;
      const objRequest = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authorization,
        },
      };

      const url = `${this.baseUrl}/v2/card/`;
      const body = JSON.stringify(fullCreditCard);
      const response = await axios.post(url, body, objRequest);

      return response.data;
    } catch (err) {
      const message = get(err, 'response.data.detail', 'Não foi possível registrar o cartão');
      throw new Error(message);
    }
  }

  login(username, password) {
    const body = {
      grant_type: constants.GRANT_TYPE,
      response_type: constants.RESPONSE_TYPE_TOKEN,
      client_id: constants.CLIENT_ID,
      client_secret: constants.CLIENT_SECRET,
      username,
      password,
    };
    const url = `${this.baseUrl}/oauth2/access_token/`;
    const formData = new FormData();
    for (const k in body) {
      formData.append(k, body[k]);
    }
    const objRequest = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post(url, formData, objRequest);
  }
}

export default new IsipagController();
