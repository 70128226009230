
class queueFactory {

}

import get from 'lodash/get';
import differenceBy from 'lodash/differenceBy';

import type {IRequest} from '../../entities/Request';
import requestsWS from './requestsWS';
import reponsesWS from './responsesWS';
import { store } from '~/App.web';
import {processOrderFinish, processOrderStart} from '../../store/processOrder/action';
import CacheStore from "~/modules-wrapper/react-native-cache-store";
import addProductInOrder from "~/services/carts/addProduct";
import { Alert } from "react-native";
import { STORE_QUEUE_ITEMS } from "~/store/storesConstants";

const TIMEOUT_REQUEST_PROCESS = 5 * 1000;
const REQUEST_PROCESS = 'REQUEST_PROCESS';

const MESSAGE_ERRO_UPLOAD = {
  type: 'error', message: 'Estamos com problema ao sincronizar seus itens. Por favor tente novamente.'
};

const requestFactory = {
  ws: requestsWS,
};

const responseFactory = {
  self: reponsesWS,
};

function buildReponse(req: IRequest): void {
  const id = new Date().getTime();
  const response = {
    id,
    request_id: req.id,
    context: req.context,
    source: {},
    messages: [],
  };

  return response;
}

const TAG = 'RequestManager';

class RequestManager {
  constructor() {
    this.queue = null;
    this.queueItems = [];
    this.init();
  }

  async init() {
    const itemsCache = await CacheStore.get(STORE_QUEUE_ITEMS);
    this.queueItems = itemsCache || [];
    this.queue = await queueFactory();

    this.queue.addWorker(REQUEST_PROCESS, async (id, requests) => {

      store.dispatch(processOrderStart());

      console.log('INIT methodExec');

      const request = requests[0];

      console.log('INIT methodExec');
      const requestInstance = requestFactory[request.source.type];
      const {method} = request.source.setup;
      const methodExec = requestInstance(method);

      console.log('INIT methodExec', methodExec);

      const promise = new Promise(async (resolve, reject) => {
        try {
          let source = request.source;
          if (request.context === 'addProductInOrder') {
            source = requests;
          }

          const resp = await methodExec(source);
          const response = buildReponse(request);
          response.source = resp.source;
          response.messages = resp.messages || {};

          const responsetInstance = responseFactory[response.source.type];
          const methodResponse = request.source.setup.method;
          const methodResponseExec = responsetInstance(methodResponse);

          store.dispatch(methodResponseExec(response));

          resolve();
        } catch (error) {
          console.log(TAG, error);

          reject(new Error(MESSAGE_ERRO_UPLOAD));

          // console.log('addWorker response error', error);
          //
          // const response = buildReponse(request);
          // const errors = get(error, 'source.data', []);
          // if (errors.length) {
          //   response.message = errors[0].message;
          // }
          //
          // response.source = {};
          // response.messages = error.messages;
          //
          // const responseInstance = responseFactory.self;
          // const methodResponse = request.source.setup.method;
          // const methodResponseExec = responseInstance(methodResponse);
          //
          // store.dispatch(methodResponseExec(response));
          //
          // // console.log('addWorker response reject');
          // resolve({error: 'failed'});
        }
      });

      return promise;
    }, {
      onSuccess: async (id, payload) => {
        this.removeItemsProcessed(payload);

        setTimeout(async ()=> {
          const isJobProcessing = await this.isJobProcessing();
          if (!isJobProcessing) {
            const items = this.getItemToProcess();
            if (items.length > 0) {
              await this.execProcessItem();
            } else {
              store.dispatch(processOrderFinish());
            }
          }
        }, 100);
      },
      onStart: async (id, payload) => {},
      onComplete: async (id, payload) => {},
      onFailure: async (id, payload) => {},
      onFailed: async (id, payload) => {
        store.dispatch(processOrderFinish());
        Alert.alert('', MESSAGE_ERRO_UPLOAD.message);
      },
    });
  }

  removeItemsProcessed = (payload) => {
    const items = this.getItems();
    const itemsWithoutProcessed = differenceBy(items, payload, 'id');
    this.setItems(itemsWithoutProcessed);
  };

  push = async (request: IRequest) => {
    const params = {};

    get(request, 'source.setup.args', []).forEach((item) => {
      params[item.key] = item.value;
    });

    await this.execProcessItem({
      context: request.context,
      id: request.id,
      source: {
        setup: { ...get(request, 'source.setup') },
        type: get(request, 'source.type'),
      },
      params,
    });
  };

  execProcessItem = async (request) => {
    store.dispatch(processOrderStart());

    console.log('execProcessItem request', request);

    const requestInstance = requestFactory[request.source.type];
    const {method} = request.source.setup;

    console.log('execProcessItem request method', method);

    const methodExec = requestInstance(method);
    const promise = new Promise(async (resolve, reject) => {
      try {
        let source = request.source;
        const resp = await methodExec(request);

        console.log('execProcessItem request resp', resp);

        const response = buildReponse(request);
        response.source = resp.source;
        response.messages = resp.messages || {};

        const responsetInstance = responseFactory[response.source.type];
        const methodResponse = request.source.setup.method;
        const methodResponseExec = responsetInstance(methodResponse);

        console.log('methodResponseExec', methodResponseExec);

        store.dispatch(methodResponseExec(response));

        setTimeout(()=> {
          store.dispatch(processOrderFinish());
        }, 300);

        resolve();
      } catch (error) {
        console.log(TAG, error);

        reject(new Error(MESSAGE_ERRO_UPLOAD));

        // console.log('addWorker response error', error);
        //
        // const response = buildReponse(request);
        // const errors = get(error, 'source.data', []);
        // if (errors.length) {
        //   response.message = errors[0].message;
        // }
        //
        // response.source = {};
        // response.messages = error.messages;
        //
        // const responseInstance = responseFactory.self;
        // const methodResponse = request.source.setup.method;
        // const methodResponseExec = responseInstance(methodResponse);
        //
        // store.dispatch(methodResponseExec(response));
        //
        // // console.log('addWorker response reject');
        // resolve({error: 'failed'});
      }
    });

    return promise;

  };

  addItem = (request: IRequest) => {
    const items = this.getItems();

    const params = {};

    get(request, 'source.setup.args', []).forEach((item) => {
      params[item.key] = item.value;
    });

    items.push({
      context: request.context,
      id: request.id,
      source: {
        setup: { ...get(request, 'source.setup') },
        type: get(request, 'source.type'),
      },
      params,
    });

    this.setItems(items);
  };

  getItems() {
    return this.queueItems;
  }

  setItems(items) {
    this.queueItems = items;

    CacheStore.set(STORE_QUEUE_ITEMS, items);
  }

  getItemToProcess = () => {
    const items = this.getItems();
    const processItems = [];
    items.forEach((item)=> {
      let context = items[0].context;
      if (item.context == context) {
        processItems.push(item);
      }
    });

    return processItems;
  };

  async process() {
    // await this.queue.start();
    return true;
  }

  isJobProcessing = async () => {
    return false;
    // const count = await this.queue.getConcurrentJobs();
    // return this.queue.status === 'active' || count.length > 0;
  };
}

export default new RequestManager();
