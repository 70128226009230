import Repository from './Repository';
import { COMPANY_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'CompanyRepository';

export default class CompanyRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = COMPANY_SCHEMA;
  }

  getCompany(companyId) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getCompany(companyId).then(compay => {
        resolve(compay);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  allCompany() {
    return new Promise((resolve, reject) => {
      SQLiteManager.allCompany().then(company => {
        resolve(company);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCompany(company) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCompany(company).then(dataCompany => {
        resolve(dataCompany);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCustomer(customer) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCustomer(customer).then(dataCustomer => {
        resolve(dataCustomer);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCustomerCompanyAccounts(customer) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCustomerCompanyAccounts(customer).then(ccAccount => {
        resolve(ccAccount);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addImages(img) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addImages(img).then(dataImg => {
        resolve(dataImg);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addAddress(addr) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addAddress(addr).then(dataAddr => {
        resolve(dataAddr);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addPhones(phone) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addPhones(phone).then(dataPhone => {
        resolve(dataPhone);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
