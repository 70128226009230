import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';

import Product from '~/components/Product/ProductContainer';

import appStyles from '~/appStyles';
import styles from './styles';
import type { IProduct } from '~/entities/Product';
import EmptyView from '~/components/EmptyView';
import Images from '~/../assets/images';

type Props = {
  screenId: string,
  favorites: any,
  onPressLike?: (product: IProduct) => void,
};

class Favorites extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      productSelect: new Map(),
    };
  }

  emptyView = () => (
    <EmptyView
      icon={Images.iconHeartOutline}
      message="Você ainda não possui produtos favoritos"
    />
  );

  onProductSelect = (id: string) => {
    this.setState(state => {
      const productSelect = new Map(state.productSelect);
      productSelect.set(id, !productSelect.get(id));
      return { productSelect };
    });
  };

  renderItem = ({ item }) => (
    <Product
      id={item.id}
      hideQuantity={item.type === 'matriz'}
      onProductSelect={this.onProductSelect}
      isProductSelect={!!this.state.productSelect.get(item.id)}
      product={item}
      type="linear"
      screenId={this.props.screenId}
      onPressLike={this.props.onPressLike}
    />
  );

  render() {
    const { favorites } = this.props;

    return (
      <View style={appStyles.container}>
        {
          <FlatList
            style={{ elevation: 10 }}
            data={favorites}
            extraData={this.state}
            keyExtractor={item => item.id}
            renderItem={this.renderItem}
            ItemSeparatorComponent={() => <View style={styles.separator} />}
            ListEmptyComponent={this.emptyView}
          />
        }
      </View>
    );
  }
}

export default Favorites;
