export const APP_INFO = 'APP_INFO';
export const CATEGORIES_ROOT = 'CATEGORIES_ROOT';
export const COMBOS_APPLY = 'COMBOS_CACHE';
export const FAVORITES = 'FAVORITES';
export const PROMOTIONS = 'PROMOTIONS';
export const SYNC = 'APP_INFO';
export const COMPANY_ACTIVE = 'COMPANY_ACTIVE';
export const STORE_ITEM_ACTIVE = 'STORE_ITEM_ACTIVE';
export const DATA_ACCOUNT = 'DATA_ACCOUNT';
export const CART_ANONYMOUS = 'CART_ANONYMOUS';
export const CART_ERROR = 'CART_ERROR';
export const AUTH_TOKEN = 'auth_token';
export const CREDIT_CARD_SELECTED = 'CREDIT_CARD_SELECTED';
export const INSTALLMENT_SELECTED = 'INSTALLMENT_SELECTED';

export const NOTIFICATION_TOKEN_KEY = 'notification_token';
export const SENDER_ID = '150002699110';
export const LIKED_PRODUCTS = 'LIKED_PRODUCTS';
export const USER_GENRE = 'USER_GENRE';
