import Repository from './Repository';
import { COMBO_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'ComboRepository';

export default class ComboRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = COMBO_SCHEMA;
  }

  addCombos(combos) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addCombos(combos).then(combo => {
        resolve(combo);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getProductsCombos(coddesconto, all) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getProductsCombos(coddesconto, all).then(prodCombo => {
        resolve(prodCombo);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCampaignsCombos() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getCampaignsCombos().then(campaignsCombo => {
        resolve(campaignsCombo);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
