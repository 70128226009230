import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({

  container: {
    flex: 1,
    paddingTop: 4,
    paddingBottom: 4,
  },

  list: {
    alignSelf: 'center',
    width: '95%',
  },

  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    paddingTop: 16,
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: '94%',
  },

  content: {
    paddingTop: 4,
    alignSelf: 'center',
  },

  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    // marginRight: '20%',
  },

  location: {
    fontSize: 17,
    color: colors.grayMid,
  },
  locationDetail: {
    fontSize: 16,
    color: colors.grayMid,
  },

  div: {
    flexDirection: 'row',
  },
  space: {
    marginLeft: 60,
    bottom: 12,
    // left: '20%',
  },
  icon: {
    position: 'absolute',
    paddingTop: 13,
    paddingLeft: 5,
  },
});

export default styles;
