// @flow

import React, { PureComponent } from 'react';
import { View, Image, Text, Animated } from 'react-native';
import { TouchableOpacity } from 'react-native';

import styles from './styles';

type InlineAlertProps = {
  icon: number,
  text: string,
  animated?: boolean,
  type?: 'success' | 'warning' | 'danger',
  onPress?: () => void,
};

class InlineAlert extends PureComponent<InlineAlertProps> {
  state = {
    wave: new Animated.Value(0),
  };

  componentDidMount(): void {
    if (this.props.animated) {
      this.startAnimation(true);
    }
  }

  componentDidUpdate({ animated }): void {
    (() => {
      if (animated === this.props.animated) return;
      this.startAnimation(this.props.animated);
    })();
  }

  startAnimation(start: boolean) {
    const animation = Animated.loop(Animated.sequence([
      Animated.timing(this.state.wave, {
        toValue: 1,
        duration: 700,
      }),
      Animated.timing(this.state.wave, {
        toValue: 0,
        duration: 10,
        delay: 400,
      }),
    ]));
    if (start) {
      animation.start();
    } else {
      animation.stop();
    }
  }

  render() {
    const {
      icon, text, onPress, type,
    } = this.props;
    const scaleY = this.state.wave.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 1.3],
    });
    const scaleX = this.state.wave.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 1.08],
    });

    const opacity = this.state.wave.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 0],
    });

    return (
      <View>
        <Animated.View
          style={[
            styles[`animation__${type}`],
            {
              opacity,
              transform: [{
                scaleY,
              }, {
                scaleX,
              }],
            },
          ]}
        />
        <View style={styles.containerOverflow}>
          <TouchableOpacity
            onPress={onPress}
            disabled={!onPress}
          >
            <View style={styles[`container__${type}`]}>
              <View style={styles.containerIcon}>
                <Image
                  style={styles[`icon__${type}`]}
                  source={icon}
                />
              </View>
              <Text style={styles.text}>
                {text}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

InlineAlert.defaultProps = {
  type: 'danger',
};

export default InlineAlert;
