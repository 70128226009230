import Repository from './Repository';
import { PRODUCT_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

export default class ProductRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PRODUCT_SCHEMA;
  }

  allProds() {
    return new Promise((resolve, reject) => {
      SQLiteManager.all('Product').then(prod => {
        resolve(prod);
      }).catch((error) => {

        console.log('allProds error', error)

        reject(error);
      });
    });
  }

  allProductsBySearch(value) {
    return new Promise((resolve, reject) => {
      SQLiteManager.allProductsBySearch(value).then(prod => {
        resolve(prod);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAllProductsByCategoryCode(code) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAllProductsByCategoryCode(code).then(prod => {
        resolve(prod);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getProductById(id) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getProductById(id).then(prod => {
        resolve(prod);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getProdsByLimit(limit) {
    return new Promise((resolve, reject) => {
      SQLiteManager.getByLimit('Product', limit).then(prod => {
        resolve(prod);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addProduct(data) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addProduct(data).then(prod => {
        resolve(prod);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateProduct(prod) {
    return new Promise((resolve, reject) => {
      SQLiteManager.updateProduct(prod).then(dataProd => {
        resolve(dataProd);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
