const CategoryImages = {
  iconBeach: require('./img/Category/beach.png'),
  iconFitness: require('./img/Category/fitness.png'),
  iconCloth: require('./img/Category/clothesFilled.png'),
  iconList: require('./img/Category/list.png'),
  iconCategory: require('./img/Category/categoryFilled.png'),
  iconDrinks: require('./img/Category/barFilled.png'),
  iconPet: require('./img/Category/petCommands.png'),
  iconFood: require('./img/Category/food.png'),
  iconHousekeeping: require('./img/Category/housekeeping.png'),
  iconMaintenance: require('./img/Category/maintenance.png'),
  iconAlimenticios: require('./img/Category/alimentos.png'),
  iconBebidas: require('./img/Category/bebidas.png'),
  iconCuidados: require('./img/Category/cuidado.png'),
  iconFarmacia: require('./img/Category/farmacia.png'),
  iconHigiene: require('./img/Category/higiene.png'),
  iconLimpeza: require('./img/Category/limpeza.png'),
};

export default CategoryImages;
