import axios from 'axios';

import { URL_BASE, getConfig } from '../index';
import type { ISource } from '../../entities/Source';

const TAG = 'Update Order';

export default async function updateOrder(source: ISource) {
  const config = await getConfig();
  const { params } = source;
  const { idOrder, companyId } = params;

  delete params.idOrder;
  delete params.companyId;

  if (params.sale_conditions && params.sale_conditions.length > 0) {
    params.sale_conditions = params.sale_conditions.map(sale_condition => ({
      ...sale_condition,
      code: sale_condition.value_code,
    }));
  }

  return new Promise((resolve, reject) =>
    axios
      .post(`${URL_BASE}/cart/${companyId}/detail/${idOrder}/`, params, config)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(TAG, error.response.data);
        reject(error.response.data);
      }));
}
