import React, { Component } from 'react';
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SideMenu from './SideMenu';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import { connect } from 'react-redux';

import { SCREEN_HOME, SCREEN_LOGIN, SCREEN_SIDEMENU, SCREEN_ADDRESS, SCREEN_LIST_ADDRESS } from '~/screens/screens';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import { STORE_SET_CURRENT_ACCOUNT, STORE_SET_CURRENT_NET_INFO, STORE_LOAD_INFO_USER_STATE } from '~/store/storesConstants';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import ComponentHelper from '~/components/ComponentHelper';
import { DATA_ACCOUNT } from '~/constants/cache';
import Login from '~/utils/Login';
import setCurrentStoreItem, { syncCurrentStoreItem } from '../../store/setCurrentStoreItem/action';
import setNewLogin from '../../store/newLogin/action';
import setLoadPromo from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setLoadCart from '../../store/setLoadCart/action';
import setInfoUser from '../../store/setInfoUser/action';
import setErrorCompanyCustomer from '../../store/setErrorCompanyCustomer/action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import setCurrentCompany from '../../store/setCurrentCompany/action';
import { setCompanyCustomerActiveFromCompany } from '~/utils/CompanyCustomer';
import filter from 'lodash/filter';
import find from 'lodash/find';
import AppConfig from '~/AppConfig';
import { SALE_MODEL_B2C } from '~/constants/saleModel';
import { USER_GENRE } from '~/constants/cache';
import defaultMenu, { DENOUNCE_OPTION, REGISTER_ADDR } from './utils/defaultMenu';
// import { startFromStoreFlow } from '~/App.web';
import CompanyController from '~/controllers/CompanyController';
import CartController from '~/controllers/CartController';
import PaymentOptionController from '~/controllers/PaymentOptionController';
import { PAYMENT_TYPE_CREDIT_CARD } from '~/constants/typePayment';
import AccountController from '~/controllers/AccountController';
import { toast } from 'react-toastify';

type Props = {
  menu: {},
  componentId: string,
  dispatch: () => {},
  currentNetInfo: any,
  valueDataUser: any,
  sCurrentAccount: {
    user: {},
    isAnonymous: boolean,
  },
};

const TAG = 'SideMenuContainer';

class SideMenuContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      progressVisible: false,
      textProgress: '',
      alertTitle: 'Aguarde',
      menuOptions: [],
      showAlert: false,
      showModalLogout: false
    };
    this.login = new Login();
  }

  UNSAFE_componentWillMount() {
    // this.bottomTabEventListener = NavigationHelper.popRootTab(4, SCREEN_SIDEMENU.name);
    if (!AppConfig.registerAddress) {
      const withoutItemMenuAddr = defaultMenu.sections.filter(item => item.id !== 'section_5');
      this.setState({ menuOptions: withoutItemMenuAddr });
    } else {
      this.setState({ menuOptions: defaultMenu.sections });
    }
  }

  async componentDidMount() {
    const isAnonymous = await AccountController.isAnonymous();
    if (!isAnonymous) await this.setCreditCardOption();

    CacheStore.get(DATA_ACCOUNT)
      .then(data => {
        this.props.dispatch(setCurrentAccount(data));
      })
      .catch(error => {
        console.log('LFS get dataAccount cache error', error);
      });
  }

  onShowModalLogout = () => {
    if (!this.props.sCurrentAccount.isAnonymous && this.props.sCurrentAccount.user) {
      this.setState({
        showModalLogout: true,
      });
    } else {
      toast.dark('Você não está logado');
    }
  }

  onCancelShowModalLogout = () => {
    this.setState({
      showModalLogout: false,
    });
  }

  doPressLogout = () => {
    this.setState({
      showModalLogout: false,
    }, ()=> {
      this.logout();
    });
  };

  onPressLogout = ComponentHelper.debounce(this.doPressLogout);

  showLogin = () => {
    this.props.navigation.push(SCREEN_LOGIN.name);
  };

  showAddress = () => {
    NavigationHelper.push(SCREEN_SIDEMENU.name, SCREEN_LIST_ADDRESS.name, true);
  };

  doPress = (screen) => {
    const { sCurrentAccount } = this.props;
    if (screen.should_be_logged && sCurrentAccount.isAnonymous) {
      Alert.alert(
        'Login necessário',
        'Você precisa estar logado para criar cartões. \nDeseja entrar agora?',
        [
          {
            text: 'Cancelar',
            onPress: null,
          },
          {
            text: 'Entrar',
            onPress: this.showLogin.bind(this),
          },
        ],
      );
    } else {
      const props = { sourceScreen: SCREEN_SIDEMENU.name };
      if (screen.screen === SCREEN_SIDEMENU.name) {
        this.hide();
      } else if (screen.screen === SCREEN_LOGIN.name) {
        this.showLogin();
      } else {
        this.props.navigation.navigate(screen.screen, {
          ...props,
          title: screen.title,
        });
      }
    }
  };

  doPressSheet = (sheetRef) => sheetRef.open();

  onPress = ComponentHelper.debounce(this.doPress);
  onPressSheet = ComponentHelper.debounce(this.doPressSheet);

  onAlertBuyingForPress = () => {
    this.hide();
  };

  hide() {
    Navigation.mergeOptions(this.props.componentId, {
      sideMenu: {
        left: {
          visible: false,
        },
      },
    });
  }

  async setDenounceOption() {
    const data = await AsyncStorage.getItem(USER_GENRE);
    const userGenre = data ? JSON.parse(data) : {};
    if (userGenre && userGenre.id && userGenre.id === 1) {
      this.setState({ menuOptions: [...this.state.menuOptions, DENOUNCE_OPTION] });
    }
  }

  async setCreditCardOption() {
    const paymentOptionsController = new PaymentOptionController();
    const paymentOptions = await paymentOptionsController.getAllPaymentOption();
    const creditCardPayment = find(paymentOptions, { kind: PAYMENT_TYPE_CREDIT_CARD });
    //TODO: REMOVER COMENTARIOS
    // if (!creditCardPayment) {
    //   const menuOptions = filter(this.state.menuOptions, (o) => o.id !== 'section_0');
    //   this.setState({ menuOptions });
    // }
  }

  async componentDidAppear() {
    if (AppConfig.showDenounceOption) this.setDenounceOption();
  }

  logout = async () => {
    const netInfo = this.props.currentNetInfo;
    if (!netInfo.is_connected) {
      Alert.alert('', 'Você está offline! Conecte a internet para sair da sua conta.');
      return;
    }
    const newLogout = {
      type: '',
      auth: true,
    };
    const loadData = {
      type: '',
      load: true,
    };
    const errorCompanyCustomer = {
      type: '',
      error: false,
    };

    this.props.dispatch(setNewLogin(newLogout));
    this.props.dispatch(setLoadCategories(loadData));
    this.props.dispatch(setLoadPromo(loadData));
    this.props.dispatch(setLoadProdContext(loadData));
    this.props.dispatch(setLoadNoticies(loadData));
    this.props.dispatch(setLoadCart(loadData));
    this.props.dispatch(setErrorCompanyCustomer(errorCompanyCustomer));

    this.setState({ progressVisible: true, textProgress: 'Saindo da sua conta...' });

    try {
      setTimeout(async () => {
        await AsyncStorage.clear();
        const clearUserData = {
          type: '',
          info: '',
        };

        this.props.dispatch(setInfoUser(clearUserData));
        const dataAccount = await this.login.processLoginAnonymous();

        if (AppConfig.startFromStore) {
          setTimeout(() => {
            startFromStoreFlow();
          }, 200);
        } else {
          setTimeout(() => {
            this.props.navigation.navigate(SCREEN_HOME.name);
          }, 200);
        }


        this.setState({ progressVisible: false, textProgress: '' });
        this.props.dispatch(setCurrentAccount(dataAccount));
        const companyController = new CompanyController();
        const cartController = new CartController();
        const companies = await companyController.allCompany();
        let orderActive = await cartController.getCart(null, true);
        orderActive = orderActive[0];
        const currentCompany = companies.find(com => com.id === orderActive.company_id);
        const companyCustomer = currentCompany.customer_company_accounts[0];

        await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);
        this.props.dispatch(setCurrentCompany(currentCompany));
        this.props.dispatch(setCurrentStoreItem(companyCustomer));
        this.props.dispatch(syncCurrentStoreItem(companyCustomer));
      }, 1000);
    } catch (error) {
      Alert.alert('Ops...', 'Erro ao sair da sua conta. Contate o suporte.');
      this.setState({ progressVisible: false, textProgress: '' });
      console.log(TAG, error);
    }
  };

  canShow(user, section) {
    if (section.id === 'section_4') {
      return AppConfig.contacts.some(contact => contact.setup[0].value !== '');
    }
    return (!user && ['section_2', 'section_3', 'section_5'].indexOf(section.id) === -1)
    || (user && !(section.id === 'section_3' && (AppConfig.sale_model === SALE_MODEL_B2C || AppConfig.startFromStore)));
  }

  render() {
    const { sCurrentAccount, valueDataUser } = this.props;

    let user;
    if (sCurrentAccount && !sCurrentAccount.isAnonymous && sCurrentAccount.user) {
      user = sCurrentAccount.user;
    }

    const menuOptions = {
      sections: filter(this.state.menuOptions, (section) => this.canShow(user, section)),
    };

    return (
      <SideMenu
        {...this.props}
        user={user || valueDataUser.info.user}
        menu={menuOptions}
        screenId={this.props.componentId}
        onPressOption={this.onPress}
        onPressLogout={this.onPressLogout}
        onAlertBuyingForPress={this.onAlertBuyingForPress}
        progressVisible={this.state.progressVisible}
        textProgress={this.state.textProgress}
        alertTitle={this.state.alertTitle}
        onPressSheet={this.onPressSheet}
        onShowModalLogout={this.onShowModalLogout}
        onCancelShowModalLogout={this.onCancelShowModalLogout}
        showModalLogout={this.state.showModalLogout}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

const getDataUserState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_INFO_USER_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentAccount: getAccount(state),
    currentNetInfo: getNetInfo(state),
    valueDataUser: getDataUserState(state),
  };
}

export default connect(mapStateToProps)(SideMenuContainer);
