import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const badgeSize = 15;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusBadge: {
    width: badgeSize,
    height: badgeSize,
    borderRadius: badgeSize / 2,
    backgroundColor: colors.grayContainerDark,
    marginRight: sizes.horizontalSpaceSmall,
  },
  statusBadge__success: {
    backgroundColor: colors.successColorDark,
  },
  title: {
    fontWeight: '600',
    fontSize: 13,
    color: '#000',
    marginRight: sizes.horizontalSpaceSmall,
  },
  date: {
    fontSize: 12,
    color: colors.grayDark,
  },
  divider: {
    marginLeft: badgeSize / 2,
    height: 30,
    width: 1,
    marginVertical: 1,
    backgroundColor: colors.grayContainerDark,
  },
});

export default styles;
