import axios from 'axios';

import { URL_BASE, getConfig, getCurrentCompany } from '../index';
import type { ISource } from '../../entities/Source';

export default async function notifyMe(source: ISource) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  const { args } = source.setup;
  const params = {};
  args.forEach(arg => params[arg.key] = arg.value);

  return new Promise((resolve, reject) =>
    axios.post(`${URL_BASE}/wish_lists/${company}/`, params, config)
      .then(response => {
        const { data } = response;

        data.source.data = {
          products: data.source.data, productNotify: params.product, amount: params.amount,
        };

        resolve(data);
      })
      .catch(error => {
        const { data } = error.response;
        data.messages = {};
        data.messages.productNotify = params.product;
        return reject(data);
      }));
}
