import React, { Component } from 'react';
import { View, Image } from 'react-native';
import { Touchable } from 'react-native-web';

import Images from '~/../assets/images';
import styles from './styles';

type Props = {
  value: boolean,
  onPress: (value: boolean) => void,
};

class CheckBox extends Component<Props> {
  render() {
    const { value, onPress } = this.props;
    return (
      <View style={styles.overflowContainer}>
        <Touchable
          onPress={() => onPress(!value)}
        >
          <View
            style={[
              styles.container,
              value ? styles.container__active : {},
            ]}
          >
            {
              value
                ? (
                  <Image
                    style={styles.icon}
                    source={Images.iconCheck}
                  />
                )
                : null
            }
          </View>
        </Touchable>
      </View>
    );
  }
}

export default CheckBox;
