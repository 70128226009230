import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({

  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  list: {
    alignSelf: 'center',
    width: '95%',
  },

  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    marginLeft: 30,
    padding: 15,
    elevation: 5,
    height: 70,
  },

  content: {
    paddingTop: 4,
  },

  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    marginRight: '20%',
  },

  location: {
    fontSize: 17,
    color: colors.grayMid,
  },
  locationDetail: {
    fontSize: 16,
    color: colors.grayMid,
  },

  div: {
    flexDirection: 'row',
  },
  space: {
    marginLeft: 40,
    bottom: 12,
  },
  icon: {
    position: 'absolute',
    paddingTop: 25,
  },
});

export default styles;
