import React from 'react';
import get from 'lodash/get';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Text } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/dist/MaterialIcons';
import PromotionsRoutes from './promotions.routes';
import ProductsCategoriesRoutes from './productsCategories.routes';
import CartRoutes from './carts.routes';
import SideMenuRoutes from './sideMenu.routes';
import HomeRoutes from './home.routes';
import { SCREEN_HOME, SCREEN_SIDEMENU } from '~/screens/screens';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { STORE_ACTIVE_ORDER_FETCH } from '~/store/storesConstants';
import { connect } from 'react-redux';
import { colors } from '~/values';

const TabStack = createBottomTabNavigator();

const TabBottomRoutes = (props) => {
  const count = get(props.sOrderActive, 'activeOrder.payload.products', []).length;

  const getLabelStyled = ({
    color,
    focused
  }, tab) => (
    <Text style={{
      color,
      fontWeight: focused ? '700' : '500',
      fontSize: 10
    }}>
      {tab}
    </Text>
  );

  return (
    <TabStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      tabBarOptions={
        {
          activeTintColor: colors.primaryColor,
          // tabStyle: styles.tabContainer,
          keyboardHidesTabBar: true,
          labelPosition: "below-icon",
          // labelStyle: styles.labelStyle,
          style: { flexDirection: "row-reverse" },
        }
      }
    >
      <TabStack.Screen
        name={SCREEN_HOME.name}
        component={HomeRoutes}
        options={{
          title: 'Home',
          tabBarLabel: props => getLabelStyled(props, 'Home'),
          tabBarIcon: ({
            color,
            size
          }) => (
            <MaterialIcons name="home" size={size} color={color}/>
          ),
        }}
      />

      <TabStack.Screen
        name="ProductsAndCategories"
        component={ProductsCategoriesRoutes}
        options={{
          tabBarLabel: props => getLabelStyled(props, 'Produtos'),
          tabBarIcon: ({
            color,
            size
          }) => (
            <MaterialIcons name="shopping-bag" size={size} color={color}/>
          ),
        }}
      />

      <TabStack.Screen
        name="Cart"
        component={CartRoutes}
        options={{
          tabBarBadge: count > 0 ? count : null,
          tabBarLabel: props => getLabelStyled(props, 'Carrinho'),
          tabBarIcon: ({
            color,
            size
          }) => (
            <MaterialIcons name="shopping-cart" size={size} color={color}/>
          ),
        }}
      />

      <TabStack.Screen
        name="Promotions"
        component={PromotionsRoutes}
        options={{
          tabBarLabel: props => getLabelStyled(props, 'Ofertas'),
          tabBarIcon: ({
            color,
            size
          }) => (
            <MaterialIcons name="local-offer" size={size} color={color}/>
          ),
        }}
      />

      <TabStack.Screen
        name={SCREEN_SIDEMENU.name}
        component={SideMenuRoutes}
        options={{
          tabBarLabel: props => getLabelStyled(props, 'Conta'),
          tabBarIcon: ({
            color,
            size
          }) => (
            <MaterialIcons name="account-circle" size={size} color={color}/>
          ),
        }}
      />
    </TabStack.Navigator>
  );
};

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH])
    .toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
  };
}

export default connect(mapStateToProps)(TabBottomRoutes);
