import { StyleSheet } from 'react-native';
import { colors } from '~/values';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  separator: {
    height: 1,
    backgroundColor: colors.grayContainer,
  },
});

export default styles;

