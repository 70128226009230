import React, { Component } from 'react';
import { Alert } from 'react-native';
import AddCreditCard from './AddCreditCard';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import WalletController from '~/controllers/WalletController';
import IsipagController from '~/controllers/Isipag/IsipagController';
import DeviceInfo from 'react-native-device-info';
import moment from 'moment';
import { STORE_SET_CURRENT_ACCOUNT } from '~/store/storesConstants';
import {
  SCREEN_ABOUT,
  SCREEN_ADDRESS,
  SCREEN_CREDIT_CARD_LIST,
  SCREEN_PROFILE,
  SCREEN_SIDEMENU
} from "~/screens/screens";
import {toast} from "react-toastify";
import CPFCheck from "~/common/CPFCheck";

class AddCreditCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  validateCard(card) {
    const messages = [];

    const {
      cardValidDate, cardHolder, cardCVV, cardCPF, brand,
    } = card;
    const expireDate =
      cardValidDate && cardValidDate.includes('/') ? cardValidDate.split('/') : ['', ''];
    const expire_month = expireDate[0] || '13';
    const expire_year = expireDate[1] || '1';

    const name_on_card = cardHolder;
    const cvv = cardCVV || '';
    const cpf = cardCPF || '';
    const type = brand || '';

    if (!type) {
      messages.push('- O número de cartão de crédito digitado não é válido');
    }

    if(!CPFCheck(cpf)){
      messages.push('- O CPF não é válido');
    }

    if (!name_on_card || name_on_card.length < 3) {
      messages.push('- O nome do titular deve ter mais que 3 caracteres');
    }

    if (expire_month.length <= 0) {
      messages.push('- O mês de validade é obrigatório');
    } else if (parseInt(expire_month) > 13) {
      messages.push('- O mês de validade não pode ser maior do que 12 (Dezembro)');
    }

    if (expire_year.length <= 0) {
      messages.push('- O ano de validade é obrigatório');
    } else if (expire_year.length < 4) {
      messages.push('- O ano de validade deve possuir 4 dígitos (Ex.: 2018)');
    } else if (parseInt(expire_year) < moment().year()) {
      messages.push(`- O ano de validade não pode ser menor do que o ano atual (${moment().year()})`);
    }

    if (type === 'amex' && cvv.length !== 4) {
      messages.push('- O CVV deve possuir 4 dígitos (American Express)');
    } else if (type !== 'amex' && cvv.length !== 3) {
      messages.push('- O CVV deve possuir 3 dígitos');
    }
    return messages;
  }

  async cardExists(mask, brand, expireMonth, expireYear) {
    let exists = false;
    const cards = await WalletController.getCards();
    for (let i = 0; i < cards.length; i += 1) {
      const currentCard = cards[i];
      exists =
        currentCard.mask === mask &&
        currentCard.brand === brand &&
        currentCard.expire_month === expireMonth &&
        currentCard.expire_year === expireYear;
      if (exists) break;
    }
    return exists;
  }

  saveCard = card => async () => {
    let messages = [];
    const {
      cardValidDate, cardNumber, cardHolder, cardCVV, cardCPF, brand, isMain,
    } = card;

    const expireDate =
      cardValidDate && cardValidDate.includes('/') ? cardValidDate.split('/') : ['', ''];
    const expire_month = expireDate[0] || '13';
    const expire_year = expireDate[1] || '1';

    const mask = cardNumber.slice(cardNumber.length - 4, cardNumber.length);

    const exists = await this.cardExists(mask, brand, expire_month, expire_year);
    if (exists) {
      toast.warning('Ops... Esse cartão já foi adicionado. Tente novamente com um novo cartão.');
    } else {
      const number = cardNumber || '';
      const name_on_card = cardHolder;
      const cvv = cardCVV || '';
      const cpf = cardCPF || '';
      const type = brand || '';
      const device = DeviceInfo.getUniqueId();

      messages = this.validateCard(card);

      if (messages.length) {
        toast.error('Erro ao adicionar:\n' + messages.join('\n'));
      } else {
        const { user } = this.props.sCurrentAccount;
        const { email } = user;
        const { main: phone } = user.phones;

        const fullCreditCard = {
          expire_year,
          expire_month,
          number,
          name_on_card,
          cvv,
          cpf,
          type,
          device,
          email,
          phone,
        };

        if (this.props.location.latitude) {
          fullCreditCard.latitude = this.props.location.latitude;
          fullCreditCard.longitude = this.props.location.longitude;
        }

        this.setState({ loading: true });
        await this.addCardToIsipag(fullCreditCard, isMain);

        if (this.props.loadOptions) {
          this.props.loadOptions();
        }
        const cards = await WalletController.getCards();
        this.props.navigation.navigate(SCREEN_SIDEMENU.name, {update: true});

      }
    }
  };

  addCardToIsipag = async (fullCard, isMain) => {
    try {
      const card = await IsipagController.registerNewCard(fullCard);

      this.setState({ loading: false });
      this.addCardToWallet(card, isMain);
    } catch (err) {
      console.log('ERR', err);
      this.setState({ loading: false }, () => {
        this.showErrorSaveCard(err.message);
      });
    }
  };

  showErrorSaveCard(message) {
    setTimeout(() => {
      Alert.alert('Erro ao criar cartão', message);
    }, 100);
  }

  addCardToWallet(card, isMain) {
    card.main = isMain;
    WalletController.addNewCard(card)
      .then(() => {
        this.setState({ loading: false });
        // Navigation.pop(this.props.componentId);
      })
      .catch(err => {
        this.setState({ loading: false }, () => {
          Alert.alert('Não foi possível adicionar o cartão!', err);
        });
      });
  }

  render() {
    return <AddCreditCard save={this.saveCard} loading={this.state.loading} />;
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getLocation = createImmutableSelector([state => state], state =>
  state.getIn(['location']).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    location: getLocation(state).payload,
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(AddCreditCardContainer);
