import React, { Component } from 'react';
import { Text, View, FlatList, Image, Alert, ActivityIndicator , TouchableOpacity, Platform} from 'react-native';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import axios from 'axios';
import get from 'lodash/get';
import { updateOrderDataManager } from '~/store/activeOrderFetch/common';
import { URL_BASE, getConfig, getCurrentCompany } from '~/services/index';
import { ModalAddress } from '~/components';
import Images from '~/../assets/images';
import { colors } from '~/values';
import styles from './styles';

import CartController from '~/controllers/CartController';
import activeOrder from '~/store/activeOrderFetch/activeOrder/action';
import { store } from '~/App.web';
import alert from '~/components/AlertWeb';
import { StackActions } from '@react-navigation/native';
import { SCREEN_LIST_ADDRESS } from '~/screens/screens';
import CustomAlert from '~/components/CustomAlert';
import {toast} from 'react-toastify';

type Props = {
  dataAddr: any,
  addrSelect: any,
};

class Location extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.dataAddr || [],
      itemModal: {},
      sendData: false,
    };
    this.popupRef = React.createRef();
    this.cartController = new CartController();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataAddr !== this.props.dataAddr) {
      this.setState({ data: this.props.dataAddr });
    }
  }

  onPress = () => {
    this.popupRef.show();
  };

  onPressItem = (item) => {
    this.setState({ itemModal: item });
  }

  onclosePopup = () => {
    this.popupRef.close();
  };

  onPressCheck = async (item) => {
    CacheStore.set('ADDRSELECT', item.id);

    let apiRes = null;
    const config = await getConfig();
    const company = await getCurrentCompany();
    const cart = await this.cartController.getCart(null, true);
    const url = `${URL_BASE}/cart/${company}/detail/${cart[0].id}/delivery_addresses/`;

    const payload = {
      delivery_address: {
        code: '1',
        description: 'Principal',
        district: item.district,
        postal_code: item.postal_code,
        city: item.city,
        complement: item.complement,
        number: item.street_number,
        state: item.state_city,
        address: item.street,
      },
    };

    this.setState({ sendData: true });
    try {
      apiRes = await axios.post(url, payload, config);
      console.log('API RES CHOICE ADDR: ', apiRes);
      if (apiRes !== null && apiRes.status === 200) {
        const order = get(apiRes.data, ['source', 'data']) || [];
        await updateOrderDataManager({ ...order });
        const cartController = new CartController();
        await cartController.insertCartInCache([order]);

        store.dispatch(activeOrder());

        this.setState({ sendData: false });

        toast.success('O endereço foi definido como principal');

        setTimeout(()=> {
          this.props.navigation.dispatch(StackActions.popToTop());
        }, 300);
      }
    } catch (err) {
      this.setState({ sendData: false });
      toast.error(`Erro: ${err.response.status}`);
    }
  }

  renderCardsLocation = (item) => (
    <TouchableOpacity onPress={() => {
      this.setState({
        currentItem: item.item,
      }, ()=> {
        this.setState({
          showModalAlertSelectAddress: true,
        })
      });
    }}>
      <View style={styles.container}>
        <View style={styles.item}>
          <View style={{ top: 10, position: 'absolute', left: '96%'  }}>
            <TouchableOpacity onPress={() => { this.onPress(); this.onPressItem(item.item); }}>
            <Image
                  source={Images.iconDotsVertical}
                  style={{ width: 20, height: 16, marginLeft: 10, marginTop: 8, tintColor: colors.primaryColor }} />
            </TouchableOpacity>
          </View>
          <View style={{ top: 10, position: 'absolute', left: '89%' }}>
            {item.item.id === this.props.addrSelect ?
              <Image
                source={Images.iconCheck}
                style={{ tintColor: colors.primaryColor, position: 'absolute', height: 30, width: 30 }}
              />
            : <View />}
          </View>
          <View style={styles.content}>
            <View style={styles.icon}>
              {item.item && item.item.kind === 'HOME' && <Image
                source={Images.iconHome}
                style={{ tintColor: colors.grayDark, height: 30, width: 30 }}
              />}
              {item.item && item.item.kind === 'OFFICE' && <Image
                source={Images.iconOffice}
                style={{ tintColor: colors.grayDark, height: 30, width: 30 }}
              />}
              {item.item && item.item.kind !== 'HOME' && item.item.kind !== 'OFFICE' && <Image
                source={Images.iconAddress}
                style={{ tintColor: colors.grayDark, height: 30, width: 30 }}
              />}
            </View>
            <View style={styles.space}>
              <View style={styles.div}>
                <Text style={styles.title}>{`${!!item.item.kind && item.item.kind !== 'HOME' && item.item.kind !== 'OFFICE' ? `${item.item.surname} - ` : ''} ${item.item.city}` || 'N/A'}</Text>
                <View style={{ marginLeft: '3%' }} />
                <ModalAddress
                  addr={this.state.itemModal}
                  ref={(target) => { this.popupRef = target; }}
                  onTouchOutside={() => { this.onclosePopup(); }}
                  {...this.props}
                />
              </View>
              <Text style={styles.location}>{`${item.item.street}, ${item.item.street_number} - ${item.item.district || 'N/A'}`}</Text>
              <Text style={styles.locationDetail}>{`${item.item.complement} - ${item.item.reference_point}`}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>);

  render() {
    return (
      <View>
        {this.state.sendData ?
          <ActivityIndicator style={{ top: 20 }} size="large" color={colors.primaryColor} />
        : <FlatList
          data={this.state.data}
          keyExtractor={(item, index) => `${item.street_number}-${index}`}
          renderItem={item => this.renderCardsLocation(item)}
          ListEmptyComponent={<View />}
        />}

        <CustomAlert
          showModal={this.state.showModalAlertSelectAddress}
          title="Selecionar endereço"
          cancelButtonTitle="Cancelar"
          cancelButtonPress={()=> {
            this.setState({
              showModalAlertSelectAddress: false,
            });
          }}
          okButtonPress={()=> {
            this.setState({
              showModalAlertSelectAddress: false,
            }, ()=> {
              this.onPressCheck(this.state.currentItem);
            });
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginTop: Platform.OS === 'ios' ? 5 : 20, textAlign: 'center' }}>
              Deseja definir esse endereço como principal para entrega(s)?
            </Text>
          </View>
        </CustomAlert>
      </View>
    );
  }
}

export default Location;
