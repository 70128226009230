import { SET_LOAD_PROD_CONTEXT_STATE } from './action';

import { Map } from 'immutable';

const initialState = Map({
  payload: Map({
    type: '',
    load: true,
  }),
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SET_LOAD_PROD_CONTEXT_STATE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;