import React, { Component } from 'react';
import Images from '~/../assets/images';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import intersectionBy from 'lodash/intersectionBy';

import ProductsAndCategories from './ProductsAndCategories';
// import NavigationHelper from '~/screens/NavigationHelper';
import {
  SCREEN_HOME,
  SCREEN_PRODUCTS_AND_CATEGORIES,
  SCREEN_SEARCH_HISTORY,
} from '~/screens/screens';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { STORE_ACTIVE_ORDER_FETCH, STORE_PRODUCTS_FETCH, STORE_LOGIN_STATE, STORE_SET_CURRENT_ACCOUNT } from '~/store/storesConstants';
import listProducts, {
  listProductsByProductsAndCategories,
  PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST,
  PRODUCTS_LIST_SUCCESS,
  PRODUCT_NOTIFY_REQUEST,
  PRODUCT_NOTIFY_SUCCESS,
} from '../../store/productsFetch/action';
import { HOME_PRODUCTS_LIMIT } from '~/screens/Home/HomeContainer';
import { mergeProductsWithOrder, sortByProductsNoStock } from '~/utils/product';
import ComponentHelper from '~/components/ComponentHelper';
import { activeOrder } from '~/store/activeOrderFetch/activeOrder/action';

import CategoryController from '~/controllers/CategoryController';
import ProductController from '~/controllers/ProductController';
import AppConfig from '../../AppConfig';
import styles from '~/components/Quantity/styles';
import { Button } from '~/components';

type Props = {
  valueLogin: any,
  title: string,
  categoryCode: any,
  componentId: any,
  dispatch: () => {},
  sourceScreen: string,
  fromSideMenu: boolean,
  sProductFetch: {
    type: string,
    payload: {
      products: [],
      categories: [],
      products_categories: [],
      categories_relationships: [],
    },
    loading: boolean,
  },
  sOrderActive: Object<any>,
};

class ProductsAndCategoriesContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      products: [],
      categories: [],
      loading: false,
    };

    this.props.navigation.setOptions({
      title: get(props, 'route.params.title', 'Produtos e categorias'),
    })
    this.props.navigation.addListener('focus', () => {
      if (this.props.valueLogin.auth === false) {
        const categoryCode = get(this.props, 'route.params.categoryCode', 0);
  
        console.log('GLEGLE categoryCode', categoryCode);
  
        this.props.dispatch(listProductsByProductsAndCategories(categoryCode));
      }
    });
  }

  componentDidMount() {
    console.log('PRODUCTS_CATEGORIES', this.props.valueLogin.auth)
    if (this.props.valueLogin.auth === false) {
      /* const categoryCode = get(this.props, 'route.params.categoryCode', 0);

      console.log('GLEGLE categoryCode', categoryCode);

      this.props.dispatch(listProductsByProductsAndCategories(categoryCode));

      console.log('PRODUCTS_CATEGORIES this.props', this.props); */

      // if (this.props.sOrderActive && this.props.sOrderActive.activeOrder && isEmpty(this.props.sOrderActive.activeOrder.payload)) {
      //   this.props.dispatch(activeOrder());
      // }
    }

    this.props.navigation.setOptions({
      headerRight: () => (
        <Button
          iconLeft={Images.iconSearch}
          iconButton
          onPress={() => {
            this.props.navigation.push(SCREEN_SEARCH_HISTORY.name);
          }}
        />
      ),
    });
  }

  // componentWillUnmount() {
    // if (this.navigationEventListener) {
    //   this.navigationEventListener.remove();
    // }

    // if (this.bottomTabEventListener) {
    //   this.bottomTabEventListener.remove();
    // }
  // }

  doCategoryClick = (category) => {
    this.props.navigation.push(SCREEN_PRODUCTS_AND_CATEGORIES.name, {
      categoryCode: category.category_code,
      title: category.description
    });
  };

  onCategoryClick = ComponentHelper.debounce(this.doCategoryClick);

  // navigationButtonPressed({ buttonId }) {
  //   if (buttonId === 'searchButton') {
  //     NavigationHelper.push(this.props.componentId, SCREEN_SEARCH_HISTORY.name, false);
  //   } else if (buttonId === 'backButton') {
  //     const parentIsHome = this.props.sourceScreen === SCREEN_HOME.name;
  //     this.props.dispatch(listProducts(
  //       parentIsHome ? 0 : this.props.categoryCode || 0,
  //       parentIsHome ? HOME_PRODUCTS_LIMIT : null,
  //     ));
  //     // Navigation.pop(this.props.componentId)
  //     //   .then()
  //     //   .catch();
  //   }
  // }

  componentDidUpdate(prevProps) {
    (() => {

      console.log('componentDidUpdate', this.props.sProductFetch);

      if (this.props.sProductFetch.list.type === prevProps.sProductFetch.list.type) return;
      if (prevProps.sProductFetch.list.type !== PRODUCTS_LIST_PRODUCTS_AND_CATEGORIES_REQUEST) {
        return;
      }
      console.log('AQUI AQUI this.props', this.props.route);
      if (this.props.sProductFetch.list.type !== PRODUCTS_LIST_SUCCESS) return;
      console.log('AQUI AQUI this.props', this.props.route);

      const { products, categories } = this.props.sProductFetch.list.payload;
      const { loading } = this.props.sProductFetch.list;

      const categoryCode = get(this.props, 'route.params.categoryCode', undefined);

      console.log('EPIC PRODCT categoryCode', categoryCode);

      const result = categories.filter(item => item.parent_code === categoryCode);

      console.log('EPIC PRODCT result', result);

      this.setState({
        products,
        categories: result,
        loading,
      }, ()=> {this.setState({ isVisible: true })});
    })();

    (async () => {
      if (this.props.sProductFetch.notifyMe.type === prevProps.sProductFetch.notifyMe.type) return;
      if (prevProps.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_REQUEST) return;
      if (this.props.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_SUCCESS) return;
      let { products, categories } = this.props.sProductFetch.list.payload;
      const { loading } = this.props.sProductFetch.notifyMe;

      // const categoryCode = get(this.props, 'route.params.categoryCode', 0);
      //
      // console.log('AQUI AQUI categoryCode', categoryCode);
      // console.log('AQUI AQUI this.props', this.props.route);

      if (this.state.categories.length === 0) {
        if (categoryCode !== 0) {
          const productController = new ProductController();
          const productsByCategory = await productController.getAllProductsByCategoryCode(this.props.categoryCode);
          products = intersectionBy(products, productsByCategory, 'id');

          console.log('AQUI AQUI products', products);

          const categoryController = new CategoryController();
          categories = await categoryController.getAllCategoriesByCategoryCode(this.props.categoryCode);

          console.log('AQUI AQUI categories', categories);
        }
      }

      this.setState({
        products,
        categories,
        loading,
      }, () => {this.setState({ isVisible: true })});
    })();
  }

  render() {
    const { categories, products, loading } = this.state;
    const { sCurrentAccount } = this.props
    let prodsFiltered = products.filter((prod) => prod.name.trim() !== '');

    let orderProducts = [];
    if (this.props.sOrderActive.activeOrder) {
      orderProducts = this.props.sOrderActive.activeOrder.payload.products;
    }

    if (sCurrentAccount && sCurrentAccount.isAnonymous){
      prodsFiltered = prodsFiltered.filter(prod => prod.stock > 0);
    }

    const allProducts = mergeProductsWithOrder(prodsFiltered, orderProducts);
    const { isVisible } = this.state;

    const allProductsSorteds = AppConfig.sortByProductsNoStock ?
      sortByProductsNoStock(allProducts) :
      sortBy(allProducts, 'name');

    return (
      <ProductsAndCategories
        {...this.props}
        componentId={this.props.componentId}
        products={allProductsSorteds}
        categories={categories}
        loading={!isVisible || loading}
        onPressCategory={this.onCategoryClick}
        newLogin={this.props.valueLogin.auth}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getProductsAndCategories = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_PRODUCTS_FETCH]).toJS());

const getOrderActive = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getLoginState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOGIN_STATE, 'payload']).toJS());

  const getAccount = createImmutableSelector([state => state], state =>
    state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sProductFetch: getProductsAndCategories(state),
    sOrderActive: getOrderActive(state),
    valueLogin: getLoginState(state),
    sCurrentAccount: getAccount(state)
  };
}

export default connect(mapStateToProps)(ProductsAndCategoriesContainer);
