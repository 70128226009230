/* @flow */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { SHIPPING_METHODS_FETCH_REQUEST, failure, success } from './action';
import list from '~/services/delivery-addresses/index';
import ShippingMethodsController from '~/controllers/ShippingMethodsController';

const getData = () => {
  const shippController = new ShippingMethodsController();
  const promise = shippController.getShippingMethods();
  return Observable.fromPromise(promise);
};

const shippingsFetch = (action$: any) =>
  action$
    .ofType(SHIPPING_METHODS_FETCH_REQUEST)
    .mergeMap((payload) => getData(payload)
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(shippingsFetch);
