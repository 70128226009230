import Repository from './Repository';
import { NOTICES_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

export default class NoticesRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = NOTICES_SCHEMA;
  }

  getNotices() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getNotices().then(notice => {
        resolve(notice);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addNotices(notices) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addNotices(notices).then(notice => {
        resolve(notice);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
