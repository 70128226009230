import React, { Component } from 'react';
import Profile from './Profile';
import Images from '~/../assets/images';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { COMPANY_ACTIVE, DATA_ACCOUNT } from '~/constants/cache';
// import { SCREEN_SIGNUP } from '~/screens/screens';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { STORE_SET_CURRENT_ACCOUNT } from '~/store/storesConstants';
import AppConfig from '~/AppConfig';

const data = [
  {
    key: 'display_name',
    label: 'Nome',
    value: '-',
    icon: Images.iconId,
  },
  {
    key: 'email',
    label: 'E-mail',
    value: '-',
    icon: Images.iconMail,
  },
  {
    key: 'nin',
    label: 'CPF',
    value: '-',
    icon: Images.iconAccount,
  },
  {
    key: 'birthday',
    label: 'Data de Nascimento',
    value: '-',
    icon: Images.iconCalendar,
  },
  {
    key: 'company',
    label: 'Empresa',
    value: '-',
    icon: Images.iconShop,
  },
];

type Props = {
  componentId: string,
  sCurrentAccount: {},
};

const TAG = 'ProfileContainer';

class ProfileContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
    };

    // const rightButtons = [];
    //
    // if (AppConfig.canEditRegister) {
    //   rightButtons.push({ id: 'edit', text: 'Editar', icon: Images.iconEdit });
    // }

    // Navigation.events().bindComponent(this);
    // Navigation.mergeOptions(this.props.componentId, {
    //   topBar: {
    //     elevation: 0,
    //     title: {
    //       text: 'Perfil',
    //     },
    //     rightButtons,
    //   },
    // });
  }

  UNSAFE_componentWillMount() {
    // Navigation.events().registerNavigationButtonPressedListener(({ buttonId }) => {
    //   if (buttonId === 'edit') {
    //     const props = { isUpdate: true };
    //     NavigationHelper.push(this.props.componentId, SCREEN_SIGNUP.name, false, props);
    //   }
    // });
  }

  componentDidMount() {
    this.loadInfo();
  }

  loadInfo = async () => {
    const company = await CacheStore.get(COMPANY_ACTIVE);

    this.setState({
      company,
    });
  };

  render() {
    const { sCurrentAccount } = this.props;
    let user = {};
    if (sCurrentAccount) {
      ({ user } = sCurrentAccount);
    }

    console.log('sCurrentAccount', sCurrentAccount);

    const { company } = this.state;
    return <Profile user={user} data={data} company={company} />;
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(ProfileContainer);
