/* @flow */
import React from 'react';
import { View, Text, FlatList } from 'react-native';
import Option from '../Option/Option';
import find from 'lodash/find';

import styles from './styles';

type Props = {
  attribute: {
    name: string,
    values: Array<string>,
    type: 'image' | 'color' | 'text',
  },
  onPressAttribute: () => void,
  selectedAttributes: {},
  filteredAttributes: [],
}

const Attribute = ({
  onPressAttribute, attribute, selectedAttributes, filteredAttributes,
}: Props) => {
  const renderItem = ({ item: option }) => {
    const selected = selectedAttributes[attribute.code] === option;
    const filterAttribute = find(filteredAttributes, { attribute: attribute.code });
    const isPresent = filterAttribute ? filterAttribute.values.includes(option) : true;
    return (
      <Option
        isPresent={isPresent}
        selected={selected}
        clickButton
        onPress={() => onPressAttribute(attribute.code, option)}
        option={option}
        type={attribute.type}
      />
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <Text style={styles.name}>
        {attribute.label}
      </Text>
      <View style={styles.optionsContainer}>
        <FlatList
          keyExtractor={(item) => item}
          horizontal
          extraData={selectedAttributes}
          renderItem={renderItem}
          data={attribute.values}
        />
      </View>
    </View>
  );
};

Attribute.defaultProps = {
  onPressAttribute: () => {},
};

export default Attribute;

