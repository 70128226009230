const images = {
  polibrasLogo: require('./img/polibras-logo.png'),
  diamantesLogo: require('./img/diamantes-logo.png'),
  donizeteLogo: require('./img/donizete-logo.png'),
  cantuLogo: require('./img/cantu-logo.png'),
  pariLogo: require('./img/pariLogo.png'),

  iconAbout: require('./img/about.png'),
  iconAccount: require('./img/account.png'),
  iconAddress: require('./img/address.png'),
  iconAlert: require('./img/alert.png'),
  iconAt: require('./img/at.png'),
  iconCalendar: require('./img/calendar.png'),
  iconAvatar: require('./img/account.png'),
  iconBack: require('./img/back.png'),
  iconBag: require('./img/bag.png'),
  iconBell: require('./img/bell.png'),
  iconCart: require('./img/cart.png'),
  iconCategoryList: require('./img/categoryList.png'),
  iconCheck: require('./img/check.png'),
  iconChevronDown: require('./img/chevronDown.png'),
  iconChevronLeft: require('./img/chevronLeft.png'),
  iconChevronRight: require('./img/chevronRight.png'),
  iconChevronUp: require('./img/chevronUp.png'),
  iconCircleRightArrow: require('./img/circleRightArrow.png'),
  iconClipboard: require('./img/clipboard.png'),
  iconClose: require('./img/close.png'),
  iconCoinBig: require('./img/coin_big.png'),
  iconCollapseArrow: require('./img/collapseArrow.png'),
  iconDeal: require('./img/deals.png'),
  iconDealFilled: require('./img/deal_filled.png'),
  iconDeliveryTruck: require('./img/deliveryTruck.png'),
  iconDownload: require('./img/download.png'),
  iconDotsVertical: require('./img/dots_vertical.png'),
  iconExpandArrow: require('./img/expandArrow.png'),
  iconEye: require('./img/eye.png'),
  iconEyeHidden: require('./img/eye_hidden.png'),
  iconHome: require('./img/home.png'),
  iconHeart: require('./img/heart.png'),
  iconHeartOutline: require('./img/heartOutline.png'),
  iconHistory: require('./img/history.png'),
  iconId: require('./img/id.png'),
  iconLeft: require('./img/left.png'),
  iconList: require('./img/list.png'),
  iconLogin: require('./img/login.png'),
  iconLogout: require('./img/logout.png'),
  iconMail: require('./img/mail.png'),
  iconMarker: require('./img/marker.png'),
  iconMenu: require('./img/menu.png'),
  iconMenuDown: require('./img/menuDown.png'),
  iconMinus: require('./img/minus.png'),
  iconMicrophone: require('./img/microphone.png'),
  iconNoImageBig: require('./img/no_image_big.png'),
  iconOrder: require('./img/order.png'),
  iconPhone: require('./img/phone.png'),
  iconPlus: require('./img/plus.png'),
  iconSad: require('./img/sad.png'),
  iconSearch: require('./img/search.png'),
  iconShop: require('./img/shop.png'),
  iconStar: require('./img/star.png'),
  iconStore: require('./img/store.png'),
  iconTitles: require('./img/titles.png'),
  iconWallet: require('./img/wallet.png'),
  iconEdit: require('./img/edit.png'),
  iconCheckAll: require('./img/checkAll.png'),
  iconDoubleTick: require('./img/doubleTick.png'),
  iconPlace: require('./img/place.png'),
  iconDots: require('./img/dots.png'),
  iconTrash: require('./img/trash.png'),
  iconGpsLocation: require('./img/gps.png'),
  iconCalculator: require('./img/shipping_calculator.png'),

  iconPastelCards: require('./img/cardsPastel.png'),
  iconPastelGift: require('./img/giftPastel.png'),
  iconPastelTicket: require('./img/ticketPastel.png'),

  flag_american: require('./img/Flags/american.png'),
  flag_aura: require('./img/Flags/aura.png'),
  flag_diners: require('./img/Flags/diners.png'),
  flag_discover: require('./img/Flags/discover.png'),
  flag_elo: require('./img/Flags/elo.png'),
  flag_hiper: require('./img/Flags/hiper.png'),
  flag_jbc: require('./img/Flags/jbc.png'),
  flag_mastercard: require('./img/Flags/mastercard.png'),
  flag_master: require('./img/Flags/mastercard.png'),
  flag_visa: require('./img/Flags/visa.png'),
  flag_default: require('./img/Flags/default.png'),

  imageCreditCard: require('./img/creditCard.png'),
  imageCardSecurity: require('./img/cardSecurity.png'),
  imagePaymentSlip: require('./img/paymentSlip.png'),
  imageMoney: require('./img/money.png'),
  iconOffice: require('./img/office.png'),
};

export default images;
