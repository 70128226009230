import { Alert, Linking, Platform } from 'react-native';
import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_NOTICES } from '../screens/screens';

const actionOptions = {
  0: 'external_url',
  1: 'external_app',
  2: 'screen',
  3: 'only_show',
};

const setAction = (props) => {
  const openExternalUrl = (url) => {
    Linking.canOpenURL(`${url}`)
      .then((supported) => {
        if (!supported) {
          return Alert.alert('Atenção', 'Não foi possível abrir o link.');
        }
        return Linking.openURL(`${url}`);
      })
      .catch((err) => console.error('An error occurred', err));
  };

  const openExternalApp = (packageName) => {
    const link = Platform.OS === 'android' ?
      `market://details?id=${packageName}` :
      `itms-apps://itunes.apple.com/us/app/id${packageName}?mt=8`;
    Linking.canOpenURL(link).then((supported) => {
      if (!supported) {
        return Alert.alert('Atenção', 'Não foi possível encontrar o app.');
      }
      return Linking.openURL(link);
    }).catch((err) => console.error('An error occurred', err));
  };
  const openScreen = (data) =>
    NavigationHelper.push(props.componentId, data.id, false, data.props);

  const onlyShow = () =>
    NavigationHelper.push(props.componentId, SCREEN_NOTICES.name, false, props);

  switch (props.action.type) {
    case actionOptions[0]:
      openExternalUrl(props.action.data.url);
      break;
    case actionOptions[1]:
      openExternalApp(props.action.data.package);
      break;
    case actionOptions[2]:
      openScreen(props.action.data);
      break;
    case actionOptions[3]:
      onlyShow(props);
      break;
    default:
      break;
  }
};

export default setAction;
