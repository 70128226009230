import React from 'react';
import { View } from 'react-native';
import {Placeholder, PlaceholderMedia } from 'rn-placeholder';

import colors from '../../values/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeProductMediaStyle = {
  ...backgroundGray,
  borderRadius: 10,
  height: 236,
  width: 130,
  margin: 4,
};

export default function HomeProducts() {
  return (
    <Placeholder animation="fade">
      <View>
        <View style={{ marginTop: 10, paddingLeft: 10 }}>
          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <PlaceholderMedia style={homeProductMediaStyle} />
            <PlaceholderMedia style={homeProductMediaStyle} />
            <PlaceholderMedia style={homeProductMediaStyle} />
            <PlaceholderMedia style={homeProductMediaStyle} />
            <PlaceholderMedia style={homeProductMediaStyle} />
          </View>
        </View>
      </View>
    </Placeholder>
  );
}
