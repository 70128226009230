import React, { useState, useEffect } from 'react';
import { Alert, Platform, Text } from 'react-native';
import NetInfo from "@react-native-community/netinfo";
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import CacheStore from '~/modules-wrapper/react-native-cache-store';

import Login from './Login';

// import { startHomeUI, startFromStoreFlow } from '../../App';
import {
  SCREEN_HOME,
  SCREEN_LOGIN,
  SCREEN_PROFILE,
  SCREEN_SIGNUP,
  SCREEN_STORES,
} from '~/screens/screens';
// import NavigationHelper from '~/screens/NavigationHelper';
import WalletController from '~/controllers/WalletController';
import CompanyController from '../../controllers/CompanyController';
import CartController from '../../controllers/CartController';
import loginService from '~/services/account';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import ComponentHelper from '~/components/ComponentHelper';
import {
  STORE_ACTIVE_ORDER_FETCH, STORE_SET_CURRENT_NET_INFO,
  STORE_LOGIN_STATE, STORE_LOAD_PROMOTIONS_STATE, STORE_LOAD_CATEGORIES_STATE,
  STORE_LOAD_PROD_CONTEXT_STATE, STORE_LOAD_NOTICIES_STATE,
} from '../../store/storesConstants';
import setCurrentStoreItem, { syncCurrentStoreItem } from '~/store/setCurrentStoreItem/action';
import { updateOrder } from '../../store/activeOrderFetch/updateOrder/action';
import LoginProcess from '../../utils/Login';
import { syncCompanies, verifyCustomerInCompany, syncCart } from '../../utils/store';
import { STORE_ITEM_ACTIVE } from '../../constants/cache';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import setCurrentCompany from '../../store/setCurrentCompany/action';
import setNewLogin from '../../store/newLogin/action';
import setLoadPromotions from '../../store/setLoadPromotions/action';
import setLoadCategories from '../../store/setLoadCategories/action';
import setLoadProdContext from '../../store/setLoadProdContext/action';
import setLoadNoticies from '../../store/setLoadNoticies/action';
import setInfoUser from '../../store/setInfoUser/action';
import setCurrentNetInfo from '~/store/setCurrentNetInfo/action';
import { setCompanyCustomerActiveFromCompany } from '../../utils/CompanyCustomer';
import _ from 'lodash';
// import NotificationController from '~/controllers/Notifications/NotificationController';
import CartHelper from '../../components/CartHelper';
import CustomAlert from '~/components/CustomAlert';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import AppConfig from '../../AppConfig';
import { useAuth } from '~/hooks/auth';
import { toast } from 'react-toastify';
import { StackActions } from '@react-navigation/native';

let login = null;
let cartHelper = null;

const LoginContainer = (props) => {


  console.log('props', props);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [logging, setLogging] = useState(false);
  const [fieldsIsValid, setFieldsIsValid ] = useState(true);
  const [progressVisible, setProgressVisible] = useState(false);
  const [textProgress, setTextProgress ] = useState('');
  const [alertTitle, setAlertTitle ] = useState('Aguarde');
  const [storeInvalid, setStoreInvalid ] = useState(false);

  const { signIn } = useAuth();

  useEffect(()=> {
    login = new LoginProcess();
    cartHelper = new CartHelper();
  }, []);

  const onUsernameError = error => {
    setFieldsIsValid(error === '');
  };

  const onUsernameChange = value => {
    console.log('onUsernameChange value', value);
    setEmail(value);
  };

  const onPasswordChange = value => {
    console.log('onPasswordChange value', value);
    setPassword(value);
  };

  const onPressPasswordForget = () => {
  }

  const onPressSignIn = async () => {
    const netInfo = props.currentNetInfo;
    if (!netInfo.is_connected) {
      Alert.alert('', 'Você está offline! Conecte a internet para realizar o login.');
      return;
    }

    if (fieldsIsValid) {
      console.log('if inside')

      const verify_login_password_input = email && password ? true : false
      console.log('VERIFy LOGIN: ', verify_login_password_input);
      if (!verify_login_password_input){
        toast.error('Preencha E-mail e/ou senha.');
        return;
      }

      setLogging(true);
      const data = await loginService(email, password);
      console.log('DATA: ', data);
      if (JSON.stringify(data.message) === JSON.stringify('Invalid credentials.')) {
        setLogging(false);

        toast.error('E-mail ou senha inválidos.');
      } else {
        const { sOrderActive } = props;

        console.log('sOrderActive: ', sOrderActive);

        await login.deleteDataUser(data, sOrderActive);

        console.log('sOrderActive: data, sOrderActive', data, sOrderActive);

        const userData = {
          type: '',
          info: data,
        };

        console.log('userData: ', userData);

        props.dispatch(setInfoUser(userData));
        setProgressVisible(false);
        setTextProgress('');

        setLogging(false);

        if (AppConfig.startFromStore) {
          // setTimeout(() => {
          //   startFromStoreFlow();
          // }, 200);
        } else {
          // setTimeout(() => {
          //   startHomeUI();
          // }, 200);
        }

        const newLogin = {
          type: '',
          auth: true,
        };
        const loadData = {
          type: '',
          load: true,
        };

        console.log('antes Platform.OS',Platform.OS);

        props.dispatch(setNewLogin(newLogin));
        props.dispatch(setLoadPromotions(loadData));
        props.dispatch(setLoadCategories(loadData));
        props.dispatch(setLoadProdContext(loadData));
        props.dispatch(setLoadNoticies(loadData));

        console.log('antes Platform.OS',Platform.OS);

        const userName = _.get(data, 'user.display_name');
        const helloText = userName ? `Olá, ${userName}! ` : '';

        toast.loading(`${helloText}. Estamos sincronizando seus dados...`);

        // NotificationController.configure();

        console.log('antes Platform.OS',Platform.OS);
        console.log('antes processLoginUser');
        if (Platform.OS !== 'web') {
          await WalletController.create(data);
        }
        console.log('antes processLoginUser');

        try {
          console.log('antes processLoginUser');
          await login.processLoginUser(data);
          console.log('deṕois processLoginUser');

          const companyController = new CompanyController();
          const cartController = new CartController();

          props.dispatch(setCurrentAccount(data));

          const companies = await companyController.allCompany();

          console.log('TESTE TESTE companies', companies);

          let orderActive = await cartController.getCart(null, true);

          console.log('AQUI AQUI AQUI ');
          console.log('AQUI AQUI AQUI orderActive', orderActive);

          if (orderActive) {
            orderActive = orderActive[0];

            let customer = null;
            const currentCompany = companies.find(com => com.id === orderActive.company_id);
            if (currentCompany) {
              const companyCustomer = currentCompany.customer_company_accounts[0];
              if (companyCustomer) {
                await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);
                props.dispatch(setCurrentCompany(currentCompany));
                props.dispatch(setCurrentStoreItem(companyCustomer));
                props.dispatch(syncCurrentStoreItem(companyCustomer));

                customer = await verifyCustomerInCompany();
                console.log('TESTE TESTE');

                if (customer) {
                  toast.dismiss();

                  setTimeout(()=> {
                    toast.loading(`Sincronizado! Agora só carregar os dados do carrinho...`);
                  }, 300);

                  setTextProgress('Sincronizado! Agora só carregar os dados do carrinho...');

                  try {
                    const storeActive = await CacheStore.get(STORE_ITEM_ACTIVE);
                    if (!AppConfig.startFromStore) {
                      await cartHelper.associateAnonymousToUser(storeActive, props.dispatch, SCREEN_LOGIN);
                    }

                    await signIn();
                    props.navigation.dispatch(StackActions.popToTop('Home'));

                    setTimeout(()=> {
                      props.navigation.navigate(SCREEN_HOME.name);
                      toast.dismiss();
                    }, 300);
                  } catch (errors) {
                    setTextProgress('Erro ao atualizar o carrinho. Carregando dados padrões...');
                    toast.error('Erro ao atualizar o carrinho. Carregando dados padrões...');
                    await processLoginAnonymous();
                  }
                }
              }
            } else {
              await CacheStore.set(STORE_ITEM_ACTIVE, {});
              props.dispatch(setCurrentStoreItem(null));
              props.dispatch(syncCurrentStoreItem(null));
            }
          } else {
            console.log('AQUI AQUI AQUI 1, this.props.navigation', props.navigation);
            setProgressVisible(false);
            toast.dismiss();
            setTextProgress('');
            console.log('AQUI AQUI AQUI 1, this.props.navigation', props.navigation);

            await signIn();
            console.log('AQUI AQUI AQUI 1, this.props.navigation', props.navigation);
            props.navigation.popToTop(SCREEN_HOME.name);
          }
        } catch (error) {

          console.log('AQUI AQUI AQUI 1, error', props.navigation);
          console.log('ERROR', error);

          props.dispatch(setCurrentAccount(data));

          await CacheStore.set(STORE_ITEM_ACTIVE, {});
          props.dispatch(setCurrentStoreItem(null));
          props.dispatch(syncCurrentStoreItem(null));

          if (error && error.response && error.response.status === 403) {
            setLogging(false);
            setProgressVisible(false);
            toast.clearWaitingQueue();
            setStoreInvalid(true);
            return;
          }

          setLogging(false);
          setProgressVisible(false);
          toast.clearWaitingQueue();
          return;
        }
      }
    } else {
      toast.clearWaitingQueue();
      Alert.alert('E-mail inválido', 'Digite o email corretamente para entrar no sistema.');
    }
  };

  const signUpPress = () => {
    console.log('SIGIN');

    console.log('this.props.navigation', props.navigation);

    props.navigation.navigate(SCREEN_SIGNUP.name, {...props});

    // NavigationHelper.push(this.props.componentId, SCREEN_SIGNUP.name, false);
  };

  const onSignUpPress = ComponentHelper.debounce(signUpPress);

  const processLoginAnonymous = async () => {
    let dataAccount = {};

    try {
      const clearUserData = {
        type: '',
        info: '',
      };

      props.dispatch(setInfoUser(clearUserData));
      dataAccount = await login.processLoginAnonymous();
    } catch (erro) {
      throw erro;
    }

    props.dispatch(setCurrentAccount(dataAccount));

    const companyController = new CompanyController();
    const cartController = new CartController();
    const companies = await companyController.allCompany();
    let orderActive = await cartController.getCart(null, true);
    orderActive = orderActive[0];

    const currentCompany = companies.find(com => com.id === orderActive.company_id);
    const companyCustomer = currentCompany.customer_company_accounts[0];

    await setCompanyCustomerActiveFromCompany(currentCompany, companyCustomer);
    props.dispatch(setCurrentCompany(currentCompany));
    props.dispatch(setCurrentStoreItem(companyCustomer));
    props.dispatch(syncCurrentStoreItem(companyCustomer));

    return true;
  }

  const associateAnonymousToUser = async (customerCompany) => {
    let order = {};
    try {
      order = await login.associateAnonymousToUser(customerCompany);
    } catch (error) {
      throw error;
    }

    props.dispatch(setCurrentStoreItem(customerCompany));
    props.dispatch(updateOrder(order, SCREEN_LOGIN.name));
    props.dispatch(syncCurrentStoreItem(customerCompany));

    return true;
  }

  if (storeInvalid) {
    return (
      <CustomAlert
        showModal={storeInvalid}
        title="Acesso negado"
        message="Seu acesso nessa loja não é permitido! Caso queira comprar nessa loja, por favor solicite acesso."
        modalLoading={false}
        disableButtons={false}
        okButtonPress={async () => {
          await syncCompanies();
          setStoreInvalid(false);
          // NavigationHelper.push(this.props.componentId, SCREEN_STORES.name);
        }}
      />
    );
  }

  console.log('email', email);
  console.log('password', password);

  return (
    <Login
      email={email}
      password={password}
      logging={logging}
      onUsernameChange={onUsernameChange}
      onPasswordChange={onPasswordChange}
      onUsernameError={onUsernameError}
      onPressSignIn={onPressSignIn}
      onPressPasswordForget={onPressPasswordForget}
      onSignUpPress={onSignUpPress}
      progressVisible={progressVisible}
      textProgress={textProgress}
      alertTitle={alertTitle}
    />
  );
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload']).toJS());

const getLoginState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOGIN_STATE, 'payload']).toJS());

const getLoadPromoState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_PROMOTIONS_STATE, 'payload']).toJS());

const getLoadCatState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_CATEGORIES_STATE, 'payload']).toJS());

const getLoadProdContextState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_PROD_CONTEXT_STATE, 'payload']).toJS());

const getLoadNoticiesState = createImmutableSelector([state => state], state =>
  state.getIn([STORE_LOAD_NOTICIES_STATE, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    currentNetInfo: getNetInfo(state),
    valueLogin: getLoginState(state),
    valueLoadPromo: getLoadPromoState(state),
    valueLoadCat: getLoadCatState(state),
    valueLoadProdContext: getLoadProdContextState(state),
    valueLoadNoticies: getLoadNoticiesState(state),
  };
}

export default connect(mapStateToProps)(LoginContainer);
