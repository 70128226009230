import React, { Component } from 'react';
import Confirmation from './Confirmation';
import { SCREEN_HOME } from '~/screens/screens';

class ConfirmationContainer extends Component {
  constructor(props) {
    super(props);
  }

  goHome = () => {
    this.props.navigation.navigate(SCREEN_HOME.name);
  };

  render() {
    return <Confirmation goHome={this.goHome}/>;
  }
}

export default ConfirmationContainer;
