import { combineEpics } from 'redux-observable';

import location from './location/epic';
import activeOrder from './activeOrderFetch/activeOrder/epic';
import addProduct from './activeOrderFetch/addProduct/epic';
import updateOrder from './activeOrderFetch/updateOrder/epic';
import addBonus from './activeOrderFetch/addBonus/epic';
import promotionsFetch from './promotionsFetch/epic';
import productsContextFetch from './productsContextFetch/epic';
import titlesFetch from './titlesFetch/epic';
import saleConditionsFetch from './saleConditionsFetch/epic';
import shippingMethodsFetch from './shippingMethodsFetch/epic';
import changeStore from './changeStore/epic';
import productsFetch from './productsFetch/epic';

export default combineEpics(
  productsFetch,
  location,
  activeOrder,
  addProduct,
  updateOrder,
  addBonus,
  promotionsFetch,
  productsContextFetch,
  titlesFetch,
  saleConditionsFetch,
  shippingMethodsFetch,
  changeStore,
);
