export const SCREEN_ADDRESSES = { name: 'Addresses', title: 'Endereços' };
export const SCREEN_ADDRESS = { name: 'Address', title: 'Endereçoss' };
export const SCREEN_MAP = { name: 'Map', title: 'Mapa' };
export const SCREEN_LIST_ADDRESS = { name: 'ListAddress', title: 'Lista Endereços' };
export const SCREEN_COMPLETE_ADDRESS = { name: 'CompleteAddress', title: 'Completar Endereço' };
export const SCREEN_COMBO_PRODUCTS = { name: 'ComboProducts', title: 'Produtos de um Combo' };
export const SCREEN_COMBOS = { name: 'Combos', title: 'Combos' };
export const SCREEN_HOME = { name: 'Home', title: 'Home' };
export const SCREEN_LICENSE_AGREEMENT = { name: 'LicenseAgreement', title: 'Termo de Licença' };
export const SCREEN_LOGIN = { name: 'Login', title: 'Login' };
export const SCREEN_PRODUCT = { name: 'Product', title: 'Produto' };
export const SCREEN_CHOOSE_STORE = { name: 'ChooseStore', title: 'Solicitar Acesso' };
export const SCREEN_CART = { name: 'Cart', title: 'Carrinho' };
export const SCREEN_SIDEMENU = { name: 'SideMenu', title: 'Conta' };
export const SCREEN_ORDERS = { name: 'Orders', title: 'Pedidos' };
export const SCREEN_ORDER_DETAILS = { name: 'OrderDetails', title: 'Detalhes do pedido' };
export const SCREEN_WARNINGS = { name: 'Warnings', title: 'Avisos' };
export const SCREEN_PROFILE = { name: 'Profile', title: 'Perfil' };
export const SCREEN_PROMOTIONS = { name: 'Promotions', title: 'Promoções' };
export const SCREEN_PROMOTION_DETAIL = { name: 'PromotionDetails', title: 'Detalhes da promoção' };
export const SCREEN_FAVORITES = { name: 'Favorites', title: 'Favoritos' };
export const SCREEN_TITLES = { name: 'Titles', title: 'Títulos' };
export const SCREEN_PRODUCTS_AND_CATEGORIES = { name: 'ProductsAndCategories', title: 'Produtos' };
export const SCREEN_PRODUCTNCATEGORIES = {
  name: 'ProductsAndCategories',
  title: 'Produtos e Categorias',
};
export const SCREEN_OFFERS = { name: 'Offers', title: 'Ofertas' };
export const SCREEN_SEARCH_RESULT = { name: 'SearchResult', title: 'Busca' };
export const SCREEN_ABOUT = { name: 'About', title: 'Sobre' };
export const SCREEN_STORES = { name: 'Stores', title: 'Lojas' };
export const SCREEN_LETMEKNOW = { name: 'LetMeKnow', title: 'Avise-me quando chegar' };
export const SCREEN_SIGNUP = { name: 'SignUp', title: 'Novo cadastro' };
export const SCREEN_WALLET = { name: 'Wallet', title: 'Minha carteira' };
export const SCREEN_CARD_SECURITY = { name: 'CardSecurity', title: 'Senha de segurança' };
export const SCREEN_CREDIT_CARD_LIST = { name: 'CreditCardList', title: 'Meus cartões' };
export const SCREEN_ADD_CREDIT_CARD = { name: 'AddCreditCard', title: 'Novo cartão' };
export const SCREEN_SEARCH_HISTORY = { name: 'History', title: 'Histórico de busca' };
export const SCREEN_NOTIFICATION = { name: 'Notification', title: 'Notificações' };
export const SCREEN_NOTICES = { name: 'Notices', title: 'Notícias' };
export const SCREEN_NOTIFICATION_DETAIL = {
  name: 'NotificationDetail',
  title: 'Detalhe da notificação',
};
export const SCREEN_CHECKOUT = { name: 'Checkout', title: 'Pagamento e Entrega' };
export const SCREEN_CONFIRMATION = { name: 'Confirmation', title: 'Confirmação' };
export const SCREEN_FILTERS = { name: 'Filters', title: 'Filtrar' };

export const COMPONENT_HEADER_SEARCH = { name: 'HeaderSearch', title: 'Busca' };
