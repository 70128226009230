import Repository from './Repository';
import { APP_CONFIG_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

export default class AppConfigRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = APP_CONFIG_SCHEMA;
  }
  getAppConfig() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getAppConfig().then(appConfig => {
        resolve(appConfig);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  addAppConfig(config) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addAppConfig(config).then(appConfig => {
        resolve(appConfig);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
