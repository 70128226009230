import axios from 'axios';

import { getConfig, getCurrentCompany, URL_BASE } from '../index';

const TAG = 'apply combo';

export default async function applyCombo(idOrder, params) {
  const config = await getConfig();
  const company = await getCurrentCompany();

  return new Promise((resolve) =>
    axios.post(`${URL_BASE}/cart/${company}/detail/${idOrder}/combos/`, params, config)
      .then(response => {
        resolve(response.data.source.data);
      })
      .catch(error => {
        console.log(TAG, error);
        resolve([]);
      }));
}
