// @flow
import React, { Component, ReactNode, ReactNodeArray } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Snackbar from '~/modules-wrapper/react-native-snackbar';
import connect from 'react-redux/es/connect/connect';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';

import type { IProduct } from '~/entities/Product';
import Product from '~/components/Product/Product';
import type { Props as ProductProps } from '~/components/Product/Product';
// import NavigationHelper from '~/screens/NavigationHelper';
import { SCREEN_LOGIN, SCREEN_PRODUCT } from '~/screens/screens';
import {
  addProductOrder,
  ORDER_ADD_PRODUCT_REQUEST,
  ORDER_ADD_PRODUCT_RESPONSE_SUCCESS,
  ORDER_ADD_PRODUCT_SUCCESS,
} from '../../store/activeOrderFetch/addProduct/action';
import { notifyProduct } from '../../store/productsFetch/action';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_SET_CURRENT_STORE_ITEM,
  STORE_SET_CURRENT_ACCOUNT,
} from '../../store/storesConstants';
import ComponentHelper from '~/components/ComponentHelper';
import AppConfig from '~/AppConfig';
import { getFavoritesByStoreActive } from '~/utils/product';
import AccountController from '~/controllers/AccountController';

export type Props = {
  id?: string,
  hideQuantity: boolean,
  hideBottomTab?: boolean,
  screenId: string,
  type?: 'linear' | 'grid',
  product: IProduct,
  showLetMeKnow?: boolean,
  showDetail?: boolean,
  showPopupMenu?: boolean,
  canOpenDetail?: boolean,
  isProductSelect?: boolean,
  sOrderActive: Object<any>,
  sCustomerActive: Object<any>,
  selectedProduct?: IProduct,
  FooterComponent?: ReactNode | ReactNodeArray,
  numberOfDescriptionLines: number,
  onPressLike?: (product: IProduct) => void,
  onProductSelect?: (id: string) => void,
  dispatch: () => void,
  opened: boolean,
};

class ProductContainer extends Component<Props & ProductProps> {
  state = {
    openDetail: this.props.canOpenDetail,
  }
  UNSAFE_componentWillMount(): void {
    this.setState({
      product: this.props.product,
      selectedProduct: this.props.selectedProduct,
      showQuantity: true,
    });
  }

  componentDidMount() {
    if (typeof this.state.openDetail === 'undefined') this.setState({ openDetail: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.product.price !== this.state.product.price) return true;
    if (nextProps.product.price !== this.props.product.price) return true;

    if (nextState.product.quantity !== this.state.product.quantity) return true;
    if (nextProps.product.quantity !== this.props.product.quantity) return true;

    if (nextState.showQuantity !== this.state.showQuantity) return true;

    if (nextState.product.notifyMe !== this.state.product.notifyMe) return true;
    if (nextProps.product.notifyMe !== this.props.product.notifyMe) return true;

    if (nextState.selectedProduct !== this.state.selectedProduct) return true;
    if (nextProps.selectedProduct !== this.props.selectedProduct) return true;

    if (nextProps.sOrderActive.type !== this.props.sOrderActive.type) return true;

    return nextProps.isProductSelect !== this.props.isProductSelect;
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (prevProps.product.quantity === this.props.product.quantity) return;
      if (this.props.product.quantity === this.state.product.quantity) return;
      this.setState({ product: this.props.product });
    })();

    (() => {
      if (prevProps.product.price === this.props.product.price) return;
      this.setState({ product: this.props.product });
    })();

    (() => {
      if (prevProps.product.notifyMe === this.props.product.notifyMe) return;
      this.setState({ product: this.props.product });
    })();

    (() => {
      if (prevProps.selectedProduct === this.props.selectedProduct) return;
      if (this.props.selectedProduct === this.state.selectedProduct) return;
      this.setState({ selectedProduct: this.props.selectedProduct });
    })();

    (() => {
      if (prevProps.sOrderActive.type === this.props.sOrderActive.type) return;
      if (this.props.sOrderActive.type === ORDER_ADD_PRODUCT_REQUEST) return;
      if (
        this.props.sOrderActive.type === ORDER_ADD_PRODUCT_SUCCESS ||
        this.props.sOrderActive.type === ORDER_ADD_PRODUCT_RESPONSE_SUCCESS
      ) {
        this.setState({ showQuantity: true });
      }
    })();
  }

  // eslint-disable-next-line react/sort-comp
  doPressLike = async (product: IProduct) => {
    const isAnonymous = await AccountController.isAnonymous();
    if (isAnonymous) {
      this.props.navigation.push(SCREEN_LOGIN.name, false);
    } else {
      const LIKED_PRODUCTS = await getFavoritesByStoreActive();
      await AsyncStorage.getItem(LIKED_PRODUCTS)
        .then((fav) => {
          try {
            const favorites = fav ? JSON.parse(fav) : [];
            let executeOnce = false;
            if (favorites.length === 0) {
              favorites.push(product);
              AsyncStorage.setItem(LIKED_PRODUCTS, JSON.stringify(favorites));
            } else {
              favorites.map((element) => {
                if (product.id === element.id) {
                  const temp = favorites.filter(el => el.id !== product.id);
                  AsyncStorage.setItem(LIKED_PRODUCTS, JSON.stringify(temp));
                } else if (!executeOnce) {
                  executeOnce = true;
                  favorites.push(product);
                  AsyncStorage.setItem(LIKED_PRODUCTS, JSON.stringify(favorites));
                }
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
      if (this.props.onPressLike) this.props.onPressLike(product);
    }
  };

  onPressLike = ComponentHelper.debounce(this.doPressLike);

  onRemoveItem = product => {
    const { sOrderActive } = this.props;
    this.props.dispatch(addProductOrder(sOrderActive.activeOrder.payload, product, 0));
  };

  onAddProductInOrder = (product: IProduct, quantity: number) => {
    if (this.state.product.type === 'product') {
      this.addProductInOrder(product, quantity);
    } else {
      this.addProducMatrixtInOrder(product, quantity);
    }
  };

  addProductInOrder(product, quantity) {
    const { sOrderActive } = this.props;
    const { multiple, stock } = product;
    let error = false;
    let message = '';
    const quantityOld = product.quantity;
    product.quantity = quantity;
    this.setState({ product });

    if (quantity < 0) {
      message = 'Quantidade inválida.';
      error = true;
    } else if (quantity % multiple !== 0) {
      message = 'Multiplo de venda inválido.';
      error = true;
    } else if (quantity > stock) {
      message = 'Estoque insuficiente.';
      error = true;
    }

    if (error) {
      Snackbar.show({
        title: `Não é possível inserir ${product.name}. ${message}`,
        duration: Snackbar.LENGTH_LONG,
      });
      product.quantity = quantityOld;
      this.setState({ product });
    } else {
      this.setState({ showQuantity: false });
      const { onProductSelect, id } = this.props;
      if (onProductSelect) {
        onProductSelect(id);
      }

      this.props.dispatch(addProductOrder(sOrderActive.activeOrder.payload, product, quantity));
    }
  }

  addProducMatrixtInOrder(product, quantity) {
    product.attributesNames = [];
    delete product.children;
    delete product.created_at;
    delete product.updated_at;
    delete product.attributes_values;
    delete product.deleted_at;
    delete product.images;
    delete product.attributesValues;

    const { sOrderActive } = this.props;
    const { multiple, stock } = product;
    let error = false;
    let message = '';
    const quantityOld = product.quantity;
    product.quantity = quantity;
    this.setState({ selectedProduct: product });

    if (quantity < 0) {
      message = 'Quantidade inválida.';
      error = true;
    } else if (quantity % multiple !== 0) {
      message = 'Multiplo de venda inválido.';
      error = true;
    } else if (quantity > stock) {
      message = 'Estoque insuficiente.';
      error = true;
    }

    if (error) {
      Snackbar.show({
        title: `Não é possível inserir ${product.name}. ${message}`,
        duration: Snackbar.LENGTH_LONG,
      });
      product.quantity = quantityOld;
      this.setState({ selectedProduct: product });
    } else {
      this.setState({ showQuantity: false });
      const { onProductSelect, id } = this.props;
      if (onProductSelect) {
        onProductSelect(id);
      }

      this.props.dispatch(addProductOrder(sOrderActive.activeOrder.payload, product, quantity));
    }
  }

  onPressProd = product => {
    const { showLetMeKnow, hideBottomTab } = this.props;
    console.log('this.props.navigation.navigate', this.props.navigation);

    if (!showLetMeKnow) {
      const screenOptions = hideBottomTab ? {
        bottomTabs: {
          visible: false,
          drawBehind: true,
        },
      } : {};

      const props = {
        product,
        screenOptions,
      };

      console.log('this.props.navigation.navigate', this.props.navigation);

      this.props.navigation.push(SCREEN_PRODUCT.name, {...props});
    }
  };

  onNotifyMe = (quantity, product) => {
    const { onProductSelect, id } = this.props;
    if (onProductSelect) {
      onProductSelect(id);
    }
    this.props.dispatch(notifyProduct(quantity, product, this.props.sCustomerActive.customer_id));
  };

  onPress = ComponentHelper.debounce(this.onPressProd);

  isErrorProduct() {
    const { product } = this.state;
    const { sOrderActive } = this.props;

    if (sOrderActive.activeOrder.payload.messages) {
      return sOrderActive.activeOrder.payload.messages.find(message => message.sender_id === product.id);
    }

    return false;
  }

  render() {
    const {
      type,
      showLetMeKnow,
      showPopupMenu,
      showDetail,
      canOpenDetail,
      FooterComponent,
      hideQuantity,
      sCurrentAccount,
      numberOfDescriptionLines,
    } = this.props;

    const { product, selectedProduct, showQuantity, openDetail } = this.state;
    const prod = product.type === 'product' ? product : { ...product, ...selectedProduct };

    return (
      <Product
        type={type}
        product={prod}
        FooterComponent={FooterComponent}
        hideQuantity={hideQuantity}
        showPrice={!sCurrentAccount.isAnonymous || AppConfig.showPricesToAnonymous}
        isAnonymous={sCurrentAccount.isAnonymous}
        showDetail={showDetail}
        canOpenDetail={openDetail}
        showLetMeKnow={showLetMeKnow}
        showPopupMenu={showPopupMenu}
        showQuantity={showQuantity}
        onPress={this.onPress}
        onPressLike={this.onPressLike}
        onRemoveItem={this.onRemoveItem}
        onNotifyMe={this.onNotifyMe}
        onAddProductInOrder={this.onAddProductInOrder}
        error={this.isErrorProduct()}
        numberOfDescriptionLines={numberOfDescriptionLines}
      />
    );
  }
}

ProductContainer.defaultProps = {
  product: {},
  hideBottomTab: false,
};

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH]).toJS());

const getCustomerActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM, 'payload']).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    sCustomerActive: getCustomerActive(state),
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(ProductContainer);
