import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';

const iconSize = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    ...defaultShadow,
    alignItems: 'flex-start',
    padding: sizes.horizontalSpacing,
    paddingTop: sizes.verticalSpaceSeparatorXL,
  },
  addressContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginHorizontal: sizes.horizontalSpacing,
  },
  addressLine1: {
    color: colors.grayDark,
    fontSize: sizes.textNormal,
    fontWeight: '600',
    textAlign: 'left',
  },
  addressLine2: {
    color: colors.grayDark,
    fontSize: sizes.textSmall,
    fontWeight: '300',
    textAlign: 'left',
  },
  icon: {
    tintColor: colors.primaryColor,
    width: iconSize,
    height: iconSize,
    resizeMode: 'contain',
  },
});

export default styles;
