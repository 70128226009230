import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const size = 40;
const margin = 4;
const iconContainerSize = size - (2 * margin);
const iconPadding = 6;

const styles = StyleSheet.create({
  list: {
    backgroundColor: '#FFF',
  },
  separator: {
    height: 3,
    backgroundColor: colors.grayContainer,
  },
  rootIconContainer: {
    height: iconContainerSize,
    width: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    overflow: 'hidden',
    margin,
  },
  iconContainer: {
    height: iconContainerSize,
    borderRadius: iconContainerSize / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: iconContainerSize - (3 * iconPadding),
    width: iconContainerSize - (3 * iconPadding),
  },
  iconClose: {
    tintColor: colors.grayDarkest,
  },
  iconHistory: {
    tintColor: colors.grayMid,
    height: iconContainerSize - (2 * iconPadding),
    width: iconContainerSize - (2 * iconPadding),
  }
  
});

export default styles;
