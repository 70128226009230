/* @flow */

import { List, Map } from 'immutable';

import {
  FINALLY_ORDER_REQUEST,
  ORDER_ACTIVE_FAILURE,
  ORDER_ACTIVE_REQUEST,
  ORDER_ACTIVE_SUCCESS,
} from './activeOrder/action';
import {
  ORDER_ADD_PRODUCT_FAILURE,
  ORDER_ADD_PRODUCT_REQUEST,
  ORDER_ADD_PRODUCT_RESPONSE_FAILURE,
  ORDER_ADD_PRODUCT_RESPONSE_SUCCESS,
  ORDER_ADD_PRODUCT_SUCCESS,
} from './addProduct/action';
import {
  ORDER_ADD_BONUS_FAILURE,
  ORDER_ADD_BONUS_REQUEST,
  ORDER_ADD_BONUS_RESPONSE_FAILURE,
  ORDER_ADD_BONUS_RESPONSE_SUCCESS,
  ORDER_ADD_BONUS_SUCCESS,
} from './addBonus/action';
import {
  ORDER_UPDATE_ORDER_FAILURE,
  ORDER_UPDATE_ORDER_REQUEST,
  ORDER_UPDATE_ORDER_RESPONSE_FAILURE,
  ORDER_UPDATE_ORDER_RESPONSE_SUCCESS,
  ORDER_UPDATE_ORDER_SUCCESS,
} from '~/store/activeOrderFetch/updateOrder/action';

const initialState = Map({
  type: '',
  activeOrder: Map({
    loading: false, payload: Map({}), messages: List([]),
  }),
  addProduct: Map({
    loading: false, waitResponse: false,
  }),
  addBonus: Map({
    loading: false, waitResponse: false,
  }),
  updateOrder: Map({
    loading: false, waitResponse: false, payload: Map({}),
  }),
});

export default function reducer(state = initialState, action): any {
  const { type } = action;

  // Actions active order
  if (type === ORDER_ACTIVE_REQUEST || type === FINALLY_ORDER_REQUEST) {
    return state.updateIn(['type'], () => type).updateIn(['activeOrder', 'loading'], () => true);
  }

  if (type === ORDER_ACTIVE_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['activeOrder', 'loading'], () => false)
      .setIn(['activeOrder', 'messages'], List([]))
      .setIn(['activeOrder', 'payload'], Map(payload));
  }

  if (type === ORDER_ACTIVE_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['activeOrder', 'loading'], () => false).setIn(['activeOrder', 'messages'], List(payload));
  }

  // actions add product in order
  if (type === ORDER_ADD_PRODUCT_REQUEST) {
    return state
      .updateIn(['type'], () => type).updateIn(['addProduct', 'loading'], () => true).updateIn(['addProduct', 'waitResponse'], () => true);
  }

  if (type === ORDER_ADD_PRODUCT_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['addProduct', 'loading'], () => false)
      .setIn(['activeOrder', 'messages'], List([]))
      .setIn(['activeOrder', 'payload'], Map(payload));
  }

  if (type === ORDER_ADD_PRODUCT_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['addProduct', 'loading'], () => false).setIn(['activeOrder', 'messages'], List(payload));
  }

  if (type === ORDER_ADD_PRODUCT_RESPONSE_SUCCESS || type === ORDER_ADD_PRODUCT_RESPONSE_FAILURE) {
    return state.updateIn(['type'], () => type).updateIn(['addProduct', 'waitResponse'], () => false);
  }

  // actions add bonus in order
  if (type === ORDER_ADD_BONUS_REQUEST) {
    return state
      .updateIn(['type'], () => type).updateIn(['addBonus', 'loading'], () => true).updateIn(['addBonus', 'waitResponse'], () => true);
  }

  if (type === ORDER_ADD_BONUS_SUCCESS) {
    const { payload } = action;

    if (payload) {
      return state
        .updateIn(['type'], () => type)
        .updateIn(['addBonus', 'loading'], () => false)
        .setIn(['activeOrder', 'messages'], List([]))
        .setIn(['activeOrder', 'payload'], Map(payload));
    }

    return state.updateIn(['type'], () => type);
  }

  if (type === ORDER_ADD_BONUS_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['addBonus', 'loading'], () => false).setIn(['activeOrder', 'messages'], List(payload));
  }

  if (type === ORDER_ADD_BONUS_RESPONSE_SUCCESS || type === ORDER_ADD_BONUS_RESPONSE_FAILURE) {
    return state.updateIn(['type'], () => type).updateIn(['addBonus', 'waitResponse'], () => false);
  }

  // actions update order in order
  if (type === ORDER_UPDATE_ORDER_REQUEST) {
    const { payload } = action;
    const { screen } = payload;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['updateOrder', 'loading'], () => true)
      .updateIn(['updateOrder', 'waitResponse'], () => true)
      .setIn(['updateOrder', 'payload'], Map({ screen }));
  }

  if (type === ORDER_UPDATE_ORDER_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['updateOrder', 'loading'], () => false)
      .setIn(['activeOrder', 'messages'], List([]))
      .setIn(['activeOrder', 'payload'], Map(payload));
  }

  if (type === ORDER_UPDATE_ORDER_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).updateIn(['updateOrder', 'loading'], () => false).setIn(['activeOrder', 'messages'], List(payload));
  }

  if (type === ORDER_UPDATE_ORDER_RESPONSE_SUCCESS || type === ORDER_UPDATE_ORDER_RESPONSE_FAILURE) {
    return state.updateIn(['type'], () => type).updateIn(['updateOrder', 'waitResponse'], () => false);
  }

  return state;
}
