import { Platform } from 'react-native';
import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  ORDER_UPDATE_ORDER_REQUEST,
  ORDER_UPDATE_ORDER_RESPONSE_FAILURE,
  ORDER_UPDATE_ORDER_RESPONSE_SUCCESS,
} from './action';
import DataManager from '../../../database/DataManager';
import { SchemaModels } from '../../../database/utils/DBUtils';
import SourceBuilder, { CONTEXT, METHODS } from '~/utils/SourceBuilder';
import RequestBuilder from '~/utils/RequestBuilder';
import requestManager from '~/utils/RequestManager';
import { activeOrderDataManager, updateOrderDataManager } from '../common';
import DeviceInfo from 'react-native-device-info';
import { failureUpdateOrder, successUpdateOrder } from '~/store/activeOrderFetch/updateOrder/action';
import CartController from '~/controllers/CartController';

const addOrderDataManager = ({ order }) => {
  const cartController = new CartController();
  const promise = cartController.insertCartInCache([order]);
  return Observable.fromPromise(promise);
};

const updateOrder = (action$: any) =>
  action$
    .ofType(ORDER_UPDATE_ORDER_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap((payload) => addOrderDataManager(payload)
      .mergeMap(() => activeOrderDataManager())
      .flatMap(data => {

        console.log('updateOrder data', data);

        const order = data[0];
        const args = {
          idOrder: order.id,
          companyId: order.company_id,
          customer_id: order.customer_id,
          device_id: DeviceInfo.getUniqueId(),
          platform: Platform.OS,
          sale_conditions: order.sale_conditions,
        };

        console.log('updateOrder args', args);

        const source = SourceBuilder(METHODS.order.updateOrder, args);
        const request = RequestBuilder(source, CONTEXT.updateOrder);
        requestManager.push(request);

        return Observable.of(successUpdateOrder(order));
      })
      .catch(data => Observable.of(failureUpdateOrder(data))));

const updateOrderResponseSuccess = (action$: any) =>
  action$
    .ofType(ORDER_UPDATE_ORDER_RESPONSE_SUCCESS)
    .map(({ payload }) => payload)
    .mergeMap(payload => updateOrderDataManager(payload[0])
      .mergeMap(() => activeOrderDataManager())
      .flatMap(data => Observable.of(successUpdateOrder(data[0])))
      .catch(data => Observable.of(failureUpdateOrder(data))));

const failureUpdateOrderDataManager = () => {
  const cartController = new CartController();
  const promise = cartController.clearCartCache();
  return Observable.fromPromise(promise);
};

const updateOrderResponseFailure = (action$: any) =>
  action$
    .ofType(ORDER_UPDATE_ORDER_RESPONSE_FAILURE)
    .map(({ payload }) => payload)
    .mergeMap((payload) => failureUpdateOrderDataManager(payload)
      .mergeMap(payload => Observable.of(failureUpdateOrder(payload))));

export default combineEpics(updateOrder, updateOrderResponseSuccess, updateOrderResponseFailure);
