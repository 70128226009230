import { SELECT_LOCATION_CHANGE_STATE } from './action';

import { Map } from 'immutable';

const initialState = Map({
  payload: Map({}),
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SELECT_LOCATION_CHANGE_STATE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;
