import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  titleContainer: {
    backgroundColor: colors.primaryColor,
    paddingHorizontal: sizes.horizontalSpacing,
    paddingVertical: sizes.verticalSpaceSeparatorXL,
    alignItems: 'center',
  },
  title: {
    color: '#FFF',
    fontWeight: '600',
    fontSize: sizes.textBig,
    textAlign: 'center',
  },
  messageContainer: {
    backgroundColor: '#FFF',
    paddingHorizontal: sizes.horizontalSpacing,
    paddingVertical: sizes.verticalSpaceSeparatorNormal,
    alignItems: 'center',
    marginTop: sizes.verticalSpaceSeparatorNormal,
  },
  message: {
    color: colors.grayDark,
    textAlign: 'center',
  },
  actionsContainer: {
    marginHorizontal: sizes.horizontalSpaceLarge,
    marginTop: sizes.verticalSpaceSeparatorXL,
  },
});

export default styles;
