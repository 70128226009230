import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    minHeight: 48,
    paddingVertical: 10,
    alignItems: 'center',
  },
  image: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
  nameContainer: {
    marginLeft: 10,
  },
  name: {
    fontSize: sizes.textLittleBig,
    fontWeight: '600',
    color: colors.grayDark,
  },
  description: {
    fontSize: 15,
    fontWeight: '300',
    color: colors.grayDark,
  },
});

export default styles;
