import isEmpty from 'lodash/isEmpty';

import SourceInterpreter from '~/utils/SourceInterpreter';
import { ERROR_REPONSE_DEFAULT } from '~/constants/messages';

export const ORDER_ADD_BONUS_REQUEST = 'ORDER_ADD_BONUS_REQUEST';
export const ORDER_ADD_BONUS_SUCCESS = 'ORDER_ADD_BONUS_SUCCESS';
export const ORDER_ADD_BONUS_FAILURE = 'ORDER_ADD_BONUS_FAILURE';
export const ORDER_ADD_BONUS_RESPONSE_FAILURE = 'ORDER_ADD_BONUS_RESPONSE_FAILURE';
export const ORDER_ADD_BONUS_RESPONSE_SUCCESS = 'ORDER_ADD_BONUS_RESPONSE_SUCCESS';

function responseBonusFailure(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_BONUS_RESPONSE_FAILURE, payload });
}

function responseBonusSuccess(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_BONUS_RESPONSE_SUCCESS, payload });
}

export function responseAddBonusInOrder(response) {
  const data = SourceInterpreter(response);
  const emptySource = isEmpty(response.source);

  if (emptySource) {
    let errors = data ? data.messages : null;
    if (!errors && response.message) {
      errors = [{ type: 'error', message: response.message }];
    } else {
      errors = ERROR_REPONSE_DEFAULT;
    }

    return responseBonusFailure(errors);
  }

  return responseBonusSuccess(data);
}

export function successAddBonusOrder(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_BONUS_SUCCESS, payload });
}

export function failureAddBonusOrder(payload) {
  return dispatch => dispatch({ type: ORDER_ADD_BONUS_FAILURE, payload });
}

export function addBonusOrder(order, bonus, quantity) {
  return dispatch => dispatch({
    type: ORDER_ADD_BONUS_REQUEST,
    payload: { order, bonus, quantity },
  });
}
