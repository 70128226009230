import CacheStore from '~/modules-wrapper/react-native-cache-store';

import { listProductContext } from '../../../services/products/index';
import downloadProducts from './products';
import downloadSaleConditions from './saleConditions';
import SourceBuilder, { METHODS } from '~/utils/SourceBuilder';
import DataManager from '../../../database/DataManager';
import AccountController from '~/controllers/AccountController';
import { SchemaModels } from '~/database/utils/DBUtils';
import { getCurrentCustomer } from '~/services';
import { getConfig, URL_BASE, getCurrentCompany } from '../../../services/index';
import listBase from '../../../services/listBase';
import listCombos from '../../../services/combos';
import CartController from '~/controllers/CartController';

export default async function download() {
  const config = await getConfig();
  const company = await getCurrentCompany();

  const campaignsPromise = listBase(`${URL_BASE}/companies/${company}/campaigns/`, config);
  const productsPromise = downloadProducts();
  const noticesPromise = listBase(`${URL_BASE}/notices/${company}/`, config);
  // const registerAddrPromise = listBase(`${URL_BASE}/address`, config);
  let salesPromise = new Promise(resolve => resolve([]));
  let shippingMethodsPromise = new Promise(resolve => resolve([]));
  let wishListPromise = new Promise(resolve => resolve([]));
  let saleConditionsPromise = new Promise(resolve => resolve([]));

  const isAnonymous = await AccountController.isAnonymous();

  console.log('AQUI AQUI isAnonymous', isAnonymous);

  if (!isAnonymous) {
    const cartController = new CartController();
    const cartIdCache = await CacheStore.get('CART_ID');
    const cart = await cartController.getCart(null, true);
    const cartId = cart && cart.length > 0 ? cart[0].id : null;
    const customer = await getCurrentCustomer();
    const customerId = customer.customer_id;

    salesPromise = listBase(`${URL_BASE}/sales/${company}/`, {
      ...config,
      params: {
        customer_id: customerId,
        all_orders: '0',
      },
    });
    wishListPromise = listBase(`${URL_BASE}/wish_lists/${company}/?customer=${customerId}`, config);
    shippingMethodsPromise = listBase(`${URL_BASE}/cart/${company}/detail/${cartId || cartIdCache}/shipping_methods/`, config);
    saleConditionsPromise = downloadSaleConditions();
  }

  const downloadPromises = [
    wishListPromise,
    campaignsPromise,
    productsPromise,
    noticesPromise,
    salesPromise,
    saleConditionsPromise,
    shippingMethodsPromise,
    // registerAddrPromise,
  ];

  const dataManager = new DataManager();
  const contextsConfig = dataManager.getConfigValue('allContexts');

  for (const context of contextsConfig) {
    const args = { code: context };
    const source = SourceBuilder(METHODS.products.listProductContext, args);
    downloadPromises.push(listProductContext(source));
  }

  const dataCombos = listCombos();
  downloadPromises.push(dataCombos);

  return await Promise.all(downloadPromises);
}
