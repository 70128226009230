import { StyleSheet } from 'react-native';
import { colors, sizes, defaultShadow } from '~/values';

const closeSize = 22;
const closeMargin = 4;
const radius = sizes.boxBorderRadius;

const baseContainer = {
  borderRadius: radius,
};

const styles = StyleSheet.create({
  rootContainer: {
    ...baseContainer,
    backgroundColor: colors.primaryColor,
    marginHorizontal: 8,
    marginTop: 8,
  },
  shadowContainer: {
    ...baseContainer,
    ...defaultShadow,
    backgroundColor: '#FFF',
  },
  overflowContainer: {
    ...baseContainer,
    overflow: 'hidden',
  },
  container: {
    ...baseContainer,
    backgroundColor: '#FFF',
  },
  childrenContainer: {
    marginTop: 10,
  },
  topContainer: {
    marginTop: 36,
    height: closeMargin + closeSize,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDecoration: {
    width: 6,
    height: closeSize + closeMargin + 6,
    backgroundColor: colors.primaryColor,
  },
  title: {
    flex: 1,
    minHeight: closeSize + closeMargin,
    paddingLeft: 12,
    color: colors.primaryColor,
    fontWeight: '600',
    marginRight: 16,
    fontSize: sizes.textBig,
  },
  closeRootContainer: {
    width: closeMargin + closeSize,
    height: closeMargin + closeSize,
    borderRadius: (closeMargin + closeSize) / 2,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  closeContainer: {
    width: closeSize,
    height: closeSize,
    borderRadius: closeSize / 2,
    borderColor: colors.primaryColor,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconClose: {
    tintColor: colors.primaryColor,
    width: closeSize - 4,
    height: closeSize - 4,
  },
});

export default styles;
