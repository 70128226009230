/* @flow */

import React, { Component } from 'react';

import { TextInput, Platform } from 'react-native';

// import { checkEmail } from '~/common';
import Input from "./unform/Input";

// const isValid = (entry: string) => checkEmail(entry);

// type Props = {
//   onError: () => void,
//   onSuccess: () => void,
//   label?: string,
//   value?: string,
//   defaultValue?: string,
//   error?: string,
//   onChangeText?: (text?: string) => void,
//   autoFocus?: string,
//   error?: string,
//   format?: string | (() => string),
//   inputRef?: () => void,
//   keyboardType?: string,
//   label?: string,
//   maxLength?: number,
//   name?: string,
//   onBlur?: () => void,
//   onChange?: () => void,
//   onInputFocus?: () => void,
//   onSuccess?: () => void,
//   placeholder?: string,
//   required?: boolean,
//   returnKeyType?: () => void,
//   secureTextEntry?: boolean,
//   style?: {},
//   isMoney?: boolean,
// };

const Email = ({onChange}) => {
  // onBlur = (entry: string) => {
  //   if (isValid(entry) === false) {
  //     this.setState({
  //       error: 'O formato entrado para o e-mail é inválido.',
  //     });
  //     this.props.onError('O formato entrado para o e-mail é inválido.');
  //     return;
  //   }
  //
  //   this.props.onSuccess(entry);
  // };
  //
  // onChange = (entry: string) => {
  //   // Valida a entrada e remove a mensagem de erro caso tenha sida apresentada.
  //   if (isValid(entry)) {
  //     this.setState({
  //       error: '',
  //     });
  //     this.props.onError('');
  //   }
  //   this.props.onChange(entry);
  // };

  return (
    <Input
      onChange={onChange}
      type={"email"}
      label={"Email"} name={"email"}
    />
  );
}

export default Email;
