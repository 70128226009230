import React, { Component } from 'react';
import Checkout from './Checkout';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import { connect } from 'react-redux';
import { Alert } from 'react-native';
import AppConfig from '~/AppConfig';
import { updateOrderDataManager } from '~/store/activeOrderFetch/common';
import updateShipping from '~/services/delivery-addresses/update';
import WalletController from '~/controllers/WalletController';
import saleConditions, {
  SALES_CONDITIONS_LIST_REQUEST,
  SALES_CONDITIONS_LIST_SUCCESS,
} from '~/store/saleConditionsFetch/action';
import {
  STORE_ACTIVE_ORDER_FETCH,
  STORE_SALE_CONDITIONS_FETCH,
  STORE_SET_CURRENT_NET_INFO,
  STORE_SET_CURRENT_STORE_ITEM,
} from '~/store/storesConstants';
import { SCREEN_ADD_CREDIT_CARD, SCREEN_ADDRESSES, SCREEN_CHECKOUT, } from '../screens';
import {
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_SLIP,
} from '~/constants/typePayment';
import { ORDER_ACTIVE_SUCCESS } from '~/store/activeOrderFetch/activeOrder/action';
import Card from '~/utils/Wallet/Card';
import Images from '~/../assets/images';
import {
  ORDER_UPDATE_ORDER_REQUEST,
  ORDER_UPDATE_ORDER_SUCCESS,
  updateOrder,
} from '~/store/activeOrderFetch/updateOrder/action';
import constantsIsipag from '~/constants/isipag';
import Sync, { WISH_LIST_PRODUCTS } from '~/utils/Sync';
import { ORDER_UPDATE_ORDER_RESPONSE_SUCCESS } from '../../store/activeOrderFetch/updateOrder/action';
import setCurrentAccount from '~/store/setCurrentAccount/action';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { CREDIT_CARD_SELECTED, INSTALLMENT_SELECTED } from '~/constants/cache';
import downloadProducts from '~/utils/Sync/download/products';
import wishListProducts from '../../services/products/wishListProducts';
import listCombos from '../../services/combos';
import { SchemaModels } from '../../database/utils/DBUtils';

type Props = {
  componentId: any,
  account: any,
  currentNetInfo: any,
  dispatch: () => {
  },
  sSaleConditions: {
    type: string,
    payload: {
      payment_options: [],
      payment_terms: [],
      payment_combine_keys: [],
    },
    loading: boolean,
  },
  sOrderActive: any,
};

class CheckoutContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.sync = new Sync();

    this.state = {
      sendingOrder: false,
      cards: [],
      account: {},
      showPaymentSlipModal: false,
      showInstallmentCardModal: false,
      paymentSlipOptions: [],
      updatingCart: false,
      optionSelected: {},
      customer: {},
      useSameAddress: true,
      installments: [],
      installmentSelected: {},
    };
  }

  componentDidMount(): void {
    this.loadOptions();
    this.props.dispatch(saleConditions());

    const customer = get(this.props.sCurrentStoreItem, 'payload.customer', {});
    console.log('componentDidMount customer', customer);

    this.setState({
      customer,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    (() => {
      const saleConditionsOld = get(this.props.sOrderActive, 'activeOrder.payload.sale_conditions', []);
      const saleConditionsNew = get(prevProps.sOrderActive, 'activeOrder.payload.sale_conditions', []);
      const optionOld = find(saleConditionsOld, { key: 'meio' }) || {};
      const termOld = find(saleConditionsOld, { key: 'prazo' }) || {};
      const optionNew = find(saleConditionsNew, { key: 'meio' }) || {};
      const termNew = find(saleConditionsNew, { key: 'prazo' }) || {};

      if (prevProps.sSaleConditions.type === SALES_CONDITIONS_LIST_REQUEST
        && this.props.sSaleConditions.type === SALES_CONDITIONS_LIST_SUCCESS) {
        this.loadOptions();
      }

      if ((prevProps.sOrderActive.type === ORDER_UPDATE_ORDER_REQUEST
          && this.props.sOrderActive.type === ORDER_UPDATE_ORDER_SUCCESS)
        || (this.props.sOrderActive.type === ORDER_ACTIVE_SUCCESS
          && (termOld.value_code != termNew.value_code
            || optionOld.value_code != optionNew.value_code))) {
        this.loadOptions();
      }
    })();

    (async () => {
      if (this.props.sOrderActive.type === prevProps.sOrderActive.type) return;
      if (prevProps.sOrderActive.type === ORDER_UPDATE_ORDER_SUCCESS) return;
      if (prevProps.sOrderActive.type !== ORDER_UPDATE_ORDER_RESPONSE_SUCCESS) return;
      if (this.props.sOrderActive.type !== ORDER_UPDATE_ORDER_SUCCESS) return;
      if (this.props.sOrderActive.updateOrder.payload.screen === SCREEN_CHECKOUT.name) {
        await this.syncProducts();
        this.setState({
          updatingCart: false,
          updatingAddress: false,
          showModalChangeItems: true,
        });
      }
    })();
  }

  async syncProducts() {
    const result = await Promise.all([
      downloadProducts(), wishListProducts(), listCombos(),
    ]);

    const data = result[0];
    data[WISH_LIST_PRODUCTS] = get(result[1], ['source', 'data']) || [];
    data[SchemaModels.COMBOS] = get(result[2], ['source', 'data']) || [];

    await this.sync.process(data);
  }

  getCards = async () => {
    const cards = await WalletController.getCards();
    this.setState({ cards });
  };

  loadOptions = async () => {
    await this.getCards();

    this.setState({
      options: this.getOptions(),
    });
  };

  addCard = () => {
    this.props.navigation.navigate("SideMenu", {screen: SCREEN_ADD_CREDIT_CARD.name});
  };

  onPressPaymentSlip = (index) => {
    this.setState({
      showPaymentSlipModal: true,
      indexSelected: index
    });
  };

  onClosePaymentSlip = () => {
    this.setState({ showPaymentSlipModal: false });
  };

  onHandleInstallmentCardModal = () => {
    this.setState({ showInstallmentCardModal: !this.state.showInstallmentCardModal });
  };

  onSelectPayment = (index, selectedTerm = 0) => {
    const options = this.state.options.map((option, key) => {
      option.selected = (index === key);
      return option;
    });

    this.setState({ options });

    const cart = get(this.props.sOrderActive, 'activeOrder.payload');
    const listSaleConditions = this.props.sSaleConditions.payload;
    const {
      payment_options: paymentOptions,
      payment_combine_keys: paymentCombineKeys,
      payment_terms: paymentTerms,
    } = listSaleConditions;

    this.setState({
      updatingCart: true,
    });

    const selected = options[index].option;
    const paymentCombineKey = filter(paymentCombineKeys, { payment_option_id: selected.id })[0];
    const paymentTerm = !selectedTerm ? find(paymentTerms, { id: paymentCombineKey.payment_term_id }) : selectedTerm;

    cart.sale_conditions = [
      {
        value_code: selected.id,
        key: 'meio',
        description: 'Meio',
        value_description: selected.description,
      },
      {
        value_code: paymentTerm.id,
        key: 'prazo',
        description: 'Prazo',
        value_description: paymentTerm.description,
      },
    ];

    this.props.dispatch(updateOrder({
      ...cart,
    }, SCREEN_CHECKOUT.name));
  };

  onSelectInstallment = (installment) => {
    CacheStore.set(INSTALLMENT_SELECTED, installment);
    this.onHandleInstallmentCardModal();

    this.setState({ installmentSelected: installment });
  };

  onSelectPaymentSlip = (optionSelected) => {
    this.onSelectPayment(this.state.indexSelected, optionSelected);
    this.onClosePaymentSlip();
  };

  onSelectAddress = async (address) => {
    const cart = get(this.props.sOrderActive, 'activeOrder.payload');
    cart.delivery_address = address;

    this.setState({
      updatingAddress: true,
    });

    try {
      await updateShipping({ ...cart });
      await updateOrderDataManager({ ...cart });
    } catch (e) {
    } finally {
      this.setState({
        updatingAddress: false,
      });
    }
  };

  getOptions = () => {
    const installments = [];
    const listSaleConditions = this.props.sSaleConditions.payload;
    const { cards } = this.state;
    const saleConditions = get(this.props.sOrderActive, 'activeOrder.payload.sale_conditions', []);
    const option = find(saleConditions, { key: 'meio' });
    const term = find(saleConditions, { key: 'prazo' });

    const {
      payment_combine_keys: paymentCombineKeys,
      payment_terms: paymentTerms,
    } = listSaleConditions;

    const paymentOptions = (listSaleConditions.payment_options || []).map((paymentOption) => ({
      ...paymentOption,
      kind: paymentOption.kind.trim(),
    }));

    const options = [];
    const paymentCreditCard = find(paymentOptions, { kind: PAYMENT_TYPE_CREDIT_CARD });
    if (paymentCreditCard) {
      cards.map((card, index) => {
        let selected = false;
        if (this.state.selectedCard) {
          selected = paymentCreditCard.id == option.value_code &&
            this.state.selectedCard.identifier === card.identifier;
        } else {
          const cardFound = options.find(op => op.kind === PAYMENT_TYPE_CREDIT_CARD &&
            op.selected === true);

          selected = paymentCreditCard.id == option.value_code && !cardFound;
        }

        if (selected) {
          CacheStore.set(CREDIT_CARD_SELECTED, card);
        }

        options.push({
          image: Card.brandImageFromBrandName(card.brand),
          text: `${Card.nameFromBrand(card.brand)} terminado em ${card.mask}`,
          kind: PAYMENT_TYPE_CREDIT_CARD,
          option: paymentCreditCard,
          onPress: () => {
            this.setState({
              selectedCard: card,
            });

            CacheStore.set(CREDIT_CARD_SELECTED, card);

            this.onSelectPayment(index);
          },
          selected,
        });
      });

      const maxInstalments = get(this.props.sOrderActive, 'activeOrder.payload.payment_type.max_instalments', 1);
      for (let i = 0; i < maxInstalments; i++) {
        installments.push({
          value: i + 1,
          description: i === 0 ? 'À Vista' : `${i + 1}X`,
        });
      }

      options.push({
        image: Images.imageCreditCard,
        text: 'Novo cartão de crédito',
        onPress: this.addCard,
        option: paymentCreditCard,
        kind: PAYMENT_TYPE_CREDIT_CARD,
      });
    }

    const paymentSlip = find(paymentOptions, { kind: PAYMENT_TYPE_SLIP });
    if (paymentSlip) {
      const items = filter(paymentCombineKeys, { payment_option_id: paymentSlip.id })
        .map((paymentSlipIds) => find(paymentTerms || [], {
          id: paymentSlipIds.payment_term_id,
        }));

      this.setState({
        paymentSlipOptions: items.sort((a, b) => {
          let columnA = a.display_order;
          let columnB = b.display_order;
          if (!columnA && !columnB) {
            columnA = a.description;
            columnB = b.description;
          }

          return columnA > columnB;
        }),
      });

      const isSelected = paymentSlip.id == option.value_code;

      options.push({
        image: Images.imagePaymentSlip,
        text: isSelected ? `${paymentSlip.description} ${term.value_description}` : paymentSlip.description,
        kind: PAYMENT_TYPE_SLIP,
        selected: isSelected,
        option: paymentSlip,
        onPress: () => {
          this.onPressPaymentSlip(findIndex(options, { kind: PAYMENT_TYPE_SLIP }));
        },
      });

      const cart = get(this.props.sOrderActive, 'activeOrder.payload');
      if (cart.payment_type.key === constantsIsipag.PAYMENT_TYPES.CREDIT_CARD && cards.length
        && !this.state.selectedCard) {
        this.setState({
          selectedCard: cards[0],
        });
      }
    }

    const paymentCash = find(paymentOptions, { kind: PAYMENT_TYPE_CASH });
    if (paymentCash) {
      options.push({
        image: Images.imageMoney,
        text: paymentCash.description,
        kind: PAYMENT_TYPE_CASH,
        option: paymentCash,
        selected: paymentCash.id == option.value_code,
        onPress: () => {
          this.onSelectPayment(options.length - 1);
        },
      });
    }

    this.setState({ installments }, async () => {
      const installment = await CacheStore.get(INSTALLMENT_SELECTED);
      if (installment) {
        if (installment.value <= installments.length) {
          this.setState({ installmentSelected: installment });
        } else {
          this.setState({
            installmentSelected: {
              value: 1,
              description: 'À Vista'
            }
          });
        }
      }
    });

    return options;
  };

  onPressNewAddress = () => {
    const cart = get(this.props.sOrderActive, 'activeOrder.payload');

    NavigationHelper.push(this.props.componentId, SCREEN_ADDRESSES.name, false, {
      cart,
      onSelectAddress: this.onSelectAddress,
    }, SCREEN_ADDRESSES.title);
  };

  render() {
    const cart = get(this.props.sOrderActive, 'activeOrder.payload');
    const {
      installments,
      installmentSelected,
      showModalChangeItems
    } = this.state;

    return (
      <Checkout
        {...this.props}
        cart={cart}
        loading={this.state.loading}
        updatingCart={this.state.updatingCart}
        updatingAddress={this.state.updatingAddress}
        paymentSlip={find(this.state.options, { kind: PAYMENT_TYPE_SLIP }) || {}}
        showPaymentSlipModal={this.state.showPaymentSlipModal}
        showInstallmentCardModal={this.state.showInstallmentCardModal}
        paymentSlipOptions={this.state.paymentSlipOptions}
        onSelectPaymentSlip={this.onSelectPaymentSlip}
        onSelectInstallment={this.onSelectInstallment}
        addCard={this.addCard}
        user={this.props.account.user}
        cards={this.state.cards}
        options={this.state.options || []}
        onPressNewAddress={this.onPressNewAddress}
        customer={this.state.customer}
        useShipping={AppConfig.shipping}
        showSameAddressCheckBox={false}
        onRequestClose={this.onClosePaymentSlip}
        onHandleInstallmentCardModal={this.onHandleInstallmentCardModal}
        showInstallments={installments.length > 1}
        installments={installments}
        installmentSelected={installmentSelected}
        showModalChangeItems={showModalChangeItems}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getSaleConditions = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SALE_CONDITIONS_FETCH])
    .toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn(['setCurrentAccount'])
    .toJS().payload);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH])
    .toJS());

const getNetInfo = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_NET_INFO, 'payload'])
    .toJS());

const getCurrentStoreItem = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_STORE_ITEM])
    .toJS());

function mapStateToProps(state) {
  return {
    sSaleConditions: getSaleConditions(state),
    account: getAccount(state),
    sOrderActive: getOrderActive(state),
    currentNetInfo: getNetInfo(state),
    sCurrentStoreItem: getCurrentStoreItem(state),
  };
}

export default connect(mapStateToProps)(CheckoutContainer);
