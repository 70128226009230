// @flow

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { Touchable } from 'react-native-web';

import styles from './styles';
import ImageComponent from '~/components/Image';
import Images from '../../../assets/images';

export type Props = {
  notices: {},
  onPress: () => void,
};

class Notices extends Component<Props> {
  onPress(notice) {
    if (this.props.onPress) this.props.onPress(notice);
  }

  renderInfos() {
    const { notices } = this.props;
    return (
      <View style={styles.infosContainer}>
        <Text numberOfLines={2} style={[styles.title]}>
          {notices.title}
        </Text>
        <Text numberOfLines={2} style={styles.description}> {notices.description} </Text>
      </View>
    );
  }

  render() {
    const { notices } = this.props;

    let image;
    if (notices.image) {
      image = { uri: notices.image };
    }

    return (
      <View style={styles.shadowContainer__grid}>
        <View style={styles.overflowContainer__grid}>
          <Touchable
            onPress={() => this.onPress(notices)}
          >
            <View>
              <View style={styles.container__grid}>
                <View>
                  <ImageComponent
                    defaultImage={Images.iconTitles}
                    style={styles.image__grid}
                    source={image || null}
                  />
                </View>
                <View style={styles.infosContainer}>{this.renderInfos()}</View>
              </View>
            </View>
          </Touchable>
        </View>
      </View>
    );
  }
}

Notices.defaultProps = {
  onPress: () => {},
};

export default Notices;
