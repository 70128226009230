
const CryptoJS = require('crypto-js');

class Crypto {
  static encrypt = (content, password) => {
    const ciphertext = CryptoJS.AES.encrypt(content, password);
    return ciphertext.toString();
  };

  static decrypt = (content, password) => {
    const bytes = CryptoJS.AES.decrypt(content.toString(), password);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  static sha256 = (content) => CryptoJS.SHA256(content)
}

export default Crypto;
