import { StyleSheet } from 'react-native';
import { colors, sizes } from '~/values';
import appStyles from '~/appStyles';

const styles = StyleSheet.create({
  container: {
    ...appStyles.container,
    backgroundColor: 'white',
  },
  name: {
    ...appStyles.productName,
    marginBottom: sizes.horizontalSpacing,
  },
  description: {
    color: colors.grayDark,
  },
  quantityContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
});

export default styles;
