import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { success, failure } from './action';
import DataManager from '../../database/DataManager';
import { SchemaModels } from '../../database/utils/DBUtils';
import { TITLES_LIST_REQUEST } from '~/store/titlesFetch/action';

const listTitlesDataManager = () => {
  // const dataManager = new DataManager();

  // const promise = dataManager.all(SchemaModels.TITLES);
  // return Observable.fromPromise(promise);
};

const titlesList = (action$: any) =>
  action$
    .ofType(TITLES_LIST_REQUEST)
    .mergeMap((payload) => listTitlesDataManager(payload)
      .flatMap(data => Observable.of(success(data)))
      .catch(data => Observable.of(failure(data))));

export default combineEpics(titlesList);
