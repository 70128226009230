import Repository from './Repository';
import { REGISTER_ADDRESS_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

export default class RegisterAddressRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = REGISTER_ADDRESS_SCHEMA;
  }

  getRegisterAddress() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getRegisterAddress().then(regAddr => {
        resolve(regAddr);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  addRegisterAddress(address) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addRegisterAddress(address).then(regAddr => {
        resolve(regAddr);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  updateRegisterAddress(addr) {
    return new Promise((resolve, reject) => {
      SQLiteManager.updateRegisterAddr(addr).then(regAddr => {
        resolve(regAddr);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  deleteRegisterAddress(id) {
    return new Promise((resolve, reject) => {
      SQLiteManager.deleteRegisterAddress(id).then(regAddr => {
        resolve(regAddr);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
