import getAllProducts, { notifyMe } from '../../services/products/index';
import { addProductInOrder, addBonusInOrder, updateOrder } from '../../services/carts';

const methodsWS = {
  getAllProducts,
  notifyMe,
  addProductInOrder,
  addBonusInOrder,
  updateOrder,
};

export default function requestsWS(method: string): Function {
  return methodsWS[method];
}
