// @flow

import React, { Component } from 'react';
import { View, Image, Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import type { IProduct } from '~/entities/Product';

import styles from './styles';
import StockerProductResource from '../../services/resources/StockerProductResource';

export type Props = {
  product: {
    name?: string,
    description?: string,
    quantity: number,
  },
  onPress?: (product: IProduct) => void,
};

class ProductBonus extends Component<Props> {
  render() {
    const { onPress, product } = this.props;
    const { name, description, ean, quantity } = product;
    return (
      <TouchableOpacity
        onPress={() => onPress(product)}
      >
        <View style={styles.container}>
          <Image
            style={styles.image}
            source={StockerProductResource.getThumbImage(ean) || null}
          />
          <View style={styles.infoContainer}>
            <Text style={styles.name}>
              {name || description}
            </Text>
            <Text style={styles.startingAt}>{quantity} unidades</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

ProductBonus.defaultProps = {
  onPress: () => {},
};

export default ProductBonus;
