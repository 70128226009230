import React, { Component } from 'react';
import ProductPresentational from './ProductPresentational';
import axios from 'axios';
// import { Navigation } from 'react-native-navigation';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Product from '../../entities/Product';
import imagesProduct from '../../services/products/imagesProduct';
import { STORE_ACTIVE_ORDER_FETCH } from '~/store/storesConstants';
import { mergeOnlyProductWithOrder, mergeOnlyProductChildrenWithOrder } from '~/utils/product';
import Images from '~/../assets/images';
import StockerProductResource from '../../services/resources/StockerProductResource';
import { PRODUCT_NOTIFY_SUCCESS } from '../../store/productsFetch/action';
import { STORE_PRODUCTS_FETCH, STORE_SET_CURRENT_ACCOUNT } from '../../store/storesConstants';
import detail from '../../services/products/detail';

type Props = {
  componentId: any,
  product: Product,
  screenOptions: Object<any>,
  sOrderActive: Object<any>,
  sProductFetch: Object<any>,
};

class ProductContainer extends Component<Props> {
  constructor(props) {
    super(props);

    const product = get(this.props, 'route.params.product');

    console.log('ProductContainer product', product);

    this.state = {
      selectedAttributes: {},
      attributesNames: product.attributesNames || [],
      product,
      combinations: [],
      loadingCondPrice: true,
      loadingImage: true,
    };

    const { componentId, screenOptions } = props;
    // Navigation.mergeOptions(componentId, {
    //   topBar: {
    //     elevation: 0,
    //   },
    //   ...screenOptions,
    // });
  }

  async UNSAFE_componentWillMount() {
    const product = get(this.props, 'route.params.product');
    this.loadProduct(product);
  }

  componentDidUpdate(prevProps) {
    (async () => {
      if (prevProps.sProductFetch.notifyMe.type === this.props.sProductFetch.notifyMe.type) return;
      if (this.props.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_SUCCESS) return;

      let product = get(this.props, 'route.params.product');
      let { sProductFetch } = this.props;
      product = sProductFetch.list.payload.products.find(p => p.id === product.id);
      this.loadProduct(product);
    })();
  }

  onAttributeClicked = (attribute, value) => {
    const { selectedAttributes } = this.state;

    this.setState(
      {
        selectedAttributes: {
          ...selectedAttributes,
          [attribute]: value,
        },
      },
      this.selectedProduct,
    );
  };

  getProduct() {
    const { selectedAttributes, product } = this.state;
    const keys = Object.keys(selectedAttributes);
    return product.children.find(child => {
      let count = 0;
      keys.forEach(key => {
        const value = child.attributesValues.find(option => option.code === key && option.value === selectedAttributes[key]);
        if (value) count += 1;
      });
      return count === keys.length;
    });
  }

  selectedProduct = async () => {
    const { selectedAttributes, attributesNames, product } = this.state;

    const keys = Object.keys(selectedAttributes);
    if (keys.length === attributesNames.length) {
      const selectedProduct = this.getProduct();

      if (selectedProduct && (!selectedProduct.images || selectedProduct.images.length === 0)) {
        selectedProduct.images = [];
        if (selectedProduct.ean) {
          const dataImage = StockerProductResource.getThumbImage(selectedProduct.ean);

          try {
            const response = await axios.get(dataImage.uri, { headers: dataImage.headers });
            selectedProduct.images.push({ uri: response.request.responseURL });
          } catch (e) {
            const ean = encodeURIComponent(selectedProduct.ean);
            try {
              const images = await imagesProduct(ean);
              selectedProduct.images = images;
            } catch (er) {
              selectedProduct.images.push(Images.iconNoImageBig);
            }
          }
        } else {
          const dataImage = StockerProductResource.getThumbImage(product.ean);

          try {
            const response = await axios.get(dataImage.uri, { headers: dataImage.headers });
            selectedProduct.images.push({ uri: response.request.responseURL });
          } catch (e) {
            const ean = encodeURIComponent(product.ean);
            try {
              const images = await imagesProduct(ean);
              selectedProduct.images = images;
            } catch (er) {
              selectedProduct.images.push(Images.iconNoImageBig);
            }
          }
        }
      }

      this.setState({ selectedProduct });
    } else {
      this.setState({ selectedProduct: undefined });
    }
  };

  async loadPriceConditions(product) {
    let priceConditions = [];
    if (product && product.id) {
      const detailResp = await detail(product.id);
      if (detailResp) {
        const responseData = get(detailResp, ['source', 'data']) || [];

        if (responseData && responseData[0]) {
          priceConditions = responseData[0].price_conditions || [];
          this.setState({ loadingCondPrice: false });
        }
      }
    }
    return priceConditions;
  }

  async loadProduct(product) {
    this.props.navigation.setOptions({
      title: product.name,
    });

    const { attributesNames, children = [] } = product;
    attributesNames.forEach(attribute => {
      attribute.values = [];
      children.forEach(child => {
        child.attributesValues.forEach(option => {
          if (option.code === attribute.code && !attribute.values.includes(option.value)) {
            attribute.values.push(option.value);
          }
        });
      });
    });

    const priceConditions = await this.loadPriceConditions(product);

    product = { ...product, price_conditions: priceConditions };

    if (product.images && product.images.length > 0) {
      this.setState({ loadingImage: false, product, attributesNames }, () => {
        if (attributesNames) {
          const selectedAttributes = {};
          attributesNames.forEach(attribute => {
            selectedAttributes[attribute.code] = attribute.values[0];
          });

          this.setState({ selectedAttributes }, this.selectedProduct);
        }
      });
    } else if (product.ean) {
      product.images = [];
      const dataImage = StockerProductResource.getThumbImage(product.ean);

      try {
        const response = await axios.get(dataImage.uri, { headers: dataImage.headers });
        product.images.push({ uri: response.request.responseURL });
        this.setState({ loadingImage: false });
      } catch (e) {
        product.images.push(Images.iconNoImageBig);
        this.setState({ loadingImage: false });
      }

      this.setState({ product, attributesNames }, () => {
        if (attributesNames) {
          const selectedAttributes = {};
          attributesNames.forEach(attribute => {
            selectedAttributes[attribute.code] = attribute.values[0];
          });

          this.setState({ selectedAttributes }, this.selectedProduct);
        }
      });
    }
  }

  productsAdds(product, productsOrder) {
    if (!product.children) {
      return [];
    }

    const productsFilter = product.children
      .filter(prod => {
        const productFound = mergeOnlyProductChildrenWithOrder(prod, productsOrder);
        return productFound.quantity > 0;
      })
      .map(prod => mergeOnlyProductChildrenWithOrder(prod, productsOrder));

    return productsFilter.map(prod => {
      const {
        id,
        type,
        quantity,
        price,
        product_code,
        multiple,
        stock,
        liked,
        attributesValues,
        images,
      } = prod;
      const {
        code, name, description, ean, minPrice,
      } = product;

      return {
        id,
        code,
        name,
        description,
        ean,
        type,
        quantity,
        price,
        minPrice,
        product_code,
        multiple,
        stock,
        liked,
        attributesValues,
        images,
      };
    });
  }

  render() {
    const {
      selectedAttributes,
      attributesNames,
      combinations,
      product,
      selectedProduct,
      loadingCondPrice,
      loadingImage,
    } = this.state;

    const { sOrderActive, sCurrentAccount, componentId } = this.props;

    const selectedProductMerged = mergeOnlyProductChildrenWithOrder(
      selectedProduct,
      sOrderActive.products,
    );

    let productMerged = {};
    if (
      product.type === 'product' ||
      (product.type === 'matriz' && !isEmpty(selectedProductMerged))
    ) {
      productMerged = mergeOnlyProductWithOrder(product, sOrderActive.products);
    }
    const productsAdds = this.productsAdds(product, sOrderActive.products);

    return (
      <ProductPresentational
        product={productMerged}
        loadingCondPrice={loadingCondPrice}
        loadingImage={loadingImage}
        selectedProduct={selectedProductMerged}
        added={productsAdds}
        onAttributeClicked={this.onAttributeClicked}
        onProductClick={productClicked => this.loadProduct(productClicked)}
        attributes={attributesNames}
        selectedAttributes={selectedAttributes}
        filteredAttributes={attributesNames}
        combinations={combinations}
        isAnonymous={sCurrentAccount.isAnonymous}
        screenId={componentId}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

const getProductsAndCategories = createImmutableSelector([state => state], state =>
  state.getIn([STORE_PRODUCTS_FETCH]).toJS());

const getAccount = createImmutableSelector([state => state], state =>
  state.getIn([STORE_SET_CURRENT_ACCOUNT, 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    sProductFetch: getProductsAndCategories(state),
    sCurrentAccount: getAccount(state),
  };
}

export default connect(mapStateToProps)(ProductContainer);
