import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import intersectionBy from 'lodash/intersectionBy';
import SearchResult from './SearchResult';
import TextUtils from '~/utils/TextUtils';
import { SCREEN_PRODUCTS_AND_CATEGORIES, SCREEN_SEARCH_RESULT } from '../screens';
import CategoryController from '~/controllers/CategoryController';
import ProductController from '~/controllers/ProductController';
import ComponentHelper from '~/components/ComponentHelper';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import { STORE_ACTIVE_ORDER_FETCH, STORE_PRODUCTS_FETCH } from '~/store/storesConstants';
import { connect } from 'react-redux';
import { mergeProductsWithOrder, parseProducts, sortByProductsNoStock } from '~/utils/product';

import { sortByObject } from '~/utils/Array';
import AppConfig from '~/AppConfig';

import listProducts, {
  listProductsByProductsAndCategories,
  PRODUCT_NOTIFY_REQUEST,
  PRODUCT_NOTIFY_SUCCESS,
} from '../../store/productsFetch/action';

type Props = {
  searchText: string,
  componentId: string,
  sOrderActive: {
    products: [],
  },
  sProductFetch: Object<any>,
};

function search(array, field, text) {
  return array.filter((item) =>
    TextUtils.slugfy(deburr(item[field])).includes(TextUtils.slugfy(deburr(text))));
}

const TAG = 'SearchResultContainer';

class SearchResultContainer extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      categories: [],
    };
  }

  async componentDidMount(): void {

    this.props.navigation.setOptions({
      title: `"${this.props.route.params.searchText}"`
    });

    await this.getProducts();
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (this.props.sProductFetch.notifyMe.type === prevProps.sProductFetch.notifyMe.type) return;
      if (prevProps.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_REQUEST) return;
      if (this.props.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_SUCCESS) return;

      const { products } = this.props.sProductFetch.list.payload;

      const productsIntersect = intersectionBy(products, this.state.products, 'id');
      const productsSort = productsIntersect.sort(sortByObject('name'));
      const productsNotStock = sortByProductsNoStock(productsSort);
      this.setState({ products: AppConfig.sortByProductsNoStock ? productsNotStock : productsSort });
    })();
  }

  getProducts = async () => {
    const searchText = this.props.route.params.searchText;
    const categoryController = new CategoryController();
    const productController = new ProductController();
    const resultCategories = await categoryController.allCategoriesBySearch(searchText);
    const resultProducts = await productController.allProductsBySearch(searchText);
    const cat = resultCategories.sort(sortByObject('description'));
    let prods = resultProducts.sort(sortByObject('name'));
    prods = parseProducts(resultProducts);
    const productsNotStock = sortByProductsNoStock(prods);
    this.setState({ categories: cat, products: AppConfig.sortByProductsNoStock ? productsNotStock : prods });
  };

  doCategoryClick = (category) => {
    const props = { categoryCode: category.category_code, title: category.description };
    const navigation = this.props.route.params.navigation;
    navigation.push(SCREEN_PRODUCTS_AND_CATEGORIES.name, {...props});
  };

  onCategoryClick = ComponentHelper.debounce(this.doCategoryClick);

  render() {
    const prodsFiltered = this.state.products.filter((prod) => prod.name !== '');
    const products = mergeProductsWithOrder(prodsFiltered, this.props.sOrderActive.products);
    const navigation = this.props.route.params.navigation;

    return (
      <SearchResult
        products={products}
        categories={this.state.categories}
        onPressCategory={this.onCategoryClick}
        componentId={this.props.componentId}
        navigation={navigation}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getProductsAndCategories = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_PRODUCTS_FETCH]).toJS());

const getOrderActive = createImmutableSelector([(state) => state], (state) =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

function mapStateToProps(state) {
  return {
    sProductFetch: getProductsAndCategories(state),
    sOrderActive: getOrderActive(state),
  };
}

export default connect(mapStateToProps)(SearchResultContainer);
