// @flow

import React from 'react';
import { View, Text, Image } from 'react-native';
import { Touchable } from 'react-native-web';

import Images from '~/../assets/images';

import styles from './styles';

export type WalletProps = {
  type: 'grid' | 'linear',
  icon: number,
  onPress: () => void,
  text: string,
};

const WalletOption = ({
  type, icon, onPress, text,
}: WalletProps) => (
  <Touchable
    onPress={onPress}
  >
    <View style={styles[`container__${type}`]}>
      <Image
        style={styles[`icon__${type}`]}
        source={icon}
      />
      <Text style={styles[`text__${type}`]}>{text}</Text>
      {
        type === 'linear'
          ? (
            <Image
              style={styles.iconChevronRight}
              source={Images.iconChevronRight}
            />
          )
          : null
      }
    </View>
  </Touchable>
);

export default WalletOption;
