// @flow

import React from 'react';
import { View, Image, Text } from 'react-native';
import { TouchableOpacity } from 'react-native';

import styles from './styles';

type SideMenuOptionProps = {
  onPress: (index: number) => void,
  active?: boolean,
  index: number,
  text: string,
  icon: number,
};

const SideMenuOption = ({
  onPress, index, text, icon, active
}: SideMenuOptionProps) => {
  const onPressOption = () => {
    onPress(index);
  };

  return (
    <TouchableOpacity
      onPress={onPressOption}
    >
      <View
        style={[
          styles.container,
          active ? styles.container__active : {},
        ]}
      >
        {
          icon
            ? (
              <Image
                style={[
                  styles.icon,
                  active ? styles.icon__active : {},
                ]}
                source={icon}
              />
            )
            : null
        }
        <Text
          style={[
            styles.text,
            active ? styles.text__active : {},
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default SideMenuOption;
