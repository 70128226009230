import sourcesWS from './sourcesWS';
import type { ISource } from '../../entities/Source';

const sources = {
  ws: sourcesWS,
};

export const CONTEXT = {
  products: 'products',
  notifyMe: 'notifyMe',
  addProductInOrder: 'addProductInOrder',
  addBonusInOrder: 'addBonusInOrder',
  updateOrder: 'updateOrder',
};

export const METHODS = {
  products: { listProduct: 'listProduct', listProductContext: 'listProductContext', notifyMe: 'notifyMe' },
  order: { addProduct: 'addProduct', addBonus: 'addBonus', updateOrder: 'updateOrder' },
};

export default function SourceBuilder(id: string, args?: Object<any>) : ISource {
  // TODO: Construir a logica de selecionar o tipo de source, estamos setando primeiramente o source tipo ws
  const type = 'ws';

  const sourceBuilder = sources[type];
  const source = sourceBuilder(id);

  if (args) {
    const values = Object.values(args);
    source.setup.args.forEach((arg, index) => {
      arg.value = values[index];
    });
  }

  return source;
}
