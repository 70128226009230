import Repository from './Repository';
import { PROMOTION_SCHEMA } from '../schemas';
import SQLiteManager from '../SQLiteManager';

const TAG = 'PromotionRepository';

export default class PromotionRepository extends Repository {
  constructor() {
    super();
    this.tableSchema = PROMOTION_SCHEMA;
  }

  getPromotions() {
    return new Promise((resolve, reject) => {
      SQLiteManager.getPromotions().then(promo => {
        resolve(promo);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addPromotion(promos) {
    return new Promise((resolve, reject) => {
      SQLiteManager.addPromotion(promos).then(promo => {
        resolve(promo);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
