
import AsyncStorage from '@react-native-async-storage/async-storage';

class CacheStore {

  getAllItems = () => {
    return [];
  }

  set = async (key, value) => {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  }

  get = async (key) => {
    const value = await AsyncStorage.getItem(key);
    return value === null ? null : JSON.parse(value);
  }

  remove = async (key) => {
    await AsyncStorage.removeItem(key);
  }

  flush = async () => {
    const promise = new Promise((resolve, reject)=> {
      resolve();
    })

    return promise;
  }

}

export default new CacheStore();
