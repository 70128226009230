import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CreditCard, Button } from '~/components';

import Images from '~/../assets/images';

import styles from './styles';

type DetailProps = {
  onPressNewCard: () => void,
  creditCards: Array<{
    brand: string,
    final: string,
    main?: boolean,
  }>,
};

class CreditCardList extends PureComponent<DetailProps> {
  keyExtractor = (item, index) => index.toString();

  renderHeader() {
    return (
      <View style={styles.addContainer}>
        <Button
          flat
          title="Adicionar novo cartão"
          iconLeft={Images.iconPlus}
          onPress={this.props.onPressNewCard}
        />
      </View>
    );
  }

  renderItem = ({ item, index }) => (
    <CreditCard
      brand={item.brand}
      final={item.mask}
      main={item.main}
      item={item}
      onRemove={this.props.onRemove}
    />
  );

  render() {
    const { creditCards } = this.props;
    return (
      <View style={styles.container}>
        <FlatList
          data={creditCards}
          keyExtractor={this.keyExtractor}
          ListHeaderComponent={this.renderHeader()}
          renderItem={this.renderItem}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
        />
      </View>
    );
  }
}

CreditCardList.defaultProps = {
  creditCards: [],
};

export default CreditCardList;
