/* @flow */
/* global Number */
import Masked from 'vanilla-masker';

export default function Money(value, noCurrency) {
  const moneyFormatter = {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  };
  if (noCurrency === true) {
    moneyFormatter.unit = '';
  }
  if (value) {
    const number = typeof value === 'string' ? parseFloat(value) : value.toFixed(2);
    return Masked.toMoney(number, moneyFormatter);
  }
  return Masked.toMoney('0.00', moneyFormatter);
}
