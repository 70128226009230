import CacheStore from '~/modules-wrapper/react-native-cache-store';
import { COMPANY_ACTIVE, STORE_ITEM_ACTIVE } from '~/constants/cache';
import { store } from '~/App.web';
import setCurrentStoreItem from '../../store/setCurrentStoreItem/action';

// eslint-disable-next-line import/prefer-default-export
export async function setCompanyCustomerActiveFromCompany(company, customer) {
  store.dispatch(setCurrentStoreItem(customer));
  await CacheStore.set(COMPANY_ACTIVE, company);
  await CacheStore.set(STORE_ITEM_ACTIVE, customer);
}
