import React, { Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';
import connect from 'react-redux/es/connect/connect';
import isEmpty from 'lodash/isEmpty';

import Favorites from './Favorites';
import { SCREEN_FAVORITES } from '~/screens/screens';
import { STORE_ACTIVE_ORDER_FETCH, STORE_PRODUCTS_FETCH } from '../../store/storesConstants';
import { getFavoritesByStoreActive, mergeProductsWithOrder, parseProducts } from '~/utils/product';
import activeOrder from '../../store/activeOrderFetch/activeOrder/action';
import listProducts, { PRODUCT_NOTIFY_SUCCESS } from '../../store/productsFetch/action';

const TAG = 'FavoritesContainer';

type Props = {
  dispatch: () => {},
  componentId: string,
  sOrderActive: Object<any>,
  sProductFetch: Object<any>,
};

class FavoritesContainer extends Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      favorites: [],
      loading: true,
    };

    // Navigation.mergeOptions(this.props.componentId, {
    //   topBar: {
    //     elevation: 0,
    //     title: {
    //       text: SCREEN_FAVORITES.title,
    //     },
    //   },
    // });
  }

  componentDidMount(): void {
    // this.navigationEventListener = Navigation.events().bindComponent(this);

    const products = this.props.sProductFetch.list.payload;
    if (isEmpty(products)) {
      this.props.dispatch(listProducts(0));
    }

    this.loadFavorites();
    this.props.dispatch(activeOrder());
  }

  componentWillUnmount() {
    // if (this.navigationEventListener) {
    //   this.navigationEventListener.remove();
    // }
  }

  componentDidUpdate(prevProps) {
    (() => {
      if (prevProps.sProductFetch.notifyMe.type === this.props.sProductFetch.notifyMe.type) return;
      if (this.props.sProductFetch.notifyMe.type !== PRODUCT_NOTIFY_SUCCESS) return;
      this.loadFavorites();
    })();
  }

  onPressLike = (product) => {
    if (!product.liked) {
      this.loadFavorites();
    }
  };

  getProducts() {
    return mergeProductsWithOrder(this.state.favorites, this.props.sOrderActive.products);
  }

  async loadFavorites() {
    const LIKED_PRODUCTS = await getFavoritesByStoreActive();

    console.log('loadFavorites LIKED_PRODUCTS', LIKED_PRODUCTS);

    try {
      const prodsLiked = await AsyncStorage.getItem(LIKED_PRODUCTS);
      const parseToJson = JSON.parse(prodsLiked || '[]');
      const favoritesParse = parseProducts(parseToJson);
      this.setState({ favorites: favoritesParse, loading: false });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { loading } = this.state;
    const products = this.getProducts();

    return (
      <Favorites
        screenId={this.props.componentId}
        favorites={products}
        loading={loading}
        onPressLike={this.onPressLike}
      />
    );
  }
}

const createImmutableSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const getOrderActive = createImmutableSelector([state => state], state =>
  state.getIn([STORE_ACTIVE_ORDER_FETCH, 'activeOrder', 'payload']).toJS());

const getProductsAndCategories = createImmutableSelector([state => state], state =>
  state.getIn([STORE_PRODUCTS_FETCH]).toJS());

function mapStateToProps(state) {
  return {
    sOrderActive: getOrderActive(state),
    sProductFetch: getProductsAndCategories(state),
  };
}

export default connect(mapStateToProps)(FavoritesContainer);
