import React, { Component } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Images from '~/../assets/images';
import Menu, { MenuItem } from '~/modules-wrapper/react-native-material-menu';

import { TouchableOpacity } from 'react-native';

const ICON_SIZE = 24;

const styles = StyleSheet.create({
  dots: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    tintColor: 'grey',
  },
});

type Props = {
  onRemoveItem: () => {},
  item: {}
};

export default class PopupMenu extends Component<Props> {
    menu = null;

    onRemoveItem = () => {
      if (this.props.onRemoveItem) this.props.onRemoveItem(this.props.item);
      this.hideMenu();
    };

    setMenuRef = ref => {
      this.menu = ref;
    };

    hideMenu = () => {
      this.menu.hide();
    };

    showMenu = () => {
      this.menu.show();
    };

    render() {
      return (
        <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <Menu
            ref={this.setMenuRef}
            button={
              <TouchableOpacity
                onPress={this.showMenu}
              >
                <Image
                  source={Images.iconDotsVertical}
                  style={styles.dots}
                />
              </TouchableOpacity>
            }
          >
            <MenuItem onPress={this.onRemoveItem}>Remover item</MenuItem>
          </Menu>
        </View>
      );
    }
}
