import listBase from '../../../services/listBase';
import { getConfig, URL_BASE, getCurrentCompany } from '../../../services/index';
import saleConditions from '../../../services/sale-conditions';
import { SchemaModels } from '../../../database/utils/DBUtils';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import DataManager from '../../../database/DataManager';
import CacheStore from '~/modules-wrapper/react-native-cache-store';
import CartController from '~/controllers/CartController';

export default async function downloadSaleConditions() {
  const config = await getConfig();
  const company = await getCurrentCompany();
  const dataDownload = [];

  const cartId = await CacheStore.get('CART_ID');

  const cartController = new CartController();
  const cart = await cartController.getCart(null, true);

  const response = await listBase(`${URL_BASE}/cart/${company}/detail/${cart && cart.length > 0 ? cart[0].id : cartId}/sale_conditions/`, config);
  const data = get(response, ['source', 'data']) || [];

  if (data.length > 0) {
    const groups = groupBy(data, 'key');

    const paymentOptions = groups.meio[0].values.map(item => ({
      description: item.description,
      id: item.code,
      kind: item.kind.trim(),
    }));

    const paymentTerms = groups.prazo[0].values.map(item => ({
      description: item.description,
      id: item.code,
    }));

    const paymentCombineKeys = groups['combine-keys'][0].values.map(item => ({
      id: `${item.prazo}-${item.meio}`,
      payment_term_id: item.prazo,
      payment_option_id: item.meio,
    }));

    dataDownload[SchemaModels.PAYMENT_OPTIONS] = paymentOptions;
    dataDownload[SchemaModels.PAYMENT_TERMS] = paymentTerms;
    dataDownload[SchemaModels.PAYMENT_COMBINE_KEYS] = paymentCombineKeys;
  } else {
    dataDownload[SchemaModels.PAYMENT_OPTIONS] = data;
    dataDownload[SchemaModels.PAYMENT_TERMS] = data;
    dataDownload[SchemaModels.PAYMENT_COMBINE_KEYS] = data;
  }

  return dataDownload;
}
