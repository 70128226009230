import React, { Component } from 'react';
import { FlatList, Image, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

import Images from '~/../assets/images';

type Props = {
  onEnter: any,
  searchHistory: string,
};

const Icon = ({ icon, onPress }) => (
  <View style={styles.rootIconContainer}>
    <TouchableOpacity onPress={onPress}>
      <View style={styles.iconContainer}>
        <Image style={styles.icon} source={icon} />
      </View>
    </TouchableOpacity>
  </View>
);

const IconHistory = ({ icon }) => (
  <View style={styles.rootIconContainer}>
    <View style={styles.iconContainer}>
      <Image style={styles.iconHistory} source={icon} />
    </View>
  </View>
);

const styleItem = StyleSheet.create({
  list: {
    paddingHorizontal: 0,
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 8,
    padding: 0,
  },
});

class History extends Component<Props> {
  search = search => () => {
    this.props.onEnter(search);
  };

  remove = index => () => {
    this.props.onRemove(index);
  };

  renderItem = ({ item, index }) => (
    <TouchableOpacity onPress={this.search(item.text)}>
      <View style={styleItem.listItem}>
        <View style={{ flexDirection: 'row', flexGrow: 2 }}>
          <IconHistory icon={Images.iconHistory} style={styles.iconHistory} />
          <Text style={{ marginLeft: 4, fontSize: 16, padding: 10 }}>{item.text}</Text>
        </View>
        <Icon icon={Images.iconClose} style={styles.iconClose} onPress={this.remove(index)} />
      </View>
    </TouchableOpacity>
  );

  keyExtractor = (item, index) => `${item.id}-${index}`

  render() {
    return (
      <View>
        <FlatList
          style={{ marginTop: 14 }}
          contentContainerStyle={styleItem.list}
          data={this.props.searchHistory}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
        />
      </View>
    );
  }
}

export default History;
