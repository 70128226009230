// @flow

import React from 'react';
import { Image, Text, View } from 'react-native';
import { Touchable } from 'react-native-web';
import Images from '~/../assets/images';

import styles from './styles';

export type InstallmentCardProps<ItemT> = {
  onPress: (data: ItemT) => void,
};

const InstallmentCard = ({
  onPress, installmentSelected,
} : InstallmentCardProps) => {
  return (
    <Touchable
      onPress={() => onPress()}
    >
      <View style={styles.container}>
        <Image
          style={styles.icon}
          source={Images.iconWallet}
        />
        <Text style={styles.text}>
          Selecionar Parcelamento: <Text style={styles.textStore}>{installmentSelected.description}</Text>
        </Text>
        <Image
          style={styles.icon}
          source={Images.iconChevronRight}
        />
      </View>
    </Touchable>
  );
};

export default InstallmentCard;
