import CacheStore from '~/modules-wrapper/react-native-cache-store';
import get from 'lodash/get';
import { DATA_ACCOUNT } from '~/constants/cache';

class AccountController {
  static async isAnonymous() {
    const account = await CacheStore.get(DATA_ACCOUNT) || {};
    const email = get(account, 'user.email', '');

    console.log('AccountController isAnonymous email', email);
    console.log('AccountController isAnonymous email', email.includes('@tempuser.com'));

    return email.includes('@tempuser.com');
  }
}

export default AccountController;
