/* @flow */

import React, { Component } from 'react';
import { Platform, StyleSheet } from 'react-native';
// import Icon from 'react-native-vector-icons/MaterialIcons';
import { colors, sizes } from '~/values';
import Input from './unform/Input';

const Password = ({onChange}) => {
  // state = {
  //   isPassword: true,
  //   eyeIcon: 'visibility-off',
  //   iconSize: sizes.textBig,
  //   iconColor: colors.grayTextInput,
  // };

  // changePwdType = () => {
  //   const { isPassword } = this.state;
  //
  //   this.setState({
  //     eyeIcon: isPassword ? 'visibility' : 'visibility-off',
  //     isPassword: !isPassword,
  //   });
  // };
  //
  // onChange = (entry: string) => {
  //   console.log('PWD: ', entry.target.value);
  //   this.props.onSuccess(entry);
  // };

  return (
    <Input
      onChange={onChange}
      type={'password'}
      label={'Senha'} name={'password'}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    position: 'absolute',
    bottom: 45,
    right: 0,
  }
});


Password.defaultProps = {
  onBlur: () => {
  },
  onError: () => {
  },
  onSuccess: () => {
  },
};

export default Password;

/*<Icon
    style={styles.icon}
    name={eyeIcon}
    size={iconSize}
    color={iconColor}
    onPress={this.changePwdType}
  />*/
