import { Modal, ScrollView, StyleSheet, View } from 'react-native';
import { Box } from '~/components';
import SimpleOption from '~/components/SimpleOption';
import React from 'react';
import { sizes } from '~/values';

type Props = {
  modalTitle: string,
  options: [],
  isVisible: boolean,
  onPress: () => {},
  onClose: () => {},
  onPressItem: (company: {}) => {},
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    paddingHorizontal: sizes.horizontalSpacing,
  },
  paymentSlipOptionsContainer: {
    marginTop: sizes.horizontalSpacing,
    maxHeight: 250,
  },
});

class PickerModal extends React.Component<Props, void> {
  getOptions(options, onPressItem) {
    const optionsView = [];
    let i = 0;
    options.forEach(option => {
      optionsView.push(<SimpleOption key={i} text={option.text} onPress={() => onPressItem(option)} />);
      i += 1;
    });
    return optionsView;
  }

  render() {
    const {
      modalTitle, options, isVisible, onClose, onPressItem,
    } = this.props;

    return (
      <Modal visible={isVisible} animated transparent>
        <View style={styles.modalContainer}>
          <View>
            <Box title={modalTitle} onClose={onClose}>
              <View style={styles.paymentSlipOptionsContainer}>
                <ScrollView>
                  <View>{this.getOptions(options, onPressItem)}</View>
                </ScrollView>
              </View>
            </Box>
          </View>
        </View>
      </Modal>
    );
  }
}

export default PickerModal;
