import React, { Component } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { KeyboardAwareScrollView } from '~/modules-wrapper/react-native-keyboard-aware-scroll-view';

import { Button, Input, Section } from '~/components';
import styles from '~/screens/Login/styles';
import colors from '~/values/color';
import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';
import AppConfig from '../../AppConfig';
import RBSheet from '~/modules-wrapper/react-native-raw-bottom-sheet';
import listStates from '~/services/states';
import RBSheetListItems from '~/components/RBSheetListItems';
import { sizes } from '~/values';
import Images from '~/../assets/images';
import { USER_GENRE } from '~/constants/cache';
import { Form } from '@unform/web';
import InputWeb from '~/components/Input/unform/InputWeb';
import ProgressView from '~/components/ProgressView';

type Props = {
  onPressSave: () => {},
  logging: boolean,
  isUpdate: boolean,
  form: Object,
};

const styles2 = StyleSheet.create({
  listContainer: {
    flex: 1,
    padding: 25,
  },
  listTitle: {
    fontSize: 16,
    marginBottom: 20,
    color: '#666',
    fontWeight: '600',
  },
  listButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  listIcon: {
    fontSize: 26,
    color: '#666',
    width: 60,
  },
  listLabel: {
    fontSize: 16,
  },
});

class SignUp extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      cities: [],
      stateSelected: null,
      citySelected: null,
      loadingState: false,
    };
    this.myRef = React.createRef();
  }

  onPressSave = () => {
    if (this.props.onPressSave) this.props.onPressSave(this.state);
  };

  onEntrySuccess(entry: {}) {
    this.setState({
      ...entry,
    });
  }

  componentDidMount() {
    this.loadStates();
    console.log('this.props.form', this.props.form);
  }

  async UNSAFE_componentWillMount() {
    console.log('WILL UMOUNT');
    console.log('WILL UNMOUNT props: ', this.props);
    if (this.props.isUpdate) {
      const data = await AsyncStorage.getItem(USER_GENRE);
      const genre = data ? JSON.parse(data) : '';
      if (genre && genre.title) {
        this.setState({ genre: genre.title });
      }
    }
  }

  loadStates = async () => {
    this.setState({
      loadingState: true,
    });

    try {
      const states = await listStates();

      this.setState({
        states,
      });
    } catch (e) {

    } finally {
      this.setState({
        loadingState: false,
      });
    }
  }

  setRequired = fieldId => {
    const { fields } = AppConfig.screenSettings.register_settings;
    const field = fields.find(({ id }) => id === fieldId);
    return field.required;
  }

  renderLabel = label => {
    const { fields } = AppConfig.screenSettings.register_settings;
    const field = find(fields, { description: label });

    let name = `${label}`;
    if (field.required) {
      name = `${label} *`;
    }

    return name;
  }

  handleSubmit(data) {
    console.log('data: ', data);
    console.log('this.myRef: ', this.myRef.current);
  }

  render() {
    const { logging, isUpdate, form } = this.props;
    const {
      loadingState, stateSelected, citySelected, genre,
    } = this.state;
    const { acceptCNPJ } = AppConfig.screenSettings.register_settings;
    return (
      // <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
        <View>
          <RBSheetListItems
            showSearch
            setRef={(ref) => {
              this.RBSheetState = ref;
            }}
            renderLabel={(item) => <Text style={styles.listLabel}>{`${item.name} (${item.uf})`}</Text>}
            onSelectOption={(item) => {
              this.onEntrySuccess({ state: item.uf });
              this.setState({
                cities: item.cities,
                stateSelected: item,
                citySelected: null,
              });

              this.RBSheetState.close();
            }}
            items={this.state.states}
            title="Estados"
          />

          <RBSheetListItems
            showSearch
            setRef={(ref) => {
              this.RBSheetCity = ref;
            }}
            onSelectOption={(item) => {
              this.onEntrySuccess({ city: item.name });
              this.setState({
                citySelected: item,
              });

              this.RBSheetCity.close();
            }}
            items={this.state.stateSelected ? this.state.stateSelected.cities : []}
            title="Cidades"
          />

          <RBSheetListItems
            showSearch={false}
            height={220}
            setRef={(ref) => {
              this.RBSheetGenre = ref;
            }}
            renderLabel={({ title = '' }) => <Text style={styles.listLabel}>{`${title}`}</Text>}
            onSelectOption={async (item) => {
              await AsyncStorage.setItem(USER_GENRE, JSON.stringify(item));
              this.setState({
                genre: item.title,
              });

              this.RBSheetGenre.close();
            }}
            items={[
              { title: 'Masculino', id: 0 },
              { title: 'Feminino', id: 1 },
              { title: 'Prefiro não dizer', id: 2 },
            ]}
            title="Gênero"
          />

          {
            !isUpdate && (
              <Section title="Identidade">
                {/*<Input.PersonDocument*/}
                {/*  label={acceptCNPJ ? 'CPF/CNPJ *' : 'CPF'}*/}
                {/*  required={this.setRequired('name')}*/}
                {/*  placeholder={acceptCNPJ ? 'CPF/CNPJ *' : 'CPF'}*/}
                {/*  onChange={(personDocument) => this.onEntrySuccess({ personDocument })}*/}
                {/*  value={isUpdate ? form.nin || '' : ''}*/}
                {/*  acceptCNPJ={acceptCNPJ}*/}
                {/*/>*/}
              </Section>
            )
          }
          <Section title="Informações básicas">
            {/*<Input*/}
            {/*  label="Seu nome *"*/}
            {/*  required={this.setRequired('name')}*/}
            {/*  onChange={(name) => this.onEntrySuccess({ name })}*/}
            {/*  value={isUpdate ? form.name || '' : ''}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  label="Nome de exibição *"*/}
            {/*  required={this.setRequired('exhibitionName')}*/}
            {/*  onChange={(exhibitionName) => this.onEntrySuccess({ exhibitionName })}*/}
            {/*  value={isUpdate ? form.display_name || '' : ''}*/}
            {/*/>*/}
            <View style={{
              marginBottom: 20,
            }}
            >
              { genre && <Text>Gênero</Text> }
              <TouchableOpacity
                onPress={() => this.RBSheetGenre.open()}
              >
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                >
                  <Text style={{ fontSize: 16, color: '#000' }}>
                    {genre || 'Gênero'}
                  </Text>
                  <Image
                    style={{
                      width: 15,
                      height: 15,
                    }}
                    source={Images.iconChevronDown}
                  />
                </View>
              </TouchableOpacity>
              <View style={{
                height: 1,
                marginTop: 5,
                backgroundColor: colors.black,
              }}
              />
            </View>
            {/*<Input.Date*/}
            {/*  label="Data de nascimento (Opcional)"*/}
            {/*  placeholder="XX/XX/XXXX"*/}
            {/*  onChange={(birthday) => this.onEntrySuccess({ birthday })}*/}
            {/*  value={isUpdate ? moment(form.birthday, 'YYYY/MM/DD').format('DD/MM/YYYY') || '' : ''}*/}
            {/*/>*/}
            {/*{!isUpdate && (*/}
            {/*  <Input.Email*/}
            {/*    label="Email *"*/}
            {/*    onChange={(email) => this.onEntrySuccess({ email })}*/}
            {/*  />*/}
            {/*)}*/}
            {/*<Input.Phone*/}
            {/*  label="Celular *"*/}
            {/*  onSuccess={(phone) => this.onEntrySuccess({ phone })}*/}
            {/*  value={isUpdate ? form.phones.main || '' : ''}*/}
            {/*  isPhone*/}
            {/*  format="(XX) XXXXX-XXXX"*/}
            {/*/>*/}
            {/*<Input.Phone*/}
            {/*  required={this.setRequired('landline')}*/}
            {/*  label={this.renderLabel('Telefone fixo')}*/}
            {/*  onSuccess={(landline) => this.onEntrySuccess({ landline })}*/}
            {/*  value={isUpdate ? form.phones.secondary || '' : ''}*/}
            {/*  isLandline*/}
            {/*  format="(XX) XXXX-XXXX"*/}
            {/*/>*/}
          </Section>
          {!isUpdate && (
            <Section title="Senha *">
              {/*<Input.Password*/}
              {/*  label="Senha"*/}
              {/*  onSuccess={(password) => this.onEntrySuccess({ password })}*/}
              {/*/>*/}
              {/*<Input.Password*/}
              {/*  label="Confirmar senha *"*/}
              {/*  onSuccess={(confirmationPassword) => this.onEntrySuccess({ confirmationPassword })}*/}
              {/*/>*/}
            </Section>
          )}
          <Form ref={this.myRef} initialData={initialData} onSubmit={this.handleSubmit}>
            <InputWeb name="name" />
            <InputWeb type="email" name="email" />

            <InputWeb  name="address.street" />
            <InputWeb  name="address.number" />

            <button type="submit">Enviar</button>
          </Form>
          <Section title="Endereço">
            {/*<Input.ZipCode*/}
            {/*  required={this.setRequired('zipCode')}*/}
            {/*  label={this.renderLabel('CEP')}*/}
            {/*  onSuccess={(zipCode) => this.onEntrySuccess({ zipCode })}*/}
            {/*  value={isUpdate ? get(form, 'address.postal_code', '') : ''}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  required={this.setRequired('street')}*/}
            {/*  label={this.renderLabel('Rua')}*/}
            {/*  onChange={(street) => this.onEntrySuccess({ street })}*/}
            {/*  value={isUpdate ? get(form, 'address.address', '') : ''}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  required={this.setRequired('addressNumber')}*/}
            {/*  label={this.renderLabel('Número')}*/}
            {/*  keyboardType="numeric"*/}
            {/*  onChange={(addressNumber) => this.onEntrySuccess({ addressNumber })}*/}
            {/*  value={isUpdate ? get(form, 'address.number', '') : ''}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  label={this.renderLabel('Bairro')}*/}
            {/*  required={this.setRequired('district')}*/}
            {/*  onChange={(district) => this.onEntrySuccess({ district })}*/}
            {/*  value={isUpdate ? get(form, 'address.district', '') : ''}*/}
            {/*/>*/}
            {/*<View>*/}
            {/*  <Text>Estado</Text>*/}

            {/*  <TouchableOpacity*/}
            {/*    onPress={() => this.RBSheetState.open()}*/}
            {/*    disabled={loadingState}*/}
            {/*  >*/}
            {/*    <View style={{*/}
            {/*      flexDirection: 'row',*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'space-between',*/}
            {/*    }}*/}
            {/*    >*/}
            {/*      <Text style={{*/}
            {/*        marginTop: 5,*/}
            {/*      }}*/}
            {/*      >*/}
            {/*        {stateSelected ? `${stateSelected.name} (${stateSelected.uf})` : isUpdate ? get(form, 'address.state', '') : (!stateSelected && !isUpdate) ? '--- Selecione o Estado ---' : ''}*/}
            {/*      </Text>*/}
            {/*      <Image*/}
            {/*        style={{*/}
            {/*          width: 15,*/}
            {/*          height: 15,*/}
            {/*        }}*/}
            {/*        source={Images.iconChevronDown}*/}
            {/*      />*/}
            {/*    </View>*/}
            {/*  </TouchableOpacity>*/}
            {/*  <View style={{*/}
            {/*    height: 1,*/}
            {/*    marginTop: 5,*/}
            {/*    backgroundColor: colors.black,*/}
            {/*  }}*/}
            {/*  />*/}
            {/*</View>*/}

            {/*<View style={{*/}
            {/*  marginTop: 20,*/}
            {/*  marginBottom: 20,*/}
            {/*}}*/}
            {/*>*/}
            {/*  <Text>Cidade</Text>*/}

            {/*  <TouchableOpacity*/}
            {/*    onPress={() => this.RBSheetCity.open()}*/}
            {/*    disabled={loadingState || !stateSelected}*/}
            {/*  >*/}
            {/*    <View style={{*/}
            {/*      flexDirection: 'row',*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'space-between',*/}
            {/*    }}*/}
            {/*    >*/}
            {/*      <Text style={{*/}
            {/*        marginTop: 5,*/}
            {/*      }}*/}
            {/*      >*/}
            {/*        {citySelected ? citySelected.name : isUpdate ? get(form, 'address.city', '') : '--- Selecione a Cidade ---'}*/}
            {/*      </Text>*/}
            {/*      <Image*/}
            {/*        style={{*/}
            {/*          width: 15,*/}
            {/*          height: 15,*/}
            {/*        }}*/}
            {/*        source={Images.iconChevronDown}*/}
            {/*      />*/}
            {/*    </View>*/}
            {/*  </TouchableOpacity>*/}
            {/*  <View style={{*/}
            {/*    height: 1,*/}
            {/*    marginTop: 5,*/}
            {/*    backgroundColor: colors.black,*/}
            {/*  }}*/}
            {/*  />*/}
            {/*</View>*/}
            {/* <Input */}
            {/*  label={this.renderLabel('Estado')} */}
            {/*  required={this.setRequired('state')} */}
            {/*  onChange={(state) => this.onEntrySuccess({ state })} */}
            {/*  value={isUpdate ? get(form, 'address.state', '') : ''} */}
            {/* /> */}
            {/* <Input */}
            {/*  required={this.setRequired('city')} */}
            {/*  label={this.renderLabel('Cidade')} */}
            {/*  onChange={(city) => this.onEntrySuccess({ city })} */}
            {/*  value={isUpdate ? get(form, 'address.city', '') : ''} */}
            {/* /> */}
            <Input
              required={this.setRequired('complement')}
              label={this.renderLabel('Complemento')}
              onChange={(complement) => this.onEntrySuccess({ complement })}
              value={isUpdate ? get(form, 'address.complement', '') : ''}
            />
          </Section>
          <Button
            title={logging ? 'Salvando...' : 'Salvar'}
            big
            containerStyle={{ margin: 16 }}
            style={[
              styles.loginButton,
              logging ? { backgroundColor: colors.grayDark } : styles.loginButton,
            ]}
            onPress={this.onPressSave}
          />
        </View>
      // </KeyboardAwareScrollView>
    );
  }
}

export default SignUp;
