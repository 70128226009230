import React from 'react';
import { View, Text } from 'react-native';
import {Placeholder, PlaceholderMedia } from 'rn-placeholder';

import colors from '~/values/color';

const backgroundGray = {
  backgroundColor: colors.gray,
};

const homeBannersItemStyle = {
  ...backgroundGray,
  height: 200,
  width: '100%',
  padding: 40,
  borderRadius: 20,
};

export default function HomeBanner() {
  return (
    <Placeholder animation="fade">
      <View>
        <Text style={{ textAlign: 'center' }}>Carregando seus dados...</Text>
        <PlaceholderMedia hasRadius style={homeBannersItemStyle} />
      </View>
    </Placeholder>
  );
}
